<template>
    <v-sheet theme="light">
        <v-container class="w-100" style="max-width: 100%;">
            <v-layout>
                <v-hover
                    v-slot:default="{ props }"
                    v-model="isHovering"
                >
                    <v-navigation-drawer
                        v-if="this.userPermissions.general"
                        v-bind="props"
                        v-model="drawer"
                        class="navbar-nav d-flex align-items-center"
                        style="z-index: 1100 !important;"
                        :floating="this.$vuetify.display.mdAndDown"
                        :permanent="this.$vuetify.display.mdAndUp"
                        :rail="railWatcher"
                    >
                        <side-panel :rail="railWatcher" @hide-nav-drawer="toggleHideMenu"/>
                    </v-navigation-drawer>
                </v-hover>
                <v-main ref="mainContent" @click="rail = true">
                    <BreadCrumbs
                        v-if="shouldDisplayBreadCrumbs()"
                    />
                    <router-view/>
                    <AlertModal></AlertModal>
                </v-main>
            </v-layout>
        </v-container>
    </v-sheet>
</template>

<script>
import SidePanel from "@/components/common/SidePanel.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import AlertModal from "@/components/common/AlertModal.vue";
import {mapGetters} from "vuex";

export default {
    name: "MainLayout",
    components: {AlertModal, SidePanel, BreadCrumbs},
    data() {
        return {
            drawer: true,
            rail: false,
            isNavOpen: true,
            isHovering: false,
        };
    },
    computed: {
        railWatcher() {
            return !(this.isHovering || this.isNavOpen);
        },
        isMobile() {
            return this.$vuetify.display.mdAndDown;
        },
        ...mapGetters(['userPermissions', 'sideMenuToggle', 'userRole']),
    },
    methods: {
        /**
         * Determines whether the BreadCrumbs component should be displayed.
         * BreadCrumbs will display only if:
         * - The user has `canViewLiveApp` permissions.
         * - The screen size is `mdAndDown` for mobile conditions.
         * - The current route name is not in the excluded routes.
         * - When on mobile, `ClientList` and `IntermediateList` are allowed.
         *
         * @returns {boolean} - Returns true if BreadCrumbs should be shown; otherwise, false.
         */
        shouldDisplayBreadCrumbs() {
            const excludedRoutes = [
                "OfferVMC",
                "OfferSimple",
                "OfferPlumbing",
                "OfferTechnicalRoom",
                "OfferCeiling",
                "OfferWalls",
                "OfferAutomation",
                "OfferFanCoilUnit",
                "OfferThermalChannel",
                "OfferPDC",
                "OffersSummary",
                "OfferVacuum",
                "OfferServices",
                "ReturnCreate",
                "GreenHouseSolarClientCreate",
            ];

            // Exclude ClientList and IntermediateList only on larger screens
            if (!this.isMobile) {
                excludedRoutes.push("ClientList", "IntermediateList");
            }

            return (
                this.userPermissions.general &&
                this.userPermissions.general.canViewLiveApp &&
                !excludedRoutes.includes(this.$route.name)
            );
        },
        toggleHideMenu(value) {
            this.isNavOpen = value;
        },
        setOverlayHeight() {
            if (this.isMobile && this.drawer) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        const appHeight = document.getElementById("app")?.offsetHeight;
                        const overlay = document.querySelector(".v-navigation-drawer__scrim");

                        if (overlay && appHeight) {
                            overlay.style.setProperty("height", `${appHeight}px`, "important");
                        }
                    }, 50); // Delay to ensure overlay is rendered
                });
            }
        },
        resetOverlayHeight() {
            const overlay = document.querySelector(".v-navigation-drawer__scrim");
            if (overlay) {
                overlay.style.height = ""; // Reset to default
            }
        },
    },
    watch: {
        drawer(newVal) {
            if (newVal) {
                this.setOverlayHeight();
            } else {
                this.resetOverlayHeight();
            }
        },
        sideMenuToggle: {
            handler() {
                this.drawer = true;
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.setOverlayHeight);
        this.setOverlayHeight();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.setOverlayHeight);
    }
};
</script>
<style>
.content-container {
    padding: 0 2rem;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}
</style>
