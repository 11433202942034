<template>
    <BreadCrumbsWithButtons :buttons="buttons"/>
    <ClientContract v-if="client.id" :client="client"/>
    <v-card class="mt-3" style="z-index: 999; overflow: visible">
        <v-toolbar>
            <v-toolbar-title>
                {{ isEditing ? "Editare Client" : "Adăugare Client" }}
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form ref="form" v-model="isValid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6" lg="3">
                            <v-autocomplete
                                v-model="client.agent"
                                :items="myUsers"
                                label="Agent"
                                variant="outlined"
                                density="compact"
                                item-title="full_name"
                                item-value="id"
                                hide-details
                                clearable
                                @update:modelValue="onUserChange"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.first_name"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Prenume"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.last_name"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Nume"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                variant="outlined"
                                label="Serie buletin"
                                density="compact"
                                v-model="client.id_card_series"
                                :rules="[rules.id_card_series]"
                                :hide-details="isIdCardSeriesValid"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                variant="outlined"
                                label="Număr buletin"
                                density="compact"
                                v-model="client.id_card_number"
                                :rules="[rules.id_card_number]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                label="CNP"
                                variant="outlined"
                                density="compact"
                                v-model="client.cnp"
                                :rules="[rules.required, rules.cnp]"
                                :hide-details="isCnpValid"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-autocomplete
                                clearable
                                label="Județ"
                                variant="outlined"
                                density="compact"
                                :items="regions"
                                v-model="client.region"
                                item-title="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :hide-details="isRegionValid"
                                @update:modelValue="onRegionChange"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-combobox
                                clearable
                                label="Localitate"
                                variant="outlined"
                                density="compact"
                                :items="filteredCities"
                                v-model="client.city"
                                item-title="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :hide-details="isCityValid"
                                @update:modelValue="handleCityChange"
                                allow-new
                                new-item-text="Adaugă noua localitate"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                label="Strada"
                                variant="outlined"
                                density="compact"
                                v-model="client.street"
                                :rules="[rules.required]"
                                :hide-details="isStreetValid"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="1">
                            <v-text-field
                                label="Nr."
                                variant="outlined"
                                density="compact"
                                v-model="client.number"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field
                                label="Email"
                                variant="outlined"
                                density="compact"
                                v-model="client.email"
                                :rules="[rules.email]"
                                :hide-details="isEmailValid"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                label="Telefon"
                                variant="outlined"
                                density="compact"
                                v-model="client.phone"
                                :rules="[rules.phone]"
                                :hide-details="isPhoneValid"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-textarea
                                v-model="client.notes"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Note"
                                rows="1"
                                auto-grow
                            />
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.beneficiary_code_afm"
                                label="Cod Beneficiar AFM"
                                variant="outlined"
                                density="compact"
                                hide-details
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.file_number_afm"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Nr. dosar AFM"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <div class="d-flex flex-row align-center flex-wrap">
                                <v-menu
                                    v-model="applicationDateMenu"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data înscriere în aplicație"
                                            readonly
                                            variant="outlined"
                                            density="compact"
                                            dirty
                                            hide-details
                                        >
                                            {{ formatDate(client.application_date) }}
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="client.application_date"
                                        @update:modelValue="applicationDateMenu = false"
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <div class="d-flex flex-row align-center flex-wrap position-relative">
                                <v-menu
                                    v-model="cfDateMenu"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data CF"
                                            readonly
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            dirty
                                        >
                                            {{ formatDate(client.cf_date) }}
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="client.cf_date"
                                        @update:modelValue="cfDateMenu = false"
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <div class="d-flex flex-row align-center flex-wrap position-relative">
                                <v-menu
                                    v-model="identificationExpirationDateMenu"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data expirare buletin"
                                            readonly
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            dirty
                                        >
                                            {{ formatDate(client.identification_expiration_date) }}
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="client.identification_expiration_date"
                                        @update:modelValue="identificationExpirationDateMenu = false"
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <div class="d-flex flex-row align-center flex-wrap position-relative">
                                <v-menu
                                    v-model="fiscalCertificateLocalDateMenu"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data Certificat atestare fiscală - bugetul local"
                                            readonly
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            dirty
                                        >
                                            {{ formatDate(client.fiscal_certificate_local_date) }}
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="client.fiscal_certificate_local_date"
                                        @update:modelValue="fiscalCertificateLocalDateMenu = false"
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <div class="d-flex flex-row align-center flex-wrap position-relative">
                                <v-menu
                                    v-model="fiscalCertificateStateDateMenu"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data Certificat atestare fiscală - bugetul de stat"
                                            readonly
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            dirty
                                        >
                                            {{ formatDate(client.fiscal_certificate_state_date) }}
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="client.fiscal_certificate_state_date"
                                        @update:modelValue="fiscalCertificateStateDateMenu = false"
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <div class="d-flex flex-row align-center flex-wrap position-relative">
                                <v-menu
                                    v-model="collectiveLandRegistryDateMenu"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data Extras de carte funciară colectiv"
                                            readonly
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            dirty
                                        >
                                            {{ formatDate(client.collective_land_registry_date) }}
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="client.collective_land_registry_date"
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.connection"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Branșament"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="1">
                            <v-text-field
                                v-model="client.panel_count"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Nr. panouri"
                                type="number"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="1">
                            <v-text-field
                                v-model="client.panel_power"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Putere panouri"
                                type="number"
                                required
                                suffix="kW"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.inverter"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Invertor"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="1">
                            <v-text-field
                                v-model="client.inverter_power"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Putere invertor"
                                type="number"
                                required
                                suffix="kW"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.inverter_type"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Tip invertor"
                                required
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title>
                De încarcat (documente PDF)
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <DocumentManager
                :document-options="documentFields"
                :documents="documentFiles"
                @add-file="handleAddFile"
                @delete-file="handleDeleteFile"
                @view-document="handleViewDocument"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import DocumentManager from "@/components/common/DocumentManager.vue";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";
import ClientContract from "@/components/clients/ClientContract.vue";

export default {
    name: "GreenHouseSolarClientCreate",
    components: {
        ClientContract,
        BreadCrumbsWithButtons,
        DocumentManager,
    },
    props: {
        clientId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            client: this.getEmptyClient(),
            isValid: false,
            isEditing: false,
            rules: {
                required: value => !!value || 'Câmp obligatoriu',
                cnp: () => this.validateCnp(),
                phone: () => this.validatePhone(),
                email: () => this.validateEmail(),
                id_card_series: () => this.validateIdCardSeries(),
                id_card_number: () => this.validateIdCardNumber(),
            },
            buttons: [
                {
                    text: "Anulează",
                    onClick: this.cancel,
                    disabled: () => false,
                    color: 'danger',
                    visible: () => true,
                },
                {
                    text: "Salvează",
                    onClick: this.submit,
                    disabled: () => false,
                    color: 'success',
                    visible: () => true,
                },
            ],
            regions: [],
            subregions: [],
            cities: [],
            documentFiles: {
                id_card: [],
                fiscal_certificate_local: [],
                fiscal_certificate_state: [],
                land_registry: [],
                collective_land_registry: [],
                offer: [],
                proforma: [],
                advance_invoice: [],
                goods_delivery_annex: [],
                connection_certificate: [],
                final_invoice: [],
            },
            documentFields: [
                {key: "id_card", label: "Carte de identitate"},
                {key: "fiscal_certificate_local", label: "Certificat de atestare fiscală - bugetul local"},
                {key: "fiscal_certificate_state", label: "Certificat de atestare fiscală - bugetul de stat"},
                {key: "land_registry", label: "Extras de cartea funciară"},
                {key: "collective_land_registry", label: "Extras de carte funciară colectiv"},
                {key: "offer", label: "Oferta"},
                {key: "proforma", label: "Proforma"},
                {key: "advance_invoice", label: "Factura avans"},
                {key: "goods_delivery_annex", label: "Anexa marfa timisa"},
                {key: "connection_certificate", label: "Certificat de racordare"},
                {key: "final_invoice", label: "Factura finală"},
            ],
            contentTypes: null,
            attachmentsToDelete: [],
            myUsers: [],
            applicationDateMenu: false,
            cfDateMenu: false,
            identificationExpirationDateMenu: false,
            fiscalCertificateLocalDateMenu: false,
            fiscalCertificateStateDateMenu: false,
            collectiveLandRegistryDateMenu: false,
        };
    },
    computed: {
        /**
         * Validates the client's CNP (national identification number) based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's CNP is valid; otherwise, false.
         */
        isCnpValid() {
            return this.rules.cnp(this.client.cnp) === true;
        },
        /**
         * Checks if the client's region field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's region is selected; otherwise, false.
         */
        isRegionValid() {
            return this.rules.required(this.client.region) === true;
        },
        /**
         * Checks if the client's city field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's city is selected; otherwise, false.
         */
        isCityValid() {
            return this.rules.required(this.client.city) === true;
        },
        /**
         * Checks if the client's street field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's street field is valid; otherwise, false.
         */
        isStreetValid() {
            return this.rules.required(this.client.street) === true;
        },
        /**
         * Checks if the client's email is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's email is valid; otherwise, false.
         */
        isEmailValid() {
            return this.rules.email() === true;
        },
        /** Checks if the client's phone number is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's phone number is valid; otherwise, false.
         */
        isPhoneValid() {
            return this.rules.phone() === true;
        },
        /** Checks if the client's id_card_series is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's id_card_series is valid; otherwise, false.
         */
        isIdCardSeriesValid() {
            return this.rules.id_card_series() === true;
        },
        /**
         * Filters the list of cities based on the selected region.
         * @computed
         * @returns {Array<Object>} - A filtered list of cities that match the selected region.
         */
        filteredCities() {
            if (!this.client.region) {
                return [];
            }
            return this.cities.filter(city => city.region_id === this.client.region);
        },
    },
    async mounted() {
        await this.loadRegionsAndCities();
        await this.loadMyUsers();
        if (this.clientId) {
            await this.fetchClient();
        } else {
            this.client.agent = JSON.parse(sessionStorage.getItem('userId'));
            this.initializeDates();
        }
    },
    methods: {
        /**
         * Formats a given Date object into a "dd/mm/yyyy" format string.
         *
         * @param {Date} date - The date object to format.
         * @returns {string} - The formatted date string, or an empty string if invalid.
         */
        formatDate(date) {
            if (!date) return "";
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        /**
         * Parses an ISO date string into a JavaScript Date object.
         * Returns null if the input is invalid or null.
         *
         * @param {string} date - The ISO date string to parse.
         * @returns {Date|null} - The parsed Date object or null.
         */
        parseDateFromISO(date) {
            return date ? new Date(date) : null;
        },
        /**
         * Initializes date fields in the client object to the current date.
         * This is useful for setting default values for date-related fields.
         */
        initializeDates() {
            this.client.application_date = new Date();
            this.client.cf_date = new Date();
            this.client.identification_expiration_date = new Date();
            this.client.fiscal_certificate_local_date = new Date();
            this.client.fiscal_certificate_state_date = new Date();
            this.client.collective_land_registry_date = new Date();
        },
        /**
         * Handles the selection of a new user for the client, updating the client's associated user ID.
         * @param {number} newUserId - The ID of the newly selected user.
         * @method
         */
        onUserChange(newUserId) {
            this.client.agent = newUserId;
        },
        /**
         * Loads the list of users who are agents, storing them in the `myUsers` array.
         * @method
         */
        loadMyUsers() {
            // Retrieve the list of users from session storage
            const users = JSON.parse(sessionStorage.getItem('myUsers')) || [];
            this.myUsers = users;
        },
        /**
         * Handles changes in the client's city selection, differentiating between selecting an existing city and creating a new one.
         * @param {(Object|string)} value - The selected city object or a custom city name string.
         * @method
         */
        handleCityChange(value) {
            // If the value is an object (existing city)
            if (typeof value === 'object') {
                // Assign the city ID or name based on your backend expectations
                this.client.city = value?.name;
            }
            // If the value is a string (new custom city)
            else if (typeof value === 'string') {
                this.client.city = value;
            }
        },
        /**
         * Resets the client's selected city when the region changes.
         * @method
         */
        onRegionChange() {
            // Reset city when region changes
            this.client.city = null;
        },
        /**
         * Loads regions and cities from an external JSON file, processing and sorting them for display.
         * @async
         * @method
         */
        async loadRegionsAndCities() {
            try {
                const response = await fetch("/cities.json");
                const data = await response.json();

                // Process regions and sort them alphabetically
                this.regions = data
                    .filter(item => item.model === "cities_light.region")
                    .map(item => ({
                        id: item.pk,
                        name: item.fields.name,
                        display_name: item.fields.display_name,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                // Prefixes to remove from city names
                const prefixesToRemove = ["Comuna", "Oras", "Municipiul"];

                // Process cities and remove prefixes
                this.cities = data
                    .filter(item => item.model === "cities_light.subregion")
                    .map(item => {
                        let {name, display_name} = item.fields;

                        // Remove specified prefixes from name and display_name
                        prefixesToRemove.forEach(prefix => {
                            const regex = new RegExp(`^${prefix}\\s+`, 'i');
                            name = name.replace(regex, '');
                            display_name = display_name.replace(regex, '');
                        });

                        return {
                            id: item.pk,
                            name: name,
                            display_name: display_name,
                            region_id: item.fields.region,
                        };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                console.error("Error loading regions and cities:", error);
            }
        },
        getEmptyClient() {
            return {
                beneficiary_code_afm: "",
                file_number_afm: "",
                application_date: "",
                cf_date: "",
                identification_expiration_date: "",
                fiscal_certificate_local_date: "",
                fiscal_certificate_state_date: "",
                collective_land_registry_date: "",
                first_name: "",
                last_name: "",
                id_card_series: "",
                id_card_number: "",
                cnp: "",
                region: "",
                city: "",
                street: "",
                number: "",
                email: "",
                phone: "",
                notes: "",
                connection: "",
                panel_count: "",
                panel_power: "",
                inverter: "",
                inverter_power: "",
                inverter_type: "",
            };
        },
        /**
         * Converts a date to ISO format if it exists.
         * @param {string | Date | null} date - The date to convert.
         * @returns {string | null} - The ISO string or null if the date is invalid.
         */
        toISODate(date) {
            if (!date) return null;
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const day = String(d.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        /**
         * Fetches client data from the server based on the client ID.
         * Populates client fields and maps attachments to document fields.
         * Initializes editing state and dynamically maps attachments to their document types.
         *
         * @async
         */
        async fetchClient() {
            if (!this.clientId) return;
            try {
                const response = await apiClient.get(`/api/greenhouse-solar-clients/${this.clientId}/`);
                const clientData = response.data;

                // Assign client data
                this.client = clientData;

                this.client.application_date = this.parseDateFromISO(clientData.application_date);
                this.client.cf_date = this.parseDateFromISO(clientData.cf_date);
                this.client.identification_expiration_date = this.parseDateFromISO(clientData.identification_expiration_date);
                this.client.fiscal_certificate_local_date = this.parseDateFromISO(clientData.fiscal_certificate_local_date);
                this.client.fiscal_certificate_state_date = this.parseDateFromISO(clientData.fiscal_certificate_state_date);
                this.client.collective_land_registry_date = this.parseDateFromISO(clientData.collective_land_registry_date);

                // Initialize documentFiles mapping dynamically
                for (const key in this.documentFiles) {
                    this.documentFiles[key] = [];
                }

                // Map attachments to document fields if `document_type` exists
                if (clientData.attachments && clientData.attachments.length > 0) {
                    for (const attachment of clientData.attachments) {
                        if (attachment.document_type) {
                            const fieldKey = attachment.document_type; // Use document_type for field mapping
                            if (this.documentFiles[fieldKey]) {
                                this.documentFiles[fieldKey].push(attachment);
                            }
                        }
                    }
                }

                this.isEditing = true;
            } catch (error) {
                console.error("Error fetching client:", error);
            }
        },
        /**
         * Handles the addition of new files for a given document type.
         * Associates files with their document type and updates the `documentFiles` array.
         *
         * @param {Object} param - Contains the key (document type) and the array of files to add.
         * @param {string} param.key - The document type key.
         * @param {File[]} param.files - The files to add.
         */
        handleAddFile({key, files}) {
            if (!this.documentFiles[key]) {
                this.$set(this.documentFiles, key, []);
            }
            files.forEach(file => {
                // Attach document type to each file
                file.document_type = key;
                this.documentFiles[key].push(file);
            });
        },
        /**
         * Handles the deletion of a specific file from the UI and prepares it for backend deletion if necessary.
         *
         * @param {Object} param - Contains the document type and file to delete.
         * @param {string} param.documentType - The type of the document.
         * @param {Object} param.file - The file object to be removed.
         */
        handleDeleteFile({documentType, file}) {
            if (file.id) {
                // Add file to attachmentsToDelete
                this.attachmentsToDelete.push(file.id);
            }

            // Remove from UI regardless of deletion
            this.documentFiles[documentType] = this.documentFiles[documentType].filter(
                (existingFile) => existingFile !== file
            );
        },
        /**
         * Opens a specific document in a new browser tab for viewing.
         *
         * @param {Object} document - The document object containing the URL to open.
         */
        handleViewDocument(document) {
            // Open the document in a new tab or modal
            window.open(document.file_url, "_blank");
        },
        /**
         * Submits the client data to the backend for saving or updating.
         * Converts all date fields to ISO format and handles file uploads and attachments.
         *
         * @async
         */
        async submit() {
            try {
                // Ensure all date fields are converted to ISO format
                const formattedClient = {...this.client};
                formattedClient.application_date = this.toISODate(formattedClient.application_date);
                formattedClient.cf_date = this.toISODate(formattedClient.cf_date);
                formattedClient.identification_expiration_date = this.toISODate(formattedClient.identification_expiration_date);
                formattedClient.fiscal_certificate_local_date = this.toISODate(
                    formattedClient.fiscal_certificate_local_date
                );
                formattedClient.fiscal_certificate_state_date = this.toISODate(
                    formattedClient.fiscal_certificate_state_date
                );
                formattedClient.collective_land_registry_date = this.toISODate(
                    formattedClient.collective_land_registry_date
                );

                // Add attachments to delete
                if (this.attachmentsToDelete.length > 0) {
                    formattedClient.attachments_to_delete = this.attachmentsToDelete;
                }

                const url = this.isEditing
                    ? `/api/greenhouse-solar-clients/${this.clientId}/`
                    : "/api/greenhouse-solar-clients/";
                const method = this.isEditing ? "put" : "post";

                let response = await apiClient[method](url, formattedClient);

                await this.uploadFiles(response.data.id); // Handle file uploads
                this.$router.push({name: "GreenHouseSolarClientsList"}); // Navigate back
            } catch (error) {
                console.error("Error saving client:", error);
            }
        },
        /**
         * Uploads all associated files for a client to the backend.
         * Attaches document type and content type information for each file.
         *
         * @param {number} clientId - The ID of the client to associate the uploaded files with.
         * @async
         */
        async uploadFiles(clientId) {
            for (const field of this.documentFields) {
                const files = this.documentFiles[field.key]?.filter(file => file instanceof File); // Only new files
                if (files && files.length > 0) {
                    const formData = new FormData();
                    const contentTypeId = await this.getContentTypeId("greenhousesolarclient");
                    formData.append("content_type", contentTypeId);
                    formData.append("object_id", this.clientId ? this.clientId : clientId);

                    files.forEach(file => {
                        formData.append("files", file);
                        formData.append("document_type", field.key); // Add document type for each file
                    });

                    try {
                        await apiClient.post(`/api/attachments/upload/`, formData, {
                            headers: {"Content-Type": "multipart/form-data"},
                        });
                    } catch (error) {
                        console.error(`Error uploading files for ${field.label}:`, error);
                    }
                }
            }
        },
        /**
         * Retrieves the content type ID for a given model name.
         * Used to identify the content type when uploading files.
         *
         * @param {string} modelName - The name of the model to fetch the content type ID for.
         * @returns {number} - The content type ID for the specified model.
         * @async
         */
        async getContentTypeId(modelName) {
            if (!this.contentTypes) {
                const response = await apiClient.get("/api/content-types/");
                this.contentTypes = response.data;
            }
            return this.contentTypes[modelName.toLowerCase()];
        },
        /**
         * Cancels the current action and navigates the user back to the GreenHouse Solar Clients List view.
         */
        cancel() {
            this.$router.push({name: "GreenHouseSolarClientsList"});
        },
        /**
         * Validates the client's CNP (national identification number) based on format and length requirements.
         * @returns {(boolean|string)} - True if the CNP is valid, or an error message string if invalid.
         * @method
         */
        validateCnp() {
            const cnp = this.client.cnp;
            if (!cnp) {
                return 'Introduceți un CNP valid.';
            }
            if (cnp.length !== 13) {
                return 'CNP trebuie să aibă exact 13 caractere.';
            }
            if (!/^[1256]/.test(cnp)) {
                return 'CNP trebuie să înceapă cu 1, 2, 5 sau 6.';
            }
            return true;
        },
        /**
         * Validates the client's phone number based on length and formatting requirements.
         * @returns {(boolean|string)} - True if the phone number is valid, or an error message string if invalid.
         * @method
         */
        validatePhone() {
            if (
                (!/^\d{10}$/.test(this.client.phone)) &&
                (!/^\+\d{11}$/.test(this.client.phone))
            ) {
                return 'Introduceti un număr de telefon valid, format din 10 cifre sau incepand cu + si 11 cifre.';
            }
            return true;
        },
        /**
         * Validates the client's email address format using a regex pattern.
         * @returns {(boolean|string)} - True if the email is valid, or an error message string if invalid.
         * @method
         */
        validateEmail() {
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!pattern.test(this.client.email)) {
                return 'Introduceti o adresă de email validă.';
            }
            return true;
        },
        /**
         * Validates the client's ID card series to ensure it contains at least 2 letters.
         * Allows only alphabetic characters.
         *
         * @returns {boolean|string} - True if valid, or an error message if invalid.
         */
        validateIdCardSeries() {
            const series = this.client.id_card_series;
            if (!series || series.length < 2) {
                return 'Seria buletinului trebuie să conțină cel puțin 2 litere.';
            }
            if (!/^[A-Za-z]+$/.test(series)) {
                return 'Seria buletinului poate conține doar litere.';
            }
            return true;
        },
        /**
         * Validates the client's ID card number to ensure it contains exactly 6 digits.
         * Allows only numeric characters.
         *
         * @returns {boolean|string} - True if valid, or an error message if invalid.
         */
        validateIdCardNumber() {
            const number = this.client.id_card_number;
            if (!number || number.length !== 6) {
                return 'Numărul buletinului trebuie să aibă exact 6 cifre.';
            }
            if (!/^\d{6}$/.test(number)) {
                return 'Numărul buletinului trebuie să conțină doar cifre.';
            }
            return true;
        },
    },
};
</script>

<style scoped>
/* Add styles if needed */
</style>
