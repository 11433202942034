<template>
    <v-card :elevation="3" class="mt-3">
        <v-card-title class="d-flex flex-row align-items-center">
            Generare contract
            <div class="ml-1">
                <v-icon size="small" class="mr-2">
                    mdi-information-outline
                </v-icon>
                <v-tooltip
                    location="top center"
                    origin="auto"
                    no-click-animation
                    activator="parent"
                >
                    <v-container class="text-center">
                        <h6>
                            Din această secțiune contractul poate fi generat cu datele clientului<br>
                            și poate fi și trimis acestuia pe adresa de mail.
                        </h6>
                    </v-container>
                </v-tooltip>
            </div>
        </v-card-title>
        <v-card-subtitle
            v-if="contractAccess?.is_signed"
        >
            <v-alert
                icon="mdi-check"
                title="Pentru acest client există deja un contract trimis și semnat."
                color="success"
                density="compact"
                variant="outlined"
            >
            </v-alert>
        </v-card-subtitle>
        <v-card-text class="mt-5">
            <v-text-field
                v-model="contractValue"
                label="Valoare contract"
                type="number"
                density="compact"
                min="0"
                :disabled="contractAccess && contractAccess?.is_signed"
                variant="outlined"
                required
                :error-messages="contractValue ? '' : 'Valoarea contractului este obligatorie'"
                :error="!contractValue"
                @input="validateValoareContract"
            ></v-text-field>
            <v-btn
                :disabled="!contractValue"
                @click="saveContractValue"
                color="success"
                class="mt-3 mr-2"
                v-if="contractValue !== originalContractValue"
            >
                Salvează valoarea contractului
            </v-btn>
            <v-btn :disabled="!contractValue || !originalContractValue || contractValue !== originalContractValue"
                   @click="generatePDFContract" class="mt-3">
                Vizualizează contract
            </v-btn>
            <v-btn
                :disabled="!contractValue|| !originalContractValue || this.contractAccess.is_signed || contractValue !== originalContractValue"
                @click="sendEmail"
                class="ml-3 mt-3"
                :loading="isLoading">
                Trimite contractul
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import {createPDFContract, showAlertModal} from "@/utils/utils";
import apiClient from "@/utils/apiClient";

export default {
    name: "ClientContract",
    components: {},
    data() {
        return {
            requiredFieldsErrors: {},
            isClientUpdated: true,
            isLoading: false,
            contractValue: null,
            originalContractValue: null,
            contractAccess: {
                is_signed: false,
            },
        };
    },
    props: {
        client: {
            type: Object,
        }
    },
    computed: {},
    async mounted() {
        if (this.client) {
            setTimeout(async () => {
                // Fetch ContractAccess data
                await this.fetchContractAccess();
            }, 250)
        }
    },
    methods: {
        /**
         * Fetches the contract access data for the current client by making an API call.
         * If the data is found, initializes local state with the contract value and sign status.
         * Otherwise, it resets local data to null.
         *
         * @async
         * @function fetchContractAccess
         * @returns {Promise<void>} Resolves when the contract access data has been fetched and applied to component state.
         */
        async fetchContractAccess() {
            try {
                const response = await apiClient.get(`/api/pdf/get-contract-access/${this.client.id}/`);
                if (response && response.data) {
                    this.contractAccess = response.data;
                    this.contractValue = this.contractAccess.contract_value;
                    this.originalContractValue = this.contractAccess.contract_value;
                } else {
                    this.contractAccess = null;
                    this.contractValue = null;
                    this.originalContractValue = null;
                }
            } catch (error) {
                if (!error.data.error.includes('ContractAccess not found for this client')) {
                    console.error('Error fetching ContractAccess data:', error);
                    showAlertModal(this.$store, 'Eroare la obținerea contractului. Încercați din nou.', 'danger', 5000);
                }
            }
        },
        /**
         * Saves or updates the contract value for the current client.
         * If the contract access record exists, it patches the existing record.
         * Otherwise, it creates a new one.
         *
         * @async
         * @function saveContractValue
         * @returns {Promise<void>} Resolves after the contract value is successfully saved or updated in the backend.
         */
        async saveContractValue() {
            if (!this.contractValue) {
                showAlertModal(this.$store, "Valoarea contractului este obligatorie!", "warning", 5000);
                return;
            }

            try {
                this.isLoading = true;

                if (this.contractAccess.id) {
                    // Update existing ContractAccess
                    const response = await apiClient.patch(`/api/pdf/update-contract-access/${this.client.id}/`, {
                        contract_value: this.contractValue,
                    });

                    if (response.status === 200) {
                        this.originalContractValue = this.contractValue;
                        showAlertModal(this.$store, 'Valoarea contractului a fost actualizată!', 'success', 5000);
                    }
                } else {
                    // Create new ContractAccess
                    const response = await apiClient.post(`/api/pdf/update-contract-access/${this.client.id}/`, {
                        contract_value: this.contractValue,
                    });

                    if (response.status === 201) {
                        this.contractAccess = response.data;
                        this.originalContractValue = this.contractValue;
                        showAlertModal(this.$store, 'Valoarea contractului a fost salvată!', 'success', 5000);
                    }
                }

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error saving contract value:', error);
                showAlertModal(this.$store, 'A apărut o eroare la salvarea valorii contractului.', 'danger', 5000);
            }
        },
        /**
         * Validates the contract value (valoare contract) to ensure it is a non-negative number.
         * Resets the contract value to null if it is invalid (NaN or negative).
         *
         * @function validateValoareContract
         * @returns {void} Does not return anything; it updates local component state directly.
         */
        validateValoareContract() {
            if (this.contractValue < 0) {
                this.contractValue = 0;
            } else if (!this.contractValue || isNaN(this.contractValue)) {
                showAlertModal(this.$store, "Valoarea contractului trebuie să fie un număr valid!", "warning", 5000);
                this.contractValue = null; // Reset if invalid
            }
        },
        /**
         * Generates a PDF for the contract and opens it in a new tab.
         * Constructs the URL for the PDF and opens it directly using the createPDFContract helper function.
         *
         * The method makes use of the createPDFContract function, passing the client ID to generate and display the contract.
         * In case of an error during the PDF generation, it logs the error to the console.
         */
        async generatePDFContract() {
            if (this.contractAccess?.is_signed) {
                let url = `${process.env.VUE_APP_API_BASE_URL}/media/${this.contractAccess.contract_path}`;
                window.open(url, "_blank");
                return;
            }
            if (!this.contractValue) {
                showAlertModal(this.$store, "Vă rugăm introduceți o valoare pentru contract!", "warning", 5000);
                return;
            }

            // Save contract value if modified
            if (this.contractValue !== this.originalContractValue) {
                await this.saveContractValue();
            }

            try {
                await createPDFContract(this.client.id, 3, this.contractValue);
            } catch (error) {
                showAlertModal(this.$store, "A apărut o eroare la generarea contractului.", "danger", 12000, error);
            }
        },
        /**
         * Sends an email with a link to access the contract to the client.
         * The email includes a link to the contract, which the client can access after entering an OTP
         * that will be sent separately via SMS when they attempt to sign the contract.
         * If successful, it displays a success modal to the user. In case of errors, it displays an error modal.
         *
         * Sends an API request to the backend to:
         * - Generate a new contract PDF.
         * - Delete any previous contract records for this client.
         * - Send an email to the client's email address with a link to view the contract.
         *
         * @async
         * @function sendEmail
         * @returns {Promise<void>} Displays a success or error alert modal based on the response status.
         */
        async sendEmail() {
            // Save contract value if modified
            if (this.contractValue !== this.originalContractValue) {
                await this.saveContractValue();
            }
            if (!this.contractValue) {
                showAlertModal(this.$store, "Vă rugăm introduceți o valoare pentru contract!", "warning", 5000);
                return;
            }
            try {
                this.isLoading = true;
                const response = await apiClient.post('/api/pdf/send-email/', {
                    phoneNumber: this.client.phone,
                    email: this.client.email,
                    client_id: this.client.id,
                    document_type: 3,
                    contract_value: this.contractValue,
                    message: 'Your contract has been generated and sent to your email.'
                });

                if (response.status === 200) {
                    this.isLoading = false;
                    showAlertModal(this.$store, 'Contractul a fost trimis pe mail-ul clientului!', 'success', 5000);
                } else {
                    this.isLoading = false;
                    showAlertModal(this.$store, 'A apărut o eroare! Vă rugăm reîncercați!', 'danger', 12000)
                }
            } catch (error) {
                this.isLoading = false;
                showAlertModal(this.$store, 'A apărut o eroare la trimiterea mail-ului! Vă rugăm să încercați din nou după reîmprospătarea paginii!', 'danger', 12000, error)
            }
        },
    }
};
</script>