<template>
    <div>
        <BreadCrumbsWithButtons :buttons="buttons"/>
        <a
            href="#"
            id="section1"
            ref="section1"
        ></a>
        <!-- Return Information -->
        <v-card
            class="mt-3"
            style="z-index: 999; overflow: visible"
        >
            <v-toolbar>
                <v-toolbar-title>{{ returnId ? "Editare retur" : "Creare retur" }}</v-toolbar-title>
                <v-toolbar-items v-if="$vuetify.display.mdAndDown">
                    <v-btn v-if="userRole.includes('superuser') || userRole.includes('returnServiceSuperUser')"
                           size="large">
                        <v-icon>mdi-dots-vertical</v-icon>
                        <v-menu
                            activator="parent"
                            :close-on-content-click="true"
                        >
                            <v-list>
                                <v-list-item>
                                    <v-switch
                                        v-model="returnData.operated"
                                        label="Operat"
                                        color="success"
                                        variant="outlined"
                                        inset
                                        density="compact"
                                        :readonly="operatedReadonly"
                                        hide-details
                                        @update:modelValue="normalizeReturnStates"
                                    ></v-switch>
                                </v-list-item>
                                <v-list-item>
                                    <v-switch
                                        v-model="returnData.received"
                                        label="Recepționat"
                                        color="success"
                                        variant="outlined"
                                        inset
                                        density="compact"
                                        :readonly="receivedReadonly"
                                        hide-details
                                        @update:modelValue="normalizeReturnStates"
                                    ></v-switch>
                                </v-list-item>
                                <v-list-item>
                                    <v-switch
                                        v-model="returnData.invoiced"
                                        label="Facturat"
                                        color="success"
                                        variant="outlined"
                                        inset
                                        density="compact"
                                        :readonly="!returnData.invoice_numbers || !returnData.received || invoicedReadonly"
                                        hide-details
                                        @update:modelValue="normalizeReturnStates"
                                    ></v-switch>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-btn>
                </v-toolbar-items>
                <v-toolbar-items v-else class="mr-8" style="height: 60%; align-self:unset">
                    <v-switch
                        v-if="userRole.includes('superuser') || userRole.includes('returnServiceSuperUser')"
                        class="mr-4"
                        v-model="returnData.operated"
                        label="Operat"
                        color="success"
                        variant="outlined"
                        inset
                        density="compact"
                        :readonly="operatedReadonly"
                        hide-details
                        @update:modelValue="normalizeReturnStates"
                    ></v-switch>
                    <v-switch
                        v-if="userRole.includes('superuser') || userRole.includes('returnServiceSuperUser')"
                        class="mr-4"
                        v-model="returnData.received"
                        label="Recepționat"
                        color="success"
                        variant="outlined"
                        inset
                        density="compact"
                        :readonly="receivedReadonly"
                        hide-details
                        @update:modelValue="normalizeReturnStates"
                    ></v-switch>
                    <v-switch
                        v-if="userRole.includes('superuser') || userRole.includes('returnServiceSuperUser')"
                        class="mr-4"
                        v-model="returnData.invoiced"
                        label="Facturat"
                        color="success"
                        variant="outlined"
                        inset
                        density="compact"
                        :readonly="invoicedReadonly"
                        :disabled="!returnData.invoice_numbers || !returnData.received"
                        hide-details
                        @update:modelValue="normalizeReturnStates"
                    ></v-switch>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <!-- Return-level fields -->
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="3"
                    >
                        <v-textarea
                            :label="'Persoana de contact' + (!returnData.contact_person ? ' (dacă este cazul)' : '')"
                            v-model="returnData.contact_person"
                            variant="outlined"
                            density="compact"
                            hide-details
                            rows="1"
                            auto-grow
                            :readonly="!userPermissions.returnsManagement.canEditContactPerson || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                        ></v-textarea>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        md="2"
                    >
                        <v-text-field
                            label="Număr telefon"
                            v-model="returnData.phone"
                            variant="outlined"
                            density="compact"
                            :rules="[validatePhone]"
                            :hide-details="isPhoneValid"
                            :readonly="!userPermissions.returnsManagement.canEditPhoneNumber || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        md="2"
                    >
                        <v-autocomplete
                            label="Județ"
                            :items="regions"
                            v-model="returnData.region"
                            variant="outlined"
                            density="compact"
                            hide-details
                            item-title="title"
                            item-value="value"
                            @update:modelValue="filterSubregions"
                            :readonly="!userPermissions.returnsManagement.canEditRegion || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                        ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="2"
                    >
                        <v-autocomplete
                            label="Localitate"
                            :items="filteredSubregions"
                            v-model="returnData.subregion"
                            variant="outlined"
                            density="compact"
                            hide-details
                            item-title="title"
                            item-value="value"
                            :readonly="!userPermissions.returnsManagement.canEditCity || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                        ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                    >
                        <v-text-field
                            label="Adresă preluare"
                            v-model="returnData.address"
                            variant="outlined"
                            density="compact"
                            hide-details
                            :readonly="!userPermissions.returnsManagement.canEditAddress || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="2">
                        <div class="d-flex flex-row align-center flex-wrap">
                            <v-menu
                                v-model="dateMenu"
                                transition="scale-transition"
                                offset-y
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-text-field
                                        label="Dată preluare"
                                        dirty
                                        readonly
                                        v-bind="props"
                                        locale="ro"
                                        variant="outlined"
                                        density="compact"
                                        hide-details
                                        :disabled="!userPermissions.returnsManagement.canEditPickupDate || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                                    >
                                        {{ formatDate(returnData.pickup_date) }}
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="returnData.pickup_date"
                                    @update:modelValue="dateMenu = false"
                                    locale="ro"
                                ></v-date-picker>
                            </v-menu>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="3" class="d-flex flex-row align-items-start justify-content-evenly">
                            <v-menu
                                v-model="timeStartMenu"
                                transition="scale-transition"
                                offset-y
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-text-field
                                        v-model="returnData.pickup_time_start"
                                        label="Oră preluare de la"
                                        readonly
                                        v-bind="props"
                                        variant="outlined"
                                        density="compact"
                                        hide-details
                                        :disabled="!userPermissions.returnsManagement.canEditPickupTimeStart || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                                    >
                                    </v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="returnData.pickup_time_start"
                                    format="24hr"
                                    title="Selectează ora"
                                    :scrollable="true"
                                    @update:minute="timeStartMenu = false"
                                    locale="ro"
                                ></v-time-picker>
                            </v-menu>
                            <v-menu
                                v-model="timeEndMenu"
                                transition="scale-transition"
                                offset-y
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-text-field
                                        v-model="returnData.pickup_time_end"
                                        label="Până la"
                                        readonly
                                        v-bind="props"
                                        variant="outlined"
                                        density="compact"
                                        hide-details
                                        class="pl-3"
                                        :disabled="!userPermissions.returnsManagement.canEditPickupTimeEnd || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="returnData.pickup_time_end"
                                    title="Selectează ora"
                                    :scrollable="true"
                                    @update:minute="timeEndMenu = false"
                                    format="24hr"
                                    locale="ro"
                                ></v-time-picker>
                            </v-menu>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="3"
                    >
                        <v-textarea
                            label="Note"
                            v-model="returnData.notes"
                            variant="outlined"
                            density="compact"
                            hide-details
                            rows="1"
                            auto-grow
                            :readonly="!userPermissions.returnsManagement.canEditNotes || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                        ></v-textarea>
                    </v-col>
                    <v-col
                        v-if="returnData.operated || userPermissions.returnsManagement.canViewReturnTransportationCost"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="2"
                    >
                        <v-text-field
                            label="Cost transport"
                            v-model="returnData.transportation_cost"
                            variant="outlined"
                            density="compact"
                            suffix="RON"
                            :readonly="!userPermissions.returnsManagement.canViewReturnTransportationCost"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="2"
                    >
                        <v-text-field
                            label="Nr. factură/facturi"
                            v-model="returnData.invoice_numbers"
                            variant="outlined"
                            density="compact"
                            :readonly="!userPermissions.returnsManagement.canEditInvoiceNumbers"
                            :hide-details="!!returnData.invoice_numbers"
                            :error="!returnData.invoice_numbers && userPermissions.returnsManagement.canEditInvoiceNumbers"
                            :error-messages="!returnData.invoice_numbers && userPermissions.returnsManagement.canEditInvoiceNumbers ? 'Acest câmp este obligatoriu!' : ''"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <a
            href="#"
            id="section2"
            ref="section2"
        ></a>
        <!-- Clients Section -->
        <v-card class="mt-3">
            <v-toolbar>
                <v-toolbar-title>Listă clienți</v-toolbar-title>
                <v-toolbar-items>
                    <v-btn
                        :readonly="!userPermissions.returnsManagement.canAddClient || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                        color="primary"
                        @click="addClient"
                    >
                        Adaugă Client
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <ReturnClientComponent
                    v-for="(client, clientIndex) in returnData.clients"
                    :key="clientIndex"
                    :ref="'returnClientChild' + clientIndex"
                    :client="client"
                    :clientIndex="clientIndex"
                    :return-data="returnData"
                    :products="products"
                    :headers="returnClientHeaders"
                    :class="returnData.clients && returnData.clients.length > 0 && clientIndex > 0 ? 'mt-3' : ''"
                    @remove-client="removeClient"
                    @update-client="updateClient"
                />
            </v-card-text>
        </v-card>
        <a
            href="#"
            id="section3"
            ref="section3"
        ></a>
        <!-- Packages Section -->
        <v-card class="mt-3">
            <v-toolbar>
                <v-toolbar-title>Listă pachete</v-toolbar-title>
                <v-toolbar-items>
                    <v-btn
                        color="primary"
                        @click="addPackage"
                        :readonly="!userPermissions.returnsManagement.canAddPackage || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                    >
                        Adaugă Pachet
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <ReturnPackageComponent
                    v-for="(packageItem, packageIndex) in returnData.packages"
                    :key="packageIndex"
                    :ref="'returnPackageChild' + packageIndex"
                    :packageItem="packageItem"
                    :packageIndex="packageIndex"
                    :return-data="returnData"
                    @remove-package="removePackage"
                    @update-package="updatePackage"
                />
            </v-card-text>
        </v-card>
        <v-card v-if="returnId && userPermissions.returnsManagement.canViewReturnHistoryLog" class="mt-3">
            <v-toolbar>
                <v-toolbar-title>
                    Istoric
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <ReturnHistoryLog
                    :return-id="returnId"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import navMixin from "@/mixins/navMixin";
import apiClient from "@/utils/apiClient";
import {fetchRegions, showAlertModal, fetchSubregions} from "@/utils/utils";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";
import ReturnClientComponent from "@/components/clients/ReturnClientComponent.vue";
import ReturnPackageComponent from "@/components/clients/ReturnPackageComponent.vue";
import ReturnHistoryLog from "@/components/returns/ReturnHistoryLog.vue";
import {mapGetters} from "vuex";
import {VTimePicker} from "vuetify/labs/components";

export default {
    name: "ReturnCreate",
    mixins: [navMixin],
    components: {
        BreadCrumbsWithButtons,
        ReturnClientComponent,
        ReturnPackageComponent,
        ReturnHistoryLog,
        VTimePicker,
    },
    data() {
        return {
            sidebarLinks: [
                {
                    name: "Informații retur",
                    link: "#section1",
                    id: "section1",
                },
                {
                    name: "Clienți",
                    link: "#section2",
                    id: "section2",
                },
                {
                    name: "Pachete",
                    link: "#section3",
                    id: "section3",
                },
            ],
            returnId: null,
            returnData: {
                address: "",
                pickup_date: null,
                pickup_time_start: null,
                pickup_time_end: null,
                contact_person: "",
                notes: "",
                transportation_cost: null,
                invoice_numbers: null,
                region: null,
                subregion: null,
                phone: "",
                clients: [],
                packages: [],
                operated: false,
                invoiced: false,
                received: false,
            },
            regions: [],
            subregions: [],
            products: [], // Master list of products
            headers: [
                {title: "Cod produs", key: "product_code", align: "start", width: "100px"},
                {title: "Nume produs", key: "product_name", align: "start", width: "250px"},
                {title: "Cantitate (UM)", key: "quantity", align: "center", width: "150px"},
                {title: "Observații", key: "observations", align: "center", minWidth: "100px"},
                {title: "Service", key: "is_for_service", align: "center", width: "50px"}
            ],
            buttons: [
                {
                    text: "Înapoi",
                    onClick: this.returnToReturnsList,
                    color: "secondary",
                    visible: () => true,
                    disabled: () => this.loading,
                },
                {
                    text: "Salvează returul",
                    onClick: () => this.handleSaveReturn(),
                    disabled: () => this.loading,
                    color: "success",
                    visible: () => this.userRole.includes('superuser') || this.userRole.includes('returnServiceSuperUser') || !this.returnData.operated && !this.userRole.includes('logisticsOnly'),
                },
                {
                    text: "Operează returul",
                    onClick: () => this.handleSaveReturn(() => {
                        this.returnData.operated = true;
                    }),
                    disabled: () => this.loading || !this.returnData.transportation_cost,
                    color: "success",
                    visible: () => this.userRole.includes('logisticsOnly') && !this.returnData.operated,
                },
                {
                    text: "Recepționează returul",
                    onClick: () => this.handleSaveReturn(() => {
                        this.returnData.received = true;
                    }),
                    disabled: () => this.loading || !this.returnData.operated,
                    color: "success",
                    visible: () => this.userRole.includes('acquisitionsOnly') && !this.returnData.received,
                },
                {
                    text: "Facturează returul",
                    onClick: () => this.handleSaveReturn(() => {
                        this.returnData.invoiced = true;
                    }),
                    disabled: () => this.loading || !this.returnData.invoice_numbers,
                    color: "success",
                    visible: () => this.userRole.includes('ordersOnly') && !this.returnData.invoiced,
                },
            ],
            contentTypes: null,
            phoneErrorMessage: "",
            formattedPickupDate: null,
            dateMenu: false,
            timeStartMenu: false,
            timeEndMenu: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters(['userPermissions', 'userRole']),
        /**
         * Determines whether the "operated" switch should be read-only.
         * Returns `true` if:
         * - The return is already operated, but the user does not have permission to set "operated" to false.
         * - The user does not have permission to view the transportation cost.
         * - The user does not have permission to set "operated" to true.
         *
         * This ensures that the switch cannot be toggled unless all the required permissions and conditions are met.
         */
        operatedReadonly() {
            return (
                (!this.userPermissions.returnsManagement.canSetOperatedToFalse && this.returnData.operated)
                || !this.userPermissions.returnsManagement.canViewReturnTransportationCost
                || !this.userPermissions.returnsManagement.canSetOperatedToTrue
            );
        },
        /**
         * Determines whether the "received" switch should be read-only.
         * Returns `true` if:
         * - The user does not have permission to edit the return's received status.
         * - The return is already received, but the user does not have permission to set operated to false.
         * - The return cannot be received due to failing the "canReceive" condition (e.g., missing required quantities or real quantities).
         *
         * This ensures that the "received" switch cannot be toggled unless the user has the appropriate permissions and the return is in a state that allows it.
         */
        receivedReadonly() {
            return (
                !this.userPermissions.returnsManagement.canEditReturnReceivedStatus
                || (!this.userPermissions.returnsManagement.canSetOperatedToFalse && this.returnData.received)
                || !this.canReceive
            );
        },
        /**
         * Determines whether the "invoiced" switch should be read-only.
         * Returns `true` if:
         * - The return is already invoiced, but the user does not have permission to set operated to false.
         * - The user does not have permission to set the return to invoiced.
         *
         * This ensures that the "invoiced" switch cannot be toggled unless the user has the appropriate permissions and the return is in the correct state.
         */
        invoicedReadonly() {
            return (
                (!this.userPermissions.returnsManagement.canSetOperatedToFalse && this.returnData.invoiced)
                || !this.userPermissions.returnsManagement.canSetReturnToInvoiced
            );
        },
        filteredSubregions() {
            if (this.returnData.region) {
                return this.subregions
                    .filter((item) => item.region === this.returnData.region)
                    .sort((a, b) => a.title.localeCompare(b.title));
            }
            return [];
        },
        returnClientHeaders() {
            let conditionedHeaders = this.headers;

            // Only add these headers if the user can edit real quantities OR the return is received
            if (this.userPermissions.returnsManagement.canEditProductsRealQuantities || this.returnData.received) {
                conditionedHeaders.splice(3, 0, // Insert before 'Observații'
                    {title: "Cantitate reală (UM)", key: "real_quantity", align: "center", width: "150px"},
                    {title: "Observații recepție", key: "reception_observations", align: "center", minWidth: "150px"}
                );
            }

            return conditionedHeaders;
        },
        canReceive() {
            // If there's any client or item that doesn't meet the criteria, return false
            // Criteria: Each item must have either a real_quantity or is_correct_quantity == true
            for (const client of this.returnData.clients) {
                for (const item of client.productData) {
                    if (item.quantity) {
                        const hasRealQuantity = item.real_quantity && !isNaN(parseInt(item.real_quantity, 10));
                        const hasCorrectQuantity = !!item.is_correct_quantity; // Convert to boolean

                        if (!hasRealQuantity && !hasCorrectQuantity) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        isPhoneValid() {
            return this.validatePhone(this.returnData.phone) === true;
        },
    },
    async mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks;
        this.returnId = this.$route.params.returnId;
        // Fetch regions and subregions
        this.regions = await fetchRegions();
        if (!sessionStorage.getItem("subregions")) {
            this.subregions = await fetchSubregions();
        } else {
            this.subregions = JSON.parse(sessionStorage.getItem("subregions"));
        }
        // Fetch products
        await this.fetchProducts();
        if (this.returnId) {
            await this.fetchReturn(this.returnId);
        } else {
            this.initializeDatesAndTime();
            this.addClient(); // Start with one client by default
            this.addPackage(); // Start with one package by default
        }
    },
    methods: {
        /**
         * Handles the return save action, allowing pre-save modifications.
         * This can be used to perform actions before saving (e.g., setting operated to true).
         *
         * @param {Function} [beforeSaveAction] - Optional function to execute before saving.
         */
        async handleSaveReturn(beforeSaveAction) {
            if (beforeSaveAction) {
                beforeSaveAction();
            }

            if (!this.validateReturn()) {
                return; // Exit early if validation fails
            }

            await this.saveReturn();
        },
        /**
         * Normalizes the return's operated, received, and invoiced states according to the logic:
         * 1. If operated = false, received and invoiced = false.
         * 2. If received = false, invoiced = false.
         * 3. If invoiced = true, then operated and received must be true.
         * 4. If received = true, operated must be true.
         *
         * Additionally, if transportation_cost is required for operating or invoicing, enforce that as well.
         */
        normalizeReturnStates() {
            let {operated, received, invoiced} = this.returnData;

            // 1. If operated = false, force received and invoiced = false
            if (!operated) {
                received = false;
                invoiced = false;
            }

            // 2. If received = false, invoiced must be false
            if (!received) {
                invoiced = false;
            }

            // 3. If invoiced = true, operated and received must be true
            if (invoiced) {
                operated = true;
                received = true;
            }

            // 4. If received = true, operated must be true
            if (received && !operated) {
                operated = true;
            }

            // Update returnData
            this.returnData.operated = operated;
            this.returnData.received = received;
            this.returnData.invoiced = invoiced;

            // If certain fields must be present for the transitions:
            // For example, if transportation_cost must be set before operated can be true:
            if (this.returnData.operated && !this.returnData.transportation_cost) {
                // Reset operated and invoiced to false if no transportation_cost
                this.returnData.operated = false;
                this.returnData.invoiced = false;
                showAlertModal(this.$store, "Costul transportului trebuie completat înainte de a seta returul ca operat.", 'warning', 6000);
            }
        },
        // Convert HH:mm string to ISO string
        timeToIso(date, time) {
            if (!time) return null;
            const [hours, minutes] = time.split(":").map(Number);
            const isoDate = new Date(date);
            isoDate.setHours(hours, minutes, 0, 0);
            return isoDate.toISOString();
        },
        // Convert ISO string to HH:mm format
        isoToTime(isoString) {
            if (!isoString) return null;
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
        },
        // Initialize date and times for new return
        initializeDatesAndTime() {
            const now = new Date();
            this.returnData.pickup_date = now; // Set date in YYYY-MM-DD format
            this.returnData.pickup_time_start = `${now.getHours().toString().padStart(2, "0")}:${now
                .getMinutes()
                .toString()
                .padStart(2, "0")}`; // Set current time as HH:mm
            this.returnData.pickup_time_end = this.returnData.pickup_time_start; // Default same as start time
        },
        /**
         * Formats a given Date object into a "dd/mm/yyyy" format string.
         *
         * @param {Date} date - The date object to format.
         * @returns {string} - The formatted date string, or an empty string if invalid.
         */
        formatDate(date) {
            if (!date) return "";
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const day = String(d.getDate()).padStart(2, "0");
            return `${day}/${month}/${year}`;
        },
        /**
         * Formats a given Date object to show only the time in "HH:mm" format.
         *
         * @param {Date} date - The date object to format.
         * @returns {string} - The formatted time string, or an empty string if invalid.
         */
        formatTime(date) {
            if (!date) return "";
            return date.toLocaleTimeString("ro-RO", {hour: "2-digit", minute: "2-digit"});
        },
        /**
         * Fetches a list of products from the backend and populates the `this.products` array.
         * Logs an error if the API request fails.
         */
        async fetchProducts() {
            try {
                const response = await apiClient.get("/api/items/");
                this.products = response.data;
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        },
        /**
         * Clears the selected subregion whenever a new region is chosen, ensuring the user
         * must explicitly re-select a matching subregion.
         */
        filterSubregions() {
            this.returnData.subregion = null;
        },
        /**
         * Adds a new, empty client object to the returnData.clients array.
         * Each client starts with an empty productData array constructed from the products list.
         */
        addClient() {
            this.returnData.clients.push({
                name: "",
                phone: "",
                notes: "",
                items: [],
                productData: this.products.map((product) => ({
                    ...product,
                    quantity: null,
                    observations: "",
                    is_for_service: false,
                    real_quantity: null,
                    reception_observations: "",
                    is_correct_quantity: false,
                })),
            });
        },
        /**
         * Updates a specific client's data within returnData.clients based on the provided payload.
         *
         * @param {Object} payload - Contains `clientIndex` and `clientData`.
         */
        updateClient({clientIndex, clientData}) {
            Object.assign(this.returnData.clients[clientIndex], clientData);
        },
        /**
         * Removes a client from the returnData.clients array based on the given index.
         *
         * @param {number} clientIndex - The index of the client to remove.
         */
        removeClient(clientIndex) {
            this.returnData.clients.splice(clientIndex, 1);
        },
        /**
         * Adds a new, empty package object to the returnData.packages array.
         */
        addPackage() {
            this.returnData.packages.push({
                length: null,
                width: null,
                height: null,
                weight: null,
                type: 'Colet',
                attachments: [],
                photos: [],
                newPhotos: [],
            });
        },
        /**
         * Updates a specific package's data within returnData.packages based on the provided payload.
         *
         * @param {Object} payload - Contains `packageIndex` and `packageData`.
         */
        updatePackage({packageIndex, packageData}) {
            Object.assign(this.returnData.packages[packageIndex], packageData);
        },
        /**
         * Removes a package from the returnData.packages array based on the given index.
         *
         * @param {number} packageIndex - The index of the package to remove.
         */
        removePackage(packageIndex) {
            this.returnData.packages.splice(packageIndex, 1);
        },
        /**
         * Validates a phone number. Must be either 10 digits or start with '+' followed by 11 digits.
         *
         * @param {string} value - The phone number input.
         * @returns {string|boolean} - Returns an error message if invalid, otherwise `true`.
         */
        validatePhone(value) {
            const phone = value;
            if (!phone || phone.length === 0) {
                return "Introduceți un număr de telefon valid.";
            }
            if (!/^\d{10}$/.test(phone) && !/^\+\d{11}$/.test(phone)) {
                return "Introduceți un număr de telefon valid, format din 10 cifre sau începând cu + și 11 cifre.";
            }
            return true;
        },
        /**
         * Validates if the overall return form data is complete and consistent.
         * Checks address, region, subregion, phone, and client names.
         * Also checks if all clients are valid if the user is restricted (not superuser) but can edit real quantities or return received status.
         * If the user role is 'agent', ensures packages have valid dimensions and attachments.
         *
         * @returns {boolean} - `true` if all validations pass, otherwise `false`.
         */
        validateReturn() {
            const hasAddress = this.returnData.address.trim() !== "";
            const hasRegion = this.returnData.region !== null;
            const hasSubregion = this.returnData.subregion !== null;
            const allClientsHaveName = this.returnData.clients.every(client => client.name.trim() !== "");
            const allClientsAreValid = this.returnData.clients.every(client => client.isValid);
            const hasPhone = this.returnData.phone && this.returnData.phone.trim() !== "";

            if (!hasPhone) {
                showAlertModal(this.$store, "Numărul de telefon trebuie completat.", "danger", 12000);
                return false;
            }

            if (!hasAddress) {
                showAlertModal(this.$store, "Adresa returnării trebuie completată.", "danger", 12000);
                return false;
            }

            if (!hasRegion || !hasSubregion) {
                showAlertModal(
                    this.$store,
                    "Trebuie să selectați județul și localitatea pentru returnare.",
                    "danger",
                    12000
                );
                return false;
            }

            if (!allClientsHaveName) {
                showAlertModal(this.$store, "Toți clienții trebuie să aibă completat numele.", "danger", 12000);
                return false;
            }

            // Additional validation for agents
            if (this.userRole === "agent") {
                const hasAtLeastOnePackage = this.returnData.packages.length > 0;

                const allPackagesValid = this.returnData.packages.every(pkg =>
                    pkg.width && pkg.height && pkg.length &&
                    (pkg.attachments.length > 0 || pkg.newPhotos.length > 0)
                );

                if (!hasAtLeastOnePackage) {
                    showAlertModal(this.$store, "Trebuie să existe cel puțin un colet.", "danger", 12000);
                    return false;
                }

                if (!allPackagesValid) {
                    showAlertModal(
                        this.$store,
                        "Toate pachetele trebuie să aibă dimensiuni valide (lungime, lățime, înălțime) și cel puțin poză.",
                        "danger",
                        12000
                    );
                    return false;
                }
            }

            if (
                !this.userPermissions.isSuperuser &&
                (this.userPermissions.returnsManagement.canEditProductsRealQuantities ||
                    this.userPermissions.returnsManagement.canEditReturnReceivedStatus)
            ) {
                if (!allClientsAreValid) {
                    showAlertModal(
                        this.$store,
                        "Există erori la nivelul clienților. Verificați cantitățile reale și observațiile de recepție.",
                        "danger",
                        12000
                    );
                    return false;
                }
            }

            return true;
        },
        /**
         * Navigates back to the returns list view.
         */
        returnToReturnsList() {
            this.$router.push("/returns-tab");
        },
        /**
         * Checks if a given Date object is valid.
         *
         * @param {Date} d - The date object to check.
         * @returns {boolean} - `true` if valid, otherwise `false`.
         */
        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        },
        /**
         * Attempts to parse a given value into a valid Date object.
         * Returns `null` if the date is invalid.
         *
         * @param {string|Date} value - The value to convert into a Date object.
         * @returns {Date|null} - The valid Date object or `null` if invalid.
         */
        getValidDate(value) {
            let dateObj = value;
            if (!(dateObj instanceof Date)) {
                dateObj = new Date(dateObj);
            }

            if (!this.isValidDate(dateObj)) {
                console.error("Invalid date:", value);
                return null;
            }
            return dateObj;
        },
        /**
         * Converts a date to ISO format if it exists.
         * @param {string | Date | null} date - The date to convert.
         * @returns {string | null} - The ISO string or null if the date is invalid.
         */
        toISODate(date) {
            if (!date) return null;
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const day = String(d.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        /**
         * Formats the clients list before saving, filtering only items with quantities and preparing the structure
         * to match the backend's expected format.
         *
         * @returns {Array} - A formatted list of clients and their products.
         */
        formatClients() {
            return this.returnData.clients.map((client) => ({
                id: client.id,
                name: client.name,
                items: client.productData
                    .filter((product) => product.quantity && product.quantity > 0)
                    .map((product) => ({
                        id: product.id,
                        product_code: product.product_code,
                        product_name: product.product_name,
                        quantity: product.quantity,
                        observations: product.observations,
                        is_for_service: product.is_for_service,
                        real_quantity: product.real_quantity,
                        reception_observations: product.reception_observations,
                        is_correct_quantity: product.is_correct_quantity,
                    })),
            }));
        },
        /**
         * Formats the packages list before saving, including attachments and removal lists,
         * to match the backend's expected format.
         *
         * @returns {Array} - A formatted list of packages.
         */
        formatPackages() {
            return this.returnData.packages.map((packageItem) => ({
                id: packageItem.id,
                length: packageItem.length,
                width: packageItem.width,
                height: packageItem.height,
                weight: packageItem.weight,
                type: packageItem.type,
                real_quantity: packageItem.real_quantity,
                reception_observations: packageItem.reception_observations,
                is_correct_quantity: packageItem.is_correct_quantity,
                attachments_to_delete: packageItem.attachmentsToDelete
                    ? packageItem.attachmentsToDelete.map((a) => a.id)
                    : [],
            }));
        },
        /**
         * Returns a fully formatted return data object ready to be sent to the backend API.
         * Includes properly formatted dates, times, clients, and packages.
         * If the pickup_date is invalid, returns `undefined`.
         *
         * @returns {Object|undefined} - The formatted return data or `undefined` if date is invalid.
         */
        getFormattedReturnData() {
            const pickupDate = this.getValidDate(this.returnData.pickup_date);
            if (!pickupDate) {
                // handle invalid pickup_date as needed
                return;
            }

            return {
                address: this.returnData.address,
                pickup_date: this.toISODate(pickupDate),
                pickup_time_start: this.timeToIso(pickupDate, this.returnData.pickup_time_start), // Convert HH:mm to ISO
                pickup_time_end: this.timeToIso(pickupDate, this.returnData.pickup_time_end), // Convert HH:mm to ISO
                contact_person: this.returnData.contact_person,
                notes: this.returnData.notes,
                transportation_cost: this.returnData.transportation_cost,
                invoice_numbers: this.returnData.invoice_numbers,
                region: this.returnData.region,
                subregion: this.returnData.subregion,
                phone: this.returnData.phone,
                operated: this.returnData.operated,
                invoiced: this.returnData.invoiced,
                received: this.returnData.received,
                clients: this.formatClients(),
                packages: this.formatPackages(),
            };
        },
        /**
         * Saves the return data to the backend. Handles both create (POST) and update (PUT) operations.
         * On success, updates internal IDs, uploads package photos if any, and refetches the return data.
         * On failure, shows an error message.
         */
        async saveReturn() {
            if (!this.validateReturn()) {
                return; // Exit early if validation fails
            }
            this.loading = true;

            try {
                const formattedReturnData = this.getFormattedReturnData();

                let response;
                if (this.returnId) {
                    // Update existing return
                    response = await apiClient.put(`/api/returns/${this.returnId}/`, formattedReturnData);
                } else {
                    // Create new return
                    response = await apiClient.post("/api/returns/create/", formattedReturnData);
                    this.returnId = response.data.id.toString();
                }

                // Update IDs and upload any package photos if needed
                this.updateReturnDataIds(response.data);
                await this.uploadPackagePhotos();
                await this.fetchReturn(this.returnId);

                setTimeout(() => {
                    showAlertModal(this.$store, "Returul a fost salvat cu succes!", "success", 5000);
                    this.loading = false;
                }, 500);
            } catch (error) {
                console.error("Error saving return:", error);
                showAlertModal(
                    this.$store,
                    "A intervenit o eroare la salvarea returnării. Vă rugăm reîncercați.",
                    "danger",
                    12000,
                    error
                );
                this.loading = false;
            }
        },
        /**
         * Uploads any new photos added to packages after the main return data has been saved.
         * Uses the content type and object ID to associate attachments with the correct return packages.
         */
        async uploadPackagePhotos() {
            for (let i = 0; i < this.returnData.packages.length; i++) {
                const packageItem = this.returnData.packages[i];
                if (packageItem.newPhotos && packageItem.newPhotos.length > 0) {
                    const formData = new FormData();
                    const contentTypeId = await this.getContentTypeId("returnpackage");
                    formData.append("content_type", contentTypeId);
                    formData.append("object_id", packageItem.id);
                    packageItem.newPhotos.forEach((photo) => {
                        formData.append("files", photo);
                    });

                    await apiClient.post(`/api/attachments/upload/`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    packageItem.newPhotos = [];
                }
            }
        },
        /**
         * Updates return and related items' IDs after saving or updating on the backend,
         * ensuring the client and package arrays in `returnData` stay in sync with the backend.
         *
         * @param {Object} responseData - The data returned by the backend after saving.
         */
        updateReturnDataIds(responseData) {
            // Update return ID
            this.returnData.id = responseData.id;

            // Update package IDs
            responseData.packages.forEach((responsePackage, index) => {
                if (this.returnData.packages[index]) {
                    this.returnData.packages[index].id = responsePackage.id;
                }
            });

            // Update client IDs and their items
            responseData.clients.forEach((responseClient, clientIndex) => {
                if (this.returnData.clients[clientIndex]) {
                    this.returnData.clients[clientIndex].id = responseClient.id;

                    // Map item IDs
                    responseClient.items.forEach((responseItem) => {
                        const product = this.returnData.clients[clientIndex].productData.find(
                            (p) => p.product_code === responseItem.product_code
                        );
                        if (product) {
                            product.id = responseItem.id;
                        }
                    });
                }
            });
        },
        /**
         * Fetches the content type ID for a given model name. Used for uploading attachments.
         * If contentTypes is not cached, it first fetches it from the server.
         *
         * @param {string} modelName - The model name (e.g., "returnpackage").
         * @returns {number} - The content type ID for the given model name.
         */
        async getContentTypeId(modelName) {
            if (!this.contentTypes) {
                const response = await apiClient.get("/api/content-types/");
                this.contentTypes = response.data;
            }
            return this.contentTypes[modelName.toLowerCase()];
        },
        /**
         * Fetches the full return details from the backend for a given returnId.
         * Re-formats dates, times, and re-builds clients and packages arrays so the UI can display them properly.
         * Logs an error if fetching fails.
         *
         * @param {number} returnId - The ID of the return to fetch.
         */
        async fetchReturn(returnId) {
            try {
                const response = await apiClient.get(`/api/returns/${returnId}/`);
                this.returnData = response.data;

                // Convert pickup_date from "YYYY-MM-DD" to Date
                if (this.returnData.pickup_date) {
                    this.returnData.pickup_date = new Date(this.returnData.pickup_date);
                }

                // Convert pickup_time_start ISO to { hours, minutes, seconds }
                if (this.returnData.pickup_time_start) {
                    this.returnData.pickup_time_start = this.isoToTime(this.returnData.pickup_time_start); // Convert ISO to HH:mm
                }

                // Convert pickup_time_end ISO to { hours, minutes, seconds }
                if (this.returnData.pickup_time_end) {
                    this.returnData.pickup_time_end = this.isoToTime(this.returnData.pickup_time_end); // Convert ISO to HH:mm
                }

                // Handle clients
                this.returnData.clients.forEach((client, clientIndex) => {
                    const savedItemsMap = {};
                    client.items.forEach((savedItem) => {
                        savedItemsMap[savedItem.product_code] = savedItem;
                    });
                    client.showOnlyWithQuantity = true;

                    // Initialize client.productData
                    client.productData = this.products.map((product) => ({
                        ...product,
                        quantity: savedItemsMap[product.product_code]?.quantity || null,
                        observations: savedItemsMap[product.product_code]?.observations || "",
                        is_for_service: savedItemsMap[product.product_code]?.is_for_service || false,
                        real_quantity: savedItemsMap[product.product_code]?.real_quantity || null,
                        reception_observations: savedItemsMap[product.product_code]?.reception_observations || "",
                        is_correct_quantity: savedItemsMap[product.product_code]?.is_correct_quantity || false,
                    }));

                    if (client.region) {
                        this.$nextTick(() => {
                            const childComponent = this.$refs["returnClientChild" + clientIndex];
                            if (childComponent && childComponent[0]) {
                                childComponent[0].filterSubregions();
                            }
                        });
                    }
                });

                // Handle packages
                this.returnData.packages.forEach((packageItem) => {
                    packageItem.attachments = packageItem.attachments || [];
                    packageItem.newPhotos = [];
                    packageItem.attachmentsToDelete = [];
                });

            } catch (error) {
                console.error("Error fetching return:", error);
            }
        },
    },
};
</script>

<style>
</style>
