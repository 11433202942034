<template>
    <BreadCrumbs>
        <!-- Desktop Version -->
        <template v-if="!$vuetify.display.mdAndDown" v-slot:header>
            <template v-for="button in buttons" :key="button.text">
                <v-btn
                    v-if="button.visible === undefined || button.visible()"
                    variant="plain"
                    @click.prevent="button.onClick"
                    :disabled="button.disabled()"
                    :color="button.color"
                    v-bind="button.dataCy ? { 'data-cy': button.dataCy } : {}"
                >
                    <v-icon v-if="button.icon" :class="button.icon"></v-icon>
                    <span v-else>{{ button.text }}</span>
                </v-btn>
            </template>

            <!-- PDF Options Menu on Desktop -->
            <v-btn v-if="pdfOptions && pdfOptions.length > 0" color="primary">
                PDF
                <v-icon right>mdi-menu-down</v-icon>
                <v-menu offset-y activator="parent">
                    <v-list>
                        <v-list-item
                            v-for="pdfOption in pdfOptions"
                            :key="pdfOption.text"
                            @click="pdfOption.onClick"
                            :disabled="pdfOption.disabled()"
                        >
                            <v-list-item-title>{{ pdfOption.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-btn>
        </template>

        <!-- Mobile Version -->
        <template v-else v-slot:header>
            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" icon variant="plain" density="compact" color="primary">
                        <v-icon left>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <!-- Combined Buttons and PDF Options in Mobile Dropdown -->
                    <div v-for="(option, index) in combinedOptions" :key="index">
                        <v-list-item
                            v-if="option.visible === undefined || option.visible()"
                            @click.prevent="option.onClick"
                            :disabled="option.disabled()"
                        >
                            <v-icon v-if="option.icon">{{ option.icon }}</v-icon>
                            <v-list-item-title>{{ option.text }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-menu>
        </template>
    </BreadCrumbs>
</template>

<script>
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";

export default {
    name: 'BreadCrumbsWithButtons',
    components: { BreadCrumbs },
    props: {
        buttons: {
            type: Array,
            default: () => [], // Default to an empty array if not provided
        },
        pdfOptions: {
            type: Array,
            default: () => [], // Default to an empty array if not provided
        },
    },
    computed: {
        combinedOptions() {
            // Combine buttons and pdfOptions if they are defined
            return [...(this.buttons || []), ...(this.pdfOptions || [])];
        },
    },
};
</script>
