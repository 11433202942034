<template>
    <v-card>
        <v-card-title>Istoric Modificări - Return ID {{ returnId }}</v-card-title>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="translatedHistory"
                dense
                item-value="id"
            >
                <template v-slot:[`item.changed_at`]="{ item }">
                    {{ formatDateTime(item.changed_at) }}
                </template>
                <template v-slot:[`item.changed_by_name`]="{ item }">
                    {{ item.changed_by_name || "Necunoscut" }}
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";

export default {
    name: "ReturnHistoryLog",
    components: {
    },
    props: {
        returnId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            history: [], // History logs for the specific return
            headers: [
                {title: "Câmp modificat", key: "field_name"},
                {title: "Valoare veche", key: "old_value"},
                {title: "Valoare nouă", key: "new_value"},
                {title: "Modificat de", key: "changed_by_name"},
                {title: "Data modificării", key: "changed_at"},
            ],
            fieldTranslations: {
                operated: "Operat",
                invoiced: "Facturat",
                received: "Recepționat",
            },
        };
    },
    computed: {
        /**
         * Translates the `field_name` in the history logs.
         */
        translatedHistory() {
            return this.history.map((log) => ({
                ...log,
                field_name: this.fieldTranslations[log.field_name] || log.field_name,
            }));
        },
    },
    methods: {
        /**
         * Fetches the history logs for the specific return ID.
         */
        async fetchHistory() {
            try {
                const response = await apiClient.get(`/api/returns/${this.returnId}/history/`);
                this.history = response.data;
            } catch (error) {
                console.error(`Error fetching history for return ID ${this.returnId}:`, error);
            }
        },
        /**
         * Formats a date and time string into a human-readable format.
         * @param {string} dateTime - The ISO date-time string to format.
         * @returns {string} - The formatted date and time.
         */
        formatDateTime(dateTime) {
            if (!dateTime) return "";
            const date = new Date(dateTime);

            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");

            return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
        },
    },
    mounted() {
        this.fetchHistory();
    },
};
</script>
