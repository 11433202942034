<template>
    <v-card style="border: 1px solid rgba(0,0,0,0.15);" :elevation="3">
        <v-dialog v-model="dialog" width="auto">
            <v-card>
                <v-card-text>
                    Ești sigur că vrei să ștergi clientul?
                </v-card-text>
                <v-card-actions class="justify-content-end">
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="dialog = false"
                    >
                        Nu
                    </v-btn>
                    <v-btn
                        color="danger"
                        variant="text"
                        @click="triggerDeleteClient"
                    >
                        Da
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-toolbar>
            <v-toolbar-title data-cy="clientDetails-clientName" class="cursor-pointer"
                             @click="goToClientDetails(client.id)">
                {{ client.full_name }}
            </v-toolbar-title>
            <v-btn
                v-if="!editMode && !renderedByClientContract"
                class="mr-2"
                @click.prevent="dialog = true"
                data-cy="delete-client"
                icon
                rounded="3"
                density="comfortable"
                color="white"
                variant="text"
                style="color: var(--bs-danger) !important;">
                <v-icon color="danger">mdi-delete</v-icon>
                <v-tooltip
                    activator="parent"
                    location="top"
                >
                    Șterge clientul
                </v-tooltip>
            </v-btn>
            <v-btn
                v-if="!editMode"
                color="primary"
                @click.prevent="toggleEditMode(true)"
                variant="text"
                icon
                density="comfortable"
                rounded="3"
                style="color: white;">
                <v-icon>mdi-pencil</v-icon>
                <v-tooltip
                    activator="parent"
                    location="top"
                >
                    Editează clientul
                </v-tooltip>
            </v-btn>
            <v-btn
                v-if="editMode"
                color="success"
                @click.prevent="toggleEditMode(false)"
                variant="text"
                icon
                density="comfortable"
                rounded="3"
                style="color: white;">
                <v-icon>mdi-check</v-icon>
                <v-tooltip
                    activator="parent"
                    location="top"
                >
                    Salvează modificările
                </v-tooltip>
            </v-btn>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col v-if="editMode" cols="12">
                    <v-text-field
                        v-if="editMode"
                        clearable
                        :hint="!editMode ? 'Pentru a edita numele clientului, apasă butonul de editare.' : ''"
                        type="text"
                        label="Nume client"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-account' : ''"
                        variant="solo-inverted"
                        density="compact"
                        :error="!requiredFieldsErrors?.full_name?.valid"
                        v-model="editableClient.full_name"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="editableClient.type"
                        variant="solo-inverted"
                        :hint="!editMode ? 'Pentru a edita tipul clientului, apasă butonul de editare.' : ''"
                        density="compact"
                        label="Tip client"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-account-multiple-outline' : ''"
                        :readonly="!editMode"
                        :items="clientTypes"
                        item-title="text"
                        item-value="value"
                    >
                    </v-autocomplete>
                    <v-text-field
                        :clearable="editMode"
                        :readonly="!editMode"
                        :hint="!editMode ? 'Pentru a edita email-ul, apasă butonul de editare.' : ''"
                        variant="solo-inverted"
                        label="Email"
                        type="email"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-email' : ''"
                        v-model="editableClient.email"
                        :error="!requiredFieldsErrors?.email?.valid"
                        :rules="[validateEmail]"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                    <v-text-field
                        :readonly="!editMode"
                        :clearable="editMode"
                        variant="solo-inverted"
                        :hint="!editMode ? 'Pentru a edita numărul de telefon, apasă butonul de editare.' : ''"
                        label="Telefon"
                        type="phone"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-phone' : ''"
                        :error="!requiredFieldsErrors?.phone?.valid"
                        v-model="editableClient.phone"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                    <v-text-field
                        v-if="editMode || renderedByClientContract"
                        :readonly="!editMode"
                        :clearable="editMode"
                        variant="solo-inverted"
                        label="Serie Buletin"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-card-account-details-outline' : ''"
                        v-model="editableClient.id_card_series"
                        :error="!requiredFieldsErrors?.id_card_series?.valid"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                    <v-autocomplete
                        v-if="editMode || renderedByClientContract"
                        v-model="editableClient.region"
                        variant="solo-inverted"
                        label="Județ"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-map-marker-radius' : ''"
                        :readonly="!editMode"
                        :items="regions"
                        item-title="title"
                        item-value="value"
                        :clearable="editMode"
                        :error="!requiredFieldsErrors?.region?.valid"
                        @update:modelValue="validateRequiredFields"
                    ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-if="this.userPermissions.clientManagement.canEditClientAgent"
                        v-model="editableClient.agent_id"
                        variant="solo-inverted"
                        :hint="!editMode ? 'Pentru a edita agentul, apasă butonul de editare.' : ''"
                        density="compact"
                        label="Agent"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-account-switch' : ''"
                        :readonly="!editMode"
                        :items="availableAgents"
                        item-title="full_name"
                        item-value="id"
                        :clearable="editMode"
                    >
                    </v-autocomplete>
                    <IntermediarySelect
                        ref="intermediarySelect"
                        :isDisabled="!editMode"
                        :current-intermediary="editableClient.intermediate_id"
                        :selected-agent="editableClient.agent_id"
                        @new-intermediary="withIntermediary = true"
                        @intermediary-changed="changeIntermediary"
                    />
                    <v-text-field
                        v-if="editMode || renderedByClientContract"
                        :readonly="!editMode"
                        :clearable="editMode"
                        variant="solo-inverted"
                        :hint="!editMode ? 'Pentru a edita CNP-ul, apasă butonul de editare.' : ''"
                        label="CNP"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-identifier' : ''"
                        v-model="editableClient.cnp"
                        :error="!requiredFieldsErrors?.cnp?.valid"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                    <v-text-field
                        v-if="editMode || renderedByClientContract"
                        :readonly="!editMode"
                        :clearable="editMode"
                        variant="solo-inverted"
                        label="Număr Buletin"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-card-account-details' : ''"
                        v-model="editableClient.id_card_number"
                        :error="!requiredFieldsErrors?.id_card_number?.valid"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                    <v-text-field
                        v-if="editMode || renderedByClientContract"
                        :readonly="!editMode"
                        :clearable="editMode"
                        variant="solo-inverted"
                        label="Municipiu"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-city' : ''"
                        v-model="editableClient.city"
                        :error="!requiredFieldsErrors?.city?.valid"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="editMode || renderedByClientContract">
                <v-col>
                    <v-text-field
                        :readonly="!editMode"
                        variant="solo-inverted"
                        label="Adresă"
                        density="compact"
                        :prepend-inner-icon="$vuetify.display.mdAndUp ? 'mdi-home-map-marker' : ''"
                        v-model="editableClient.address"
                        :error="!requiredFieldsErrors?.address?.valid"
                        @update:modelValue="validateRequiredFields"
                    ></v-text-field>
                </v-col>
            </v-row>
            <CreateIntermediary
                v-if="withIntermediary"
                @save-intermediary="saveIntermediary"
                @intermediary-created="refreshIntermediaries"
            />
        </v-container>
    </v-card>
</template>

<script>
import {deleteClient, fetchRegions, showAlertModal} from "@/utils/utils";
import emitter from "@/utils/emitter";
import getCurrentDateTime from "@/utils/currentTimeFunction";
import apiClient from "@/utils/apiClient";
import IntermediarySelect from "@/components/clients/IntermediarySelect.vue";
import CreateIntermediary from "@/components/clients/CreateIntermediary.vue";
import {mapGetters} from "vuex";

export default {
    components: {CreateIntermediary, IntermediarySelect},
    emits: ['update-required-fields-values', 'updated-client'],
    data() {
        return {
            editMode: false,
            editableClient: {},
            newIntermediary: null,
            withIntermediary: false,
            clientTypes: [],
            selectedAgent: parseInt(JSON.parse(sessionStorage.getItem('userId'))),
            dialog: false,
            requiredFieldsErrors: {
                full_name: {
                    message: null,
                    valid: true,
                },
                phone: {
                    message: null,
                    valid: true,
                },
                email: {
                    message: null,
                    valid: true,
                },
                cnp: {
                    message: null,
                    valid: true,
                },
                id_card_series: {
                    message: null,
                    valid: true,
                },
                id_card_number: {
                    message: null,
                    valid: true,
                },
                region: {
                    message: null,
                    valid: true,
                },
                city: {
                    message: null,
                    valid: true,
                },
                address: {
                    message: null,
                    valid: true,
                },
            },
            regions: [],
        }
    },
    props: {
        activeLink: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true
        },
        renderedByClientContract: {
            type: Boolean,
            default: false,
        }
    },
    created() {
        this.editableClient = JSON.parse(JSON.stringify(this.client));
        this.clientTypes = JSON.parse(sessionStorage.getItem('clientTypes')) || [];
    },
    async mounted() {
        this.regions = await fetchRegions();
        if (this.$parent.$options.name === 'ClientContract') {
            this.validateRequiredFields();
        }
    },
    computed: {
        ...mapGetters(['userPermissions', 'userRole']),
        /**
         * Returns an array of agents filtered by the current user's role.
         * If the user is a superAgent, they only see agents who have parent_agent = currentUserId (or themselves).
         * Otherwise, returns all users from session storage.
         * @returns {Array<Object>} An array of user objects.
         */
        availableAgents() {
            let users = JSON.parse(sessionStorage.getItem('myUsers'));
            let currentUserId = JSON.parse(sessionStorage.getItem('userId'));
            if (this.userRole === 'superAgent') {
                users = users.filter(user => user.parent_agent === currentUserId || user.id === currentUserId);
            }
            return users;
        },
    },
    watch: {
        /**
         * Watches for changes in the `client` prop to keep the local `editableClient` in sync.
         */
        client: {
            deep: true,
            handler(newVal) {
                this.editableClient = JSON.parse(JSON.stringify(newVal));
            }
        }
    },
    methods: {
        refreshIntermediaries() {
            this.$refs.intermediarySelect.updateIntermediaries();
        },
        /**
         * Validates all required fields in the editableClient object:
         *  - Calls individual validation methods for phone, cnp, email, ID card series/number, etc.
         *  - Emits an event ('update-required-fields-values') containing updated validation states.
         *  - Populates `requiredFieldsErrors` with any messages and boolean validity results.
         */
        validateRequiredFields() {
            const phoneValid = this.validatePhone();
            const cnpValid = this.validateCnp();
            const emailValid = this.validateEmail();
            const idCardSeriesValid = this.validateIdCardSeries();
            const idCardNumberValid = this.validateIdCardNumber();
            const cityValid = this.validateCity();
            const addressValid = this.validateAddress();

            const requiredFields = {
                full_name: {
                    message: this.editableClient.full_name ? '' : 'Numele complet este obligatoriu.',
                    valid: !!this.editableClient.full_name,
                },
                phone: {
                    message: phoneValid === true ? '' : phoneValid,
                    valid: phoneValid === true,
                },
                email: {
                    message: emailValid === true ? '' : emailValid,
                    valid: emailValid === true,
                },
                cnp: {
                    message: cnpValid === true ? '' : cnpValid,
                    valid: cnpValid === true,
                },
                id_card_series: {
                    message: idCardSeriesValid === true ? '' : idCardSeriesValid,
                    valid: idCardSeriesValid === true,
                },
                id_card_number: {
                    message: idCardNumberValid === true ? '' : idCardNumberValid,
                    valid: idCardNumberValid === true,
                },
                region: {
                    message: this.editableClient.region ? '' : 'Selectați un județ.',
                    valid: !!this.editableClient.region,
                },
                city: {
                    message: cityValid === true ? '' : cityValid,
                    valid: cityValid === true,
                },
                address: {
                    message: addressValid === true ? '' : addressValid,
                    valid: addressValid === true,
                },
            };

            // Emit or display the validation results
            this.$emit('update-required-fields-values', requiredFields);
            this.requiredFieldsErrors = requiredFields;
        },
        /**
         * Prompts a dialog to confirm client deletion, then deletes the client if confirmed.
         * Closes the dialog upon completion and navigates to /home if deletion is successful.
         * Uses the global utility function `deleteClient`.
         * @async
         * @returns {Promise<void>}
         */
        async triggerDeleteClient() {
            this.dialog = false;
            await deleteClient(this.client.id, '/home', this);
        },
        /**
         * Closes the `CreateIntermediary` component by setting `withIntermediary` to false.
         * Typically used when the user cancels or completes intermediary creation.
         */
        closeCreateIntermediary() {
            this.withIntermediary = false;
        },
        /**
         * Sets `withIntermediary` to false and updates the editableClient's intermediate_id property
         * with the newly selected or changed intermediary.
         * @param {number|string} intermediary - The ID or unique identifier for the chosen intermediary.
         */
        changeIntermediary(intermediary) {
            this.withIntermediary = false;
            this.editableClient.intermediate_id = intermediary;
        },
        /**
         * Receives a newly created intermediary from the CreateIntermediary component,
         * assigns it to `newIntermediary`, and updates the `editableClient.intermediate_id`.
         * @param {Object} intermediary - The full intermediary object created.
         */
        saveIntermediary(intermediary) {
            this.newIntermediary = intermediary;
            this.editableClient.intermediate_id = intermediary;
        },
        /**
         * A placeholder method that could be used to emit an event or handle logic
         * when the user clicks an "edit client" button. Currently emits 'edit-client'.
         */
        editClient() {
            emitter.emit('edit-client', this.client);
        },
        /**
         * Validates the phone number on the editableClient object.
         * Ensures either (a) no phone is set, or (b) it is 10 digits or starts with '+' followed by 11 digits.
         * If this.renderedByClientContract is true, phone is mandatory.
         * @returns {true|string} Returns true if valid, or an error message otherwise.
         */
        validatePhone() {
            const phone = this.editableClient.phone;
            if (!phone) {
                if (this.renderedByClientContract) {
                    return 'Introduceti un număr de telefon valid.';
                }
                return true;

            }
            if (phone.length === 0) {
                if (this.renderedByClientContract) {
                    return 'Introduceti un număr de telefon valid.';
                }
                return true;
            }
            if (
                (!/^\d{10}$/.test(phone)) &&
                (!/^\+\d{11}$/.test(phone))
            ) {
                return 'Introduceti un număr de telefon valid, format din 10 cifre sau incepand cu + si 11 cifre.';
            }
            return true;
        },
        /**
         * Validates the CNP (Romanian personal numeric code) on the editableClient object.
         * Checks length = 13, must start with '1' or '2'.
         * If this.renderedByClientContract is true, CNP is mandatory.
         * @returns {true|string} Returns true if valid, or an error message otherwise.
         */
        validateCnp() {
            const cnp = this.editableClient.cnp;
            if (!cnp) {
                if (this.renderedByClientContract) {
                    return 'Introduceti un CNP valid.';
                }
                return true;
            }
            if (cnp.length !== 13) {
                return 'CNP trebuie să aibă exact 13 caractere.';
            }
            if (!/^[12]/.test(cnp)) {
                return 'CNP trebuie să înceapă cu 1 sau 2.';
            }
            return true;
        },
        /**
         * Validates the email address on the editableClient object.
         * Uses a simple regex to check for a well-formed address.
         * If renderedByClientContract is true, email is mandatory.
         * @returns {true|string} Returns true if valid, or an error message otherwise.
         */
        validateEmail() {
            const email = this.editableClient.email;
            if (!email || email.length === 0) {
                if (this.renderedByClientContract) {
                    return 'Introduceti o adresă de email validă.';
                }
                return true;
            }
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!pattern.test(email)) {
                return 'Introduceti o adresă de email validă.';
            }
            return true;
        },
        /**
         * Validates the ID card series in `editableClient.id_card_series`.
         * Expects at least 2 letters, only alphabetic.
         * @returns {true|string} Returns true if valid, or an error message otherwise.
         */
        validateIdCardSeries() {
            const series = this.editableClient.id_card_series;
            if (!series || series.length < 2) {
                return 'Seria buletinului trebuie să conțină cel puțin 2 litere.';
            }
            if (!/^[A-Za-z]+$/.test(series)) {
                return 'Seria buletinului poate conține doar litere.';
            }
            return true;
        },
        /**
         * Validates the ID card number in `editableClient.id_card_number`.
         * Must be exactly 6 numeric digits.
         * @returns {true|string} Returns true if valid, or an error message otherwise.
         */
        validateIdCardNumber() {
            const number = this.editableClient.id_card_number;
            if (!number || number.length !== 6) {
                return 'Numărul buletinului trebuie să aibă exact 6 cifre.';
            }
            if (!/^\d{6}$/.test(number)) {
                return 'Numărul buletinului trebuie să conțină doar cifre.';
            }
            return true;
        },
        /**
         * Validates the city name in `editableClient.city`.
         * Must contain at least 3 characters.
         * @returns {true|string} Returns true if valid, or an error message otherwise.
         */
        validateCity() {
            const city = this.editableClient.city;
            if (!city || city.trim().length < 3) {
                return 'Municipiul trebuie să conțină cel puțin 3 caractere.';
            }
            return true;
        },
        /**
         * Validates the address in `editableClient.address`.
         * Must contain at least 3 characters.
         * @returns {true|string} Returns true if valid, or an error message otherwise.
         */
        validateAddress() {
            const address = this.editableClient.address;
            if (!address || address.trim().length < 3) {
                return 'Adresa trebuie să conțină cel puțin 3 caractere.';
            }
            return true;
        },
        /**
         * Navigates to the Client Details page for a given client ID.
         * Typically triggered by clicking the client's name in the toolbar.
         * @param {number} clientId - The unique ID of the client to view details for.
         */
        goToClientDetails(clientId) {
            this.$router.push({name: "ClientDetails", params: {id: parseInt(clientId)}});
        },
        /**
         * Toggles edit mode on/off. When turning edit mode off, attempts to save the client (if changes were made).
         * Also closes any open intermediary creation dialog.
         * @param {boolean} value - true to enable edit mode, false to disable and attempt to save.
         */
        toggleEditMode(value) {
            this.editMode = value;
            //if value is false then we also need to close the intermediary creation window
            if (!value) {
                this.saveClient();
                this.closeCreateIntermediary()
            }
        },
        /**
         * Saves the edited client to the backend or creates a new one if necessary.
         * - Validates phone and possibly other required fields if `renderedByClientContract` is true.
         * - Makes an API call to update (PUT) or create (POST) the client.
         * - Updates local session storage (`myClients`) to keep in sync.
         * - Emits an 'updated-client' event with the new/updated client data.
         * @async
         * @returns {Promise<void>} No return value; updates data in place and triggers events.
         */
        async saveClient() {
            if (this.full_name === '') {
                this.showMissingInfo = true;
                this.error = 'Introduceti un nume.';
            } else {
                this.error = '';
                const phoneValid = this.validatePhone();
                if (!phoneValid) {
                    return;
                }
                if (this.renderedByClientContract) {
                    // Validate fields before saving
                    this.validateRequiredFields();

                    // Check if all required fields are valid
                    const allValid = Object.values(this.requiredFieldsErrors).every(field => field.valid);

                    if (!allValid) {
                        showAlertModal(this.$store, 'Există erori în formular. Vă rugăm să corectați înainte de a salva.', 'warning', 5000);
                        return;
                    }
                }
                let client = {
                    agent_id: this.editableClient.agent_id,
                    cif: "",
                    created: getCurrentDateTime(),
                    email: this.editableClient.email,
                    full_name: this.editableClient.full_name,
                    modified: getCurrentDateTime(),
                    intermediate_id: this.editableClient.intermediate_id,
                    notes: '',
                    phone: this.editableClient.phone,
                    type: this.editableClient.client_type,
                    cnp: this.editableClient.cnp,
                    id_card_series: this.editableClient.id_card_series || '',
                    id_card_number: this.editableClient.id_card_number || '',
                    region: this.editableClient.region || null,
                    city: this.editableClient.city || '',
                    address: this.editableClient.address || '',
                }

                // Retrieve the 'myClients' array from sessionStorage
                let myClients = JSON.parse(sessionStorage.getItem('myClients')) || [];

                try {
                    if (this.client) {
                        // Update existing client
                        const response = await apiClient.put(`/api/update_client/${this.client.id}/`, client);
                        emitter.emit('client-updated', response.data);
                        this.$emit('updated-client', response.data);

                        // Update the client in the 'myClients' array
                        const clientIndex = myClients.findIndex(c => c.id === this.client.id);
                        if (clientIndex !== -1) {
                            myClients.splice(clientIndex, 1); // Remove the client from its current position
                            myClients.unshift(response.data); // Add the updated client to the beginning of the array
                        }

                        if (this.renderedByClientContract) {
                            // Call validateRequiredFields after successful creation
                            this.validateRequiredFields();
                        }
                    } else {
                        // Add new client
                        let newClient;
                        await apiClient.post('/api/add_client/', client)
                            .then(response => {
                                // handle successful response
                                emitter.emit('client-saved');
                                this.$emit('updated-client', response.data.client);
                                // Add the new client to the 'myClients' array
                                myClients.unshift(response.data.client);
                                newClient = response.data.client;
                            })
                            .then(() => {
                                this.$emit('client-added', newClient);

                                if (this.renderedByClientContract) {
                                    // Call validateRequiredFields after successful creation
                                    this.validateRequiredFields();
                                }
                            })
                            .catch(error => {
                                showAlertModal(this.$store, 'A apărut o eroare la salvarea clientului. Vă rugăm să încercați din nou.', 'danger', 12000, error);
                                if (error.response && error.response.status === 400) {
                                    alert('A aparut o eroare la salvarea clientului: ' + error.response.data.message);
                                } else {
                                    console.log('A aparut o eroare: ', error.response.data.message);
                                    // handle other errors
                                }
                            });
                    }

                    // Save the updated 'myClients' array back to sessionStorage
                    sessionStorage.setItem('myClients', JSON.stringify(myClients));
                } catch (error) {
                    showAlertModal(this.$store, 'A apărut o eroare la salvarea clientului. Vă rugăm să încercați din nou.', 'danger', 12000, error);
                    if (error.response && error.response.status === 400) {
                        alert('A aparut o eroare la salvarea clientului: ' + error.response.data.message);
                    } else {
                        console.log('A aparut o eroare: ', error.response.data.message);
                        // handle other errors
                    }
                }
            }
        },
    }
}
</script>
