<template>
    <v-dialog v-model="showClientDialog" width="50vw">
        <ClientInfo :client="currentClient"/>
    </v-dialog>
    <v-dialog v-model="showLocationDialog" width="50vw">
        <ClientLocation :location="selectedLocation"/>
    </v-dialog>
    <v-dialog v-model="showDeleteClientDialog" width="auto">
        <v-card>
            <v-card-text>
                Ești sigur că vrei să ștergi clientul?
            </v-card-text>
            <v-card-actions class="justify-content-end">
                <v-btn color="primary" variant="text" @click="showDeleteClientDialog = false">
                    Nu
                </v-btn>
                <v-btn data-cy="confirm-client-delete" color="danger" variant="text" @click="triggerDeleteClient">
                    Da
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-skeleton-loader type="card" :loading="loading">
        <v-data-table
            :headers="filterSuperUserHeaders"
            :items="clients"
            :page="currentPage"
            :items-per-page="itemsPerPage"
            :items-per-page-options="itemsPerPageOptions"
            :server-items-length="totalClients"
            :loading="loading"
            :expanded="expandedRows"
            hide-default-header
            @update:page="updatePage"
            @update:items-per-page="updateItemsPerPage"
        >
            <template v-slot:headers="{ columns }">
                <tr style="border-bottom: 1px solid rgba(0,0,0,0.10)">
                    <template v-for="column in columns" :key="column.key">
                        <td>
                            <span class="mr-2 cursor-pointer" style="font-weight: 700;">{{ column.title }}</span>
                            <v-icon style="cursor:pointer;" v-if="column.hasMenu" size="small"
                                    class="fas fa-chevron-down">
                                <v-menu activator="parent" :close-on-content-click="true" class="table-dropdown-menu">
                                    <v-card variant="outlined"
                                            style=" max-height: 11cm; min-width: 4.5cm; overflow: auto;">
                                        <v-list style="list-style:none" density="compact">
                                            <template v-if="column.sortKey">
                                                <v-list-item @click="sortBy(column.sortKey, 'ASC')"> Sortare asc.
                                                </v-list-item>
                                                <v-list-item @click="sortBy(column.sortKey, 'DESC')"> Sortare desc.
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </template>
                                            <slot :name="column.menuSlotName"></slot>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                            </v-icon>
                        </td>
                    </template>
                </tr>
            </template>

            <template v-slot:[`item.agent_id`]="{ item }">
                <span>{{ parseAgentId(item.agent_id) }}</span>
            </template>
            <template v-slot:[`item.full_name`]="{ item }">
                <a href="#" class="text-decoration-none" @click.prevent="goToClientDetails(item.id)">{{
                        item.full_name
                    }} </a>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <span>{{ parseClientType(item.type) }}</span>
            </template>
            <template v-slot:[`item.modified`]="{ item }">
                <span>{{ showDateTime(item.modified) }}</span>
            </template>
            <template v-slot:[`item.intermediate_id`]="{ item }">
                <span>{{ parseIntermediaryId(item.intermediate_id) }}</span>
            </template>
            <template v-slot:[`item.email`]="{ item }">
                <span>{{ item.email ? item.email : defaultValuePlaceholder }}</span>
            </template>
            <template v-slot:[`item.phone`]="{ item }">
                <span>{{ item.phone ? item.phone : defaultValuePlaceholder }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon>
                    <v-icon
                        color="grey"
                        data-cy="client-actions"
                    >
                        mdi-dots-vertical
                    </v-icon>
                    <v-menu activator="parent">
                        <v-list>
                            <v-list-item
                                v-for="(action, index) in actionMenuItems"
                                :key="index"
                                @click="action.method(item)"
                                :data-cy="action.data_cy"
                            >
                                <v-list-item-title :class="{ 'text-danger': action.isDanger }">{{
                                        action.label
                                    }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </template>
            <template v-slot:expanded-row="{ item, columns }">
                <tr v-if="item.offers">
                    <td class="py-2" :colspan="columns.length -1" style="border-top:0">
                        <OffersCard :searchBy="{criteria: 'client',value: item.id}" :collapsed="false"
                                    :search-offer-type="searchOfferType"
                                    :client-offers-data="item.offers"></OffersCard>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-skeleton-loader>
</template>

<script>
import emitter from "@/utils/emitter";
import {deleteClient} from "@/utils/utils";
import ClientInfo from "@/components/clients/ClientInfo.vue";
import OffersCard from "@/components/clients/OffersCard.vue";
import ClientLocation from "@/components/clients/ClientLocation.vue";
import {mapGetters} from "vuex";

export default {
    name: "ClientsTableList",
    components: {
        ClientInfo,
        OffersCard,
        ClientLocation,
    },
    data() {
        return {
            calculatedHeight: "calc(-173px + 100vh)",
            showClientDialog: false,
            showLocationDialog: false,
            selectedLocation: null,
            clientTypes: JSON.parse(sessionStorage.getItem('clientTypes')),
            actionMenuItems: [],
            sortKey: 'client',
            sortDirection: 'NONE',
            currentClient: null,
            dialog: false,
            menu: false,
            showDeleteClientDialog: false,
            defaultValuePlaceholder: 'Nu',
            vHeaders: [
                {
                    title: 'Agent',
                    align: 'start',
                    key: 'agent_id',
                    adminColumn: true,
                    hasMenu: true,
                    menuSlotName: 'agentMenu',
                    sortKey: 'agent'
                },
                {
                    title: 'Nume client',
                    align: 'start',
                    key: 'full_name',
                    hasMenu: true,
                    menuSlotName: 'clientMenu',
                    sortKey: 'full_name'
                },
                {title: 'Tip client', align: 'start', key: 'type', hasMenu: true, menuSlotName: 'clientTypeMenu'},
                {
                    title: 'Intermediari',
                    align: 'start',
                    key: 'intermediate_id',
                    hasMenu: true,
                    menuSlotName: 'intermediaryMenu',
                    sortKey: 'intermediate_id',
                },
                {
                    title: 'Data modificării',
                    align: 'start',
                    key: 'modified',
                    hasMenu: true,
                    menuSlotName: 'dateMenu',
                    sortKey: 'modified'
                },
                {title: 'Email', align: 'start', key: 'email'},
                {title: 'Telefon', align: 'start', key: 'phone'},
                {title: 'Acțiuni', align: 'start', key: 'actions', sortable: false},
            ],
            itemsPerPageOptions: [
                {value: 25, title: '25'},
                {value: 50, title: '50'},
                {value: 100, title: '100'},
            ],
        }
    },
    props: {
        clients: Array,
        agents: Array,
        intermediaries: Array,
        totalClients: Number,
        itemsPerPage: Number,
        currentPage: Number,
        loading: Boolean,
        searchOfferType: Number,
    },
    emits: ['updated-page', 'updated-items-per-page', 'updated-sorting',],
    mounted() {
        this.recentLocations = JSON.parse(sessionStorage.getItem('myRecentLocations'))
        emitter.on('client-updated', () => {
            this.showClientDialog = false;
        })
        emitter.on('location-saved', () => {
            this.showLocationDialog = false;
        })
        this.actionMenuItems = [
            {label: 'Modifică', method: this.editingClient, isDanger: false, data_cy: ''},
            {label: 'Adaugă locație', method: this.openAddLocationModal, isDanger: false, data_cy: ''},
            {label: 'Șterge', method: this.openDeleteClientDialog, isDanger: true, data_cy: 'delete-client'},
        ];
    },
    unmounted() {
        emitter.off('client-updated');
        emitter.off('location-saved');
    },
    computed: {
        ...mapGetters(['userPermissions']),
        emitter() {
            return emitter;
        },
        filterSuperUserHeaders() {
            return this.userPermissions.clientManagement.canViewAllClientsListHeaders ? this.vHeaders : this.vHeaders.filter(header => !header.adminColumn)
        },
        expandedRows() {
            return this.clients.filter(client => client.offers?.length).map(client => client.id)
        }
    },
    methods: {
        /**
         * Parses a client type numeric value into a human-readable text label.
         *
         * @function parseClientType
         * @param {number} type - The integer value representing a client type.
         * @returns {string} The textual label corresponding to the provided type value,
         *   or an empty string if not found.
         */
        parseClientType(type) {
            return this.clientTypes.find(clientType => clientType.value === type).text ?? ''
        },
        /**
         * Formats a date-time string into a localized format with year, month, day, and time.
         *
         * @function showDateTime
         * @param {string|Date} value - The date-time to format (ISO string or Date object).
         * @returns {string} A localized date-time string in the "ro-RO" locale.
         */
        showDateTime(value) {
            let options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            };
            return new Date(value).toLocaleDateString('ro-RO', options);
        },
        /**
         * Retrieves and displays the agent's full name or email based on an agent ID.
         *
         * @function parseAgentId
         * @param {number} agent_id - The unique ID of the agent.
         * @returns {string} The agent's full name or email if found, otherwise a placeholder text.
         */
        parseAgentId(agent_id) {
            let agent = this.agents.find(agent => agent.id === agent_id)
            return agent?.full_name ? agent?.full_name : agent?.email ?? this.defaultValuePlaceholder;
        },
        /**
         * Retrieves and displays the intermediary's name based on an intermediary ID.
         *
         * @function parseIntermediaryId
         * @param {number} intermediary_id - The unique ID of the intermediary.
         * @returns {string} The intermediary's name if found, otherwise a placeholder text.
         */
        parseIntermediaryId(intermediary_id) {
            let intermediary = this.intermediaries.find(intermediary => intermediary.id === intermediary_id)
            return intermediary?.name ?? this.defaultValuePlaceholder
        },
        /**
         * Opens the dialog to edit a client's information.
         *
         * @function editingClient
         * @param {Object} client - The client object to be edited.
         */
        editingClient(client) {
            this.currentClient = client;
            this.showClientDialog = true;
        },
        /**
         * Opens the dialog to add a location associated with a specific client.
         *
         * @function openAddLocationModal
         * @param {Object} client - The client object for which a location is being added.
         */
        openAddLocationModal(client) {
            this.currentClient = client;
            this.$store.state.clientId = client.id
            this.showLocationDialog = true;
        },
        /**
         * Opens a confirmation dialog to delete a client.
         *
         * @function openDeleteClientDialog
         * @param {Object} client - The client object to be deleted.
         */
        openDeleteClientDialog(client) {
            this.currentClient = client
            this.showDeleteClientDialog = true;
        },
        /**
         * Confirms and triggers the deletion of a client, then closes the dialog.
         *
         * @async
         * @function triggerDeleteClient
         * @returns {Promise<void>} Resolves after attempting to delete the client and
         *   updating the UI accordingly.
         */
        async triggerDeleteClient() {
            await deleteClient(this.currentClient.id, null, this);
            this.showDeleteClientDialog = false;
        },
        /**
         * Navigates or opens a new tab to show the details page of the specified client.
         *
         * @function goToClientDetails
         * @param {number} clientId - The unique ID of the client.
         */
        goToClientDetails(clientId) {
            const url = this.$router.resolve({name: "ClientDetails", params: {id: parseInt(clientId)}}).href;
            window.open(url, '_blank');
        },
        /**
         * Sorts the client list based on a provided key and direction, then emits an event
         * with the updated sorting state.
         *
         * @function sortBy
         * @param {string} key - The property/key by which to sort.
         * @param {string} direction - The direction of the sort ("ASC", "DESC", or "NONE").
         */
        sortBy(key, direction) {
            this.sortKey = key;
            this.sortDirection = this.sortDirection !== direction ? direction : 'NONE';
            let newSorting = {
                key: this.sortKey,
                direction: this.sortDirection,
            }
            this.$emit('updated-sorting', newSorting);
        },
        /**
         * Updates the current page of data in the table and emits an event for it.
         *
         * @function updatePage
         * @param {number} newPage - The new page number to display in the table.
         */
        updatePage(newPage) {
            this.$emit('updated-page', newPage);
        },
        /**
         * Updates the number of items displayed per page in the table and emits an event for it.
         *
         * @function updateItemsPerPage
         * @param {number} newItemsPerPage - The new items-per-page value.
         */
        updateItemsPerPage(newItemsPerPage) {
            this.$emit('updated-items-per-page', newItemsPerPage);
        }
    },
}
</script>

<style>
tr:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>