<template>
    <a href="#" :id="sidebarLinks[0].id" :ref="sidebarLinks[0].id"></a>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title>
                Listă prețuri
            </v-toolbar-title>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col>
                    <v-select
                        :items="priceListTypes"
                        v-model="selectedPriceListType"
                        variant="outlined"
                        item-title="title"
                        item-value="value"
                        label="Selectează tipul listei de preț"
                        density="compact"
                        hide-details
                        @change="getPriceLists"
                    />
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col cols="4">
                    <v-file-input label="Încarcă listă de produse"
                                  @change="this.showUpload = true;" ref="file"
                                  prepend-inner-icon="mdi-paperclip"
                                  prepend-icon=""
                                  density="compact"
                                  v-model="fileToUpload"
                                  :variant="this.$store.state.vuetifyFieldType"
                                  clearable
                    >
                    </v-file-input>
                </v-col>
                <v-col cols="4">
                    <v-select
                        :disabled="!fileToUpload"
                        :variant="this.$store.state.vuetifyFieldType"
                        density="compact"
                        clearable
                        v-model="existingPriceListId"
                        :value="existingPriceListId ? existingPriceListId : dateApplied"
                        :items="priceLists"
                        item-title="date_applied"
                        item-value="id"
                        :label="!isNewPriceList ? 'Selectează listă preț' : 'Creează listă preț'"
                        closable-chips
                        :dirty="isNewPriceList"
                    >
                        <template
                            v-slot:prepend-item
                        >
                            <v-list-item
                                @click="openNewPriceListDialog"
                            >
                                Creează listă nouă
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <v-dialog v-model="newListDialog" width="50vw">
                    <v-card>
                        <v-card-title>Creează listă nouă</v-card-title>
                        <v-card-text>
                            <v-locale-provider locale="ro">
                                <v-text-field
                                    ref="dateInput"
                                    v-model="dateApplied"
                                    label="Data aplicării"
                                    density="compact"
                                    type="date"
                                    :variant="this.$store.state.vuetifyFieldType"
                                    clearable
                                    @update:modelValue="checkExistingPriceList(dateApplied)"
                                ></v-text-field>
                                <v-alert
                                    v-if="showExistingPriceListError"
                                    density="compact"
                                    type="warning"
                                    title="Atenție!"
                                    closable
                                    close-label="Închide"
                                    text="Pentru data selectată există deja o listă de preț. Pentru a
    suprascrie lista, continuați cu selecția curentă sau selectați altă dată de aplicare pentru o listă nouă."
                                ></v-alert>
                            </v-locale-provider>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="danger"
                                variant="elevated"
                                @click="closeNewPriceListDialog"
                            >
                                Anulează
                            </v-btn>
                            <v-btn
                                color="primary"
                                variant="elevated"
                                style="color: white !important;"
                                @click="closeNewPriceListDialog"
                            >
                                Salvează
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-col cols="4">
                    <v-btn class="w-100"
                           :disabled="!showUpload"
                           @click="uploadFile"
                           size="large"
                           text
                    >
                        Încarcă fișier
                    </v-btn>
                </v-col>
            </v-row>
            <v-container v-if="uploadWarnings.length > 0">
                <v-row
                    v-for="warning in uploadWarnings"
                    :key="warning">
                    <v-alert
                        class="mt-3"
                        density="compact"
                        type="warning"
                        title="Atenție!"
                        closable
                        close-label="Închide"
                        :text="warning"
                    ></v-alert>
                </v-row>
            </v-container>
            <v-container v-if="uploadErrors.length > 0">
                <v-row
                    v-for="error in uploadErrors"
                    :key="error">
                    <v-alert
                        class="mt-3"
                        density="compact"
                        type="error"
                        title="Eroare!"
                        closable
                        close-label="Închide"
                        :text="error"
                    ></v-alert>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-row justify="space-between">
                <v-col cols="4">
                    <v-select :variant="this.$store.state.vuetifyFieldType"
                              density="compact"
                              v-model="selectedPriceList"
                              :items="priceLists"
                              item-title="date_applied"
                              item-value="id"
                              label="Listă preț"
                              closable-chips
                              @update:modelValue="getPriceListProducts"
                    ></v-select>
                </v-col>
                <v-col cols="4">
                    <v-tooltip activator="parent"
                               location="top"
                    >
                        Descarcă lista de prețuri actuală
                    </v-tooltip>
                    <v-btn size="large"
                           class="w-100"
                           color="surface"
                           prepend-icon="mdi-download"
                           :loading="isDownloadingPriceList"
                           text
                           @click="downloadPriceList"
                    >
                        Descarcă
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-container>
        <a href="#" :id="sidebarLinks[1].id" :ref="sidebarLinks[1].id"></a>
        <v-card-item>
            <v-locale-provider :locale="this.$store.state.i18nLocale">
                <v-data-table
                    :variant="this.$store.state.vuetifyFieldType"
                    clearable
                    :headers="headers"
                    :items="search !== '' ? filteredItems : items"
                    :items-per-page="50"
                    :custom-key-sort="customSort"
                    class="elevation-1"
                    :loading="loading"
                    :height="calculatedHeight"
                    fixed-header
                >
                    <template v-slot:top>
                        <div ref="toolbarRef">
                            <v-toolbar
                            >
                                <v-row justify="space-between" align="start">
                                    <v-col cols="4">
                                        <v-text-field
                                            variant="outlined"
                                            class="ml-2"
                                            clearable
                                            v-model="search"
                                            density="compact"
                                            prepend-inner-icon="mdi-magnify"
                                            label="Caută produs"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-form ref="form">
                                            <v-dialog
                                                v-model="dialog"
                                                width="50vw"
                                                @update:modelValue="setLatestPriceList"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-btn
                                                        color="primary"
                                                        v-bind="props"
                                                        size="large"
                                                        class="w-100"
                                                        variant="elevated"
                                                        style="color: white !important;"
                                                        text
                                                    >
                                                        Adaugă produs
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>
                                                        <span class="text-h5">{{ formTitle }}</span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col cols="6">
                                                                    <v-text-field
                                                                        :variant="this.$store.state.vuetifyFieldType"
                                                                        clearable
                                                                        v-model="editedItem.product_code"
                                                                        label="Cod produs"
                                                                        :rules="productCodeRules"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-text-field
                                                                        :variant="this.$store.state.vuetifyFieldType"
                                                                        clearable
                                                                        v-model="editedItem.product_name"
                                                                        label="Nume produs"
                                                                        :rules="productNameRules"
                                                                        :hint="productNameHint"
                                                                        persistent-hint
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="6">
                                                                    <v-select
                                                                        :variant="this.$store.state.vuetifyFieldType"
                                                                        clearable
                                                                        v-model="editedItem.measurement_unit"
                                                                        :items="measurementUnits"
                                                                        item-title="text"
                                                                        item-value="value"
                                                                        label="Unitate de măsură"
                                                                        closable-chips
                                                                    ></v-select>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-text-field
                                                                        :variant="this.$store.state.vuetifyFieldType"
                                                                        clearable
                                                                        v-model="editedItem.price"
                                                                        label="Preț"
                                                                        :rules="priceRules"
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col v-if="!isEditingItem" cols="12">
                                                                    <v-select
                                                                        :variant="this.$store.state.vuetifyFieldType"
                                                                        clearable
                                                                        v-model="editedItem.price_list_ids"
                                                                        :items="priceLists"
                                                                        item-title="date_applied"
                                                                        item-value="id"
                                                                        label="Liste de prețuri"
                                                                        multiple
                                                                        chips
                                                                        return-object
                                                                        closable-chips
                                                                    ></v-select>
                                                                </v-col>
                                                                <v-col
                                                                    v-else
                                                                    v-for="(priceListGroup, type) in groupedPriceLists"
                                                                    :key="type" cols="12">
                                                                    <v-select
                                                                        :variant="this.$store.state.vuetifyFieldType"
                                                                        clearable
                                                                        v-model="editedItem.price_list_ids[type]"
                                                                        :items="priceListGroup"
                                                                        item-title="date_applied"
                                                                        item-value="id"
                                                                        :label="`Liste de prețuri ${this.$store.state.PRICE_LIST_TYPES[type.toUpperCase()].translation}`"
                                                                        multiple
                                                                        chips
                                                                        return-object
                                                                        closable-chips
                                                                    ></v-select>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="danger"
                                                            variant="elevated"
                                                            @click="close"
                                                        >
                                                            Anulează
                                                        </v-btn>
                                                        <v-btn
                                                            color="primary"
                                                            variant="elevated"
                                                            style="color: white !important;"
                                                            @click="save"
                                                        >
                                                            Salvează
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-form>
                                    </v-col>
                                </v-row>
                                <v-dialog v-model="dialogDelete" width="50vw">
                                    <v-card>
                                        <v-card-title class="text-h5">Ești sigur că vrei să ștergi produsul?
                                        </v-card-title>
                                        <v-card-subtitle>Din care listă de prețuri vrei să ștergi produsul? <br/>
                                            <v-col v-for="(priceListGroup, type) in groupedPriceLists" :key="type"
                                                   cols="12">
                                                <v-select
                                                    :variant="this.$store.state.vuetifyFieldType"
                                                    clearable
                                                    v-model="editedItem.price_list_ids[type]"
                                                    :items="priceListGroup"
                                                    item-title="date_applied"
                                                    item-value="id"
                                                    :label="`Lista prețuri ${this.$store.state.PRICE_LIST_TYPES[type.toUpperCase()].translation}`"
                                                    chips
                                                    multiple
                                                    closable-chips
                                                ></v-select>
                                            </v-col>
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" variant="text" @click="closeDelete">Anulează
                                            </v-btn>
                                            <v-btn color="primary" variant="text" @click="deleteItemConfirm">Salvează
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        {{ formatNumber(this, item.price) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            size="small"
                            class="me-2"
                            color="primary"
                            @click="editItem(item)"
                        >
                            mdi-pencil
                        </v-icon>

                        <v-icon
                            size="small"
                            color="danger"
                            @click="deleteItem(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initialize"
                        >
                            Reset
                        </v-btn>
                    </template>
                </v-data-table>
            </v-locale-provider>
        </v-card-item>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {formatNumber, showAlertModal, sortByProductCode} from "@/utils/utils";
import {cloneDeep} from "lodash";

export default {
    components: {},
    data() {
        return {
            calculatedHeight: "calc(100vh - 64px)", // Initial value
            sidebarLinks: [
                {
                    name: 'Listă de prețuri',
                    link: '#section1',
                    id: 'section1'
                },
                {
                    name: 'Listă de produse',
                    link: '#section2',
                    id: 'section2'
                },
            ],
            uploadPriceList: null,
            dialog: false,
            dialogDelete: false,
            newListDialog: false,
            isNewPriceList: false,
            isDownloadingPriceList: false,
            headers: [
                {title: 'Cod produs', key: 'product_code', align: 'start', sortable: true},
                {title: 'Nume', key: 'product_name', align: 'start', sortable: true},
                {title: 'U.M.', key: 'measurement_unit', align: 'center', sortable: true},
                {title: 'Preț', key: 'price', align: 'end', sortable: true},
                {title: 'Acțiuni', key: 'actions', sortable: false},
            ],
            items: [],
            editedIndex: -1,
            editedItem: {
                product_code: '',
                product_name: '',
                measurement_unit: 'buc',
                price: '',
            },
            defaultItem: {
                product_code: '',
                product_name: '',
                measurement_unit: 'buc',
                price: '',
            },
            measurementUnits: [
                {text: 'm²', value: 'm²', number: 1},
                {text: 'm', value: 'm', number: 2},
                {text: 'pungă', value: 'pungă', number: 3},
                {text: 'buc', value: 'buc', number: 4},
                {text: 'l', value: 'l', number: 5},
                {text: 'set', value: 'set', number: 6},
                {text: 'cutie', value: 'cutie', number: 7},
                {text: 'rolă', value: 'rolă', number: 8},
                {text: "km", value: "km", number: 9},
                {text: "kg", value: "kg", number: 10},
                {text: "ora", value: "ora", number: 11},
            ],
            customSort: {
                'product_code': function (a, b, direction = 1) { // add direction parameter with default value 1
                    if (a && b) {
                        const aParts = a.split('.').map(Number); // a is already product_code
                        const bParts = b.split('.').map(Number); // b is already product_code
                        for (let i = 0; i < aParts.length; i++) {
                            if (aParts[i] !== bParts[i]) {
                                return direction * (aParts[i] - bParts[i]); // multiply the result by direction
                            }
                        }
                    }
                    return 0;
                },
            },
            priceLists: [],
            selectedPriceList: null,
            loading: false,
            deletePriceList: null,
            dateApplied: null,
            showUpload: false,
            fileToUpload: null,
            search: '',
            currency: {
                'ro-RO': {
                    code: ' lei'
                },
            },
            existingPriceListId: '',
            itemToDeletePriceLists: [],
            showExistingPriceListError: false,
            uploadErrors: [],
            uploadWarnings: [],
            productNameHint: '', // Hint for duplicate product name
            productCodeRules: [
                v => !!v || 'Cod produs este obligatoriu.',
                v => !this.items.some(item => item.product_code === v && !this.isEditingItem) || 'Codul produsului există deja.',
            ],
            productNameRules: [
                v => !!v.trim() || 'Numele produsului este obligatoriu.',
            ],
            priceRules: [
                v => !!v || 'Preț este obligatoriu.',
                v => !isNaN(parseFloat(v)) && parseFloat(v) > 0 || 'Prețul trebuie să fie o valoare pozitivă.',
            ],
            isEditingItem: false,
            oldEditedItem: {},
            selectedPriceListType: 'general',
            groupedPriceLists: {},
        }
    },
    computed: {
        priceListTypes() {
            // Access PRICE_LIST_TYPES from the Vuex store and map it to an array
            return Object.values(this.$store.state.PRICE_LIST_TYPES).filter(type => type.isActive);
        },
        formTitle() {
            return this.isEditingItem ? 'Editează produs' : 'Produs nou'
        },
        filteredItems() {
            if (this.search) {
                const normalizedSearch = this.search.toLowerCase();
                return this.items.filter(item =>
                    item.product_code.toLowerCase().includes(normalizedSearch) ||
                    item.product_name.toLowerCase().includes(normalizedSearch)
                );
            } else {
                return this.items;
            }
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        'editedItem.product_name'(newValue) {
            const trimmedValue = newValue.trim();
            this.productNameHint = this.items.some(item => item.product_name === trimmedValue)
                ? 'Acest nume de produs există deja.' : '';
        },
        async selectedPriceListType() {
            await this.onPriceListTypeChange();  // Fetch price lists when the selected type changes
            if (this.selectedPriceList) {
                await this.initializePriceLists();
            }
        }
    },
    async created() {
        await this.initializePriceLists();
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
        this.calculateHeight();
    },
    methods: {
        /**
         * Sets the selected price list for an item to the latest price list available.
         * This method is triggered when a new product is being added. It checks if the product is not currently being edited
         * and if the product does not already have any price list assigned. If these conditions are met, it assigns the first
         * price list from the available price lists to the product.
         */
        setLatestPriceList() {
            if (!this.isEditingItem && (!this.editedItem.price_list_ids || this.editedItem.price_list_ids.length === 0)) {
                this.editedItem.price_list_ids = [JSON.parse(JSON.stringify(this.priceLists[0]))];
            }
        },
        /**
         * Initializes the price lists by fetching the available lists, setting the current list, and loading the products.
         * Updates the sidebar links and ensures the UI reflects the current state of price lists.
         *
         * @async
         */
        async initializePriceLists() {
            await this.getPriceLists();
            this.setCurrentPriceList();
            await this.initialize();
            this.$store.state.sidebarLinks = this.sidebarLinks
        },
        /**
         * Handles the keyboard shortcuts for saving or opening the dialog.
         * Supports Ctrl+S for saving and Ctrl+A for opening the add product dialog.
         *
         * @param {KeyboardEvent} event - The keyboard event triggered by the user.
         */
        handleKeyDown(event) {
            if (this.dialog && event.ctrlKey && event.key === 's') {
                event.preventDefault(); // Prevent the default browser action for Ctrl + S
                this.save(); // Call the save method
            } else if (!this.dialog && event.ctrlKey && event.key === 'a') {
                event.preventDefault();
                this.dialog = true;
                this.setLatestPriceList();
            }
        },
        /**
         * Dynamically calculates and updates the height of the product table based on the toolbar height.
         */
        calculateHeight() {
            // Ensure the toolbarRef is available and has been rendered
            if (this.$refs.toolbarRef) {
                const toolbarHeight = this.$refs.toolbarRef.clientHeight;
                // Calculate the height dynamically and update the data property
                this.calculatedHeight = `calc(100vh - 134px - ${toolbarHeight}px)`;
            }
        },
        formatNumber,
        sortByProductCode,
        /**
         * Initializes the product data for the currently selected price list.
         * Fetches product data, assigns measurement units, and sorts items by product code.
         *
         * @async
         */
        async initialize() {
            let existingPriceListProducts = JSON.parse(sessionStorage.getItem('PRICE_LIST_PRODUCTS'));
            // This method calls the django api end-point, the assigns each product it's corresponding measurement
            // unit before assigning the values to the items array for displaying in the component
            try {
                this.loading = true;
                if (!existingPriceListProducts || this.selectedPriceListType !== existingPriceListProducts.type) {
                    const response = await apiClient.get(`/api/price_lists/${this.selectedPriceList}/items/`);
                    this.items = response.data.items.map(item => {
                        // Find the corresponding measurement unit
                        const unit = this.measurementUnits.find(u => u.number === item.measurement_unit);

                        // Replace the value with the unit text
                        item.measurement_unit = unit ? unit.text : item.measurement_unit;

                        return item;
                    });
                    this.items = this.sortProductsByProductCode(this.items);
                } else {
                    this.items = existingPriceListProducts.items;
                }
                this.loading = false;
            } catch (error) {
                showAlertModal(this.$store, 'A apărut o eroare la preluarea unităților de măsură. Vă rugăm reîncărcați pagina.', 'danger', 12000, error);
                console.log(error);
            }
        },
        /**
         * Sorts an array of products by their product code.
         *
         * @param {Array<Object>} products - The array of product objects to sort.
         * @returns {Array<Object>} - The sorted array of products.
         */
        sortProductsByProductCode(products) {
            return products.sort((a, b) => {
                return this.sortByProductCode(a, b, 1);
            });
        },
        /**
         * Checks if a price list already exists for the selected application date.
         * Updates the UI to indicate if an existing price list will be overwritten.
         *
         * @param {string} dateApplied - The date to check for existing price lists.
         */
        checkExistingPriceList(dateApplied) {
            let existingPriceListIndex = this.priceLists.findIndex(priceList => priceList.date_applied === dateApplied);
            if (existingPriceListIndex !== -1) {
                this.existingPriceListId = this.priceLists[existingPriceListIndex].id;
                this.showExistingPriceListError = true;
            } else {
                this.existingPriceListId = null;
                this.showExistingPriceListError = false;
            }
        },
        /**
         * Fetches all available price lists based on the selected type and sorts them.
         * Updates the `priceLists` variable with the fetched data.
         *
         * @async
         */
        async getPriceLists() {
            try {
                // Add the selected price list type as a query parameter in the request
                const response = await apiClient.get('/api/price_lists', {
                    params: {
                        type: this.selectedPriceListType  // Pass the selected price list type
                    }
                });

                // Assign the fetched data to priceLists variable
                this.priceLists = response.data;

                // Sort the priceLists array by id in descending order
                this.priceLists.sort((a, b) => b.id - a.id);

                // Optionally set the current price list to the latest one for the selected type
                this.setCurrentPriceList();
            } catch (error) {
                console.error('Error fetching price lists:', error);
                showAlertModal(this.$store, 'A apărut o eroare la preluarea listelor de prețuri. Vă rugăm reîncărcați pagina.', 'danger', 12000, error);
            }
        },
        /**
         * Handles changes in the selected price list type by refetching the price lists.
         *
         * @async
         */
        async onPriceListTypeChange() {
            await this.getPriceLists();  // Refetch the price lists when the type changes
        },
        /**
         * Sets the currently selected price list to the latest applicable one based on its application date.
         * Excludes future price lists and sorts the list by date to find the most recent.
         */
        setCurrentPriceList() {
            let data = this.priceLists; // your array data
            let today = new Date();

            // 1. Filter out future dates
            data = data.filter((item) => {
                // Convert the date string to a Date object
                let itemDate = new Date(item.date_applied + 'T00:00:00');
                return itemDate <= today;
            });

            // 2. Sort the array by date in descending order
            data.sort((a, b) => {
                // Convert the date strings to Date objects
                let dateA = new Date(a.date_applied + 'T00:00:00');
                let dateB = new Date(b.date_applied + 'T00:00:00');
                return dateB - dateA;
            });

            // 3. The first item now has the closest date_applied (not in the future)
            this.selectedPriceList = (data.length > 0) ? data[0].id : null;
        },
        /**
         * Fetches the products for the currently selected price list and updates the items displayed in the table.
         *
         * @async
         */
        async getPriceListProducts() {
            this.loading = true;
            try {
                // Make API call to fetch price list products
                const response = await apiClient.get(`/api/price_lists/${this.selectedPriceList}/items/`);

                // Assign the fetched data to items variable
                this.items = response.data.items;
            } catch (error) {
                console.error('Error fetching price list products:', error);
                showAlertModal(this.$store, 'A apărut o eroare la preluarea produselor cu listele lor de prețuri. Vă rugăm reîncărcați pagina.', 'danger', 12000, error);
            } finally {
                this.loading = false;
            }
        },
        /**
         * Uploads a selected CSV file for a price list and handles warnings or errors returned by the server.
         * Updates the list of price lists and products after a successful upload.
         *
         * @async
         */
        async uploadFile() {
            const file = this.fileToUpload;
            if (!file) {
                return;
            }
            let formData = new FormData();
            formData.append('csv_file', file);
            formData.append('type', this.selectedPriceListType); // Include selected type
            if (this.existingPriceListId) {
                formData.append('price_list_id', this.existingPriceListId)
            } else {
                formData.append('date_applied', this.dateApplied);
            }

            try {
                this.loading = true;
                const response = await apiClient.post("/api/upload-csv/", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                await this.getPriceLists();
                if (response.data.price_list_id) {
                    this.selectedPriceList = response.data.price_list_id;
                    await this.getPriceListProducts();
                }
                this.loading = false;
                this.showUpload = false;
                this.isNewPriceList = false;
                this.uploadErrors = [];
                this.uploadWarnings = [];
                this.uploadWarnings = response.data.warnings;
            } catch (error) {
                this.loading = false;
                this.uploadErrors = error.response.data.errors;
                this.uploadWarnings = error.response.data.warnings;
                showAlertModal(this.$store, 'A apărut o eroare la încărcarea unui fișier', 'danger', 12000, error);
                console.error('There was an error uploading the file', error);
            }
        },
        /**
         * Opens the dialog for editing an item by setting `editedItem` to a copy of the selected item.
         * This method is used when an item needs to be edited, copying its data to `editedItem` and opening the edit dialog.
         * Additionally, it makes an API call to fetch the price lists that the selected item is part of,
         * and attaches the price list IDs and application dates to the `price_list_ids` property of `editedItem`.
         *
         * @param {Object} item - The item object that is to be edited. This object is copied to `editedItem`.
         * @property {number} item.id - The unique identifier of the item to fetch its associated price lists.
         * @property {Array<Object>} editedItem.price_list_ids - The array of price list objects, each containing `id` and `date_applied`, fetched from the API.
         * @returns {Promise<void>} - Opens the edit dialog and populates `price_list_ids` after the API call.
         */
        async editItem(item) {
            this.isEditingItem = true;
            this.oldEditedItem = Object.assign({}, item);
            this.editedItem = Object.assign({}, item);

            // Fetch price lists grouped by type and wait for it to complete
            try {
                await this.getItemPriceLists(item);
                this.dialog = true;
            } catch (error) {
                console.error('Error opening edit dialog:', error);
            }
        },
        /**
         * Fetches the price lists that the selected item is part of from the API.
         * Groups the price lists by type and assigns them to the `groupedPriceLists` object.
         * Updates the `price_list_ids` property of `editedItem` with the grouped price lists.
         *
         * @param {Object} item - The item object, containing the `id` property used to fetch the associated price lists.
         * @returns {Promise<void>} - Populates `groupedPriceLists` and updates `editedItem.price_list_ids` with the fetched price lists.
         */
        getItemPriceLists(item) {
            return apiClient.get(`/api/items/${item.id}/price-lists/`)
                .then(response => {
                    const priceLists = response.data.price_lists;

                    // Group price lists by type
                    this.groupedPriceLists = this.groupByType(priceLists);
                    this.editedItem.price_list_ids = JSON.parse(JSON.stringify(this.groupedPriceLists));
                })
                .catch(error => {
                    console.error('Error fetching price lists:', error);
                    this.editedItem.price_list_ids = {};
                });
        },
        /**
         * Groups an array of price lists by their type. Each type of price list will have its own array of price lists.
         *
         * @param {Array<Object>} priceLists - The array of price list objects to be grouped.
         * @param {string} priceLists[].type - The type of the price list (e.g., 'general', 'services', 'photovoltaics').
         * @returns {Object} - An object where each key is a price list type, and the value is an array of price lists of that type.
         */
        groupByType(priceLists) {
            return priceLists.reduce((groups, priceList) => {
                const type = priceList.type || 'General'; // Use the type, or default to 'General'
                if (!groups[type]) {
                    groups[type] = [];
                }
                groups[type].push(priceList);
                return groups;
            }, {});
        },
        /**
         * Prepares to delete an item by copying the selected item to `editedItem` and fetching its associated price list IDs.
         * It then opens the delete confirmation dialog. This setup allows for confirmation before deletion and ensures that all related data
         * (including price lists) is fetched and ready for any further deletion logic.
         *
         * @param {Object} item - The item object that is to be deleted. This object is copied to `editedItem` and its price list IDs are fetched.
         * @property {number} item.id - The unique identifier of the item to fetch its associated price lists for deletion confirmation.
         * @property {Array<Object>} editedItem.price_list_ids - The array of price list objects, each containing `id` and `date_applied`, fetched from the API.
         * @returns {Promise<void>} - Opens the delete confirmation dialog and populates `itemToDeletePriceLists` after fetching the price lists.
         */
        async deleteItem(item) {
            this.editedItem = Object.assign({}, item);
            try {
                await this.getItemPriceLists(item);
                this.itemToDeletePriceLists = JSON.parse(JSON.stringify(this.editedItem.price_list_ids));
                this.dialogDelete = true;
            } catch (error) {
                console.error('Error opening edit dialog:', error);
            }
        },
        /**
         * Confirms the deletion of a product from selected price lists.
         * Sends the delete request to the server and removes the product from the UI if successful.
         */
        deleteItemConfirm() {
            this.loading = true;
            let product_code = this.editedItem.product_code;

            // Flatten the price_list_ids object (grouped by type) into a single array of IDs
            let price_list_ids = Object.values(this.editedItem.price_list_ids)
                .flat() // Flatten the arrays of price lists
                .map(price_list_id => price_list_id.id ? price_list_id.id : price_list_id); // Map to get IDs

            apiClient({
                method: 'delete',
                url: '/api/remove_product_from_price_list/',
                data: {
                    'product_code': product_code,
                    'price_list_ids': price_list_ids
                }
            })
                .then(response => {
                    if (response.status === 204) {
                        // If the selectedPriceList is part of the price_list_ids, remove the item
                        if (price_list_ids.includes(this.selectedPriceList)) {
                            this.items = this.items.filter(item => item.product_code !== product_code);
                        }
                    }
                    this.closeDelete();
                })
                .catch(error => {
                    console.error('An error occurred:', error);
                })
                .finally(() => {
                    this.dialogDelete = false;
                    this.loading = false;
                });
        },
        /**
         * Closes the delete confirmation dialog and resets the edited item.
         */
        closeDelete() {
            this.dialogDelete = false;
            setTimeout(() => {
                this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
            }, 500)
        },
        /**
         * Closes the dialog for creating a new price list without saving changes.
         */
        closeNewPriceListDialog() {
            this.newListDialog = false;
        },
        /**
         * Opens the dialog for creating a new price list.
         * Marks the dialog as being in "new list" mode.
         */
        openNewPriceListDialog() {
            this.newListDialog = true;
            this.isNewPriceList = true;
        },
        /**
         * Closes the product editing dialog and resets the edited item to default.
         */
        close() {
            this.dialog = false;
            this.isEditingItem = false;
            setTimeout(() => {
                this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
            }, 300)
        },
        /**
         * Saves a new or edited product to the server.
         * Validates the input, handles API requests, and updates the UI accordingly.
         *
         * @async
         */
        async save() {
            let validation = await this.$refs.form.validate();
            if (validation.valid) {
                // Flatten the price_list_ids into an array of IDs
                this.editedItem.price_list_ids = Object.values(this.editedItem.price_list_ids)
                    .flat()
                    .map(price_list_id => ({id: price_list_id.id ? price_list_id.id : price_list_id}));

                let response = null;
                const index = this.items.findIndex(item => item.product_code === this.oldEditedItem.product_code);

                if (index !== -1) {
                    this.editedItem.old_product_code = this.items[index].product_code;
                    response = await this.createProduct(this.editedItem);
                } else {
                    response = await this.createProduct(this.editedItem);
                }

                if (response.status === 201) {
                    const updatedProduct = response.data.product;

                    // Retrieve the stored price list products from sessionStorage
                    let existingPriceListProducts = JSON.parse(sessionStorage.getItem('PRICE_LIST_PRODUCTS'));

                    if (existingPriceListProducts) {
                        // Check if the product exists in the stored price list
                        const productIndex = existingPriceListProducts.items.findIndex(
                            item => item.product_code === this.editedItem.old_product_code
                        );

                        // If the product exists in sessionStorage, replace it
                        if (productIndex !== -1) {
                            existingPriceListProducts.items[productIndex] = updatedProduct;
                            sessionStorage.setItem('PRICE_LIST_PRODUCTS', JSON.stringify(existingPriceListProducts));
                        } else {
                            existingPriceListProducts.items.push(cloneDeep(updatedProduct));
                            sessionStorage.setItem('PRICE_LIST_PRODUCTS', JSON.stringify(existingPriceListProducts));
                        }
                    }

                    await this.initialize();
                    this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
                }

                this.isEditingItem = false;
                this.close();
            }
        },
        /**
         * Creates a new product or updates an existing one by sending data to the server.
         *
         * @param {Object} product - The product object to create or update.
         * @returns {Promise<Object>} - The server response.
         */
        async createProduct(product) {
            try {
                return await apiClient.post('/api/create_product/', product);
            } catch (error) {
                showAlertModal(this.$store, 'A apărut o eroare la actualizarea produsului. Vă rugăm reîncărcați pagina.', 'danger', 12000, error);
                console.log(error);
            }
        },
        /**
         * Downloads the currently selected price list as a CSV file.
         * Opens the file in the browser's download manager.
         *
         * @async
         */
        async downloadPriceList() {
            this.isDownloadingPriceList = true;
            try {
                const response = await apiClient({
                    url: `/api/download-csv/${this.selectedPriceList}/`,
                    method: 'GET',
                    responseType: 'blob', // important, as the server sends a file
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'lista_preturi.csv');
                document.body.appendChild(link);
                link.click();

                // It's a good idea to remove the link after the file has been downloaded.
                document.body.removeChild(link);
                this.isDownloadingPriceList = false;
            } catch (error) {
                showAlertModal(this.$store, 'A apărut o eroare la descărcarea listei de prețuri. Vă rugăm reîncărcați pagina.', 'danger', 12000, error);
                console.error("Error while downloading the file:", error);
            }
        },
    }
}
</script>

<style scoped>

</style>