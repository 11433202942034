<template>
    <v-card>
        <v-card-title>
            Creare intermediar
        </v-card-title>
        <v-card-text>
            <v-text-field
                label="Nume:"
                id="name"
                v-model="newIntermediary.name"
                @blur="saveIntermediary"
                outlined
                dense
            ></v-text-field>
            <div v-if="error" class="text-danger">{{ error }}</div>

            <v-text-field
                label="Telefon:"
                id="phone"
                v-model="newIntermediary.phone"
                @blur="saveIntermediary"
                outlined
                dense
            ></v-text-field>

            <v-text-field
                label="Email:"
                id="email"
                v-model="newIntermediary.email"
                @blur="saveIntermediary"
                outlined
                dense
            ></v-text-field>

            <v-row v-if="!onClientCreation" class="my-2 mr-1 justify-end">
                <v-btn @click="addIntermediary" color="primary">Adaugă intermediar</v-btn>
            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
import apiClient from "@/utils/apiClient";
import {showAlertModal} from "@/utils/utils";

export default {
    name: "CreateIntermediary",
    props: {
        onClientCreation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newIntermediary: {
                name: "",
                phone: "",
                email: "",
                agent_id: "",
            },
            error: ""
        };
    },
    methods: {
        /**
         * Emits an event to save intermediary data whenever a field is blurred or updated.
         * Assigns the current user ID to the intermediary before emitting the event.
         *
         * @function saveIntermediary
         * @emits save-intermediary
         */
        saveIntermediary() {
            this.newIntermediary.agent_id = parseInt(sessionStorage.getItem('userId'));
            this.$emit("save-intermediary", this.newIntermediary);
        },
        /**
         * Adds a new intermediary by sending a POST request to the backend API.
         * Validates that the intermediary's name is not empty, and upon success,
         * updates local storage, clears form fields, and emits a creation event.
         *
         * @async
         * @function addIntermediary
         * @returns {Promise<void>} Completes after the intermediary is created and
         *   the local data is refreshed. If an error occurs, displays an alert modal.
         * @emits intermediary-created
         */
        async addIntermediary() {
            if (this.newIntermediary.name.trim() === "") {
                this.error = "Numele intermediarului nu poate fi gol.";
            } else {
                this.error = '';
                let agentId = sessionStorage.getItem('userId');
                const intermediary = {
                    name: this.newIntermediary.name,
                    phone: this.newIntermediary.phone,
                    email: this.newIntermediary.email,
                    agent_id: parseInt(agentId)
                };

                try {
                    const response = await apiClient.post('/api/create_intermediary/', intermediary);
                    const savedIntermediary = response.data;
                    const intermediaries = JSON.parse(sessionStorage.getItem("intermediaries"));
                    intermediaries.push(savedIntermediary);
                    sessionStorage.setItem("intermediaries", JSON.stringify(intermediaries));
                    this.$emit("intermediary-created");

                    // Clear the input fields
                    this.newIntermediary.name = "";
                    this.newIntermediary.phone = "";
                    this.newIntermediary.email = "";
                } catch (error) {
                    console.error("Error adding intermediary:", error.response ? error.response.data : error.message);
                    showAlertModal(this.$store, 'A apărut o eroare la adăugarea intermediarului. Vă rugăm să încercați din nou.', 'danger', 12000, error);
                    if (error.response && error.response.status === 400) {
                        alert('A avut loc o eroare: ' + error.response.data.message);
                    } else {
                        console.log('A avut loc o eroare:', error.message);
                        // handle other errors
                    }
                }
            }
        },
    }
}
</script>

<style scoped>

</style>