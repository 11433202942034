<template>
	<BreadCrumbsWithButtons :buttons="buttons" :pdfOptions="pdfOptions.vmc" />
	<a
		href="#"
		:id="sidebarLinks[0].id"
		:ref="sidebarLinks[0].id"
	></a>
	<OfferInformation
		v-if="client && location"
		:client="client"
		:location="location"
		:offer="offer"
		:offerId="offerId"
		@update="updateOfferData"
		@offer-buttons-in-view="isOfferButtonsInView = $event"
	>
	</OfferInformation>
	<a
		href="#"
		:id="sidebarLinks[1].id"
		:ref="sidebarLinks[1].id"
	></a>
	<RoomsDetails
		v-if="location"
		ref="roomsDetails"
		:location="location"
		:floorGroups="floorGroups"
		:initialLocation="initialLocation"
		:selected-tubing-option="selectedTubingOption"
		:pressure-loss-limit="formattedPressureLossLimit"
		@save-location="verifyLocation($event)"
		@update-location="getLocationChanges"
		@pressure-loss-updated="setTotalPressureLoss"
		@total-air-volume-updated="setTotalAirVolume"
		@updated-floor-groups-values="setFloorGroupValues"
		@delete-unit-model="deleteUnitModel"
	>
		<template v-slot:tubingSelector>
			<v-select
				class="py-5"
				style="min-width: 150px"
				variant="outlined"
				density="compact"
				hide-details
				label="Tip tubulatură"
				:items="tubingOptions"
				v-model="selectedTubingOption"
			>
			</v-select>
			<v-text-field
				disabled
				class="ml-2 py-5"
				style="min-width: 170px"
				variant="outlined"
				suffix="Pa"
				density="compact"
				hide-details
				label="Limită cădere de presiune"
				v-model="pressureLossLimit"
			>
			</v-text-field>
		</template>
	</RoomsDetails>
	<a
		href="#"
		:id="sidebarLinks[2].id"
		:ref="sidebarLinks[2].id"
	></a>
	<UnitSetup
		v-if="location"
		:location="location"
		:floorGroupValues="floorGroupValues"
		:totalPressureLoss="locationPressureLoss"
		:totalAirVolume="locationTotalAirVolume"
		:selected-tubing-option="selectedTubingOption"
		@updated-floor-groups="updateFloorGroups"
		@updated-units="updateUnits"
		@selected-unit="addUnitProductCode"
	/>
	<a
		href="#"
		:id="sidebarLinks[3].id"
		:ref="sidebarLinks[3].id"
	></a>
	<v-toolbar v-if="this.$store.state.vmcErrors.length > 0">
		<v-toolbar-title>Mesaje</v-toolbar-title>
	</v-toolbar>
	<v-card v-if="this.$store.state.vmcErrors.length > 0">
		<v-card-text>
			<v-fade-transition
				v-for="error in this.$store.state.vmcErrors"
				:key="error"
			>
				<v-alert
					:icon="error.icon"
					:title="error.title"
					:color="error.color"
					:text="error.text"
					density="compact"
					class="mt-3"
					variant="outlined"
					closable
					close-label="Close Alert"
				>
				</v-alert>
			</v-fade-transition>
		</v-card-text>
	</v-card>
	<ProductTable
		v-if="loadProductTable"
		:offered-products="offeredProducts ?? []"
		:offer-type="offerType"
		:offer="offer"
		:headers="productTableHeaders"
		:special-products="specialProducts"
		@update-products="updateOfferProducts"
		@update-offer="updateOfferData"
		@selected-price-list="updateOfferPriceList"
        :price-list-type="this.$store.state.PRICE_LIST_TYPES.GENERAL.value"
	>
	</ProductTable>
	<a
		href="#"
		:id="sidebarLinks[4].id"
		:ref="sidebarLinks[4].id"
	></a>
	<OfferPaymentTerms
		:offer="offer"
		@update_payment_terms="updatePaymentTerms"
	></OfferPaymentTerms>
</template>

<script>
import UnitSetup from "@/components/offer/UnitSetup.vue";
import ProductTable from "@/components/offer/ProductTable.vue";
import apiClient from "@/utils/apiClient";
import RoomsDetails from "@/components/offer/RoomsDetails.vue";
import navMixin from "@/mixins/navMixin";
import offerMixin from "@/mixins/offerMixin";
import { showAlertModal } from "@/utils/utils";
import OfferInformation from "@/components/offer/OfferInformation.vue";
import OfferPaymentTerms from "@/components/offer/OfferPaymentTerms.vue";
import {
	createCircuitTubesRequirements,
	createDistributorsRequirements,
	createPlenumsRequirements,
	createUnitsRequirements,
    createDehumidificationRequirements,
} from "@/VMC/vmcLogic";
import { products } from "@/VMC/vmcProducts";
import { calculateDistributorPermutations } from "@/VMC/vmcData";
import emitter from "@/utils/emitter";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";

export default {
	name: "OfferVMC",
	mixins: [navMixin, offerMixin],
	data() {
		return {
			tubingOptions: [
				{
					title: "75",
					value: 75,
				},
				{
					title: "90",
					value: 90,
				},
				{
					title: "Ovală",
					value: 117,
				},
                
			],
            selectedTubingOption: 75,
            pressureLossLimit: 50,
			productTableHeaders: [
				{
					title: "Nr.",
					key: "number",
					align: "center",
					show: false,
					width: "70px",
				},
				{
					title: "Cod articol",
					key: "product_code",
					align: "center",
					show: true,
					width: "140px",
				},
				{
					title: "Nume articol",
					key: "product_name",
					align: "start",
					show: true,
				},
				{
					title: "Categorie",
					key: "assigned_sub_category",
					align: "center",
					show: true,
				},
				{
					title: "Cantitate",
					key: "quantity",
					align: "center",
					show: true,
					width: "130px",
				},
				{
					title: "UM",
					key: "measurement_unit",
					align: "center",
					show: true,
					width: "70px",
				},
				{
					title: "Preț listă cu TVA",
					key: "price",
					align: "end",
					show: true,
				},
				{
					title: "Discount",
					key: "discount",
					align: "center",
					show: true,
					width: "120px",
				},
				{
					title: "Preț final cu TVA",
					key: "final_price",
					align: "end",
					show: true,
				},
				{
					title: "Valoare finală cu TVA",
					key: "final_total_price",
					align: "end",
					show: true,
				},
			],
			offerPriceList: null,
			isSaveOfferClicked: false,
			createNewOfferClicked: false,
			savedLocation: true,
			productsFetched: false,
			products: [],
			startRendering: false,
			sidebarLinks: [
				{
					name: "Informații",
					link: "#section1",
					id: "section1",
				},
				{
					name: "Camere",
					link: "#section2",
					id: "section2",
				},
				{
					name: "Unități",
					link: "#section3",
					id: "section3",
				},
				{
					name: "Produse",
					link: "#section4",
					id: "section4",
				},
				{
					name: "Termeni de plată",
					link: "#section5",
					id: "section5",
				},
			],
			currentSection: "",
			locationPressureLoss: null,
			locationTotalAirVolume: null,
			floorGroupValues: [],
			floorGroups: [],
			requirementsObj: {
				requirements: [],
			},
			requirementsGetters: [
				"createPlenumsRequirements",
				"createDistributorsRequirements",
				"createCircuitTubesRequirements",
				"createUnitsRequirements",
                "createDehumidificationRequirements",
			],
			resultedProducts: [],
			vmcProducts: products,
			finalResultedProducts: [],
			recommendedProductAdded: false,
			recommendedProducts: [],
			specialProducts: [],
			locationUnits: [],
			offerType: { type: "VMC", productCategories: ["VMC"] },
		};
	},
	components: {
        BreadCrumbsWithButtons,
		OfferInformation,
		ProductTable,
		UnitSetup,
		RoomsDetails,
		OfferPaymentTerms,
	},
	async created() {
		this.locationId = this.$route.params.locationId;
		await this.getRecommendedProducts(this.offerType.type);
		const priceListItems = await this.fetchPriceListProducts();
		this.products = this.reassignSubCategories(this.setZeroQtyForBackendProducts(priceListItems.items));
		this.productsFetched = true;
	},
	mounted() {
		this.$store.state.sidebarLinks = this.sidebarLinks;
	},
	computed: {
		formattedPressureLossLimit() {
			return Number(this.pressureLossLimit);
		},
	},
	methods: {
		setExistingTubingOption(rooms) {
			// Check if any room has plenums with type 8
			const hasPlenumType90 = rooms.some(
				(room) => room.plenums && room.plenums.some((plenum) => plenum.type === 8)
			);
			const hasPlenumType117 = rooms.some(
				(room) => room.plenums && room.plenums.some((plenum) => plenum.type === 9 || plenum.type === 10)
			);

			if (hasPlenumType90) {
				this.selectedTubingOption = 90;
			} else if (hasPlenumType117) {
				this.selectedTubingOption = 117;
			}
		},
		getRequirements() {
			for (let methodName of this.requirementsGetters) {
				if (this[methodName] && typeof this[methodName] === "function") {
					const newRequirements = this[methodName](this.location).map((req) => ({
						...req,
						processed: false,
					}));
					newRequirements.forEach((req) => {
						const existingReq = this.requirementsObj.requirements.find((r) =>
							this.compareRequirements(r, req)
						);
						if (existingReq) {
							existingReq.quantity =
								(existingReq.quantity || 0) +
								(req.quantity !== null && req.quantity !== undefined ? req.quantity : 1);
						} else {
							req.quantity = req.quantity !== null && req.quantity !== undefined ? req.quantity : 1;
							this.requirementsObj.requirements.push(req);
						}
					});
				}
			}
		},
		/**
		 * Processes the required products by initializing arrays, fetching requirements,
		 * adding product codes, fetching products, and processing the final product list.
		 * This method orchestrates several steps to prepare and process product data,
		 * including fetching, filtering, and matching products based on specific criteria
		 * such as location and unit product codes. It also handles the addition of
		 * recommended products and updates the offered products accordingly.
		 */
		processRequiredProducts() {
			emitter.emit("trigger-overlay");
			if (this.checkAvailableUnitModels()) {
				setTimeout(() => {
					this.$store.commit("emptyVmcErrorArray");
					this.requirementsObj.requirements = [];
					this.resultedProducts = [];
					this.finalResultedProducts = [];
					this.recommendedProducts = [];
					this.getRequirements();
					this.addUnitProductCode(this.location.units);
					this.getProducts(this.requirementsObj, JSON.parse(JSON.stringify(this.vmcProducts)));

					this.addRecommendedProducts(this.resultedProducts, this.vmcProducts);
					this.processResultedProducts();

					this.finalResultedProducts = this.matchProducts(this.products, this.finalResultedProducts);
					this.addOrUpdateOfferedProducts();

					// emitting event for setting showOnlyOfferQty on true in ProductTable
					emitter.emit("set-products-with-quantity-true");
				}, 550);
			} else {
				emitter.emit("trigger-overlay");
				showAlertModal(
					this.$store,
					"Nu există nici o unitate cu un model valid selectat. Vă rugăm corectați!",
					"danger",
					12000
				);
			}
		},
		/**
		 * Checks if any unit has a valid (non-empty) model property.
		 * @returns {boolean} - True if any unit has a valid model, false otherwise.
		 */
		checkAvailableUnitModels() {
			// Check if any unit has a valid (non-empty) model property
			return this.location.units.some((unit) => unit.model && Object.keys(unit.model).length > 0);
		},
		/**
		 * Updates the floorGroups property and assigns the floorGroupId to each room in the location.
		 * @param {Array} floorGroups - An array of floor group objects.
		 */
		updateFloorGroups(floorGroups) {
			this.floorGroups = floorGroups;
			this.location.rooms.forEach((room) => {
				room.floorGroupId = this.floorGroups.find((group) => group.floors.includes(room.floor))?.id;
			});
		},
		/**
		 * Updates the units property of the location and stores a copy in the locationUnits variable.
		 * @param {Array} units - An array of unit objects.
		 */
		updateUnits(units) {
			this.location.units = units;
			// here we also store the units in the locationUnits variable for saving in the offer as
			// the location.units gets overwritten when location is updated
			this.locationUnits = units;
		},
		/**
		 * Deletes the model property of the specified units and updates the isDistributorChoiceManual property.
		 * @param {Array<number>} unitIndexes - An array of unit indexes to delete the model from.
		 */
		deleteUnitModel(unitIndexes) {
			unitIndexes.forEach((index) => {
				if (this.location.units[index]) {
					this.location.units[index].model = null;
					// here we are also setting the isDistributorChoiceManual property to false since the unit should not exist anymore
					this.location.units[index].isDistributorChoiceManual = false;
					// here we also delete the units in the locationUnits variable for saving in the offer as
					// the location.units gets overwritten when location is updated
					this.locationUnits[index].model = null;
					// here we are also setting the isDistributorChoiceManual property to false since the unit should not exist anymore
					this.locationUnits[index].isDistributorChoiceManual = false;
				}
			});
		},
		/**
		 * Sets the floorGroupValues property.
		 * @param {Array} floorGroupValues - An array of floor group value objects.
		 */
		setFloorGroupValues(floorGroupValues) {
			this.floorGroupValues = floorGroupValues;
		},
		/**
		 * Sets the total pressure loss for the location.
		 * @param {number} pressure - The total pressure loss value.
		 */
		setTotalPressureLoss(pressure) {
			this.locationPressureLoss = pressure;
		},
		/**
		 * Sets the total air volume for the location.
		 * @param {number} volume - The total air volume value.
		 */
		setTotalAirVolume(volume) {
			this.locationTotalAirVolume = volume;
		},
		/**
		 * Updates the location property and manages the isLocationChanged state.
		 * @param {Object} location - The new location object.
		 */
		getLocationChanges(location) {
			if (this.savedLocation) {
				this.savedLocation = !location.isLocationChanged;
			}
			this.location = location;
		},
		/**
		 * Asynchronously verifies and updates the location based on the given change description.
		 * It iterates through all rooms in the location, ensuring that each room has defined air volumes.
		 * If the initialLocation is set, it also updates the air volumes for its rooms. Finally, if there
		 * are any empty rooms in the location, it triggers an update for the location.
		 *
		 * @param {string} change_description - A description of the change to be applied to the location.
		 * @returns {Promise<void>} - A promise that resolves when the location has been updated.
		 */
		async verifyLocation(change_description) {
			this.location.change_description = change_description;

            if (this.initialLocation) {
                for (let i = 0; i < this.initialLocation.rooms.length; i++) {
                    this.initialLocation.rooms[i].air_volume = parseInt(this.initialLocation.rooms[i].air_volume);
                }
            }
            if (this.checkForEmptyRooms(this.location)) {
                await this.updateLocation(this.location.id, this.location);
            }
        },
        /**
         * Updates the location by sending a PUT request to the API and handles the response.
         * @param {string} locationId - The ID of the location to update.
         * @param {Object} newLocation - The new location data to update.
         * @returns {Promise<void>}
         */
        async updateLocation(locationId, newLocation) {
            await apiClient
                .put("/api/locations/update/" + locationId + "/", newLocation)
                .then((response) => {
                    this.initialLocation = response.data;
                    response.data.newVersion = this.location.newVersion;
                    this.location = response.data;
                    this.$router.push({
                        name: "OfferVMC",
                        params: {locationId: response.data.id},
                    });
                    if (!this.isSaveOfferClicked && !this.createNewOfferClicked) {
                        showAlertModal(this.$store, `<b>Locația</b> a fost salvată cu succes.`, "success", 3000);
                    }
                    this.savedLocation = true;
                })
                .catch((error) => {
                    console.log(error);
                    showAlertModal(this.$store, 'A apărut o eroare la salvarea locației. Vă rugăm reîncercați.', 'danger', 12000, error);
                });
        },
        /**
         * Checks the rooms in the location for empty names or invalid areas, and handles them accordingly.
         * @param {Object} location - The location object containing rooms.
         * @returns {boolean} - True if all rooms are valid or handled, false otherwise.
         */
        checkForEmptyRooms(location) {
            for (let i = 0; i < location.rooms.length; i++) {
                if (location.rooms[i].name === "" && location.rooms[i].area.sq_m > 0) {
                    showAlertModal(
                        this.$store,
                        "Va rugăm completați denumirea camerei înainte de a salva.",
                        "warning",
                        2000
                    );
                    return false;
                } else if (
                    location.rooms[i].area.sq_m === 0 ||
                    location.rooms[i].area.sq_m === "" ||
                    location.rooms[i].area.sq_m === null
                ) {
                    // remove rooms[i] from location.rooms
                    location.rooms.splice(i, 1);
                    return true;
                }
            }
            return true;
        },
        createUnitsRequirements,
        createCircuitTubesRequirements,
        calculateDistributorPermutations,
        createDistributorsRequirements,
        createPlenumsRequirements,
        createDehumidificationRequirements,
        /**
         * Adds the product code to each unit and processes its requirements.
         * @param {Array} units - An array of unit objects.
         */
        addUnitProductCode(units) {
            units.forEach((unit) => {
                let unitProduct = this.getUnitProduct(unit.model_id, this.vmcProducts);
                if (unitProduct) {
                    unitProduct.quantity = 1;
                    this.resultedProducts.push(unitProduct);

					// Check if requirements array exists and has items
					if (unitProduct.requirements && unitProduct.requirements.length > 0) {
						// Push each item in unitProduct.requirements to this.requirementsObj.requirements
						unitProduct.requirements.forEach((requirement) => {
							requirement.processed = false;
							this.requirementsObj.requirements.push(requirement);
						});
					}
				}
			});
		},
	},
	watch: {
		/**
		 * Watches the 'savedLocation' property for changes.
		 * When it becomes true, it triggers either 'createSaveOffer' or 'createOffer'
		 * based on whether 'isSaveOfferClicked' or 'createNewOfferClicked' is true, respectively.
		 */
		savedLocation: {
			handler: function (newVal) {
				// if a new version is required we need to create a new offer with the locationId of the newly created
				// dimensioning version (which is initialLocation)
				if (
					newVal &&
					((this.location.newVersion && this.isSaveOfferClicked) ||
						(this.location.newVersion && this.createNewOfferClicked))
				) {
					this.locationId = this.initialLocation.id;
					this.createOffer();
				} else if (newVal && this.isSaveOfferClicked && !this.location.newVersion) {
					this.createSaveOffer();
				} else if (newVal && this.createNewOfferClicked && !this.location.newVersion) {
					this.createOffer();
				}
			},
		},
		location(newVal) {
			try {
				if (newVal) {
					this.setExistingTubingOption(this.location.rooms);
				}
			} catch (error) {
				console.error("Error setting existing tubing option:", error);
				// Optionally, handle the error (e.g., show an alert or set a default value)
			}
		},
        selectedTubingOption: {
            handler: function (newVal) {
                if (newVal === 75) {
                    this.pressureLossLimit = 50;
                } else if (newVal === 90 || newVal === 117) {
                    this.pressureLossLimit = 55;
                }
            }
        },
	},
};
</script>
<style></style>
