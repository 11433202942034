export const pressureLoss77Tube = 5.00; //pressure loss (Pa/ml) of the Tub nou 77
export const pressureLoss = {
    tube75: {
        value: 5.00
    },
    tube90: {
        value: 4.04
    },
    tube117: {
        value: 4.96
    }
};

export const maxFlow = {
    tube75: {
        value: 39.8
    },
    tube90: {
        value: 55
    },
    tube117: {
        value: 42
    }
}

export const maxFlow77Tube = 39.8 //max flow (m3/h) of the Tub nou 77

export const basicUnitSensors = [
    { name: 'Umiditate', expansion: false },
    { name: 'Purificator Jonix', expansion: false },
    { name: 'Baterie apă', expansion: false },
    { name: 'Dispenser parfum', expansion: true },
];

export const premiumUnitSensors = [
    { name: 'Hotă', expansion: true },
    { name: 'Alarmă', expansion: true },
    { name: 'Iluminat baie', expansion: true },
    { name: 'Șemineu', expansion: true },
    { name: 'Modul modbus', expansion: false, },
    { name: 'Cablu degivrare', expansion: false, },
];

export const unitAuxiliary = [
    { name: 'Filtre rezervă', },
]

export const UNIT_EXTENSIONS = {
    one: {
        name: "Autobalansare (+)",
        value: 1,
    },
    two: {
        name: "Senzor colmatare filtre (++)",
        value: 2,
    },
    three: {
        name: "Entalpica (++)",
        value: 3,
    }
}

export const UNIT_MOUNTING_OPTIONS = {
    ceiling: {
        name: "Tavan",
        value: 1
    },
    wall: {
        name: "Perete",
        value: 2
    },
}

export const vmcUnits = [
    {
        id: 1,
        name: 'Standard',
        type: 'Standard',
        version: '180A',
        product_code: "803.01.50",
        options: [],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...unitAuxiliary.filter(aux => !aux.name.includes('Filtre rezervă')), { name: 'Filtre rezervă G4' }, { name: 'Filtre rezervă F7' }],
        availableTubing: [{ name: 'EPS', diameter: 125 }, { name: 'Flexibilă', diameter: 125, recommended: true }],
        outletTubingDiameter: 125,
        maxAirVolume: 180,
        a: -0.0041,
        b: -1.3215,
        c: 367.84,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 2,
        name: 'Standard',
        type: 'Standard',
        version: '280A',
        product_code: "803.02.50",
        options: [],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...unitAuxiliary.filter(aux => !aux.name.includes('Filtre rezervă')), { name: 'Filtre rezervă G4' }, { name: 'Filtre rezervă F7' }],
        availableTubing: [{ name: 'EPS', diameter: 125 }, { name: 'Flexibilă', diameter: 125, recommended: true }],
        outletTubingDiameter: 125,
        maxAirVolume: 280,
        a: -0.0048,
        b: -1.0119,
        c: 485.38,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 3,
        name: 'Standard',
        type: 'Standard',
        version: '400A',
        product_code: "803.04.50",
        options: [],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...unitAuxiliary,],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160, recommended: true }],
        outletTubingDiameter: 155,
        maxAirVolume: 400,
        a: -0.0023,
        b: -0.8375,
        c: 597.77,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 4,
        name: 'Standard',
        type: 'Standard',
        version: '550A',
        product_code: "803.05.50",
        options: [],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...unitAuxiliary,],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160, recommended: true }],
        outletTubingDiameter: 155,
        maxAirVolume: 550,
        a: -0.0039,
        b: -0.2651,
        c: 989.14,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 5,
        name: 'Premium Home',
        type: 'Premium Home',
        version: '200f',
        product_code: "803.15.83",
        options: [],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 125 }, { name: 'Flexibilă', diameter: 125 }],
        outletTubingDiameter: 160,
        maxAirVolume: 200,
        a: -0.0051,
        b: -1.1161,
        c: 452.32,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 6,
        name: 'Premium Home',
        type: 'Premium Home +',
        version: '200f +',
        product_code: "803.16.83",
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 125 }, { name: 'Flexibilă', diameter: 125 }],
        outletTubingDiameter: 160,
        maxAirVolume: 200,
        a: -0.0051,
        b: -1.1161,
        c: 452.32,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    // {
    //     id: 7,
    //     name: 'Premium Home',
    //     type: 'Premium Home',
    //     version: '300v',
    //     product_code: "803.17.83",
    //     values: [
    //         {volume: 260, pressureLoss: 100},
    //         {volume: 230, pressureLoss: 150},
    //         {volume: 200, pressureLoss: 200},
    //         {volume: 170, pressureLoss: 250},
    //     ],
    //     options: [],
    //     mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
    //     sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
    //     availableTubing:  [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
    //     outletTubingDiameter: 200,
    // },
    // {
    //     id: 8,
    //     name: 'Premium Home',
    //     type: 'Premium Home +',
    //     version: '300v +',
    //     product_code: "803.18.83",
    //     values: [
    //         {volume: 260, pressureLoss: 100},
    //         {volume: 230, pressureLoss: 150},
    //         {volume: 200, pressureLoss: 200},
    //         {volume: 170, pressureLoss: 250},
    //     ],
    //     options: [UNIT_EXTENSIONS.one],
    //     mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
    //     sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
    //     availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
    //     outletTubingDiameter: 200,
    // },
    // {
    //     id: 9,
    //     name: 'Premium Home',
    //     type: 'Premium Home',
    //     version: '400v',
    //     product_code: "803.19.83",
    //     values: [
    //         {volume: 300, pressureLoss: 100},
    //         {volume: 260, pressureLoss: 150},
    //         {volume: 225, pressureLoss: 200},
    //         {volume: 190, pressureLoss: 250},
    //     ],
    //     options: [],
    //     mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
    //     sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
    //     availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
    //     outletTubingDiameter: 200,
    // },
    // {
    //     id: 10,
    //     name: 'Premium Home',
    //     type: 'Premium Home +',
    //     version: '400v +',
    //     product_code: "803.20.83",
    //     values: [
    //         {volume: 300, pressureLoss: 100},
    //         {volume: 260, pressureLoss: 150},
    //         {volume: 225, pressureLoss: 200},
    //         {volume: 190, pressureLoss: 250},
    //     ],
    //     options: [UNIT_EXTENSIONS.one],
    //     mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
    //     sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
    //     availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
    //     outletTubingDiameter: 200,
    // },
    // {
    //     id: 11,
    //     name: 'Premium Home',
    //     type: 'Premium Home',
    //     version: '500v',
    //     product_code: "803.21.83",
    //     values: [
    //         {volume: 400, pressureLoss: 100},
    //         {volume: 350, pressureLoss: 150},
    //         {volume: 300, pressureLoss: 200},
    //         {volume: 270, pressureLoss: 250},
    //         {volume: 250, pressureLoss: 300},
    //     ],
    //     options: [],
    //     mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
    //     sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
    //     availableTubing: [{ name: 'EPS', diameter: 160 }],
    //     outletTubingDiameter: 200,
    // },
    // {
    //     id: 12,
    //     name: 'Premium Home',
    //     type: 'Premium Home +',
    //     version: '500v +',
    //     product_code: "803.22.83",
    //     values: [
    //         {volume: 400, pressureLoss: 100},
    //         {volume: 350, pressureLoss: 150},
    //         {volume: 300, pressureLoss: 200},
    //         {volume: 270, pressureLoss: 250},
    //         {volume: 250, pressureLoss: 300},
    //     ],
    //     options: [UNIT_EXTENSIONS.one],
    //     mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
    //     sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
    //     availableTubing: [{ name: 'EPS', diameter: 160 }],
    //     outletTubingDiameter: 200,
    // },
    {
        id: 13,
        name: 'Premium Home',
        type: 'Premium Home',
        version: '600v',
        product_code: "803.23.83",
        options: [],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 200 }],
        outletTubingDiameter: 200,
        maxAirVolume: 600,
        a: -0.0009,
        b: -0.7812,
        c: 768.77,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 14,
        name: 'Premium Home',
        type: 'Premium Home +',
        version: '600v +',
        product_code: "803.24.83",
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 200 }],
        outletTubingDiameter: 200,
        maxAirVolume: 600,
        a: -0.0009,
        b: -0.7812,
        c: 768.77,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 15,
        name: 'Premium Home',
        type: 'Premium Home',
        version: '800v',
        product_code: "803.25.83",
        options: [],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 200 }],
        outletTubingDiameter: 200,
        maxAirVolume: 800,
        a: -0.0005,
        b: -0.6001,
        c: 763.06,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 16,
        name: 'Premium Home',
        type: 'Premium Home +',
        version: '800v +',
        product_code: "803.26.83",
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 200 }],
        outletTubingDiameter: 200,
        maxAirVolume: 800,
        a: -0.0005,
        b: -0.6001,
        c: 763.06,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 17,
        name: 'Premium',
        type: 'Premium H',
        version: '300h +',
        product_code: "803.07.83",
        orientation: 'horizontal',
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 300,
        a: -0.0016,
        b: -0.4673,
        c: 322.15,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 18,
        name: 'Premium',
        type: 'Premium H ++',
        version: '300h ++',
        product_code: "803.08.83",
        orientation: 'horizontal',
        options: [UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 300,
        a: -0.0016,
        b: -0.4673,
        c: 322.15,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 19,
        name: 'Premium',
        type: 'Premium V',
        version: '300v +',
        product_code: "803.27.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 300,
        a: -0.001,
        b: -0.3981,
        c: 236.78,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 20,
        name: 'Premium',
        type: 'Premium V ++',
        version: '300v ++',
        product_code: "803.28.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 300,
        a: -0.001,
        b: -0.3981,
        c: 236.78,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 21,
        name: 'Premium',
        type: 'Premium V',
        version: '400v +',
        product_code: "803.29.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 400,
        a: -0.0012,
        b: -0.3116,
        c: 322.15,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 22,
        name: 'Premium',
        type: 'Premium V ++',
        version: '400v ++',
        product_code: "803.30.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 400,
        a: -0.0012,
        b: -0.3116,
        c: 322.15,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 23,
        name: 'Premium',
        type: 'Premium V',
        version: '500v +',
        product_code: "803.31.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 500,
        a: -0.0023,
        b: 0.2682,
        c: 367.85,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 23,
        name: 'Premium',
        type: 'Premium V ++',
        version: '500v ++',
        product_code: "803.32.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 500,
        a: -0.0023,
        b: 0.2682,
        c: 367.85,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 24,
        name: 'Premium',
        type: 'Premium V',
        version: '550v +',
        product_code: "803.33.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 200 }],
        outletTubingDiameter: 200,
        maxAirVolume: 550,
        a: -0.0023,
        b: 0.3435,
        c: 404.82,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 25,
        name: 'Premium',
        type: 'Premium V ++',
        version: '550v ++',
        product_code: "803.34.83",
        orientation: 'vertical',
        options: [UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 200 }],
        outletTubingDiameter: 200,
        maxAirVolume: 550,
        a: -0.0023,
        b: 0.3435,
        c: 404.82,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 26,
        name: 'Premium',
        type: 'Premium H',
        version: '400h +',
        product_code: "803.10.83",
        orientation: 'horizontal',
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 400,
        a: -0.0011,
        b: -0.6308,
        c: 433.45,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 26,
        name: 'Premium',
        type: 'Premium H ++',
        version: '400h ++',
        product_code: "803.11.83",
        orientation: 'horizontal',
        options: [UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 400,
        a: -0.0011,
        b: -0.6308,
        c: 433.45,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 27,
        name: 'Premium',
        type: 'Premium H',
        version: '500h +',
        product_code: "803.13.83",
        orientation: 'horizontal',
        options: [UNIT_EXTENSIONS.one],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 500,
        a: -0.0014,
        b: -0.4539,
        c: 515.01,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 27,
        name: 'Premium',
        type: 'Premium H ++',
        version: '500h ++',
        product_code: "803.14.83",
        orientation: 'horizontal',
        options: [UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 500,
        a: -0.0014,
        b: -0.4539,
        c: 515.01,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 28,
        name: 'Premium Entalpica',
        type: 'Enthalpic H',
        version: '300h',
        product_code: "804.04.83",
        orientation: 'horizontal',
        options: ['Enthalpic', UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two, UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 300,
        a: -0.0016,
        b: -0.4794,
        c: 323.21,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 29,
        name: 'Premium Entalpica',
        type: 'Enthalpic H',
        version: '400h',
        product_code: "804.05.83",
        orientation: 'horizontal',
        options: ['Enthalpic', UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two, UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 400,
        a: -0.0011,
        b: -0.6434,
        c: 437.91,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 30,
        name: 'Premium Entalpica',
        type: 'Enthalpic H',
        version: '500h',
        product_code: "804.06.83",
        orientation: 'horizontal',
        options: ['Enthalpic', UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two, UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall, UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 500,
        a: -0.0014,
        b: -0.4453,
        c: 513.45,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 31,
        name: 'Premium Entalpica',
        type: 'Enthalpic V',
        version: '300v',
        product_code: "804.07.83",
        orientation: 'vertical',
        options: ['Enthalpic', UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two, UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 300,
        a: -0.0011,
        b: -0.4005,
        c: 241.6,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 32,
        name: 'Premium Entalpica',
        type: 'Enthalpic V',
        version: '400v',
        product_code: "804.08.83",
        orientation: 'vertical',
        options: ['Enthalpic', UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two, UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160 }],
        outletTubingDiameter: 200,
        a: -0.001,
        b: -0.4128,
        c: 334.01,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 33,
        name: 'Premium Entalpica',
        type: 'Enthalpic V',
        version: '500v',
        product_code: "804.09.83",
        orientation: 'vertical',
        options: ['Enthalpic', UNIT_EXTENSIONS.one, UNIT_EXTENSIONS.two, UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...premiumUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }],
        outletTubingDiameter: 200,
        maxAirVolume: 500,
        a: -0.0019,
        b: 0.1412,
        c: 361.57,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 34,
        name: 'Standard Entalpica',
        type: 'Standard E',
        version: '230E',
        product_code: "804.01.50",
        options: ['Enthalpic', UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.ceiling],
        sensors: [...basicUnitSensors, ...unitAuxiliary.filter(aux => !aux.name.includes('Filtre rezervă')), { name: 'Filtre rezervă G4' }, { name: 'Filtre rezervă F7' }],
        availableTubing: [{ name: 'EPS', diameter: 125 }, { name: 'Flexibilă', diameter: 125, recommended: true }],
        outletTubingDiameter: 125,
        maxAirVolume: 230,
        a: -0.0068,
        b: -0.3166,
        c: 321.89,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 35,
        name: 'Standard Entalpica',
        type: 'Standard E',
        version: '280E',
        product_code: "804.02.50",
        options: ['Enthalpic', UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...unitAuxiliary.filter(aux => !aux.name.includes('Filtre rezervă')), { name: 'Filtre rezervă G4' }, { name: 'Filtre rezervă F7' }],
        availableTubing: [{ name: 'EPS', diameter: 125 }, { name: 'Flexibilă', diameter: 125, recommended: true }],
        outletTubingDiameter: 125,
        maxAirVolume: 280,
        a: -0.0051,
        b: -0.8977,
        c: 477.22,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
    {
        id: 36,
        name: 'Standard Entalpica',
        type: 'Standard E',
        version: '590E',
        product_code: "804.03.50",
        options: ['Enthalpic', UNIT_EXTENSIONS.three],
        mountingOptions: [UNIT_MOUNTING_OPTIONS.wall],
        sensors: [...basicUnitSensors, ...unitAuxiliary],
        availableTubing: [{ name: 'EPS', diameter: 160 }, { name: 'Flexibilă', diameter: 160, recommended: true }],
        outletTubingDiameter: 155,
        maxAirVolume: 590,
        a: -0.0016,
        b: -1.051,
        c: 1077.6,
        value: function (x) {
            return this.a * x * x + this.b * x + this.c;
        }
    },
];

/**
 * A function for calculating the most efficient distributor permutation, considering the total nr of circuits
 and the possible circuit permutations
 @returns The most efficient distributor permutation: {distributors: Number[], remainder: Number}
 */
export const calculateDistributorPermutations = (totalCircuits, possibleCircuitPermutations) => {
    //Divide the total nr of circuits by each possible permutation
    let permutations = possibleCircuitPermutations.map(permutation => {
        let remainder = totalCircuits % permutation;
        let quotient = Math.floor(totalCircuits / permutation);
        let distributors = []
        //Add the whole quotient to the distributors array 
        distributors.push(...Array.from({ length: quotient }, () => permutation))
        if (remainder > 0) {
            //If total nr of circuits is lower than the permutation, add the permutation to the distributors array,
            //and calculate the total loss it would create
            if (remainder === totalCircuits) {
                distributors.push(permutation)
                remainder -= permutation
            } else {
                //If the remainder is higher than 0 but lower than the total nr of circuits, repeat the process
                let result = calculateDistributorPermutations(remainder, possibleCircuitPermutations)
                distributors.push(...result.distributors)
                remainder = result.remainder
            }
        }
        return { distributors, remainder }
    })
    // Sort the permutations array for the most efficient permutation, adjusting for loss and the total number of distributors
    // Permutations with the lowest nr of distributors needed take priority over loss (unused circuits)
    return permutations.sort((a, b) => {

        // Prioritize nr of distributors over loss
        return a.distributors.length === b.distributors.length ?
            Math.abs(a.remainder) - Math.abs(b.remainder) :
            a.distributors.length - b.distributors.length

        //Prioritize loss over nr of distributors
        // return Math.abs(a.remainder) === Math.abs(b.remainder) ?
        //     a.distributors.length - b.distributors.length :
        //     Math.abs(a.remainder) - Math.abs(b.remainder)
    })[0]
}