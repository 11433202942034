<template>
    <v-card>
        <v-toolbar @click="toggleCollapsedContent" class="cursor-pointer">
            <v-toolbar-title>
                Listă centralizatoare
            </v-toolbar-title>
        </v-toolbar>
        <v-expand-transition>
            <v-card-text v-if="!collapsed">
                <v-data-table
                    :headers="headers"
                    :items="locationSummaries"
                    class="elevation-1"
                    item-key="id"
                    :items-per-page="50"
                    :loading="loading"
                >
                    <template v-slot:[`item.id`]="{ item }">
                        <div @click="openSummary(item.id)">
                            {{ item.id }}
                        </div>
                    </template>
                    <template v-slot:[`item.modified_at`]="{ item }">
                        {{
                            new Date(item.modified_at).toLocaleString("ro-RO", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                            })
                        }}
                    </template>
                    <template v-slot:[`item.locations`]="{ item }">
                        <div class="location-container d-flex flex-row flex-wrap">
                            <span v-for="(location, index) in item.locations" :key="location.id">
                                {{ location.description }}<span
                                v-if="index < item.locations.length - 1">,&nbsp;</span><br/>
                            </span>
                        </div>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                        {{ formatNumber(this, calculateSummaryTotal(item), true) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn @click="openSummary(item.id)">
                            <v-icon color="info">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn @click="confirmDeleteSummary(item.id)" class="ml-1">
                            <v-icon color="danger">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-expand-transition>
        <v-dialog v-model="deleteDialog" max-width="400px">
            <v-card>
                <v-card-title class="headline">Confirmare ștergere</v-card-title>
                <v-card-text>
                    Acest centralizator poate conține mai multe locații. <br> Ești sigur că vrei să îl ștergi?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="closeDeleteDialog">Anulare</v-btn>
                    <v-btn color="danger" @click="deleteSummary">Șterge</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {formatNumber, showAlertModal} from "@/utils/utils";

export default {
    name: "SummariesCard",
    components: {},
    props: {
        locationId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {title: "Nr", key: "id", width: "10%"},
                {title: "Data modificării", key: "modified_at", width: "20%"},
                {title: "Locații", key: "locations", width: "40%"},
                {title: "Total", key: "total", width: "15%"},
                {title: "Acțiuni", key: "actions", width: "15%"},
            ],
            locationSummaries: [],
            loading: false,
            collapsed: true,
            deleteDialog: false,
            summaryIdToDelete: null,
        };
    },
    methods: {
        /**
         * Navigates to the Offers Summary page for a specific summary.
         * Saves the current summary ID in session storage.
         * @param {number} summaryId - The ID of the summary to open.
         */
        openSummary(summaryId) {
            sessionStorage.setItem('currentSummaryId', summaryId);
            this.$router.push({name: 'OffersSummary'});
        },
        /**
         * Calculates the total value of a summary by summing the total values of its items.
         * @param {Object} summary - The summary object containing an array of items.
         * @returns {number} - The calculated total value of the summary.
         */
        calculateSummaryTotal(summary) {
            let total = 0;
            summary.items.forEach(summaryItem => {
                total += parseFloat(summaryItem.total);
            });
            return total;
        },
        /**
         * Fetches summaries for the current location from the API.
         * Updates the `locationSummaries` array and sorts summaries by their modification date.
         * Shows an alert in case of an error.
         * @async
         */
        async fetchSummariesByLocation() {
            this.loading = true;
            try {
                const response = await apiClient.get(`/api/summaries/location/${this.locationId}/`);
                this.locationSummaries = response.data.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at));
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.error('Error fetching summaries:', error.response ? error.response.data : error.message);
                showAlertModal(this.$store, 'A intervenit o eroare la preluarea rezumatelor.', 'danger', 12000, error);
            }
        },
        /**
         * Opens a confirmation dialog for deleting a summary.
         * @param {number} summaryId - The ID of the summary to be deleted.
         */
        confirmDeleteSummary(summaryId) {
            this.summaryIdToDelete = summaryId;
            this.deleteDialog = true;
        },
        /**
         * Deletes a summary by its ID.
         * Refreshes the list of summaries after successful deletion.
         * Shows success or error alerts based on the operation outcome.
         * @async
         */
        async deleteSummary() {
            try {
                await apiClient.delete(`/api/main-summaries/${this.summaryIdToDelete}/`);
                this.deleteDialog = false;
                await this.fetchSummariesByLocation();
                showAlertModal(this.$store, 'Centralizatorul a fost șters cu succes.', 'success', 2000);
            } catch (error) {
                console.error('Error deleting summary:', error.response ? error.response.data : error.message);
                this.deleteDialog = false;
                showAlertModal(this.$store, 'A intervenit o eroare la ștergerea centralizatorului.', 'danger', 12000, error);
            }
        },
        /**
         * Closes the delete confirmation dialog and clears the selected summary ID.
         */
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.summaryIdToDelete = null;
        },
        /**
         * Toggles the collapsed state of the content.
         * Fetches summaries if the content is expanded.
         * @async
         */
        async toggleCollapsedContent() {
            this.collapsed = !this.collapsed;
            if (!this.collapsed) {
                await this.fetchSummariesByLocation();
            }
        },
        formatNumber,
    },
};
</script>

<style scoped>
.location-container {
    white-space: normal;
    word-wrap: break-word;
}
</style>
