<template>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title>
                Fittinguri
            </v-toolbar-title>
            <v-toolbar-items>
                <v-btn @click="addItem" size="x-large">
                    <v-icon color="success">
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col v-for="(item, index) in plumbingItems" :key="item" cols="12">
                    <PlumbingSetupItem
                        :plumbing-item="item"
                        :plumbing-fittings="formattedAvailablePlumbingFittings"
                        @update-item="updateItem(index, $event)"
                        @remove-item="removeItem(index)"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import PlumbingSetupItem from "@/components/offer/PlumbingSetupItem.vue";
import apiClient from "@/utils/apiClient";

export default {
    name: "PlumbingSetup",
    components: {PlumbingSetupItem},
    data() {
        return {
            plumbingItems: [
                {fitting: null, quantity: 1},
            ],
        };
    },
    emits: ['update-selected-plumbing-items'],
    props: {
        plumbingFittingProducts: {
            type: Array,
            required: true,
        },
        offerId: {
            type: String,
        },
    },
    computed: {
        /**
         * Formats available plumbing fittings by mapping product names to codes for v-select options.
         * @returns {Array} Array of objects with `title` and `value` for each fitting.
         */
        formattedAvailablePlumbingFittings() {
            return this.plumbingFittingProducts.map(product => ({
                title: product.product_name,
                value: product.product_code
            }));
        },
    },
    async mounted() {
        // Check if there's an offerId and fetch the plumbing setup if it exists
        if (this.offerId) {
            await this.fetchPlumbingSetup(this.offerId);
        }
    },
    methods: {
        /**
         * Fetches the plumbing setup for a given offer ID and populates the plumbingItems array.
         * @param {String} offerId - The ID of the offer to fetch the plumbing setup for.
         * @returns {Promise<void>}
         */
        async fetchPlumbingSetup(offerId) {
            try {
                const response = await apiClient.get(`/api/plumbing-setup/${offerId}/`);
                const plumbingSetup = response.data.plumbing_items;

                // Set the selected plumbing items based on the fetched setup
                this.plumbingItems = plumbingSetup.map(item => ({
                    fitting: item.product_code,
                    quantity: item.quantity
                }));
            } catch (error) {
                console.error("Error fetching plumbing setup:", error);
            }
        },
        /**
         * Adds a new plumbing item to the plumbingItems array with default values.
         */
        addItem() {
            this.plumbingItems.push({fitting: null, quantity: 1});
        },
        /**
         * Removes a plumbing item from the plumbingItems array at the given index.
         * @param {Number} index - The index of the plumbing item to remove.
         */
        removeItem(index) {
            this.plumbingItems.splice(index, 1);
        },
        /**
         * Updates a plumbing item in the plumbingItems array at the given index with new data.
         * @param {Number} index - The index of the plumbing item to update.
         * @param {Object} updatedItem - The updated plumbing item object with fitting and quantity.
         */
        updateItem(index, updatedItem) {
            this.plumbingItems.splice(index, 1, updatedItem);
        },
    },
    watch: {
        /**
         * Watches for changes in plumbingItems and emits an event to update the parent component.
         * If any item has a falsy property, it is removed from the plumbingItems array.
         * @param {Array} newItems - The updated plumbing items array.
         */
        plumbingItems: {
            handler: function (newItems) {
                this.$emit('update-selected-plumbing-items', newItems);
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
</style>
