<template>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title :style="this.$vuetify.display.lgAndUp ? 'max-width: 200px;' : ''">
                Retururi
            </v-toolbar-title>
            <v-row v-if="this.$vuetify.display.lgAndUp">
                <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                        v-model="combinedFilters"
                        :items="filterOptions"
                        item-title="text"
                        item-value="value"
                        label="Filtrează status"
                        multiple
                        clearable
                        chips
                        closable-chips
                        variant="outlined"
                        density="compact"
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                        v-if="this.userPermissions.returnsManagement.canViewAllReturns"
                        v-model="selectedUsers"
                        :items="users"
                        item-title="full_name"
                        item-value="id"
                        label="Filtrează după agent"
                        multiple
                        clearable
                        chips
                        closable-chips
                        variant="outlined"
                        density="compact"
                        hide-details
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-toolbar-items>
                <v-btn color="success" @click="openReturnCreate">
                    Adaugă retur
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-row v-if="this.$vuetify.display.mdAndDown">
                <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                        v-model="combinedFilters"
                        :items="filterOptions"
                        item-title="text"
                        item-value="value"
                        label="Filtrează operat/facturat"
                        multiple
                        clearable
                        chips
                        closable-chips
                        variant="outlined"
                        density="compact"
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                        v-if="this.userPermissions.returnsManagement.canViewAllReturns"
                        v-model="selectedUsers"
                        :items="users"
                        item-title="full_name"
                        item-value="id"
                        label="Filtrează după agent"
                        multiple
                        clearable
                        chips
                        closable-chips
                        variant="outlined"
                        density="compact"
                        hide-details
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <div v-if="!isMobile">
                <div class="table-responsive">
                    <v-data-table
                        :headers="tableHeaders"
                        :items="filteredReturns"
                        class="elevation-1"
                        item-key="id"
                        :hover="true"
                        dense
                    >
                        <template v-slot:item="{ item }">
                            <tr
                                @click="editReturn(item, item.id)"
                                style="cursor: pointer;"
                                :class="{
                                    'text-danger': shouldHighlightReturn(item),
                                    'highlight-green': shouldDisplayAsGreen(item),
                                }"
                            >
                                <v-tooltip v-if="shouldShowTooltip(item)" location="top" activator="parent">
                                    {{ getTooltipMessage(item) }}
                                </v-tooltip>
                                <td v-if="userPermissions.returnsManagement.canViewReturnsAgents">
                                    {{ item.agent_full_name }}
                                </td>
                                <td>{{ getClientNames(item.clients) }}</td>
                                <td>{{ getSubregionName(item.subregion) }} ({{ getRegionName(item.region) }})</td>
                                <td v-if="userPermissions.returnsManagement.canViewReturnCreationDate">
                                    {{ formatDateOnly(item.created_at) }}
                                    <v-tooltip location="right" activator="parent">
                                        {{ formatHoursOnly(item.created_at) }}
                                    </v-tooltip>
                                </td>
                                <td v-if="!item.operated && this.userPermissions.isSuperuser"
                                    @click.stop="!item.operated && this.userPermissions.returnsManagement.canSetOperatedToTrue ? checkAndUpdateReturnField(item.id, 'operated', !item.operated) : displayNoPermissionModal()">
                                    <div>
                                        <v-checkbox
                                            v-model="item.operated"
                                            :readonly="!item.transportation_cost"
                                            :disabled="!userPermissions.returnsManagement.canSetOperatedToFalse || !userPermissions.returnsManagement.canViewReturnTransportationCost"
                                            density="compact"
                                            hide-details
                                        >
                                        </v-checkbox>
                                    </div>
                                </td>
                                <td v-else-if="item.operated">
                                    {{ formatDateOnly(item.operated_at) }}
                                    <v-tooltip location="right" activator="parent">
                                        {{ formatHoursOnly(item.operated_at) }}
                                    </v-tooltip>
                                </td>
                                <td v-else>
                                    Neoperat
                                </td>
                                <td v-if="!item.received && this.userPermissions.isSuperuser"
                                    @click.stop="userPermissions.returnsManagement.canEditReturnReceivedStatus ? updateReturnField(item.id, 'received', !item.received) : displayNoPermissionModal()">
                                    <div>
                                        <v-checkbox
                                            v-model="item.received"
                                            :disabled="!userPermissions.returnsManagement.canEditReturnReceivedStatus"
                                            density="compact"
                                            hide-details
                                        >
                                        </v-checkbox>
                                    </div>
                                </td>
                                <td v-else-if="item.received">
                                    {{ formatDateOnly(item.received_at) }}
                                    <v-tooltip location="right" activator="parent">
                                        {{ formatHoursOnly(item.received_at) }}
                                    </v-tooltip>
                                </td>
                                <td v-else>
                                    Nerecepționat
                                </td>
                                <td v-if="!item.invoiced && this.userPermissions.isSuperuser"
                                    @click.stop="userPermissions.returnsManagement.canSetReturnToInvoiced ? checkAndUpdateReturnField(item.id, 'invoiced', !item.invoiced) : displayNoPermissionModal()">
                                    <div>
                                        <v-checkbox
                                            v-model="item.invoiced"
                                            :readonly="!item.transportation_cost"
                                            :disabled="!userPermissions.returnsManagement.canSetInvoicedToFalse"
                                            density="compact"
                                            hide-details
                                        >
                                        </v-checkbox>
                                    </div>
                                </td>
                                <td v-else-if="item.invoiced">
                                    {{ formatDateOnly(item.invoiced_at) }}
                                    <v-tooltip location="right" activator="parent">
                                        {{ formatHoursOnly(item.invoiced_at) }}
                                    </v-tooltip>
                                </td>
                                <td v-else>
                                    Nefacturat
                                </td>
                                <td>{{ item.has_service_items ? 'Da' : 'Nu' }}</td>
                                <td>
                                    <v-btn variant="plain" @click.stop="showDeleteDialog(item.id)">
                                        <v-icon color="danger">mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </div>
            <!-- Mobile version -->
            <div v-else>
                <v-row>
                    <v-col
                        v-for="(item, index) in filteredReturns"
                        :key="index"
                        @click="editReturn(item, item.id)"
                        style="cursor: pointer;"
                        cols="12"
                    >
                        <v-card
                            :color="shouldHighlightReturn(item) ? 'danger' :
                                    shouldDisplayAsGreen(item) ? 'success' : 'default'"
                        >
                            <v-toolbar density="comfortable">
                                <v-toolbar-title>
                                    ID Retur {{ item.id }}
                                </v-toolbar-title>
                                <v-toolbar-items class="d-flex flex-wrap align-items-center">
                                    <v-btn size="large">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                        <v-menu
                                            activator="parent"
                                            :close-on-content-click="true"
                                        >
                                            <v-list>
                                                <v-list-item>
                                                    <v-switch
                                                        v-model="item.operated"
                                                        :readonly="(item.operated && !userPermissions.returnsManagement.canSetOperatedToFalse) ||
                                            (!item.operated && !userPermissions.returnsManagement.canSetOperatedToTrue)"
                                                        @click="handleOperatedToggle(item)"
                                                        label="Operat"
                                                        variant="outlined"
                                                        color="success"
                                                        inset
                                                        density="compact"
                                                        :disabled="!userPermissions.isSuperuser"
                                                        :hide-details="true"
                                                    ></v-switch>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-switch
                                                        v-model="item.received"
                                                        :readonly="!userPermissions.returnsManagement.canEditReturnReceivedStatus"
                                                        @click="updateReturnField(item.id, 'received', !item.received)"
                                                        label="Recepționat"
                                                        inset
                                                        color="success"
                                                        density="compact"
                                                        :hide-details="true"
                                                        :disabled="!userPermissions.isSuperuser"
                                                    ></v-switch>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-switch
                                                        v-model="item.invoiced"
                                                        :readonly="!userPermissions.returnsManagement.canSetInvoicedToFalse"
                                                        @click="userPermissions.returnsManagement.canSetReturnToInvoiced ? checkAndUpdateReturnField(item.id, 'invoiced', !item.invoiced) : displayNoPermissionModal()"
                                                        label="Facturat"
                                                        inset
                                                        color="success"
                                                        density="compact"
                                                        :hide-details="true"
                                                        :disabled="!userPermissions.isSuperuser"
                                                    ></v-switch>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="w-100"
                                                        variant="elevated"
                                                        color="danger"
                                                        @click="showDeleteDialog(item.id)"
                                                    >
                                                        Șterge
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-card-text>
                                <v-tooltip v-if="shouldShowTooltip(item)" location="top" activator="parent">
                                    {{ getTooltipMessage(item) }}
                                </v-tooltip>
                                <v-row>
                                    <v-col cols="12" md="3" lg="4">
                                        <template v-if="userPermissions.returnsManagement.canViewReturnsAgents">
                                            Agent: <strong>{{ item.agent_full_name }}</strong>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        Localitate (Județ): <strong>{{ getSubregionName(item.subregion) }} ({{ getRegionName(item.region) }})</strong>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        Clienți: <strong>{{ getClientNames(item.clients) }}</strong>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        Service: <strong>{{ item.has_service_items ? 'Da' : 'Nu' }}</strong>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4"
                                           v-if="this.userPermissions.returnsManagement.canViewReturnCreationDate">
                                        Data creării:
                                        <v-tooltip location="top">
                                            <template #activator="{ props }">
                                                <strong>
                                                    <span v-bind="props">{{ formatDateOnly(item.created_at) }}</span>
                                                </strong>
                                            </template>
                                            <span>{{ formatHoursOnly(item.created_at) }}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        Stare operat: <strong v-if="item.operated">{{
                                            formatDateTime(item.operated_at)
                                        }}</strong><span v-else><strong>Neoperat</strong></span>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        Stare recepționat: <strong
                                        v-if="item.received">{{ formatDateTime(item.received_at) }}</strong><span
                                        v-else><strong>Nerecepționat</strong></span>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        Stare facturat: <strong v-if="item.invoiced">{{
                                            formatDateTime(item.invoiced_at)
                                        }}</strong><span v-else><strong>Nefacturat</strong></span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <!-- Dialog for delete confirmation -->
        <v-dialog v-model="showDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Confirmare Ștergere</v-card-title>
                <v-card-text>
                    Ești sigur că dorești să ștergi acest retur?
                </v-card-text>
                <v-card-actions class="d-flex justify-content-end">
                    <v-btn color="grey" text @click="cancelDelete">Anulează</v-btn>
                    <v-btn color="danger" text @click="confirmDelete">Șterge</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="transportationDialog" max-width="500px" persistent>
            <v-card>
                <v-card-title class="headline">Cost transport</v-card-title>
                <v-card-text>
                    Pentru a actualiza returul, trebuie să adăugați un cost de transport:
                    <v-text-field
                        class="mt-2"
                        v-model="inputTransportationCost"
                        label="Cost transport"
                        variant="outlined"
                        density="compact"
                        suffix="RON"
                        required
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn color="grey" text @click="closeTransportationDialog">Anulează</v-btn>
                    <v-btn color="success" text @click="saveTransportationCost">Salvează</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {showAlertModal, fetchSubregions, fetchRegions} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
    name: "ReturnsTab",
    components: {},
    data() {
        return {
            returns: [],
            users: [],
            selectedUsers: [],
            combinedFilters: [], // array of filter values: 'operat', 'neoperat', 'facturat', 'nefacturat'
            showDialog: false,
            selectedReturnId: null,
            statusOptions: [
                {title: 'Creat', value: 1},
                {title: 'Operat', value: 2},
                {title: 'Recepționat', value: 3},
                {title: 'Facturat', value: 4},
            ],
            transportationDialog: false, // Controls the pop-up visibility
            inputTransportationCost: null, // Holds the entered transportation cost
            pendingReturnUpdate: null, // Holds temporary return info before update
            regions: [],
            subregions: [],
            timeLimitHours: 48,
        };
    },
    computed: {
        ...mapGetters(['userPermissions', 'userRole']),
        filterOptions() {
            const {returnsManagement, isSuperuser, isAgent} = this.userPermissions;
            const {canSetOperatedToTrue, canEditReturnReceivedStatus, canSetReturnToInvoiced} = returnsManagement;

            // If superuser, show all filters regardless of other conditions
            if (isSuperuser) {
                return [
                    {text: 'Toate', value: 'all'},
                    {text: 'Operat', value: 'operat'},
                    {text: 'Recepționat', value: 'received'},
                    {text: 'Facturat', value: 'facturat'},
                    {text: 'Șterse', value: 'sterse'}
                ];
            }

            if (isAgent) {
                return [
                    {text: 'Toate', value: 'all'},
                    {text: 'Operat', value: 'operat'},
                    {text: 'Recepționat', value: 'received'},
                    {text: 'Facturat', value: 'facturat'},
                ];
            }

            // Priority-based logic for non-superusers:
            // 1. If canEditReturnReceivedStatus is true → Achizitii set
            if (canEditReturnReceivedStatus) {
                return [
                    {text: 'Toate', value: 'all'},
                    {text: 'Operat', value: 'operat'},
                    {text: 'Recepționat', value: 'received'},
                ];
            }

            // 2. If canSetOperatedToTrue is true AND canEditReturnReceivedStatus is false → Logistica set
            if (canSetOperatedToTrue && !canEditReturnReceivedStatus) {
                return [
                    {text: 'Toate', value: 'all'},
                    {text: 'Operat', value: 'operat'},
                ];
            }

            // 3. If canSetReturnToInvoiced is true → Comenzi set
            if (canSetReturnToInvoiced) {
                return [
                    {text: 'Toate', value: 'all'},
                    {text: 'Recepționat', value: 'received'},
                    {text: 'Facturat', value: 'facturat'},
                ];
            }

            // 4. Fallback: no special permissions
            return [
                {text: 'Toate', value: 'all'},
                {text: 'Operat', value: 'operat'},
                {text: 'Recepționat', value: 'received'},
                {text: 'Facturat', value: 'facturat'},
            ];
        },
        tableHeaders() {
            let headers = [
                // Conditionally add the Agent column
                ...(this.userPermissions.returnsManagement.canViewReturnsAgents ? [{
                    title: 'Nume agent',
                    key: 'agent_full_name',
                    align: 'start',
                    width: '250px'
                }] : []),
                {title: 'Nume client', key: 'clients', align: 'start', width: '250px'},
                {title: 'Localitate (Județ)', key: 'address', align: 'start', width: '250px'},
                ...(this.userPermissions.returnsManagement.canViewReturnCreationDate ? [{
                    title: 'Data creării',
                    key: 'created_at',
                    align: 'start',
                    width: '150px'
                }] : []),
                {title: 'Operat', key: 'operated', align: 'center', width: '100px'},
                {title: 'Recepționat', key: 'received', align: 'center', width: '100px'},
                {title: 'Facturat', key: 'invoiced', align: 'center', width: '100px'},
                {title: 'Service', key: 'has_service_items', align: 'center', width: '100px'}, // Added column
                {title: 'Acțiuni', key: 'actions', align: 'center', width: '100px'},
            ];

            return headers;
        },
        isMobile() {
            return this.$vuetify.display.mdAndDown;
        },
        filteredReturns() {
            const {returns, combinedFilters, userPermissions} = this;
            const {
                canSetOperatedToTrue,
                canEditReturnReceivedStatus,
                canSetReturnToInvoiced
            } = userPermissions.returnsManagement;

            // Step 1: Filter based on combinedFilters
            let filtered = this.filterByCombinedFilters(returns, combinedFilters);

            if (this.userPermissions.isSuperuser) {
                return filtered;
            }

            // Step 2: If canEditReturnReceivedStatus is true
            // Show only returns that are not received or received within the last 48 hours
            if (canEditReturnReceivedStatus) {
                filtered = this.filterIfCanEditReturnReceivedStatus(filtered);
            }
                // Step 3: Else if canSetOperatedToTrue is true and canEditReturnReceivedStatus is false
            // Show only returns that are not operated or operated within the last 48 hours
            else if (canSetOperatedToTrue && !canEditReturnReceivedStatus) {
                filtered = this.filterIfCanSetOperatedToTrue(filtered);
            }
                // Step 4: Else if canSetReturnToInvoiced is true
            // Show only returns that are received or invoiced within the last 48 hours
            else if (canSetReturnToInvoiced) {
                filtered = this.filterIfCanSetReturnToInvoiced(filtered);
            }

            return filtered;
        },
    },
    async mounted() {
        if (!sessionStorage.getItem("regions")) {
            this.regions = await fetchRegions();
        } else {
            this.regions = JSON.parse(sessionStorage.getItem("regions"));
        }
        if (!sessionStorage.getItem("subregions")) {
            this.subregions = await fetchSubregions();
        } else {
            this.subregions = JSON.parse(sessionStorage.getItem("subregions"));
        }
        await this.fetchReturns();
        if (this.userPermissions.returnsManagement.canViewAllReturns) {
            await this.loadUsersFromSession();
        }
    },
    methods: {
        /**
         * Retrieves the name of a region based on its ID.
         *
         * This method searches the `regions` array for the region object with a matching ID,
         * returning the region’s name if found, or an empty string otherwise.
         *
         * @param {number} regionId - The ID of the region to retrieve.
         * @returns {string} - The name of the region.
         */
        getRegionName(regionId) {
            const region = this.regions.find((r) => r.value === regionId);
            return region ? region.title : '';
        },
        handleOperatedToggle(item) {
            if (!item.operated && this.userPermissions.returnsManagement.canSetOperatedToTrue) {
                this.checkAndUpdateReturnField(item.id, 'operated', true);
            } else if (item.operated && this.userPermissions.returnsManagement.canSetOperatedToFalse) {
                this.checkAndUpdateReturnField(item.id, 'operated', false);
            } else {
                this.displayNoPermissionModal();
            }
        },
        /**
         * Retrieves the name of a subregion based on its ID.
         *
         * This method searches the `subregions` array for the region object with a matching ID,
         * returning the region’s name if found, or an empty string otherwise.
         *
         * @param {number} subRegionId - The ID of the region to retrieve.
         * @returns {string} - The name of the region.
         */
        getSubregionName(subRegionId) {
            const region = this.subregions.find((r) => r.value === subRegionId);
            return region ? region.title : '';
        },
        /**
         * Filters returns based on combinedFilters.
         * Possible values: 'all', 'operat', 'received', 'facturat'
         * If multiple are selected, the return must meet all conditions.
         *
         * @param {Array} returns - The list of all returns.
         * @param {Array} filters - The combined filters array.
         * @returns {Array} The filtered returns.
         */
        filterByCombinedFilters(returns, filters) {
            // If 'all' is selected, show all returns
            if (filters.includes('all')) {
                return returns;
            }

            // If no filters selected, show all
            if (filters.length === 0) {
                return returns.filter(item => item.is_active);
            }

            const needOperat = filters.includes('operat');
            const needReceived = filters.includes('received');
            const needFacturat = filters.includes('facturat');
            const needSterse = filters.includes('sterse');  // <-- NEW

            return returns.filter((returnItem) => {
                // 1) If 'sterse' is selected, must be is_active === false
                if (needSterse && returnItem.is_active) {
                    return false;
                }
                // 2) If 'operat' selected, must be operated
                if (needOperat && !returnItem.operated) {
                    return false;
                }
                // 3) If 'received' selected, must be received
                if (needReceived && !returnItem.received) {
                    return false;
                }
                // 4) If 'facturat' selected, must be invoiced
                if (needFacturat && !returnItem.invoiced) {
                    return false;
                }

                // If it passes all checks, it’s included
                return true;
            });
        },
        /**
         * If canEditReturnReceivedStatus is true:
         * Show only returns that are not received or received within the last 48 hours.
         * Also must be operated.
         * @param {Array} returns - The currently filtered list of returns.
         * @returns {Array} The further filtered returns.
         */
        filterIfCanEditReturnReceivedStatus(returns) {
            const now = new Date();
            return returns.filter(returnItem => {
                // If not received but operated, always visible
                if (!returnItem.received && returnItem.operated) {
                    return true;
                }

                // If received, check received_at date
                if (returnItem.received_at) {
                    const receivedAt = new Date(returnItem.received_at);
                    const diffHours = (now - receivedAt) / 36e5;
                    return diffHours <= 48 && returnItem.operated;
                }

                // If received but no received_at date, exclude
                return false;
            });
        },
        /**
         * If canSetOperatedToTrue is true and canEditReturnReceivedStatus is false:
         * Show only returns that are not operated or operated within the last 48 hours.
         * @param {Array} returns - The currently filtered list of returns.
         * @returns {Array} The further filtered returns.
         */
        filterIfCanSetOperatedToTrue(returns) {
            const now = new Date();
            return returns.filter(returnItem => {
                // If not operated, always visible
                if (!returnItem.operated) {
                    return true;
                }

                // If operated, check operated_at date
                if (returnItem.operated_at) {
                    const operatedAt = new Date(returnItem.operated_at);
                    const diffHours = (now - operatedAt) / 36e5;
                    return diffHours <= 48;
                }

                // If operated but no operated_at, exclude
                return false;
            });
        },
        /**
         * If canSetReturnToInvoiced is true:
         * Show only returns that are received and either not invoiced yet,
         * or invoiced within the last 48 hours.
         *
         * @param {Array} returns - The currently filtered list of returns.
         * @returns {Array} The further filtered returns.
         */
        filterIfCanSetReturnToInvoiced(returns) {
            const now = new Date();
            return returns.filter(returnItem => {
                // If not received, exclude
                if (!returnItem.received) {
                    return false;
                }

                // If received and not invoiced, show it
                if (!returnItem.invoiced) {
                    return true;
                }

                // If received and invoiced, check invoiced_at
                if (returnItem.invoiced_at) {
                    const invoicedAt = new Date(returnItem.invoiced_at);
                    const diffHours = (now - invoicedAt) / 36e5;
                    return diffHours <= 48;
                }

                // If invoiced but no invoiced_at (edge case), exclude
                return false;
            });
        },
        /**
         * Displays a warning modal when the user attempts an action without having sufficient permissions.
         */
        displayNoPermissionModal() {
            showAlertModal(this.$store, `Nu aveți permisiunile necesare pentru această acțiune!`, "warning", 6000);
        },
        /**
         * Determines the reference date (timestamp) for the current return stage
         * that the *current user* is responsible for.
         *
         * Logic:
         * - If `operated` is false but user can operate, use `created_at`.
         * - If `operated` is true but `received` is false and user can mark it received, use `operated_at`.
         * - If `received` is true but `invoiced` is false and user can mark it invoiced, use `received_at`.
         * - If none of the above apply, return the latest date from the last completed stage.
         *
         * @param {Object} returnItem - The return object with status flags and timestamps.
         * @returns {Date|null} - The reference date for the stage the current user can change, or the latest completed stage.
         */
        getReferenceDate(returnItem) {
            const {
                canSetOperatedToTrue,
                canEditReturnReceivedStatus,
                canSetReturnToInvoiced,
            } = this.userPermissions.returnsManagement;

            // 1) If it's not operated, check if user can operate it
            if (!returnItem.operated && canSetOperatedToTrue) {
                return new Date(returnItem.created_at);
            }

            // 2) If it's operated but not received, check if user can mark it received
            if (returnItem.operated && !returnItem.received && canEditReturnReceivedStatus) {
                return new Date(returnItem.operated_at);
            }

            // 3) If it's received but not invoiced, check if user can mark it invoiced
            if (returnItem.received && !returnItem.invoiced && canSetReturnToInvoiced) {
                return new Date(returnItem.received_at);
            }

            if (returnItem.invoiced && returnItem.received && returnItem.operated) {
                return null;
            }

            // If no permissions are available, return the latest true status date
            if (!returnItem.invoiced && returnItem.received_at) {
                return new Date(returnItem.received_at);
            } else if (returnItem.received && returnItem.operated_at) {
                return new Date(returnItem.operated_at);
            } else if (returnItem.operated && returnItem.created_at) {
                return new Date(returnItem.created_at);
            } else if (!this.userPermissions.isSuperuser && returnItem.created_at) {
                return new Date(returnItem.created_at);
            } else {
                return null;
            }
        },
        /**
         * Calculates how many hours have elapsed since the last relevant stage transition
         * that the *current user* is responsible for.
         *
         * @param {Object} returnItem - The return object.
         * @returns {number} - Hours elapsed since that reference date, or 0 if no valid date.
         */
        getHoursElapsedSinceLastStage(returnItem) {
            const referenceDate = this.getReferenceDate(returnItem);
            if (!referenceDate) return 0;

            const now = new Date();
            const diffMs = now - referenceDate;
            return diffMs / (1000 * 60 * 60); // ms → hours
        },
        /**
         * Retrieves the appropriate tooltip message for the current return stage.
         *
         * If more than 48 hours have elapsed since the user-responsible stage transition:
         *  - Not operated after creation: "Returul nu a fost operat în 48 de ore!"
         *  - Operated but not received:  "Returul nu a fost recepționat în 48 de ore!"
         *  - Received but not invoiced:  "Returul nu a fost facturat în 48 de ore!"
         *
         * @param {Object} returnItem - The return object with status flags and timestamps.
         * @param hoursElapsed
         * @returns {string} - The tooltip message, or an empty string if none applies.
         */
        getStageMessage(returnItem, hoursElapsed) {
            // Not operated yet, but user can operate it:
            if (!returnItem.operated) {
                return `Au trecut ${Math.round(hoursElapsed)} ore de la creare!`;
            }

            // Operated but not received, and user can mark it received:
            if (returnItem.operated && !returnItem.received) {
                return `Au trecut ${Math.round(hoursElapsed)} ore de la operare!`;
            }

            // Received but not invoiced, and user can mark it invoiced:
            if (returnItem.received && !returnItem.invoiced) {
                return `Au trecut ${Math.round(hoursElapsed)} ore de la recepție!`;
            }

            // Otherwise, no message for the user
            return "";
        },
        /**
         * Checks if a tooltip should be displayed for a given return.
         * Displays the tooltip if more than 48 hours have passed since the last
         * user-responsible state transition without moving to the next.
         *
         * @param {Object} returnItem - The return object with status flags and timestamps.
         * @returns {boolean} - True if a tooltip should be displayed, otherwise false.
         */
        shouldShowTooltip(returnItem) {
            const hoursElapsed = this.getHoursElapsedSinceLastStage(returnItem);
            return hoursElapsed > this.timeLimitHours;
        },
        /**
         * Gets the appropriate tooltip message based on the return's state.
         * If more than 48 hours have passed since the relevant stage transition
         * (for which the user has permissions), returns the corresponding alert message.
         * Otherwise, returns an empty string.
         *
         * @param {Object} returnItem - The return object.
         * @returns {string} - The tooltip message, or an empty string.
         */
        getTooltipMessage(returnItem) {
            const hoursElapsed = this.getHoursElapsedSinceLastStage(returnItem);
            if (hoursElapsed <= this.timeLimitHours) return "";
            return this.getStageMessage(returnItem, hoursElapsed);
        },
        /**
         * Determines if a return should be visually highlighted in red.
         * A return is highlighted if 48 hours have passed since the last state transition
         * (that the user can perform) without moving to the next stage.
         *
         * @param {Object} returnItem - The return object.
         * @returns {boolean} - Whether the return should be highlighted in red.
         */
        shouldHighlightReturn(returnItem) {
            const hoursElapsed = this.getHoursElapsedSinceLastStage(returnItem);
            return hoursElapsed > this.timeLimitHours;
        },
        /**
         * Determines if a return should be displayed in green, indicating this user
         * has “done their part”. We specifically avoid marking everything green for a user
         * who can do *all* stages (operate, receive, invoice), because they'd effectively
         * be “always done” at some point.
         *
         * Examples:
         *  - If user can ONLY mark it operated (canSetOperatedToTrue === true,
         *    canEditReturnReceivedStatus === false, canSetReturnToInvoiced === false)
         *    and the item is already `operated === true`, then show it in green for that user.
         *  - If user can ONLY mark it received, and the item is already `received === true`,
         *    then show it in green for that user.
         *  - If user can ONLY mark it invoiced, and the item is already `invoiced === true`,
         *    then show it in green for that user.
         *
         * @param {Object} returnItem - The return object.
         * @returns {boolean} - True if we should display it in green for this user.
         */
        shouldDisplayAsGreen(returnItem) {
            const {
                canSetOperatedToTrue,
                canEditReturnReceivedStatus,
                canSetReturnToInvoiced,
            } = this.userPermissions.returnsManagement;

            const canOnlyOperate =
                canSetOperatedToTrue &&
                !canEditReturnReceivedStatus &&
                !canSetReturnToInvoiced;
            const canOnlyEditReceived =
                !canSetOperatedToTrue &&
                canEditReturnReceivedStatus &&
                !canSetReturnToInvoiced;
            const canOnlySetInvoiced =
                !canSetOperatedToTrue &&
                !canEditReturnReceivedStatus &&
                canSetReturnToInvoiced;
            const canNotSetAnyStatus = !canSetOperatedToTrue &&
                !canEditReturnReceivedStatus &&
                !canSetReturnToInvoiced;

            // If user can ONLY mark returns as operated:
            if (canOnlyOperate && returnItem.operated) {
                return true;
            }
            // If user can ONLY mark returns as received:
            if (canOnlyEditReceived && returnItem.received) {
                return true;
            }
            // If user can ONLY mark returns as invoiced:
            if (canOnlySetInvoiced && returnItem.invoiced) {
                return true;
            }
            // If user can not mark returns as anything:
            if (canNotSetAnyStatus && returnItem.invoiced) {
                return true;
            }

            // If user has multiple permissions (or none), we don't automatically mark green,
            // because either they have more steps to do or their role is broader.
            return false;
        },
        /**
         * Updates a specific field of a return (like operated, invoiced) by sending a PATCH request to the backend.
         * On successful update, replaces the return in the local array and shows a success message.
         *
         * @param {number} returnId - The ID of the return to update.
         * @param {string} field - The field name to update.
         * @param {boolean} value - The new value for that field.
         */
        async updateReturnField(returnId, field, value) {
            try {
                const response = await apiClient.patch(`/api/returns/${returnId}/`, {[field]: value});
                if (response.status === 200) {
                    const updatedReturn = response.data;
                    const index = this.returns.findIndex((r) => r.id === updatedReturn.id);
                    if (index !== -1) {
                        this.returns.splice(index, 1, updatedReturn);
                    }
                    showAlertModal(this.$store, `Returul a fost actualizat cu succes!`, "success", 5000);
                }
            } catch (error) {
                console.error(`Error updating ${field} for return ${returnId}:`, error);
                showAlertModal(
                    this.$store,
                    `A intervenit o eroare la actualizarea câmpului ${field}.`,
                    "danger",
                    12000
                );
            }
        },
        /**
         * Loads a filtered list of users from session storage, including only those with associated returns.
         * Populates the `users` array with the filtered users who are linked to returns.
         */
        async loadUsersFromSession() {
            const allUsers = JSON.parse(sessionStorage.getItem('myUsers')) || [];
            const userIdsWithReturns = new Set(this.returns.map(r => r.agent));
            this.users = allUsers.filter(u => userIdsWithReturns.has(u.id));
        },
        /**
         * Shows the delete confirmation dialog for a specific return.
         * @param {number} returnId - The ID of the return to delete.
         */
        showDeleteDialog(returnId) {
            this.selectedReturnId = returnId;
            this.showDialog = true;
        },
        /**
         * Cancels the delete operation and closes the confirmation dialog.
         */
        cancelDelete() {
            this.selectedReturnId = null;
            this.showDialog = false;
        },
        /**
         * Confirms the deletion of the selected return and removes it from the backend.
         */
        async confirmDelete() {
            if (!this.selectedReturnId) return;
            try {
                let response = await apiClient.delete(`/api/returns/${this.selectedReturnId}/`);
                if (response.status === 204 || response.status === 200) {
                    await this.fetchReturns();
                    showAlertModal(this.$store, 'Returul a fost șters cu succes!', 'success', 5000);
                }
            } catch (error) {
                showAlertModal(
                    this.$store,
                    'A intervenit o eroare la ștergerea returului. Vă rugăm reîncercați.',
                    'danger',
                    12000,
                    error
                );
                console.error('Error deleting return:', error);
            } finally {
                this.cancelDelete();
            }
        },
        /**
         * Navigates to the return creation/edit page for a specified return ID.
         *
         * @param {Object} item - The return item data.
         * @param {number} returnId - The ID of the return to edit.
         */
        editReturn(item, returnId) {
            this.$router.push({name: 'ReturnCreate', params: {returnId: returnId}});
        },
        /**
         * Fetches the returns from the backend and sorts them from oldest to newest.
         * If the user has permission to view all returns, it fetches all returns.
         * Otherwise, it fetches only the returns associated with the current user.
         */
        async fetchReturns() {
            try {
                let user_id = sessionStorage.getItem('userId');
                let endpoint = this.userPermissions.returnsManagement.canViewAllReturns
                    ? `/api/returns/`
                    : `/api/returns/user/${user_id}/`;

                let response = await apiClient.get(endpoint);
                this.returns = response.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            } catch (error) {
                console.error('Error fetching returns:', error);
            }
        },
        /**
         * Opens the page to create a new return.
         */
        openReturnCreate() {
            this.$router.push({name: 'ReturnCreate'});
        },
        /**
         * Formats a date and time string into the "zz/ll/aaaa la HH:mm:ss" format.
         * @param {string} dateTime - The ISO date-time string to format.
         * @returns {string} - The formatted date and time.
         */
        formatDateTime(dateTime) {
            if (!dateTime) return '';
            const date = new Date(dateTime);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${day}/${month}/${year} la ${hours}:${minutes}:${seconds}`;
        },
        /**
         * Formats a date string to display as 'zz/ll/aaaa' (day/month/year).
         * @param {string|Date} date - The date string or Date object.
         * @returns {string} - The formatted date string.
         */
        formatDateOnly(date) {
            if (!date) return "";
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, "0");
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        },
        /**
         * Formats a date string to display only the time part (hours, minutes, seconds).
         * @param {string} date - The date string to be formatted.
         * @returns {string} - The formatted time string.
         */
        formatHoursOnly(date) {
            return new Date(date).toLocaleTimeString("ro-RO", {
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            });
        },
        /**
         * Returns a string of client names.
         * @param {Array} clients - The clients array from the return.
         * @returns {string} - Comma-separated client names.
         */
        getClientNames(clients) {
            if (!clients || clients.length === 0) return 'N/A';
            return clients.map(client => client.name).join(', ');
        },
        /**
         * Checks if transportation cost is set before updating a return field.
         * If not, prompts the user to enter the transportation cost.
         * @param {number} returnId - The ID of the return to update.
         * @param {string} field - The field to update ('operated', 'received', etc.).
         * @param {boolean} value - The new value for the field.
         */
        async checkAndUpdateReturnField(returnId, field, value) {
            try {
                // Fetch the return's details to check transportation_cost
                const response = await apiClient.get(`/api/returns/${returnId}/`);
                const returnData = response.data;

                if (!returnData.transportation_cost && this.userPermissions.returnsManagement.canViewReturnTransportationCost) {
                    // If transportation cost is missing, open dialog
                    this.inputTransportationCost = null;
                    this.pendingReturnUpdate = {returnId, field, value};
                    this.transportationDialog = true;
                } else if (this.userPermissions.returnsManagement.canViewReturnTransportationCost) {
                    // Proceed with the update if transportation cost is set
                    await this.updateReturnField(returnId, field, value);
                } else {
                    showAlertModal(this.$store, 'Nu aveți permisiunea să modificați această proprietate. Vă rugăm contactați un administrator.', 'warning', 6000);
                }
            } catch (error) {
                console.error("Error checking transportation cost:", error);
            }
        },
        /**
         * Saves the transportation cost entered in the dialog and updates the return.
         */
        async saveTransportationCost() {
            if (this.inputTransportationCost === null || this.inputTransportationCost === "") {
                showAlertModal(this.$store, "Introduceți un cost de transport valid.", "danger", 5000);
                return;
            }

            try {
                const {returnId, field, value} = this.pendingReturnUpdate;

                // Update transportation cost in the backend
                await apiClient.patch(`/api/returns/${returnId}/`, {
                    transportation_cost: this.inputTransportationCost,
                });

                // Close dialog and proceed with the return field update
                this.transportationDialog = false;
                await this.updateReturnField(returnId, field, value);
            } catch (error) {
                console.error("Error updating transportation cost:", error);
                showAlertModal(this.$store, "Eroare la salvarea costului de transport.", "danger", 5000);
            }
        },
        /**
         * Closes the transportation cost dialog.
         */
        closeTransportationDialog() {
            this.transportationDialog = false;
            this.pendingReturnUpdate = null;
        },
    },
};
</script>
<style scoped>
.text-danger {
    color: #f44336 !important;
}

.highlight-green {
    color: green !important;
}
</style>