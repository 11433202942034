import {basicUnitSensors, premiumUnitSensors} from "../VMC/vmcData";

export const VMC_PRODUCT_TYPES = {
    DISTRIBUTOR: 1,
    PLENUM: 2,
    DAMPER: 3,
    TUB: 4,
    KIT: 5,
    GRATE: 6,
    TUBULATURA: 7,
    COT: 8,
    RACORD: 9,
    DOP: 10,
    BANDA: 11,
    DIBLU: 12,
    PREZON: 13,
    BRIDA: 14,
    CONECTOR: 15,
    REDUCTIE: 16,
    COLIER: 17,
    TEU: 18,
    MUFA: 19,
    UNIT: 20,
    SENSOR: 21,
    CONTROLLER: 22,
    MOUNT: 23,
    HEATER: 24,
    MODUL: 25,
    COMMAND: 26,
    FILTER: 27,
    MOUNTING: 28,
    BOX: 29,
    PERFUME: 30,
    GASKET: 31,
    NIPLU: 32,
    DEZUMIDIFICATOR: 33,
    GRUP: 34,
    RACORDURI: 35,
    RELEU: 36,
    TRECERE: 37,
    OLANDEZ: 38,
    TEAVA: 39,
    ROBINET: 40,
    COMANDA: 41,
    VANA: 42,
    PRELUNGITOR: 43,
    SERVOMOTOR: 44,
    SET: 45,
    INEL: 46,
    POMPA: 47,
    TABLOU: 48,
}

export const VMC_NON_STANDARD_MEASUREMENTS = {
    ONE_INCH: {
        length: 1,
        measurementUnit: "inch",
    },
    HALF_INCH: {
        length: 0.5,
        measurementUnit: "inch",
    },
}

export const DN_VARIANTS = {
    DN75: {name: 'DN75', size: 75},
    DN90: {name: 'DN90', size: 90},
    DN100: {name: 'DN100', size: 100},
    DN_OVAL: {name: 'DN_OVAL', size: 117},
    DN125: {name: 'DN125', size: 125},
    DN127: {name: 'DN127', size: 127},
    DN150: {name: 'DN150', size: 150},
    DN160: {name: 'DN160', size: 160},
    DN200: {name: 'DN200', size: 200},
    DN250: {name: 'DN250', size: 250},
    // ... Add others as needed
};

export const products = [
    {
        "id": 1,
        "product_code": "806.01.50",
        "name": "Distribuitor",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN125,
            "distribution_diameter": DN_VARIANTS.DN75,
            "size": 4,
        },
        "requirements": []
    },
    {
        "id": 2,
        "product_code": "806.02.50",
        "name": "Distribuitor",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN125,
            "distribution_diameter": DN_VARIANTS.DN75,
            "size": 6,
        },
        "requirements": []
    },
    {
        "id": 3,
        "product_code": "806.03.50",
        "name": "Distribuitor",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN125,
            "distribution_diameter": DN_VARIANTS.DN75,
            "size": 10,
        },
        "requirements": []
    },
    {
        "id": 4,
        "product_code": "806.04.50",
        "name": "Distribuitor",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN160,
            "distribution_diameter": DN_VARIANTS.DN75,
            "size": 10,
        },
        "requirements": []
    },
    {
        "id": 5,
        "product_code": "806.05.50",
        "name": "Distribuitor",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN160,
            "distribution_diameter": DN_VARIANTS.DN75,
            "size": 15,
        },
        "requirements": []
    },
    {
        "id": 6,
        "product_code": "806.06.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "additional": "fara niplu",
            "distribution_diameter": DN_VARIANTS.DN75,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "size": 4,
        },
        "requirements": []
    },
    {
        "id": 7,
        "product_code": "806.07.91",
        "name": "Distribuitor modular",
        "specifications": {
            "distribution_diameter": DN_VARIANTS.DN75,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "additional": "fara niplu",
            "size": 5,
        },
        "requirements": []
    },
    {
        "id": 8,
        "product_code": "806.08.91",
        "name": "Distribuitor modular",
        "specifications": {
            "distribution_diameter": DN_VARIANTS.DN75,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "additional": "fara niplu",
            "size": 6,
        },
        "requirements": []
    },
    {
        "id": 9,
        "product_code": "806.09.91",
        "name": "Distribuitor modular",
        "specifications": {
            "distribution_diameter": DN_VARIANTS.DN75,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "additional": "fara niplu",
            "size": 7,
        },
        "requirements": []
    },
    {
        "id": 10,
        "product_code": "806.10.91",
        "name": "Distribuitor modular",
        "specifications": {
            "distribution_diameter": DN_VARIANTS.DN75,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "additional": "fara niplu",
            "size": 8,
        },
        "requirements": []
    },

    {
        "id": 11,
        "product_code": "806.11.91",
        "name": "Distribuitor modular",
        "specifications": {
            "distribution_diameter": DN_VARIANTS.DN75,
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": DN_VARIANTS.DN125,
            "additional": "racordare 3 laturi (fara niplu)",
            "size": 8,
        },
        "requirements": []
    },
    {
        "id": 12,
        "product_code": "806.12.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "additional": "fara niplu",
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "distribution_diameter": DN_VARIANTS.DN75,
            "size": 9,
        },
        "requirements": []
    },
    {
        "id": 13,
        "product_code": "806.13.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "distribution_diameter": DN_VARIANTS.DN75,
            "additional": "fara niplu",
            "size": 10,
        },
        "requirements": []
    },
    {
        "id": 14,
        "product_code": "806.14.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "distribution_diameter": DN_VARIANTS.DN75,
            "additional": "fara niplu",
            "size": 11,
        },
        "requirements": []
    },
    {
        "id": 15,
        "product_code": "806.15.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "distribution_diameter": DN_VARIANTS.DN75,
            "additional": "fara niplu",
            "size": 12,
        },
        "requirements": []
    },
    {
        "id": 16,
        "product_code": "806.16.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "distribution_diameter": DN_VARIANTS.DN75,
            "additional": "fara niplu",
            "size": 13,
        },
        "requirements": []
    },
    {
        "id": 17,
        "product_code": "806.17.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "distribution_diameter": DN_VARIANTS.DN75,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "additional": "fara niplu",
            "size": 14,
        },
        "requirements": []
    },
    {
        "id": 18,
        "product_code": "806.18.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
            "distribution_diameter": DN_VARIANTS.DN75,
            "additional": "fara niplu",
            "size": 15,
        },
        "requirements": []
    },
    {
        "id": 19,
        "product_code": "806.19.91",
        "name": "Distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "additional": "fara niplu",
            "size": 16,
            "distribution_diameter": DN_VARIANTS.DN75,
            "diameter": [DN_VARIANTS.DN125, DN_VARIANTS.DN160, DN_VARIANTS.DN200],
        },
        "requirements": []
    },
    {
        "id": 20,
        "product_code": "813.08.91",
        "name": "Niplu distribuitor modular",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.NIPLU,
            "subtype": "modular",
            "diameter": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 21,
        "product_code": "813.10.91",
        "name": "Niplu distribuitor modular DN160",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.NIPLU,
            "subtype": "modular",
            "diameter": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 22,
        "product_code": "813.11.91",
        "name": "Niplu distribuitor modular DN200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.NIPLU,
            "subtype": "modular",
            "diameter": DN_VARIANTS.DN200,
        }
    },
    {
        "id": 23,
        "product_code": "807.01.50",
        "name": "Plenum racordare laterala - 1xDN75 (200x100)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 3,
            "dimensions": "200x100",
            "size": 1,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "perforated",
                "version": 200,
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.DAMPER,
                "subtype": "air",
                "quantity": 1
            }
        ],
    },
    {
        "id": 24,
        "product_code": "807.02.50",
        "name": "Plenum racordare laterala - 2xDN75 (300x100)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 3,
            "dimensions": "300x100",
            "size": 2,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.DAMPER,
                "subtype": "air",
                "quantity": 2
            }
        ],
    },
    {
        "id": 25,
        "product_code": "807.03.50",
        "name": "Plenum racordare laterala - 3xDN75 (300x100)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 3,
            "dimensions": "300x100",
            "size": 3,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.DAMPER,
                "subtype": "air",
                "quantity": 3
            }
        ],
    },
    {
        "id": 26,
        "product_code": "807.04.50",
        "name": "Plenum racordare spate - 1xDN75 (200x100)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 2,
            "dimensions": "200x100",
            "size": 1,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "perforated",
                "version": 200,
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.DAMPER,
                "subtype": "air",
                "quantity": 1
            }
        ],
    },
    {
        "id": 27,
        "product_code": "807.05.50",
        "name": "Plenum racordare spate - 2xDN75 (300x100)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 2,
            "dimensions": "300x100",
            "size": 2,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.DAMPER,
                "subtype": "air",
                "quantity": 2
            }
        ],
    },
    {
        "id": 28,
        "product_code": "807.06.50",
        "name": "Plenum racordare spate - 3xDN75 (300x100)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 2,
            "dimensions": "300x100",
            "size": 3,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.DAMPER,
                "subtype": "air",
                "quantity": 3
            }
        ],
    },
    {
        "id": 29,
        "product_code": "807.07.50",
        "name": "Plenum racordare laterala \"Design\" - 1xDN75 (140x140)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 5,
            "dimensions": "140x140",
            "size": 1,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "design",
                "version": DN_VARIANTS.DN100,
            }
        ],
    },
    {
        "id": 30,
        "product_code": "807.08.50",
        "name": "Plenum racordare laterala \"Design\" - 2xDN75 (200x200)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 5,
            "dimensions": "200x200",
            "size": 2,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "design",
                "version": DN_VARIANTS.DN150,
            }
        ],
    },
    {
        "id": 31,
        "product_code": "807.09.50",
        "name": "Plenum racordare spate \"Design\" - 1xDN75 (140x140)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 4,
            "dimensions": "140x140",
            "size": 1,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "design",
                "version": DN_VARIANTS.DN100,
            }
        ],
    },
    {
        "id": 32,
        "product_code": "807.10.50",
        "name": "Plenum racordare spate \"Design\" - 2xDN75 (200x200)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 4,
            "dimensions": "200x200",
            "size": 2,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "design",
                "version": DN_VARIANTS.DN150,
            }
        ],
    },
    {
        "id": 33,
        "product_code": "807.11.50",
        "name": "Plenum dublu tavan - 2xDN75-DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 7,
            "size": 1,
        },
        "requirements": [],
    },
    {
        "id": 34,
        "product_code": "807.12.50",
        "name": "Damper reglare volum aer",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DAMPER,
            "subtype": "air",
        },
    },
    {
        "id": 35,
        "product_code": "807.13.91",
        "name": "Plenum modular - 1xDN75-DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 1,
            "size": 1,
        },
        "requirements": [],
    },
    {
        "id": 36,
        "product_code": "807.14.91",
        "name": "Plenum modular - 2xDN75-DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 1,
            "size": 2,
        },
        "requirements": [],
    },
    {
        "id": 37,
        "product_code": "807.15.91",
        "name": "Plenum modular - 3xDN75-DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 1,
            "size": 3,
        },
        "requirements": [],
    },
    {
        "id": 38,
        "product_code": "809.01.50",
        "name": "Tub flexibil antifonic DN127 (10m)",
        "specifications": {
            "length": "10m",
            "type": VMC_PRODUCT_TYPES.TUB,
            "subtype": "antifonic",
            "diameter": DN_VARIANTS.DN125,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PREZON,
                "subtype": "flexibil",
                "quantity": 10
            },
            {
                "type": VMC_PRODUCT_TYPES.BRIDA,
                "diameter": DN_VARIANTS.DN125,
                "quantity": 10,
            },
        ],
    },
    // {
    //     "id": 39,
    //     "product_code": "809.02.50",
    //     "name": "Tub flexibil antifonic DN160 (10m)",
    //     "type": VMC_PRODUCT_TYPES.TUB,
    //     "category": "Flexibil",
    //     "subtype": "antifonic",
    //     "specifications": {
    //         "variantKey": 4,
    //         "length": "10m",
    //         "type": VMC_PRODUCT_TYPES.TUB,
    //         "subtype": "antifonic",
    //         "diameter": DN_VARIANTS.DN160,
    //     },
    //     "requirements": [
    //         {
    //             "type": VMC_PRODUCT_TYPES.PREZON,
    //             "subtype": "flexibil",
    //             "quantity": 10
    //         },
    //         {
    //             "type": VMC_PRODUCT_TYPES.BRIDA,
    //             "diameter": DN_VARIANTS.DN160,
    //             "quantity": 10,
    //         },
    //     ],
    // },
    {
        "id": 40,
        "product_code": "809.03.50",
        "name": "Kit terminal perete exterior DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.KIT,
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 41,
        "product_code": "809.04.50",
        "name": "Kit terminal perete exterior DN150",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.KIT,
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 42,
        "product_code": "809.53.32",
        "name": "Gratar terminal perete exterior DN160",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 43,
        "product_code": "809.54.20",
        "name": "Gratar terminal perete exterior DN200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN200,
        }
    },
    {
        "id": 45,
        "product_code": "809.35.91",
        "name": "Tub flexibil antibacterian D.75 (25m)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "diameter": "DN75",
            "distribution_diameter": DN_VARIANTS.DN75,
            "length": 25,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PREZON,
                "subtype": "flexibil",
                "quantity": 25
            },
        ],
    },
    {
        "id": 46,
        "product_code": "809.06.75",
        "name": "Cot la 90 de grade DN75",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "subtype": "DN75",
            "distribution_diameter": DN_VARIANTS.DN75,
        },
        "requirements": []
    },
    {
        "id": 47,
        "product_code": "809.08.91",
        "name": "Racord imbinare pt teava DN75",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.RACORD,
            "subtype": "",
            "distribution_diameter": DN_VARIANTS.DN75,
        }
    },
    {
        "id": 48,
        "product_code": "809.09.91",
        "name": "Kit o-ring alb DN75",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.KIT,
            "subtype": "o-ring",
            "distribution_diameter": DN_VARIANTS.DN75,
        }
    },
    {
        "id": 49,
        "product_code": "809.10.75",
        "name": "Dop DN75",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DOP,
            "subtype": DN_VARIANTS.DN75,
            "distribution_diameter": DN_VARIANTS.DN75,
        }
    },
    {
        "id": 50,
        "product_code": "809.11.75",
        "name": "Cleme fixare teava flex DN75 albastru",
        "specifications": {}
    },

    {
        "id": 51,
        "product_code": "809.12.75",
        "name": "Cleme fixare teava flex DN75 rosu",
        "specifications": {}
    },
    {
        "id": 52,
        "product_code": "1401.02.65",
        "name": "Banda perforata 12 mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.BANDA,
            "subtype": "perforata",
        }
    },
    {
        "id": 53,
        "product_code": "1401.03.65",
        "name": "Diblu prin bataie 6x40",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DIBLU,
            "subtype": "bataie",
        }
    },
    {
        "id": 54,
        "product_code": "1401.05.65",
        "name": "Banda Aluminiu (10m)",
        "specifications": {
            "length": "10m",
            "type": VMC_PRODUCT_TYPES.BANDA,
            "subtype": "aluminiu",
        }
    },
    {
        "id": 55,
        "product_code": "1401.12.81",
        "name": "Prezon M8x60 mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PREZON,
            "subtype": "flexibil",
        },
    },
    {
        "id": 56,
        "product_code": "1401.13.81",
        "name": "Prezon M8x120 mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PREZON,
            "subtype": "EPS",
        },
    },
    {
        "id": 58,
        "product_code": "1401.20.74",
        "name": "Brida M8/M10 125mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.BRIDA,
            "diameter": DN_VARIANTS.DN125
        }
    },
    {
        "id": 59,
        "product_code": "1401.21.74",
        "name": "Brida M8/M10 150mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.BRIDA,
            "diameter": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 60,
        "product_code": "1401.22.74",
        "name": "Brida M8/M10 200mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.BRIDA,
            "diameter": DN_VARIANTS.DN200,
        }
    },
    {
        "id": 61,
        "product_code": "1401.23.74",
        "name": "Brida M8/M10 250mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.BRIDA,
            "diameter": DN_VARIANTS.DN250,
        }
    },
    {
        "id": 62,
        "product_code": "809.13.66",
        "name": "Conector PVC DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.CONECTOR,
            "subtype": "PVC",
            "diameter": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 63,
        "product_code": "809.14.66",
        "name": "Conector PVC DN150",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.CONECTOR,
            "subtype": "PVC",
            "diameter": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 64,
        "product_code": "809.15.66",
        "name": "Cot PVC DN125",
        "specifications": {}
    },
    {
        "id": 65,
        "product_code": "809.16.66",
        "name": "Cot PVC DN150",
        "specifications": {}
    },
    {
        "id": 66,
        "product_code": "809.17.66",
        "name": "Reductie PVC DN150-125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.REDUCTIE,
            "subtype": "PVC",
            "variant": 150,
        }
    },
    {
        "id": 67,
        "product_code": "809.18.66",
        "name": "Reductie PVC DN200-150",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.REDUCTIE,
            "subtype": "PVC",
            "variant": 200,
        }
    },
    {
        "id": 68,
        "product_code": "809.19.66",
        "name": "Colier metalic 110-130mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COLIER,
            "subtype": "metalic",
            "diameter": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 69,
        "product_code": "809.20.66",
        "name": "Colier metalic 140-160mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COLIER,
            "subtype": "metalic",
            "diameter": DN_VARIANTS.DN150,
        }
    },
    {
        "id": 70,
        "product_code": "809.21.66",
        "name": "Colier metalic 150-170mm",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COLIER,
            "subtype": "metalic",
            "diameter": DN_VARIANTS.DN160,
        }
    },

    {
        "id": 71,
        "product_code": "809.22.66",
        "name": "Colier metalic 190-210mm",
        "specifications": {}
    },
    {
        "id": 72,
        "product_code": "809.23.83",
        "name": "Tubulatura EPS d.200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN200,
            "variant": "normal",
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PREZON,
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.BRIDA,
                "diameter": DN_VARIANTS.DN250,
            },
        ],
    },
    {
        "id": 73,
        "product_code": "809.40.32",
        "name": "Tubulatura EPS d.125 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN125,
            "variant": "slim",
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PREZON,
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.BRIDA,
                "diameter": DN_VARIANTS.DN160,
            },
        ],
    },
    {
        "id": 74,
        "product_code": "809.41.32",
        "name": "Tubulatura EPS d.160 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN160,
            "variant": "slim",
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PREZON,
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.BRIDA,
                "diameter": DN_VARIANTS.DN200,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 75,
        "product_code": "809.42.32",
        "name": "Tubulatura EPS d.200 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "subtype": 1,
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PREZON,
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": VMC_PRODUCT_TYPES.BRIDA,
                "diameter": DN_VARIANTS.DN250,
            },
        ],
    },
    {
        "id": 76,
        "product_code": "809.24.83",
        "name": "Cot EPS 45 d.200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "normal"
        }
    },
    {
        "id": 77,
        "product_code": "809.43.32",
        "name": "Cot EPS 45 d.125 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN125,
            "variant": "slim",
        }
    },
    {
        "id": 78,
        "product_code": "809.44.32",
        "name": "Cot EPS 45 d.160 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN160,
            "variant": "slim",
        }
    },
    {
        "id": 79,
        "product_code": "809.45.32",
        "name": "Cot EPS 45 d.200 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
        }
    },
    {
        "id": 80,
        "product_code": "809.25.83",
        "name": "Teu EPS d.200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEU,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "normal",
        }
    },
    {
        "id": 81,
        "product_code": "809.46.32",
        "name": "Teu EPS d.125 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEU,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN125,
            "variant": "slim",
        }
    },
    {
        "id": 82,
        "product_code": "809.47.32",
        "name": "Teu EPS d.160 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEU,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN160,
            "variant": "slim",
        }
    },
    {
        "id": 83,
        "product_code": "809.48.32",
        "name": "Teu EPS d.200 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEU,
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
        }
    },
    {
        "id": 84,
        "product_code": "809.26.83",
        "name": "Mufa EPS d.200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MUFA,
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN200,
            "variant": "normal",
        }
    },
    {
        "id": 85,
        "product_code": "809.49.32",
        "name": "Mufa EPDM d.125 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MUFA,
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN125,
            "variant": "slim",
        }
    },
    {
        "id": 86,
        "product_code": "809.50.32",
        "name": "Mufa EPDM d.160 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MUFA,
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN160,
            "variant": "slim",
        }
    },
    {
        "id": 87,
        "product_code": "809.51.32",
        "name": "Mufa EPDM d.200 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MUFA,
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
        }
    },
    {
        "id": 88,
        "product_code": "809.52.32",
        "name": "Reductie 200/160/125 (slim)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.REDUCTIE,
            "subtype": "general",
        },
    },
    {
        "id": 89,
        "product_code": "809.05.91",
        "name": "Tub flexibil antibacterian D.75 (50m)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "diameter": DN_VARIANTS.DN75,
            "distribution_diameter": DN_VARIANTS.DN75,
            "length": 50,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.RACORD,
                "distribution_diameter": DN_VARIANTS.DN75,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 92,
        "product_code": "803.01.50",
        "name": "Unitate Standard 180A",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "standard",
            "version": "180A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["180A"],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["180A"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.MOUNTING,
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 93,
        "product_code": "803.02.50",
        "name": "Unitate Standard 280A",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "standard",
            "version": "280A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280A"],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280A"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.MOUNTING,
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 94,
        "product_code": "803.04.50",
        "name": "Unitate Standard 400A",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "standard",
            "version": "400A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["400A"],
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "F7",
                "versions": ["400A"],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["400A"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "F7",
                "versions": ["400A"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.MOUNTING,
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 95,
        "product_code": "803.05.50",
        "name": "Unitate Standard 550A",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "standard",
            "version": "550A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["550A"],
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "F7",
                "versions": ["550A"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["550A"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "F7",
                "versions": ["550A"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.MOUNTING,
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 96,
        "product_code": "803.07.83",
        "name": "Unitate Premium 300h +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "300h",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors],
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 97,
        "product_code": "803.08.83",
        "name": "Unitate Premium 300h ++",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "300h",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors],
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h ++"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h ++"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 98,
        "product_code": "803.10.83",
        "name": "Unitate Premium 400h +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "400h",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors],
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 99,
        "product_code": "803.11.83",
        "name": "Unitate Premium 400h ++",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "400h",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h ++"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h ++"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 100,
        "product_code": "803.13.83",
        "name": "Unitate Premium 500h +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "500h",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 101,
        "product_code": "803.14.83",
        "name": "Unitate Premium 500h ++",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "500h",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h ++"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h ++"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 102,
        "product_code": "803.27.83",
        "name": "Unitate Premium 300v +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "300v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 103,
        "product_code": "803.28.83",
        "name": "Unitate Premium 300v ++",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "300v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v ++"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v ++"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 104,
        "product_code": "803.29.83",
        "name": "Unitate Premium 400v +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "400v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 105,
        "product_code": "803.30.83",
        "name": "Unitate Premium 400v ++",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "400v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v ++"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v ++"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 106,
        "product_code": "803.31.83",
        "name": "Unitate Premium 500v +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "500v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 107,
        "product_code": "803.32.83",
        "name": "Unitate Premium 500v ++",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "500v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v ++"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v ++"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 108,
        "product_code": "803.33.83",
        "name": "Unitate Premium 550v +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "550v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v +",
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 109,
        "product_code": "803.34.83",
        "name": "Unitate Premium 550v ++",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "premium",
            "version": "550v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v ++",
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v ++"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 110,
        "product_code": "803.15.83",
        "name": "Unitate Premium Home 200f",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "home",
            "version": "200f",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "requirements": [
            {
                "type": "support",
                "subtype": "ceiling",
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f",
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f",
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
    },
    {
        "id": 111,
        "product_code": "803.16.83",
        "name": "Unitate Premium Home 200f +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "home",
            "version": "200f",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "requirements": [
            {
                "type": "support",
                "subtype": "ceiling",
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f +",
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f +",
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
    },
    // {
    //     "id": 112,
    //     "product_code": "803.17.83",
    //     "name": "Unitate Premium Home 300v",
    //     "type": VMC_PRODUCT_TYPES.UNIT,
    //     "subtype": "home",
    //     "specifications": {
    //         "type": VMC_PRODUCT_TYPES.UNIT,
    //         "subtype": "home",
    //         "version": "300v",
    //         "plus": 0,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": VMC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": VMC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v"
    //             ],
    //         },
    //         {
    //             "type": VMC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": VMC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": VMC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": VMC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 113,
    //     "product_code": "803.18.83",
    //     "name": "Unitate Premium Home 300v +",
    //     "type": VMC_PRODUCT_TYPES.UNIT,
    //     "subtype": "home",
    //     "specifications": {
    //         "type": VMC_PRODUCT_TYPES.UNIT,
    //         "subtype": "home",
    //         "version": "300v",
    //         "plus": 1,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": VMC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": VMC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v +"
    //             ],
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v +"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 114,
    //     "product_code": "803.19.83",
    //     "name": "Unitate Premium Home 400v",
    //     "type": PDC_PRODUCT_TYPES.UNIT,
    //     "subtype": "home",
    //     "specifications": {
    //         "type": PDC_PRODUCT_TYPES.UNIT,
    //         "subtype": "home",
    //         "version": "400v",
    //         "plus": 0,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v"
    //             ],
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 115,
    //     "product_code": "803.20.83",
    //     "name": "Unitate Premium Home 400v +",
    //     "type": PDC_PRODUCT_TYPES.UNIT,
    //     "subtype": "home",
    //     "specifications": {
    //         "type": PDC_PRODUCT_TYPES.UNIT,
    //         "subtype": "home",
    //         "version": "400v",
    //         "plus": 1,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v +"
    //             ],
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v +"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 116,
    //     "product_code": "803.21.83",
    //     "name": "Unitate Premium Home 500v",
    //     "type": PDC_PRODUCT_TYPES.UNIT,
    //     "subtype": "home",
    //     "specifications": {
    //         "type": PDC_PRODUCT_TYPES.UNIT,
    //         "subtype": "home",
    //         "version": "500v",
    //         "plus": 0,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v"
    //             ],
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 117,
    //     "product_code": "803.22.83",
    //     "name": "Unitate Premium Home 500v +",
    //     "type": PDC_PRODUCT_TYPES.UNIT,
    //     "subtype": "home",
    //     "specifications": {
    //         "type": PDC_PRODUCT_TYPES.UNIT,
    //         "subtype": "home",
    //         "version": "500v",
    //         "plus": 1,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v +"
    //             ],
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.FILTER,
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v +"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": PDC_PRODUCT_TYPES.SENSOR,
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    {
        "id": 118,
        "product_code": "803.23.83",
        "name": "Unitate Premium Home 600v",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "home",
            "version": "600v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 119,
        "product_code": "803.24.83",
        "name": "Unitate Premium Home 600v +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "home",
            "version": "600v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
    },
    {
        "id": 120,
        "product_code": "803.25.83",
        "name": "Unitate Premium Home 800v",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "home",
            "version": "800v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
    },
    {
        "id": 121,
        "product_code": "803.26.83",
        "name": "Unitate Premium Home 800v +",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "home",
            "version": "800v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v +"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v +"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
    },
    {
        "id": 122,
        "product_code": "804.01.50",
        "name": "Unitate Standard 230E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "standard",
            "version": "230E",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["230E"],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["230E"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 123,
        "product_code": "804.02.50",
        "name": "Unitate Standard 280E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "standard",
            "version": "280E",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280E"],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280E"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 124,
        "product_code": "804.03.50",
        "name": "Unitate Standard 590E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "standard",
            "version": "590E",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["590E"],
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "F7",
                "versions": ["590E"],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "G4",
                "versions": ["590E"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "variant": "F7",
                "versions": ["590E"],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 125,
        "product_code": "804.04.83",
        "name": "Unitate Premium 300h Entalpic",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "enthalpic",
            "version": "300h",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 126,
        "product_code": "804.05.83",
        "name": "Unitate Premium 400h Entalpic",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "enthalpic",
            "version": "400h",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 127,
        "product_code": "804.06.83",
        "name": "Unitate Premium 500h Entalpic",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "enthalpic",
            "version": "500h",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 128,
        "product_code": "804.07.83",
        "name": "Unitate Premium 300v Entalpic",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "enthalpic",
            "version": "300v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 129,
        "product_code": "804.08.83",
        "name": "Unitate Premium 400v Entalpic",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "enthalpic",
            "version": "400v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 130,
        "product_code": "804.09.83",
        "name": "Unitate Premium 500v Entalpic",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.UNIT,
            "subtype": "enthalpic",
            "version": "500v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v"
                ],
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v"
                ],
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 131,
        "product_code": "805.01.50",
        "name": "Senzor umiditate relativă",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Umiditate",
        }
    },
    {
        "id": 132,
        "product_code": "805.06.83",
        "name": "Controler display TS",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.CONTROLLER,
            "subtype": "display",
            "version": "TS",
        }
    },
    {
        "id": 133,
        "product_code": "805.14.83",
        "name": "Suport perete Unitate Premium h",
        "type": VMC_PRODUCT_TYPES.MOUNT,
        "subtype": "wall",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MOUNT,
            "subtype": "wall",
            "variant": "Premium h",
        }
    },
    {
        "id": 134,
        "product_code": "808.10.50",
        "name": "Gratar gaurit 200x100 alb, cu magnet",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "perforated",
            "version": 200,
        }
    },
    {
        "id": 135,
        "product_code": "808.11.50",
        "name": "Gratar gaurit 300x100 alb, cu magnet",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "perforated",
            "version": 300,
        }
    },
    {
        "id": 136,
        "product_code": "808.05.50",
        "name": "Gratar Design DN100",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "design",
            "version": DN_VARIANTS.DN100,
        }
    },
    {
        "id": 137,
        "product_code": "808.14.50",
        "name": "Gratar Design DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "design",
            "version": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 138,
        "product_code": "808.06.50",
        "name": "Gratar Design DN150",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "design",
            "version": DN_VARIANTS.DN150,
        }
    },
    {
        "id": 139,
        "product_code": "808.06.50",
        "name": "Gratar refulare rotund DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "design",
            "version": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 140,
        "product_code": "810.04.63",
        "name": "Baterie electrica d.125 - 0,5 Kw (150m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.HEATER,
            "subtype": "electric",
            "version": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 141,
        "product_code": "810.05.63",
        "name": "Baterie electrica d.160 - 1,0 Kw (350m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.HEATER,
            "subtype": "electric",
            "version": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 142,
        "product_code": "810.06.63",
        "name": "Baterie electrica d.200 - 1,5 Kw (500m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.HEATER,
            "subtype": "electric",
            "version": DN_VARIANTS.DN200,
        }
    },
    {
        "id": 143,
        "product_code": "808.09.50",
        "name": "Gratar admisie/refulare rotund DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "modular",
        }
    },
    {
        "id": 144,
        "product_code": "810.01.63",
        "name": "Baterie apa d.125 - 1,1 Kw (150m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Baterie apă",
            "diameter": DN_VARIANTS.DN125,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.COMMAND,
                "subtype": "cooling",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 145,
        "product_code": "810.02.63",
        "name": "Baterie apa d.160 - 2,2 Kw (350m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Baterie apă",
            "diameter": DN_VARIANTS.DN160,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.COMMAND,
                "subtype": "cooling",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 146,
        "product_code": "810.03.63",
        "name": "Baterie apa d.200 - 3,1 Kw (500m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Baterie apă",
            "diameter": DN_VARIANTS.DN200,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.COMMAND,
                "subtype": "cooling",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 147,
        "product_code": "813.18.91",
        "name": "Tubulatura PVC DN125 (30cm)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "subtype": "plenum",
        }
    },
    {
        "id": 148,
        "product_code": "805.13.83",
        "name": "Modul expansiune",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MODUL,
            "subtype": "expansiune",
        }
    },
    {
        "id": 149,
        "product_code": "805.18.83",
        "name": "Senzor temperatura",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "temperatura",
        }
    },
    {
        "id": 150,
        "product_code": "812.01.70",
        "name": "Ionizator montaj Duct 2C (500m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Purificator Jonix",
            "volume": 500,
        }
    },
    {
        "id": 151,
        "product_code": "812.02.70",
        "name": "Ionizator montaj Duct 4C (1000m3)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Purificator Jonix",
            "volume": 1000,
        }
    },
    {
        "id": 152,
        "product_code": "805.16.83",
        "name": "Suport perete unitate H",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MOUNT,
            "subtype": "wall",
            "variant": "horizontal",
        }
    },
    {
        "id": 154,
        "product_code": "805.20.83",
        "name": "Suport perete Unitate Premium V",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MOUNT,
            "subtype": "wall",
            "variant": "Premium v"
        }
    },
    {
        "id": 155,
        "product_code": "805.09.83",
        "name": "Comanda baterie racire",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COMMAND,
            "subtype": "cooling",
        }
    },
    {
        "id": 195,
        "product_code": "807.16.91",
        "name": "Plenum dublu tavan modular - 2xDN75-DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 6,
            "size": 1,
        },
        "requirements": [],
    },
    {
        "id": 196,
        "product_code": "805.17.83",
        "name": "Suport tavan unitate H",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MOUNT,
            "subtype": "ceiling",
            "variant": "horizontal",
        }
    },
    {
        "id": 197,
        "product_code": "805.15.83",
        "name": "Suport tavan Unitate Premium h",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MOUNT,
            "subtype": "ceiling",
            "variant": "Premium h",
        }
    },
    {
        "id": 198,
        "product_code": "811.01.50",
        "name": "Filtru G4 pentru unitate 180A si 230E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "standard",
            "variant": "G4",
            "versions": ["180A", "230E"],
        }
    },
    {
        "id": 199,
        "product_code": "811.02.50",
        "name": "Filtru G4 pentru unitate 280A, 350A si 280E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "standard",
            "variant": "G4",
            "versions": ["280A", "350A", "280E"],
        }
    },
    {
        "id": 200,
        "product_code": "811.03.50",
        "name": "Filtru G4 pentru unitate 400A, 550A si 580E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "standard",
            "variant": "G4",
            "versions": ["400A", "550A", "590E"],
        }
    },
    {
        "id": 201,
        "product_code": "811.05.50",
        "name": "Filtru F7 pentru unitate 280A, 350A si 280E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "standard",
            "variant": "F7",
            "versions": ["180A", "280A", "350A", "280E", "230E"],
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.BOX,
                "subtype": VMC_PRODUCT_TYPES.FILTER,
                "variant": "F7",
                "quantity": 0,
            }
        ],
        "recommendations": [
            {
                "type": VMC_PRODUCT_TYPES.BOX,
                "subtype": VMC_PRODUCT_TYPES.FILTER,
                "variant": "F7",
            },
        ],
    },
    {
        "id": 202,
        "product_code": "811.06.50",
        "name": "Filtru F7 pentru unitate 400A, 550A si 590E",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "standard",
            "variant": "F7",
            "versions": ["400A", "550A", "590E"],
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.BOX,
                "subtype": VMC_PRODUCT_TYPES.FILTER,
                "variant": "F7",
                "quantity": 0,
            }
        ],
        "recommendations": [],
    },
    {
        "id": 203,
        "product_code": "811.08.83",
        "name": "Filtru M5 pentru unitatea Premium H 300, 400, 500 si 550",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "premium",
            "variant": "M5",
            "versions": [
                "300h", "300h +",
                "300h ++", "400h",
                "400h +", "400h ++",
                "500h", "500h +",
                "500h ++",
            ],
        }
    },
    {
        "id": 204,
        "product_code": "811.20.83",
        "name": "Filtru M5 pentru unitatea Premium V 300, 400, 500 si 550",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "premium",
            "variant": "M5",
            "versions": [
                "300v", "300v +", "300v ++",
                "400v", "400v +", "400v ++",
                "500v", "500v +", "500v ++",
                "550v +", "550v ++",
            ],
        }
    },
    {
        "id": 208,
        "product_code": "811.14.83",
        "name": "Filtru M5 pentru unitatea Premium Home v 300,400 si 500",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "home",
            "variant": "M5",
            "versions": [
                "300v", "300v +",
                "400v", "400v +",
                "500v", "500v +",
            ],
        }
    },
    {
        "id": 210,
        "product_code": "811.16.83",
        "name": "Filtru M5 pentru unitatea Premium Home v 650 si 850",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "home",
            "variant": "M5",
            "versions": [
                "600v", "600v +",
                "800v", "800v +"
            ],
        }
    },
    {
        "id": 212,
        "product_code": "811.18.83",
        "name": "Filtru M5 pentru unitatea Premium Home f 200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.FILTER,
            "subtype": "home",
            "variant": "M5",
            "versions": [
                "200f", "200f +",
            ],
        }
    },
    {
        "id": 214,
        "product_code": "805.12.83",
        "name": "Modul cascadare modbus",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Modul modbus",
        }
    },
    {
        "id": 215,
        "product_code": "805.19.83",
        "name": "Cablu degivrare",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Cablu degivrare",
        }
    },
    {
        "id": 216,
        "product_code": "809.57.83",
        "name": "Cutie duct",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.MOUNTING,
            "subtype": "purifier",
        }
    },
    {
        "id": 217,
        "product_code": "815.01.19",
        "name": "Dispenser parfum",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "Dispenser parfum",
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PERFUME,
                "subtype": "boreal",
                "variant": 120,
                "quantity": 0,
            },
            {
                "type": VMC_PRODUCT_TYPES.PERFUME,
                "subtype": "cruise",
                "variant": 120,
                "quantity": 0,
            }
        ]
    },
    {
        "id": 218,
        "product_code": "811.21.50",
        "name": "Cutie suplimentara filtru F7",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.BOX,
            "subtype": VMC_PRODUCT_TYPES.FILTER,
            "variant": "F7"
        }
    },
    {
        "id": 219,
        "product_code": "815.02.19",
        "name": "Parfum Boreal 120 ml",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PERFUME,
            "subtype": "boreal",
            "variant": 120,
        },
        "requirements": []
    },
    {
        "id": 220,
        "product_code": "815.03.19",
        "name": "Parfum Cruise 120 ml",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PERFUME,
            "subtype": "cruise",
            "variant": 120,
        },
        "requirements": []
    },
    {
        "id": 221,
        "product_code": "809.55.32",
        "name": "Garnitura AKR 150/160",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GASKET,
            "subtype": "AKR",
        },
        "requirements": []
    },
    {
        "id": 222,
        "product_code": "809.55.66",
        "name": "TEU PVC DN 150",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEU,
            "subtype": "flex",
        },
        "requirements": []
    },
    {
        "id": 39,
        "product_code": "809.02.66",
        "name": "Tub flexibil antifonic DM160 (10m)",
        "specifications": {
            "length": "10m",
            "type": VMC_PRODUCT_TYPES.TUB,
            "subtype": "antifonic",
            "diameter": DN_VARIANTS.DN160,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.PREZON,
                "subtype": "flexibil",
                "quantity": 10
            },
            {
                "type": VMC_PRODUCT_TYPES.BRIDA,
                "diameter": DN_VARIANTS.DN160,
                "quantity": 10,
            },
        ],
    },
    {
        "id": 223,
        "product_code": "808.15.60",
        "name": "Difuzor de ventilatie rotund DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "diffuser",
            "variant": "round",
        }
    },
    {
        "id": 224,
        "product_code": "808.17.60",
        "name": "Difuzor de ventilatie patrat DN125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "diffuser",
            "variant": "square",
        }
    },
    {
        "id": 225,
        "product_code": "809.34.66",
        "name": "Conector PVC DN200",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.CONECTOR,
            "subtype": "PVC",
            "diameter": DN_VARIANTS.DN200,
        }
    },
    {
        "id": 226,
        "product_code": "817.04.91",
        "name": "Distribuitor 5x90/160",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": DN_VARIANTS.DN160,
            "size": 5,
            "distribution_diameter": DN_VARIANTS.DN90,
        },
        "requirements": []
    },
    {
        "id": 227,
        "product_code": "817.05.91",
        "name": "Distribuitor 6x90/160",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": DN_VARIANTS.DN160,
            "size": 6,
            "distribution_diameter": DN_VARIANTS.DN90,
        },
        "requirements": []
    },
    {
        "id": 228,
        "product_code": "817.06.91",
        "name": "Distribuitor 8x90/160",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": DN_VARIANTS.DN160,
            "size": 8,
            "distribution_diameter": DN_VARIANTS.DN90,
        },
        "requirements": []
    },
    {
        "id": 229,
        "product_code": "816.08.66",
        "name": "Distribuitor DN160 - 8x117",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": DN_VARIANTS.DN160,
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
            "size": 8,
        },
        "requirements": []
    },
    {
        "id": 230,
        "product_code": "816.09.66",
        "name": "Distribuitor DN200 - 8x117",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DISTRIBUTOR,
            "subtype": 2,
            "diameter": DN_VARIANTS.DN200,
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
            "size": 8,
        },
        "requirements": []
    },
    {
        "id": 231,
        "product_code": "816.06.66",
        "name": "Plenum dublu tavan - DN117",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 9,
            "size": 2,
        },
        "requirements": [],
    },
    {
        "id": 232,
        "product_code": "816.07.66",
        "name": "Plenum dublu podea - DN117",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 10,
            "size": 2,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "podea",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 233,
        "product_code": "817.10.91",
        "name": "Plenum scurt 1x90/125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 8,
            "size": 1,
        },
        "requirements": [],
    },
    {
        "id": 243,
        "product_code": "817.11.91",
        "name": "Plenum scurt 2x90/125",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PLENUM,
            "subtype": 8,
            "size": 2,
        },
        "requirements": [],
    },
    {
        "id": 234,
        "product_code": "817.01.91",
        "name": "Tub flexibil antibacterian D.92/77 (50m)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "diameter": "DN90",
            "distribution_diameter": DN_VARIANTS.DN90,
            "length": 50,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.RACORD,
                "quantity": 1,
                "distribution_diameter": DN_VARIANTS.DN90,
            },
        ]
    },
    {
        "id": 235,
        "product_code": "817.02.91",
        "name": "Racord imbinare pt teava DN90",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.RACORD,
            "distribution_diameter": DN_VARIANTS.DN90
        }
    },
    {
        "id": 236,
        "product_code": "817.03.91",
        "name": "Dop DN92",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DOP,
            "subtype": DN_VARIANTS.DN90,
            "distribution_diameter": DN_VARIANTS.DN90
        }
    },
    {
        "id": 237,
        "product_code": "816.01.66",
        "name": "Tubulatura ovala 117x52 (20m)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "diameter": DN_VARIANTS.DN_OVAL,
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
            "length": 20,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.RACORD,
                "quantity": 1,
                "distribution_diameter": DN_VARIANTS.DN_OVAL,
            },
        ]
    },
    {
        "id": 238,
        "product_code": "816.02.66",
        "name": "Tubulatura ovala 117x52 (50m)",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TUBULATURA,
            "diameter": DN_VARIANTS.DN_OVAL,
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
            "length": 50,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.RACORD,
                "subtype": "",
                "quantity": 1,
                "distribution_diameter": DN_VARIANTS.DN_OVAL,
            },
        ]
    },
    {
        "id": 239,
        "product_code": "816.03.66",
        "name": "Mufa ovala 117x52",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.RACORD,
            "subtype": "",
            "distribution_diameter": DN_VARIANTS.DN_OVAL
        }
    },
    {
        "id": 240,
        "product_code": "816.04.66",
        "name": "Cot vertical 117x52",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "layout": "vertical",
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.KIT,
                "subtype": "o-ring",
                "quantity": 2,
                "distribution_diameter": DN_VARIANTS.DN_OVAL,
            },
        ]
    },
    {
        "id": 243,
        "product_code": "816.05.66",
        "name": "Cot orizontal 117x52",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "layout": "horizontal",
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.KIT,
                "subtype": "o-ring",
                "quantity": 2,
                "distribution_diameter": DN_VARIANTS.DN_OVAL,
            },
        ]
    },
    {
        "id": 241,
        "product_code": "816.10.66",
        "name": "O-ring tubulatura ovala",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.KIT,
            "subtype": "o-ring",
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
        }
    },
    {
        "id": 242,
        "product_code": "816.11.66",
        "name": "Dop tubulatura ovala 117",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DOP,
            "subtype": DN_VARIANTS.DN_OVAL,
            "distribution_diameter": DN_VARIANTS.DN_OVAL,
        }
    },
    {
        "id": 243,
        "product_code": "818.04.100",
        "name": "Dezumidificator ductibil 200m3",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DEZUMIDIFICATOR,
            "subtype": "ductibil",
            "capacity": 200,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SET,
                "subtype": "racordare",
                "capacity": 200,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 244,
        "product_code": "818.05.100",
        "name": "Dezumidificator ductibil 400m3",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.DEZUMIDIFICATOR,
            "subtype": "ductibil",
            "capacity": 400,
        },
        "requirements": [
            {
                "type": VMC_PRODUCT_TYPES.SET,
                "subtype": "racordare",
                "capacity": 400,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 245,
        "product_code": "805.22.00",
        "name": "Senzor umiditate digital",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "umiditate",
            "variant": "doza",
        }
    },
    {
        "id": 246,
        "product_code": "405.03.00",
        "name": "Grup de pompare, cu pompa \"clasa A\" 25-70",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRUP,
            "subtype": "pompare",
            "variant": "25-70",
        }
    },
    {
        "id": 247,
        "product_code": "408.05.39",
        "name": "Racorduri pompa",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.RACORDURI,
            "subtype": "pompa",
        }
    },
    {
        "id": 249,
        "product_code": "1401.19.77",
        "name": "Banda anticondens",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.BANDA,
            "subtype": "anticondens",
        }
    },
    {
        "id": 250,
        "product_code": "203.05.37",
        "name": "Teava Pex-Al-Pex 26x2,0 cu izolatie",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEAVA,
            "subtype": "pex-al-pex",
            "diameter": 26,
            "length": 2000,
        }
    },
    {
        "id": 251,
        "product_code": "701.09.45",
        "name": "Trecere 25/26-1\" FE - PRESS",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TRECERE,
            "subtype": "FE",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
            "variant": [25, 26]
        }
    },
    {
        "id": 252,
        "product_code": "1301.10.54",
        "name": "Reductie alama 1\"-1/2\"",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.REDUCTIE,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        }
    },
    {
        "id": 253,
        "product_code": "1301.16.54",
        "name": "Teu alama 1/2",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEU,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        }
    },
    {
        "id": 254,
        "product_code": "1301.07.54",
        "name": "Niplu Alama 1/2\"",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.NIPLU,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        }
    },
    {
        "id": 255,
        "product_code": "1301.13.54",
        "name": "Olandez drept alama 1/2\"",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.OLANDEZ,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        }
    },
    {
        "id": 256,
        "product_code": "203.02.37a",
        "name": "Teava Pex-Al-Pex 16x2,0mm, cu izolatie (25m) albastru",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TEAVA,
            "subtype": "pex-al-pex",
            "diameter": 16,
            "length": 2000,
        }
    },
    {
        "id": 257,
        "product_code": "701.04.45",
        "name": "Trecere 16 - 1/2\" FE PRESS",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TRECERE,
            "subtype": "FE",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
            "variant": [16],
        }
    },
    {
        "id": 258,
        "product_code": "1303.04.73",
        "name": "Robinet trecere 1/2\" II",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.ROBINET,
            "subtype": "trecere",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        }
    },
    {
        "id": 259,
        "product_code": "1301.01.54",
        "name": "Cot alama 1/2\" IE",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COT,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        }
    },
    {
        "id": 260,
        "product_code": "805.10.83",
        "name": "Comanda pompa de caldura",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.COMANDA,
            "subtype": "pompa de caldura",
        }
    },
    {
        "id": 261,
        "product_code": "405.09.00",
        "name": "Grup de amestec motorizabil, cu pompa \"clasa A\" 25-70",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRUP,
            "subtype": "amestec",
        }
    },
    {
        "id": 262,
        "product_code": "",
        "name": "Vana de amestec 0-10v",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.VANA,
            "subtype": "amestec",
        }
    },
    {
        "id": 263,
        "product_code": "",
        "name": "Prelungitor alama 50mm 1/2\"",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.PRELUNGITOR,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        }
    },
    {
        "id": 264,
        "product_code": "607.12.39",
        "name": "Servomotor 0-10v M04",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SERVOMOTOR,
            "subtype": "M04",
        }
    },
    {
        "id": 265,
        "product_code": "818.06.100",
        "name": "Set racordare 2x D.150 pentru 200 m³",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SET,
            "subtype": "racordare",
            "capacity": 200,
        }
    },
    {
        "id": 266,
        "product_code": "818.08.100",
        "name": "Set racordare 2x D.200 pentru 400 m³",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.SET,
            "subtype": "racordare",
            "capacity": 400,
        }
    },
    {
        "id": 268,
        "product_code": "701.74.45",
        "name": "Inel sertizare teava 26",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.INEL,
            "subtype": "sertizare",
            "diameter": 26,
        }
    },
    {
        "id": 269,
        "product_code": "816.12.66",
        "name": "Grila podea",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRATE,
            "subtype": "podea",
        }
    },
    {
        "id": 270,
        "product_code": "405.02.00",
        "name": "Grup de pompare, cu pompa \"clasa A\" 25-60",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.GRUP,
            "subtype": "pompare",
            "variant": "25-60",
        }
    },
    {
        "id": 271,
        "product_code": "403.01.43",
        "name": "Pompa Sistema 25-60 130 \"clasa A\", cu turatie variabila",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.POMPA,
            "subtype": "sistema",
            "variant": "25-60",
        }
    },
    {
        "id": 272,
        "product_code": "818.10.00",
        "name": "Tablou automatizare dezumidificare",
        "specifications": {
            "type": VMC_PRODUCT_TYPES.TABLOU,
            "subtype": "automatizare",
        }
    },
]
