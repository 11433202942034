import {
    checkBandaPerforataConditions,
    checkCableConditions, checkColierMetalicConditions, checkCotPVCConditions,
    checkFaseteConditions,
    checkTeavaPVCConditions
} from "../Vacuum/VacuumLogic.js";

export const VACUUM_TYPES = {
    DOZA: 1,
    TEAVA: 2,
    COT: 3,
    RACORD: 4,
    PRELUNGITOR: 5,
    MUFA: 6,
    DERIVATIE: 7,
    GRATAR: 8,
    DOP: 9,
    SUPORT: 10,
    CABLU: 11,
    ADEZIV: 12,
    FASETE: 13,
    BANDA: 14,
    PREZON: 15,
    BRIDA: 16,
    KIT: 17,
    ADAPTOR: 18,
    CURBA: 19,
    ASPIRATOR: 20,
    CUTIE: 21,
    ORNAMENT: 22,
    PRIZA: 23,
    FURTUN: 24,
    SEPARATOR: 25,
    FILTRU: 26,
    SAC: 27,
    AMORTIZOR: 28,
    MICROMANOMETRU: 29,
    SET: 30,
    ACTIVATOR: 31,
    MANER: 32,
    COLIER: 33,
    REDUCTIE: 34,
    DIBLU: 35,
    PERIE: 36,
    CONEXIUNE: 37,
    ROBOT: 38,
};

const NON_STANDARD_MEASUREMENTS = {
    D2: {
        length: 2,
        measurementUnit: "inch",
    }
}

// STANDARDS:
// weight in grams
// dimensions in mm
// angles in degrees

export const products = [
    {
        "id": 1,
        "product_code": "905.04.62",
        "name": "Doza dreptunghiulara",
        "specifications": {
            "type": VACUUM_TYPES.DOZA,
            "subtype": "dreptunghiulara",
            "connector": "normal",
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "PVC",
                "diameter": 50,
                "width": 2.1,
                "length": 2000,
                "quantity": 9,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "IE",
                "quantity": 1.5,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "II",
                "quantity": 1.5,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 90,
                "diameter": 50,
                "connection": "II",
                "quantity": 1.5,
            },
        ],
        "conditions": [
            (context) => {
                const H3 = context.vacuumSetup.total_area;
                const H7 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "dreptunghiulara" && p.specifications.connector === "normal")?.quantity || 0;
                const H8 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "dreptunghiulara" && p.specifications.connector === "spate")?.quantity || 0;
                const D2 = context.vacuumSetup.has_bucatarie_connector ? 1 : 0;
                const H9 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "bucatarie")?.quantity || 0;

                checkCableConditions(context, H7, H8, H3, D2);
                checkFaseteConditions(context, H3, H7, H8, H9);
            }
        ],
    },
    {
        "id": 2,
        "product_code": "905.05.62",
        "name": "Doza dreptunghiulara cu racordare din spate",
        "specifications": {
            "type": VACUUM_TYPES.DOZA,
            "subtype": "dreptunghiulara",
            "connector": "spate"
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "PVC",
                "diameter": 50,
                "width": 2.1,
                "length": 2000,
                "quantity": 9,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "IE",
                "quantity": 1.5,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "II",
                "quantity": 1.5,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 90,
                "diameter": 50,
                "connection": "II",
                "quantity": 1.5,
            },
        ],
        "conditions": [
            (context) => {
                const H3 = context.vacuumSetup.total_area;
                const H7 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "dreptunghiulara" && p.specifications.connector === "normal")?.quantity || 0;
                const H8 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "dreptunghiulara" && p.specifications.connector === "spate")?.quantity || 0;
                const D2 = context.vacuumSetup.has_bucatarie_connector ? 1 : 0;
                const H9 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "bucatarie")?.quantity || 0;

                checkCableConditions(context, H7, H8, H3, D2);
                checkFaseteConditions(context, H3, H7, H8, H9);
            }
        ],
    },
    {
        "id": 3,
        "product_code": "905.06.62",
        "name": "Doza pentru bucatarie",
        "specifications": {
            "type": VACUUM_TYPES.DOZA,
            "subtype": "bucatarie"
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.RACORD,
                "subtype": "priza",
                "variant": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.PRELUNGITOR,
                "subtype": "priza",
                "variant": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                const H3 = context.vacuumSetup.total_area;
                const H7 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "dreptunghiulara" && p.specifications.connector === "normal")?.quantity || 0;
                const H8 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "dreptunghiulara" && p.specifications.connector === "spate")?.quantity || 0;
                const H9 = context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === "bucatarie")?.quantity || 0;

                checkFaseteConditions(context, H3, H7, H8, H9);
            },
        ],
    },
    {
        "id": 4,
        "product_code": "905.07.62",
        "name": "Prelungitor doza",
        "specifications": {
            "type": VACUUM_TYPES.PRELUNGITOR,
            "subtype": "doza"
        }
    },
    {
        "id": 5,
        "product_code": "905.10.62",
        "name": "Doza pardoseala",
        "specifications": {
            "type": VACUUM_TYPES.DOZA,
            "subtype": "pardoseala",
            "connector": "normal"
        }
    },
    {
        "id": 6,
        "product_code": "905.11.62",
        "name": "Doza pardoseala cu racordare din spate",
        "specifications": {
            "type": VACUUM_TYPES.DOZA,
            "subtype": "pardoseala",
            "connector": "spate"
        }
    },
    {
        "id": 7,
        "product_code": "908.01.62",
        "name": "Teava PVC d.50mm, grosime 2,1mm (bare de 2m)",
        "specifications": {
            "type": VACUUM_TYPES.TEAVA,
            "subtype": "PVC",
            "diameter": 50,
            "width": 2.1,
            "length": 2000
        },
        "conditions": [
            (context) => {
                if (context) {
                    checkTeavaPVCConditions(context);
                }
            }
        ]
    },
    {
        "id": 8,
        "product_code": "908.11.62",
        "name": "Teava PVC d.40mm, grosime 2,1mm (bare de 2m)",
        "specifications": {
            "type": VACUUM_TYPES.TEAVA,
            "subtype": "PVC",
            "diameter": 40,
            "width": 2.1,
            "length": 2000
        }
    },
    {
        "id": 9,
        "product_code": "908.02.62",
        "name": "Cot PVC la 45 d.50mm IE",
        "specifications": {
            "type": VACUUM_TYPES.COT,
            "subtype": "PVC",
            "angle": 45,
            "diameter": 50,
            "connection": "IE"
        },
        "conditions": [
            (context) => {
                checkCotPVCConditions(context, 45, 50, "IE", 14);
            }
        ],
    },
    {
        "id": 10,
        "product_code": "908.12.62",
        "name": "Cot PVC la 45 d.40mm IE",
        "specifications": {
            "type": VACUUM_TYPES.COT,
            "subtype": "PVC",
            "angle": 45,
            "diameter": 40,
            "connection": "IE"
        }
    },
    {
        "id": 11,
        "product_code": "908.03.62",
        "name": "Cot PVC la 45 d.50mm II",
        "specifications": {
            "type": VACUUM_TYPES.COT,
            "subtype": "PVC",
            "angle": 45,
            "diameter": 50,
            "connection": "II"
        },
        "conditions": [
            (context) => {
                checkCotPVCConditions(context, 45, 50, "II", null);
            }
        ],
    },
    {
        "id": 12,
        "product_code": "908.13.62",
        "name": "Cot PVC la 45 d.40mm II",
        "specifications": {
            "type": VACUUM_TYPES.COT,
            "subtype": "PVC",
            "angle": 45,
            "diameter": 40,
            "connection": "II"
        }
    },
    {
        "id": 13,
        "product_code": "908.04.62",
        "name": "Cot PVC la 90 d.50mm II",
        "specifications": {
            "type": VACUUM_TYPES.COT,
            "subtype": "PVC",
            "angle": 90,
            "diameter": 50,
            "connection": "II"
        },
        "conditions": [
            (context) => {
                checkCotPVCConditions(context, 90, 50, "II", null);
            }
        ],
    },
    {
        "id": 14,
        "product_code": "908.05.62",
        "name": "Derivatie PVC la 45 d.50mm II",
        "specifications": {
            "type": VACUUM_TYPES.DERIVATIE,
            "subtype": "PVC",
            "angle": 45,
            "diameter": 50,
            "connection": "II"
        },
        "requirements": [],
    },
    {
        "id": 15,
        "product_code": "908.14.62",
        "name": "Derivatie PVC la 45 d.40mm II",
        "specifications": {
            "type": VACUUM_TYPES.DERIVATIE,
            "subtype": "PVC",
            "angle": 45,
            "diameter": 40,
            "connection": "II"
        }
    },
    {
        "id": 16,
        "product_code": "908.06.62",
        "name": "Derivatie PVC la 90 d.50mm II",
        "specifications": {
            "type": VACUUM_TYPES.DERIVATIE,
            "subtype": "PVC",
            "angle": 90,
            "diameter": 50,
            "connection": "II"
        }
    },
    {
        "id": 17,
        "product_code": "908.07.62",
        "name": "Mufa prelungire PVC d.50mm",
        "specifications": {
            "type": VACUUM_TYPES.MUFA,
            "subtype": "PVC",
            "diameter": 50
        },
    },
    {
        "id": 18,
        "product_code": "908.15.62",
        "name": "Mufa prelungire PVC d.40mm",
        "specifications": {
            "type": VACUUM_TYPES.MUFA,
            "subtype": "PVC",
            "diameter": 40
        }
    },
    {
        "id": 19,
        "product_code": "908.08.62",
        "name": "Reductie PVC d.82-50mm",
        "specifications": {
            "type": VACUUM_TYPES.REDUCTIE,
            "subtype": "PVC",
            "diameter": [82, 50]
        }
    },
    {
        "id": 20,
        "product_code": "908.16.62",
        "name": "Reductie PVC d.50-40mm",
        "specifications": {
            "type": VACUUM_TYPES.REDUCTIE,
            "subtype": "PVC",
            "diameter": [50, 40]
        }
    },
    {
        "id": 21,
        "product_code": "908.09.62",
        "name": "Gratar evacuare d.82mm",
        "specifications": {
            "type": VACUUM_TYPES.GRATAR,
            "subtype": "PVC",
            "diameter": 82
        },
    },
    {
        "id": 22,
        "product_code": "908.10.62",
        "name": "Dop PVC d.50mm",
        "specifications": {
            "type": VACUUM_TYPES.DOP,
            "subtype": "PVC",
            "diameter": 50
        }
    },
    {
        "id": 23,
        "product_code": "909.01.62",
        "name": "Suport fixare aspirator",
        "specifications": {
            "type": VACUUM_TYPES.SUPORT,
            "subtype": "aspirator",
        }
    },
    {
        "id": 24,
        "product_code": "909.02.62",
        "name": "Dop proba",
        "specifications": {
            "type": VACUUM_TYPES.DOP,
            "subtype": "proba"
        }
    },
    {
        "id": 25,
        "product_code": "909.03.62",
        "name": "Cablu electric 4x1 (25 m) (pentru control la distanta aspirator Basic)",
        "specifications": {
            "type": VACUUM_TYPES.CABLU,
            "subtype": "electric",
            "diameter": 4,
            "length": 25000,
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.DIBLU,
                "subtype": "bataie",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 26,
        "product_code": "909.04.62",
        "name": "Cablu electric 2x1 (25 m)",
        "specifications": {
            "type": VACUUM_TYPES.CABLU,
            "subtype": "electric",
            "diameter": 2,
            "length": 25000
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.ADEZIV,
                "subtype": "adeziv",
                "weight": 125,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.DIBLU,
                "subtype": "bataie",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                checkBandaPerforataConditions(context);
            }
        ],
    },
    {
        "id": 27,
        "product_code": "909.05.62",
        "name": "Cablu electric 2x1 (50 m)",
        "specifications": {
            "type": VACUUM_TYPES.CABLU,
            "subtype": "electric",
            "diameter": 2,
            "length": 50000
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.ADEZIV,
                "subtype": "adeziv",
                "weight": 250,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.DIBLU,
                "subtype": "bataie",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                checkBandaPerforataConditions(context);
            }
        ],
    },
    {
        "id": 28,
        "product_code": "909.06.62",
        "name": "Cablu electric 2x1 (100 m)",
        "specifications": {
            "type": VACUUM_TYPES.CABLU,
            "subtype": "electric",
            "diameter": 2,
            "length": 100000
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.ADEZIV,
                "subtype": "adeziv",
                "weight": 500,
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                checkBandaPerforataConditions(context);
            }
        ],
    },
    {
        "id": 29,
        "product_code": "909.08.65",
        "name": "Adeziv 125 gr.",
        "specifications": {
            "type": VACUUM_TYPES.ADEZIV,
            "subtype": "adeziv",
            "weight": 125
        }
    },
    {
        "id": 30,
        "product_code": "909.09.65",
        "name": "Adeziv 250 gr.",
        "specifications": {
            "type": VACUUM_TYPES.ADEZIV,
            "subtype": "adeziv",
            "weight": 250
        }
    },
    {
        "id": 31,
        "product_code": "909.10.65",
        "name": "Adeziv 500 gr.",
        "specifications": {
            "type": VACUUM_TYPES.ADEZIV,
            "subtype": "adeziv",
            "weight": 500
        }
    },
    {
        "id": 32,
        "product_code": "909.11.65",
        "name": "Fasete 30 cm",
        "specifications": {
            "type": VACUUM_TYPES.FASETE,
            "subtype": "fasete",
            "length": 300
        }
    },
    {
        "id": 33,
        "product_code": "1401.02.65",
        "name": "Banda perforata 12mm",
        "specifications": {
            "type": VACUUM_TYPES.BANDA,
            "subtype": "perforata",
            "width": 12
        }
    },
    {
        "id": 34,
        "product_code": "1401.03.65",
        "name": "Diblu prin bataie 6x40",
        "specifications": {
            "type": VACUUM_TYPES.DIBLU,
            "subtype": "bataie",
        }
    },
    {
        "id": 35,
        "product_code": "1401.12.81",
        "name": "Prezon M8x60mm",
        "specifications": {
            "type": VACUUM_TYPES.PREZON,
            "subtype": "M8",
            "length": 60
        }
    },
    {
        "id": 36,
        "product_code": "1401.13.81",
        "name": "Prezon M8x140mm",
        "specifications": {
            "type": VACUUM_TYPES.PREZON,
            "subtype": "M8",
            "length": 140
        }
    },
    {
        "id": 37,
        "product_code": "1401.14.81",
        "name": "Diblu 12x70",
        "specifications": {
            "type": VACUUM_TYPES.DIBLU,
            "subtype": "standard",
            "width": 12,
            "length": 70,
        }
    },
    {
        "id": 38,
        "product_code": "1401.17.81",
        "name": "Brida M8 6/4\" (48-53mm)",
        "specifications": {
            "type": VACUUM_TYPES.BRIDA,
            "subtype": "M8",
            "dimensions": [48, 53]
        }
    },
    {
        "id": 39,
        "product_code": "909.13.62",
        "name": "Doza curent tip 503",
        "specifications": {
            "type": VACUUM_TYPES.DOZA,
            "subtype": "curent",
            "variant": "503"
        }
    },
    {
        "id": 40,
        "product_code": "915.01.62",
        "name": "Kit \"Easy\" pentru pereti din rigips",
        "specifications": {
            "type": VACUUM_TYPES.KIT,
            "subtype": "Easy",
            "variant": "rigips"
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.DIBLU,
                "subtype": "bataie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.BANDA,
                "subtype": "perforata",
                "width": 12,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "Easy",
                "variant": "furtun",
                "length": 12000,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "PVC",
                "diameter": 50,
                "width": 2.1,
                "length": 2000,
                "quantity": 6,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "IE",
                "quantity": 2,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "II",
                "quantity": 2,
            },
            {
                "type": VACUUM_TYPES.MUFA,
                "subtype": "PVC",
                "diameter": 50,
                "quantity": 3,
            },
        ],
    },
    {
        "id": 41,
        "product_code": "915.02.62",
        "name": "Kit \"Easy\" pentru pereti din zidarie",
        "specifications": {
            "type": VACUUM_TYPES.KIT,
            "subtype": "Easy",
            "variant": "zidarie"
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.DIBLU,
                "subtype": "bataie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.BANDA,
                "subtype": "perforata",
                "width": 12,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "Easy",
                "variant": "furtun",
                "length": 12000,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "PVC",
                "diameter": 50,
                "width": 2.1,
                "length": 2000,
                "quantity": 6,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "IE",
                "quantity": 2,
            },
            {
                "type": VACUUM_TYPES.COT,
                "subtype": "PVC",
                "angle": 45,
                "diameter": 50,
                "connection": "II",
                "quantity": 2,
            },
            {
                "type": VACUUM_TYPES.MUFA,
                "subtype": "PVC",
                "diameter": 50,
                "quantity": 3,
            },
        ],
    },
    {
        "id": 44,
        "product_code": "915.11.62",
        "name": "Teava \"Easy\" PVC d.2\" grosime 1,6mm (bare de 2m)",
        "specifications": {
            "type": VACUUM_TYPES.TEAVA,
            "subtype": "Easy",
            "diameter": NON_STANDARD_MEASUREMENTS.D2,
            "width": 1.6,
            "length": 2000
        }
    },
    {
        "id": 45,
        "product_code": "915.12.62",
        "name": "Mufa \"Easy\" d.2\"",
        "specifications": {
            "type": VACUUM_TYPES.MUFA,
            "subtype": "Easy",
            "diameter": NON_STANDARD_MEASUREMENTS.D2
        }
    },
    {
        "id": 46,
        "product_code": "915.13.62",
        "name": "Adaptor niplu \"Easy\" d.2\"",
        "specifications": {
            "type": VACUUM_TYPES.ADAPTOR,
            "subtype": "niplu",
            "diameter": NON_STANDARD_MEASUREMENTS.D2,
            "variant": "Easy",
        }
    },
    {
        "id": 47,
        "product_code": "915.14.62",
        "name": "Curba \"Easy\" larga la 22,5 d.2\" II",
        "specifications": {
            "type": VACUUM_TYPES.CURBA,
            "subtype": "Easy",
            "angle": 22.5,
            "diameter": NON_STANDARD_MEASUREMENTS.D2,
            "connection": "II"
        }
    },
    {
        "id": 48,
        "product_code": "915.15.62",
        "name": "Curba \"Easy\" larga la 45 d.2\" II",
        "specifications": {
            "type": VACUUM_TYPES.CURBA,
            "subtype": "Easy",
            "angle": 45,
            "diameter": NON_STANDARD_MEASUREMENTS.D2,
            "connection": "II"
        }
    },
    {
        "id": 49,
        "product_code": "915.16.62",
        "name": "Curba \"Easy\" larga la 90 d.2\" II",
        "specifications": {
            "type": VACUUM_TYPES.CURBA,
            "subtype": "Easy",
            "angle": 90,
            "diameter": NON_STANDARD_MEASUREMENTS.D2,
            "connection": "II"
        }
    },
    {
        "id": 50,
        "product_code": "901.02.62",
        "name": "Aspirator Display 2",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "display",
            "variant": 2,
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.SUPORT,
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.REDUCTIE,
                "subtype": "PVC",
                "diameter": [82, 50],
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.GRATAR,
                "subtype": "PVC",
                "diameter": 82,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Display 2",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide",
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Display 2",
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
        "conditions": [],
    },
    {
        "id": 51,
        "product_code": "901.03.62",
        "name": "Aspirator Display 3",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "display",
            "variant": 3,
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.REDUCTIE,
                "subtype": "PVC",
                "diameter": [82, 50],
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.GRATAR,
                "subtype": "PVC",
                "diameter": 82,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.SUPORT,
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.AMORTIZOR,
                "usage": "sunet",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Display 3-4",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide"
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Display 3-4"
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 52,
        "product_code": "901.04.62",
        "name": "Aspirator Display 4",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "display",
            "variant": 4,
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.REDUCTIE,
                "subtype": "PVC",
                "diameter": [82, 50],
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.GRATAR,
                "subtype": "PVC",
                "diameter": 82,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.SUPORT,
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.AMORTIZOR,
                "usage": "sunet",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Display 3-4",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide"
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Display 3-4",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 53,
        "product_code": "901.05.62",
        "name": "Conexiune internet",
        "specifications": {
            "type": VACUUM_TYPES.CONEXIUNE,
            "usage": "internet"
        }
    },
    {
        "id": 54,
        "product_code": "902.01.62",
        "name": "Aspirator Basic 1",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "Basic",
            "variant": 1,
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.REDUCTIE,
                "subtype": "PVC",
                "diameter": [82, 50],
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.GRATAR,
                "subtype": "PVC",
                "diameter": 82,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.SUPORT,
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.ADAPTOR,
                "subtype": "exhaustare",
                "variant": "aer",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Basic 1",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide"
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Basic 1"
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 55,
        "product_code": "902.02.62",
        "name": "Aspirator Basic 2",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "Basic",
            "variant": 2,
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.REDUCTIE,
                "subtype": "PVC",
                "diameter": [82, 50],
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.GRATAR,
                "subtype": "PVC",
                "diameter": 82,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.SUPORT,
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.ADAPTOR,
                "subtype": "exhaustare",
                "variant": "aer",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Basic 2-4",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide"
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Basic 2-4"
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 56,
        "product_code": "902.03.62",
        "name": "Aspirator Basic 4",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "Basic",
            "variant": 4,
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.REDUCTIE,
                "subtype": "PVC",
                "diameter": [82, 50],
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.GRATAR,
                "subtype": "PVC",
                "diameter": 82,
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.SUPORT,
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.AMORTIZOR,
                "usage": "sunet",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Basic 2-4",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide"
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "Basic 2-4"
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 57,
        "product_code": "902.05.62",
        "name": "Kit control la distanta pentru aspiratoare \"Basic\"",
        "specifications": {
            "type": VACUUM_TYPES.KIT,
            "subtype": "control",
            "variant": "Basic"
        }
    },
    {
        "id": 58,
        "product_code": "902.06.62",
        "name": "Adaptor exhaustare aer",
        "specifications": {
            "type": VACUUM_TYPES.ADAPTOR,
            "subtype": "exhaustare",
            "variant": "aer"
        }
    },
    {
        "id": 59,
        "product_code": "904.01.62",
        "name": "Aspirator \"AP\" pentru montaj ingropat",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "AP",
            "mounting": "ingropat"
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.KIT,
                "subtype": "instalare",
                "variant": "AP",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "AP",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.SAC,
                "subtype": "carton",
                "variant": "AP",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide"
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "AP"
            },
            {
                "type": VACUUM_TYPES.SAC,
                "subtype": "carton",
                "variant": "AP"
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 60,
        "product_code": "904.02.62",
        "name": "Cutie pentru montaj ingropat",
        "specifications": {
            "type": VACUUM_TYPES.CUTIE,
            "subtype": "montaj",
            "mounting": "ingropat"
        }
    },
    {
        "id": 61,
        "product_code": "904.03.62",
        "name": "Kit cadru si usa de vizitare",
        "specifications": {
            "type": VACUUM_TYPES.KIT,
            "subtype": "vizitare",
        }
    },
    {
        "id": 62,
        "product_code": "904.05.62",
        "name": "Aspirator \"AP\" pentru montaj aparent",
        "specifications": {
            "type": VACUUM_TYPES.ASPIRATOR,
            "subtype": "vizitare",
            "mounting": "aparent"
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.KIT,
                "subtype": "instalare",
                "variant": "AP",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "AP",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.SAC,
                "subtype": "carton",
                "variant": "AP",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.TEAVA,
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": VACUUM_TYPES.COLIER,
                "subtype": "metalic",
                "dimensions": [50, 70],
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "covoare",
                "quantity": 0,
            },
            {
                "type": VACUUM_TYPES.PERIE,
                "subtype": "tapiterie",
            },
            {
                "type": VACUUM_TYPES.SEPARATOR,
                "subtype": "lichide"
            },
            {
                "type": VACUUM_TYPES.FILTRU,
                "subtype": "AP"
            },
            {
                "type": VACUUM_TYPES.SAC,
                "subtype": "carton",
                "variant": "AP"
            },
            {
                "type": VACUUM_TYPES.SET,
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 63,
        "product_code": "904.06.62",
        "name": "Kit instalare aspirator AP",
        "specifications": {
            "type": VACUUM_TYPES.KIT,
            "subtype": "instalare",
            "variant": "AP"
        }
    },
    {
        "id": 64,
        "product_code": "916.01.62",
        "name": "Robot aspirare, cu statie de autocuratare",
        "specifications": {
            "type": VACUUM_TYPES.ROBOT,
            "subtype": "aspirare",
            "variant": "autocuratare",
        }
    },
    {
        "id": 65,
        "product_code": "916.02.62",
        "name": "Robot aspirare",
        "specifications": {
            "type": VACUUM_TYPES.ROBOT,
            "subtype": "aspirare",
            "variant": "normal",
        }
    },
    {
        "id": 66,
        "product_code": "906.03.62",
        "name": "Priza Gewiss alba",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "Gewiss",
            "color": "alb"
        }
    },
    {
        "id": 67,
        "product_code": "906.04.62",
        "name": "Priza Gewiss neagra",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "Gewiss",
            "color": "neagra"
        }
    },
    {
        "id": 68,
        "product_code": "906.05.62",
        "name": "Priza Gewiss silver",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "Gewiss",
            "color": "silver"
        }
    },
    {
        "id": 69,
        "product_code": "906.11.62",
        "name": "Priza Bticino alba",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "Biticino",
            "color": "alb"
        }
    },
    {
        "id": 70,
        "product_code": "906.12.62",
        "name": "Priza Bticino neagra",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "Biticino",
            "color": "neagra"
        }
    },
    {
        "id": 71,
        "product_code": "906.13.62",
        "name": "Priza Bticino silver",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "Biticino",
            "color": "silver"
        }
    },
    {
        "id": 72,
        "product_code": "907.01.62",
        "name": "Suport priza Gewiss alb",
        "specifications": {
            "type": VACUUM_TYPES.SUPORT,
            "subtype": "priza",
            "variant": "Gewiss",
            "color": "alb"
        }
    },
    {
        "id": 73,
        "product_code": "907.02.62",
        "name": "Suport priza Gewiss negru",
        "specifications": {
            "type": VACUUM_TYPES.SUPORT,
            "subtype": "priza",
            "variant": "Gewiss",
            "color": "neagra"
        }
    },
    {
        "id": 74,
        "product_code": "907.03.62",
        "name": "Suport priza Gewiss silver",
        "specifications": {
            "type": VACUUM_TYPES.SUPORT,
            "subtype": "priza",
            "variant": "Gewiss",
            "color": "silver"
        }
    },
    {
        "id": 75,
        "product_code": "907.07.62",
        "name": "Suport priza Bticino alb",
        "specifications": {
            "type": VACUUM_TYPES.SUPORT,
            "subtype": "priza",
            "variant": "Biticino",
            "color": "alb"
        }
    },
    {
        "id": 76,
        "product_code": "907.08.62",
        "name": "Suport priza Bticino negru",
        "specifications": {
            "type": VACUUM_TYPES.SUPORT,
            "subtype": "priza",
            "variant": "Bticino",
            "color": "neagra"
        }
    },
    {
        "id": 77,
        "product_code": "907.09.62",
        "name": "Suport priza Bticino silver",
        "specifications": {
            "type": VACUUM_TYPES.SUPORT,
            "subtype": "priza",
            "variant": "Bticino",
            "color": "silver"
        }
    },
    {
        "id": 78,
        "product_code": "906.06.62",
        "name": "Priza bucatarie alba",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "bucatarie",
            "color": "alb"
        }
    },
    {
        "id": 79,
        "product_code": "906.07.62",
        "name": "Priza bucatarie neagra",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "bucatarie",
            "color": "neagra"
        }
    },
    {
        "id": 80,
        "product_code": "906.08.62",
        "name": "Priza bucatarie gri",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "bucatarie",
            "color": "silver"
        }
    },
    {
        "id": 81,
        "product_code": "905.08.62",
        "name": "Racord priza bucatarie",
        "specifications": {
            "type": VACUUM_TYPES.RACORD,
            "subtype": "priza",
            "variant": "bucatarie"
        }
    },
    {
        "id": 82,
        "product_code": "905.09.62",
        "name": "Prelungitor priza",
        "specifications": {
            "type": VACUUM_TYPES.PRELUNGITOR,
            "subtype": "priza",
            "variant": "bucatarie"
        }
    },
    {
        "id": 83,
        "product_code": "909.12.62",
        "name": "Teava flexibila bucatarie",
        "specifications": {
            "type": VACUUM_TYPES.TEAVA,
            "subtype": "flexibila",
            "usage": "bucatarie"
        },
        "conditions": [
            (context) => {
                if (context) {
                    checkColierMetalicConditions(context);
                }
            },
        ],
    },
    {
        "id": 84,
        "product_code": "909.14.66",
        "name": "Colier metalic 50-70",
        "specifications": {
            "type": VACUUM_TYPES.COLIER,
            "subtype": "metalic",
            "dimensions": [50, 70]
        },
    },
    {
        "id": 85,
        "product_code": "907.10.65",
        "name": "Ornament alb priza Gewiss - dreptunghiular",
        "specifications": {
            "type": VACUUM_TYPES.ORNAMENT,
            "subtype": "Gewiss",
            "variant": "dreptunghiular",
            "color": "alb",
        }
    },
    {
        "id": 86,
        "product_code": "907.11.65",
        "name": "Ornament alb priza Gewiss - rotunjit",
        "specifications": {
            "type": VACUUM_TYPES.ORNAMENT,
            "subtype": "Gewiss",
            "variant": "rotunjit",
            "color": "alb",
        }
    },
    {
        "id": 93,
        "product_code": "907.12.65",
        "name": "Ornament negru priza Gewiss (Geo) - dreptunghiular",
        "specifications": {
            "type": VACUUM_TYPES.ORNAMENT,
            "subtype": "Gewiss",
            "variant": "dreptunghiular",
            "color": "neagra",
        }
    },
    {
        "id": 94,
        "product_code": "907.13.65",
        "name": "Ornament silver priza Gewiss(Geo)",
        "specifications": {
            "type": VACUUM_TYPES.ORNAMENT,
            "subtype": "Gewiss",
            "variant": "normal",
            "color": "silver",
        }
    },
    {
        "id": 95,
        "product_code": "907.04.62",
        "name": "Ornament inox priza bucatarie",
        "specifications": {
            "type": VACUUM_TYPES.ORNAMENT,
            "subtype": "bucatarie",
            "variant": "normal",
            "color": "silver"
        }
    },
    {
        "id": 96,
        "product_code": "907.05.62",
        "name": "Ornament alb priza bucatarie",
        "specifications": {
            "type": VACUUM_TYPES.ORNAMENT,
            "subtype": "bucatarie",
            "variant": "normal",
            "color": "alb",
        }
    },
    {
        "id": 97,
        "product_code": "907.06.62",
        "name": "Ornament negru priza bucatarie",
        "specifications": {
            "type": VACUUM_TYPES.ORNAMENT,
            "subtype": "bucatarie",
            "variant": "normal",
            "color": "neagra",
        }
    },
    {
        "id": 98,
        "product_code": "915.05.62",
        "name": "Priza \"Easy\" alba",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "Easy",
            "color": "alb"
        }
    },
    {
        "id": 99,
        "product_code": "906.02.62",
        "name": "Priza \"Eco\" alba",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "ECO",
            "color": "alb"
        }
    },
    {
        "id": 100,
        "product_code": "906.09.62",
        "name": "Priza aparenta",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "aparenta",
            "color": "alb",
        }
    },
    {
        "id": 101,
        "product_code": "906.10.62",
        "name": "Priza izolata pentru exterior",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "exterior",
            "color": "alb"
        }
    },
    {
        "id": 102,
        "product_code": "906.17.62",
        "name": "Priza pardoseala + cheie",
        "specifications": {
            "type": VACUUM_TYPES.PRIZA,
            "subtype": "pardoseala",
            "color": "silver"
        }
    },
    {
        "id": 103,
        "product_code": "910.01.62",
        "name": "Furtun cu maner (9m)",
        "specifications": {
            "type": VACUUM_TYPES.FURTUN,
            "subtype": "maner",
            "variant": "normal",
            "length": 9000
        }
    },
    {
        "id": 104,
        "product_code": "910.02.62",
        "name": "Furtun cu maner si intrerupator (9m)",
        "specifications": {
            "type": VACUUM_TYPES.FURTUN,
            "subtype": "maner",
            "variant": "intrerupator",
            "length": 9000
        }
    },
    {
        "id": 105,
        "product_code": "910.04.62",
        "name": "Kit Furtun Wi-Fi pentru aspirator Display",
        "specifications": {
            "type": VACUUM_TYPES.KIT,
            "subtype": "furtun",
            "feature": "Wi-Fi",
            "variant": "Display"
        }
    },
    {
        "id": 106,
        "product_code": "910.05.62",
        "name": "Kit Furtun Wi-Fi pentru aspirator Basic",
        "specifications": {
            "type": VACUUM_TYPES.KIT,
            "subtype": "furtun",
            "feature": "Wi-Fi",
            "variant": "Basic"
        }
    },
    {
        "id": 107,
        "product_code": "915.03.62",
        "name": "Set \"Easy\" furtun si accesorii (12m)",
        "specifications": {
            "type": VACUUM_TYPES.SET,
            "subtype": "Easy",
            "variant": "furtun",
            "length": 12000,
        }
    },
    {
        "id": 108,
        "product_code": "915.04.62",
        "name": "Set \"Easy\" furtun si accesorii cu activare Wi-Fi (12m)",
        "specifications": {
            "type": VACUUM_TYPES.SET,
            "features": ["furtun", "accesorii", "Wi-Fi"],
            "length": 12000,
            "model": "Easy"
        }
    },
    {
        "id": 109,
        "product_code": "911.01.62",
        "name": "Set accesorii \"Standard\"",
        "specifications": {
            "type": VACUUM_TYPES.SET,
            "subtype": "standard"
        }
    },
    {
        "id": 110,
        "product_code": "911.02.62",
        "name": "Set accesorii \"Baza\"",
        "specifications": {
            "type": VACUUM_TYPES.SET,
            "subtype": "basic",
        }
    },
    {
        "id": 111,
        "product_code": "914.10.62",
        "name": "Amortizor sunet",
        "specifications": {
            "type": VACUUM_TYPES.AMORTIZOR,
            "usage": "sunet",
        }
    },
    {
        "id": 112,
        "product_code": "911.03.62",
        "name": "Perie pentru covoare si mochete",
        "specifications": {
            "type": VACUUM_TYPES.PERIE,
            "subtype": "covoare"
        }
    },
    {
        "id": 113,
        "product_code": "911.04.62",
        "name": "Perie pentru tapiterie",
        "specifications": {
            "type": VACUUM_TYPES.PERIE,
            "subtype": "tapiterie"
        }
    },
    {
        "id": 114,
        "product_code": "913.01.62",
        "name": "Set \"Ecoflex\" pentru bucatarie",
        "specifications": {
            "type": VACUUM_TYPES.SET,
            "subtype": "ecoflex",
        },
        "requirements": [
            {
                "type": VACUUM_TYPES.DOZA,
                "subtype": "bucatarie",
                "quantity": 1,
            }
        ],
    },
    {
        "id": 115,
        "product_code": "913.02.62",
        "name": "Separator lichide",
        "specifications": {
            "type": VACUUM_TYPES.SEPARATOR,
            "subtype": "lichide"
        }
    },
    {
        "id": 116,
        "product_code": "914.01.62",
        "name": "Micromanometru",
        "specifications": {
            "type": VACUUM_TYPES.MICROMANOMETRU
        }
    },
    {
        "id": 117,
        "product_code": "914.02.62",
        "name": "Filtru Basic 1",
        "specifications": {
            "type": VACUUM_TYPES.FILTRU,
            "subtype": "Basic 1"
        }
    },
    {
        "id": 118,
        "product_code": "914.03.62",
        "name": "Filtru Basic 2-4",
        "specifications": {
            "type": VACUUM_TYPES.FILTRU,
            "subtype": "Basic 2-4"
        }
    },
    {
        "id": 119,
        "product_code": "914.04.62",
        "name": "Filtru Display 2",
        "specifications": {
            "type": VACUUM_TYPES.FILTRU,
            "subtype": "Display 2"
        }
    },
    {
        "id": 120,
        "product_code": "914.05.62",
        "name": "Filtru Display 3-4",
        "specifications": {
            "type": VACUUM_TYPES.FILTRU,
            "subtype": "Display 3-4"
        }
    },
    {
        "id": 121,
        "product_code": "914.06.62",
        "name": "Filtru AP",
        "specifications": {
            "type": VACUUM_TYPES.FILTRU,
            "subtype": "AP"
        }
    },
    {
        "id": 122,
        "product_code": "914.09.62",
        "name": "Sac carton AP (5 buc)",
        "specifications": {
            "type": VACUUM_TYPES.SAC,
            "subtype": "carton",
            "variant": "AP"
        }
    },
    {
        "id": 123,
        "product_code": "910.03.62",
        "name": "Furtun",
        "specifications": {
            "type": VACUUM_TYPES.FURTUN
        }
    },
    {
        "id": 124,
        "product_code": "913.03.62",
        "name": "Set izolatie furtun",
        "specifications": {
            "type": VACUUM_TYPES.SET,
            "usage": "izolatie furtun"
        }
    },
    {
        "id": 125,
        "product_code": "913.04.62",
        "name": "Activator",
        "specifications": {
            "type": VACUUM_TYPES.ACTIVATOR
        }
    },
    {
        "id": 126,
        "product_code": "913.05.62",
        "name": "Prelungitor",
        "specifications": {
            "type": VACUUM_TYPES.PRELUNGITOR
        }
    },
    {
        "id": 127,
        "product_code": "915.08.62",
        "name": "Furtun \"Easy\" (12m), cu maner",
        "specifications": {
            "type": VACUUM_TYPES.FURTUN,
            "model": "Easy",
            "length": 12000,
            "feature": "cu maner"
        }
    },
    {
        "id": 128,
        "product_code": "915.09.62",
        "name": "Maner \"Easy\"",
        "specifications": {
            "type": VACUUM_TYPES.MANER,
            "model": "Easy"
        }
    },
    {
        "id": 129,
        "product_code": "915.10.62",
        "name": "Maner \"Easy\" cu activare Wi-Fi (receiver inclus)",
        "specifications": {
            "type": VACUUM_TYPES.MANER,
            "model": "Easy",
            "feature": "cu activare Wi-Fi",
            "receiver": "inclus"
        }
    }
];