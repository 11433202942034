//create product objects that can be imported into other files

import {cloneDeep} from "lodash";
import {DN_VARIANTS, VMC_NON_STANDARD_MEASUREMENTS} from "../VMC/vmcProducts.js";

export const PRODUCTS_COLOR_MAP = new Map(
    [
        ['Produse Etapa 1', '#eaf1dd'],
        ['Produse Etapa 2', '#fde9d9'],
        ['Produse Suplimentare', '#dbe5f1'],
        ['Produse Etapa 3', '#ffffff'],
        ['Produse Principale', '#eaf1dd'],
        ['Produse Dezumidificare', '#ccffcc'],
    ]
)


import {basicUnitSensors, premiumUnitSensors} from "../VMC/vmcData.js";

import {calculateDistributorPermutations, vmcUnits} from "../VMC/vmcData.js";
import store from "../store";
import {VMC_PRODUCT_TYPES} from "../VMC/vmcProducts";

let modularDistributorPossibleCircuits = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

/**
 * Checks if a unit is of a premium type based on its model ID.
 * It considers a unit as premium if its type includes "Premium" or "Enthalpic".
 *
 * @param {string} unitModelId - The model ID of the unit.
 * @returns {boolean} True if the unit is premium, false otherwise.
 */
export function checkIsUnitPremium(unitModelId) {
    const unit = fetchUnitModelData(unitModelId);
    return unit ? unit.type.includes("Premium") || unit.type.includes("Enthalpic") : false;
}

/**
 * Fetches unit model data from a predefined list based on the unit model ID.
 * Assumes that vmcUnits is an accessible array containing unit data.
 *
 * @param {string} unitModelId - The model ID of the unit.
 * @returns {Object} An object containing unit data, or an empty object if not found.
 */
function fetchUnitModelData(unitModelId) {
    let unit = {};

    unit = vmcUnits.find(unit => unit.product_code === unitModelId);

    return unit;
}

const DEHUMIDIFICATION_PRODUCT_REQUIREMENTS = {
    STANDARD: [
        {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "umiditate",
            "variant": "doza",
            "quantity": 1,
        },
        {
            "type": VMC_PRODUCT_TYPES.RACORDURI,
            "subtype": "pompa",
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 1,
        },
        {
            "type": VMC_PRODUCT_TYPES.BANDA,
            "subtype": "anticondens",
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 1,
        },
        {
            "type": VMC_PRODUCT_TYPES.TRECERE,
            "subtype": "FE",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
            "variant": [25, 26],
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 4,
        },
        {
            "type": VMC_PRODUCT_TYPES.REDUCTIE,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 2,
        },
        {
            "type": VMC_PRODUCT_TYPES.TEU,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 2,
        },
        {
            "type": VMC_PRODUCT_TYPES.NIPLU,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 3,
        },
        {
            "type": VMC_PRODUCT_TYPES.OLANDEZ,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 1,
        },
        {
            "type": VMC_PRODUCT_TYPES.TEAVA,
            "subtype": "pex-al-pex",
            "diameter": 26,
            "length": 2000,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 15,
        },
        {
            "type": VMC_PRODUCT_TYPES.TRECERE,
            "subtype": "FE",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
            "variant": [16],
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 6,
        },
        {
            "type": VMC_PRODUCT_TYPES.ROBINET,
            "subtype": "trecere",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 2,
        },
        {
            "type": VMC_PRODUCT_TYPES.COT,
            "subtype": "alama",
            "diameter": VMC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 5,
        },
        {
            "type": VMC_PRODUCT_TYPES.TEAVA,
            "subtype": "pex-al-pex",
            "diameter": 16,
            "length": 2000,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 1,
        },
        {
            "type": VMC_PRODUCT_TYPES.INEL,
            "subtype": "sertizare",
            "diameter": 26,
            "condition": (unit) => unit.dehumidification_columns === true,
            "quantity": 4,
        },
        {
            "type": VMC_PRODUCT_TYPES.GRUP,
            "subtype": "pompare",
            "variant": "25-60",
            "condition": (unit) => unit.pumping_group === true,
            "quantity": 1,
        },
        {
            "type": VMC_PRODUCT_TYPES.POMPA,
            "subtype": "sistema",
            "variant": "25-60",
            "condition": (unit) => unit.recycle_pump === true,
            "quantity": 1,
        },
        {
            "type": VMC_PRODUCT_TYPES.TABLOU,
            "subtype": "automatizare",
            "quantity": 1,
        },
    ],
}

/**
 * Creates dehumidification requirements for each unit in the location.
 * Adds a dehumidifier and related products based on the dehumidification option, unit capacity,
 * and any conditional requirements (e.g., unit.dehumidification_columns).
 *
 * Products with specific conditions will only be added if the conditions are met (e.g., certain products
 * are added only when unit.dehumidification_columns is true).
 *
 * @param {Object} location - The location object containing units.
 * @returns {Array} unitDehumidificationRequirements - The list of dehumidification requirements for all units.
 */
export function createDehumidificationRequirements(location) {
    let unitDehumidificationRequirements = [];

    // Helper function to add dehumidifier and products based on the option
    const addDehumidifierAndProducts = (unit, productRequirements) => {
        let dehumidifierRequirement = {
            "type": VMC_PRODUCT_TYPES.DEZUMIDIFICATOR,
            "subtype": "ductibil",
            "capacity": unit.model.maxAirVolume > 400 ? 400 : 200,
            "quantity": 1,
        };
        unitDehumidificationRequirements.push(cloneDeep(dehumidifierRequirement));

        productRequirements.forEach(product => {
            // Clone the product to avoid modifying the original
            let productCopy = cloneDeep(product);

            // Check if the product has a condition and if it evaluates to true
            if (!productCopy.condition || productCopy.condition(unit)) {
                // Remove the condition property if it exists
                delete productCopy.condition;

                // Add the product to the requirements
                unitDehumidificationRequirements.push(productCopy);
            }
        });
    };

    location.units.forEach(unit => {
        if (unit.dehumidification) {
            switch (unit.dehumidification_option) {
                case 'standard':
                    addDehumidifierAndProducts(unit, DEHUMIDIFICATION_PRODUCT_REQUIREMENTS.STANDARD);
                    break;
            }
        }
    });

    return unitDehumidificationRequirements;
}

/**
 * Creates a list of requirements for units based on their specific characteristics and configurations.
 * It includes various items like pipes, grates, kits, connectors, sensors, controllers, and heaters,
 * each tailored to the specific unit and model. It also considers the user's selection of tubing type
 * and diameter to determine the appropriate requirements.
 *
 * @param {Object} location - The location object containing units and their details.
 * @returns {Array} An array of unit requirements, each including type, subtype, diameter, quantity, etc.
 */
export function createUnitsRequirements(location) {
    let unitRequirements = [];

    let unitModelData;

    // 1 bucata/UNITATE
    let antiphonPipe = {
        "type": VMC_PRODUCT_TYPES.TUB,
        "subtype": "antifonic",
        "diameter": DN_VARIANTS.DN125,
    };

    // daca aleg tub EPS de distributie si in fct de diametru . 2buc per unitate
    let terminalGrate = {
        "type": VMC_PRODUCT_TYPES.GRATE,
        "subtype": "exterior",
        "diameter": DN_VARIANTS.DN160,
        "quantity": 2
    }

    // in fct de dim si tip(se potriveste doar pe tubulatura flexibila) tubulaturii de distributie. 2 buc per unitate
    let kitWallTerminal = {
        "type": VMC_PRODUCT_TYPES.KIT,
        "subtype": "exterior",
        "diameter": DN_VARIANTS.DN125,
        "quantity": 2,
    };

    // daca selectez tubulatura de distributie flexibila adaug o rola
    let aluminumStrip = {
        "type": "banda",
        "subtype": "aluminiu",
    };

    let pvcConnector = {
        "type": VMC_PRODUCT_TYPES.CONECTOR,
        "subtype": "PVC",
        "diameter": DN_VARIANTS.DN125,
    };

    let generalReduction = {
        "type": VMC_PRODUCT_TYPES.REDUCTIE,
        "subtype": "general",
        "quantity": 4,
    };

    let metallicBrace = {
        "type": VMC_PRODUCT_TYPES.COLIER,
        "subtype": "metalic",
        "diameter": DN_VARIANTS.DN125,
        "quantity": 8,
    };

    let epsElbow = {
        "type": VMC_PRODUCT_TYPES.COT,
        "subtype": "EPS",
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
        "quantity": 8,
    };

    let epdmConnector = {
        "type": VMC_PRODUCT_TYPES.MUFA,
        "subtype": "EPDM",
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
    };

    let unitSensor = {
        "type": VMC_PRODUCT_TYPES.SENSOR,
        "subtype": "",
    };

    let tsDisplay = {
        "type": VMC_PRODUCT_TYPES.CONTROLLER,
        "subtype": "display",
        "version": "TS",
    };

    let electricHeater = {
        "type": VMC_PRODUCT_TYPES.HEATER,
        "subtype": "electric",
        "version": DN_VARIANTS.DN125,
    };

    let EPSTubing = {
        "type": VMC_PRODUCT_TYPES.TUBULATURA,
        "subtype": 1,
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
        "quantity": 10,
    }

    // 1 cutie de dibluri per unitate
    let beatingDowel = {
        "type": VMC_PRODUCT_TYPES.DIBLU,
        "subtype": "bataie",
    };

    // Below we will add unit mounts for walls (for the moment) depending on the unit's model
    let verticalWallMount = {
        "type": VMC_PRODUCT_TYPES.MOUNT,
        "subtype": "wall",
        "variant": "vertical"
    };

    let horizontalWallMount = {
        "type": VMC_PRODUCT_TYPES.MOUNT,
        "subtype": "wall",
        "variant": "horizontal",
    };

    let AKRGasket = {
        "type": VMC_PRODUCT_TYPES.GASKET,
        "subtype": "AKR",
        "quantity": 4
    }

    let pvcReduction200 = {
        "type": VMC_PRODUCT_TYPES.REDUCTIE,
        "subtype": "PVC",
        "variant": 200,
        "quantity": 4,
    }

    const unitsExcludedFromGeneralReduction = ['550v +', '550v ++']

    location.units.forEach((unit) => {
        let isUnitPremium = checkIsUnitPremium(unit.model_id);
        if (isUnitPremium) {
            unitRequirements.push(tsDisplay);
        }

        // Here we generate unit requirement that depend on the actual model's properties
        unitModelData = fetchUnitModelData(unit.model_id);
        if (unitModelData) {

            if (unitModelData.type.includes('Premium')) {
                unitRequirements.push(verticalWallMount)
            } else if (unitModelData.type.includes('Home')) {
                unitRequirements.push(horizontalWallMount);
            }
        }
        if (unit.tubing === 2) {
            switch (unit.tubing_diameter) {
                case 125:
                    antiphonPipe.diameter = DN_VARIANTS.DN125;
                    kitWallTerminal.diameter = DN_VARIANTS.DN125;

                    // Here we need to add 8 metallic braces for DN125 and 8 for DN150
                    metallicBrace.diameter = DN_VARIANTS.DN125;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))
                    metallicBrace.diameter = DN_VARIANTS.DN150;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))

                    if (!isUnitPremium) {
                        unitRequirements.push(electricHeater);
                    }

                    if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (unit.model.version.includes('200f') || unit.model.version.includes('200f +'))) {
                        // if the unit version is 200f or 200f + we need to add general reductions
                        // 809.52.32 not PVC reductions and 4 PVC connectors
                        unitRequirements.push(generalReduction)

                        pvcConnector.quantity = 4;
                        unitRequirements.push(pvcConnector)

                        // here we need to add 1 EPDM connector for each general reduction
                        unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                    }
                    break;
                case 160:
                    antiphonPipe.diameter = DN_VARIANTS.DN160;
                    kitWallTerminal.diameter = DN_VARIANTS.DN160;
                    // Here we need to add 8 metallic braces for DN150 and 8 for DN160
                    metallicBrace.diameter = DN_VARIANTS.DN160;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))
                    metallicBrace.diameter = DN_VARIANTS.DN150;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))

                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN160;
                        unitRequirements.push(electricHeater);
                    }

                    if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (!unitsExcludedFromGeneralReduction.includes(unit.model.version) && !unitsExcludedFromGeneralReduction.includes(unit.model.version))) {
                        if (unit.model.version.includes('200f') || unit.model.version.includes('200f +')) {
                            // if the unit version is 200f or 200f + we need to add general reductions
                            // 809.52.32 not PVC reductions and 4 PVC connectors
                            unitRequirements.push(generalReduction)

                            pvcConnector.quantity = 4;
                            unitRequirements.push(pvcConnector)

                            // here we need to add 1 EPDM connector for each general reduction
                            unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                        } else {
                            // else we just add pvc reductions
                            unitRequirements.push(pvcReduction200);
                        }
                    }
                    break;
            }
            unitRequirements.push(aluminumStrip);
            unitRequirements.push(antiphonPipe);
            unitRequirements.push(kitWallTerminal);
        } else if (unit.tubing === 1) {
            switch (unit.tubing_diameter) {
                case 125:
                    unitRequirements.push(epsElbow);
                    epdmConnector.diameter = DN_VARIANTS.DN125;

                    // If the selected tubing = EPS and the selected diameter = 125 we need
                    // to push a wall terminal kit since there are no grates available for that tubing size
                    unitRequirements.push(kitWallTerminal);
                    // here we need to add 3 EPDM connector for each 2 EPS elbows
                    unitRequirements = pushBasedOnReference(epsElbow, epdmConnector, unitRequirements, epdmPushLogic);
                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN125;
                        unitRequirements.push(electricHeater);
                    }

                    if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (!unitsExcludedFromGeneralReduction.includes(unit.model.version) && !unitsExcludedFromGeneralReduction.includes(unit.model.version))) {
                        unitRequirements.push(generalReduction);

                        // here we need to add 1 EPDM connector for each general reduction
                        unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                    }
                    unitRequirements.push(EPSTubing);
                    epdmConnector.diameter = EPSTubing.diameter;
                    // here we need to add 1 EPDM connector for each EPS tubing
                    unitRequirements = pushBasedOnReference(EPSTubing, epdmConnector, unitRequirements, epdmPushLogic);
                    break;
                case 160:
                    epsElbow.diameter = DN_VARIANTS.DN160;
                    epdmConnector.diameter = DN_VARIANTS.DN160;
                    unitRequirements.push(epsElbow);
                    unitRequirements.push(terminalGrate);

                    // here we need to add 3 EPDM connector for each 2 EPS elbows
                    unitRequirements = pushBasedOnReference(epsElbow, epdmConnector, unitRequirements, epdmPushLogic);
                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN160;
                        unitRequirements.push(electricHeater);
                    }

                    EPSTubing.diameter = DN_VARIANTS.DN160;
                    unitRequirements.push(EPSTubing);
                    epdmConnector.diameter = EPSTubing.diameter;
                    // here we need to add 1 EPDM connector for each EPS tubing
                    unitRequirements = pushBasedOnReference(EPSTubing, epdmConnector, unitRequirements, epdmPushLogic);

                    // here we check if the outlet diameter is smaller than the tubing diameter we need to add AKR gaskets
                    // otherwise we add general reductions
                    if (unit.model && unit.model.outletTubingDiameter < unit.tubing_diameter && (unit.model.version.includes('400A') || unit.model.version.includes('550A'))) {
                        unitRequirements.push(AKRGasket);
                    } else if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (!unitsExcludedFromGeneralReduction.includes(unit.model.version) && !unitsExcludedFromGeneralReduction.includes(unit.model.version))) {
                        unitRequirements.push(generalReduction);

                        // here we need to add 1 EPDM connector for each general reduction
                        unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                    }
                    break;
                case 200:
                    epsElbow.diameter = DN_VARIANTS.DN200;
                    terminalGrate.diameter = DN_VARIANTS.DN200;
                    epsElbow.variant = "normal";
                    unitRequirements.push(terminalGrate);
                    unitRequirements.push(epsElbow);
                    epdmConnector.diameter = DN_VARIANTS.DN200;
                    epdmConnector.variant = "normal";
                    // here we need to add 3 EPDM connector for each 2 EPS elbows
                    unitRequirements = pushBasedOnReference(epsElbow, epdmConnector, unitRequirements, epdmPushLogic);
                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN200;
                        unitRequirements.push(electricHeater);
                    }
                    EPSTubing.diameter = DN_VARIANTS.DN200;
                    // This has been changed to avoid slim EPS tubing for a while until stocks are depleted
                    EPSTubing.variant = "normal";
                    unitRequirements.push(EPSTubing);
                    epdmConnector.diameter = EPSTubing.diameter;
                    // here we need to add 1 EPDM connector for each EPS tubing
                    unitRequirements = pushBasedOnReference(EPSTubing, epdmConnector, unitRequirements, epdmPushLogic);
                    break;
            }
        }

        // Here we add the unit's sensors depending
        // on their type and the unit's tubing type and diameter
        // Expansion module is also being added if one or more of a premium unit's sensors are selected
        let expansionModule = {
            "type": VMC_PRODUCT_TYPES.MODUL,
            "subtype": "expansiune",
        };

        // If water faucet is found in the selectedSensors of the unit, we need to also add a temperature sensor
        let tempSensor = {
            "type": VMC_PRODUCT_TYPES.SENSOR,
            "subtype": "temperatura",
        };

        let conditionedUnitFilterRecommendation = {
            "isRecommendedProduct": true,
            "subtype": "standard",
            "type": VMC_PRODUCT_TYPES.FILTER,
            "variant": "G4",
            "versions": [],
            "quantity": 1,
        }

        let conditionedUnitFilterProduct = {
            "subtype": "standard",
            "type": VMC_PRODUCT_TYPES.FILTER,
            "variant": "G4",
            "versions": [],
            "quantity": 1,
        }

        // if no sensors have been selected the appropriate filter for the mentioned units needs to be added to the
        // recommended products
        const isVersionEligible = (version) => {
            const eligibleVersions = ['180A', '280A', '230E', '280E'];
            return eligibleVersions.includes(version);
        };

        if (unit.model && unit.model.version) {
            const filterNotSelected = !unit.selectedSensors.includes('Filtre rezervă');
            const eligibleForFilter = isVersionEligible(unit.model.version);

            if (filterNotSelected && eligibleForFilter) {
                if (!conditionedUnitFilterRecommendation.versions.includes(unit.model.version)) {
                    conditionedUnitFilterRecommendation.versions.push(unit.model.version);
                }
                unitRequirements.push(conditionedUnitFilterRecommendation);
            }
        }
        unit.selectedSensors.forEach(sensor => {
            let unitSensorItem = JSON.parse(JSON.stringify(unitSensor));
            let isExpansionModuleNeeded = getSensorByName([...basicUnitSensors, ...premiumUnitSensors], sensor)
            unitSensorItem.subtype = sensor;

            // if the unit is standard we need to remove the cooling battery command from the unit's requirements
            let coolingBatteryCommandRemove = {
                "type": VMC_PRODUCT_TYPES.COMMAND,
                "subtype": "cooling",
                "quantity": -1,
            }

            switch (unit.tubing_diameter) {
                case 125:
                    if (sensor === 'Baterie apă') {
                        unitSensorItem.diameter = DN_VARIANTS.DN125;

                        // if the unit is standard we need to remove the cooling battery command from the unit's
                        // requirements and not push the temperature sensor
                        if (isUnitPremium) {
                            unitRequirements.push(tempSensor);
                        } else {
                            unitRequirements.push(coolingBatteryCommandRemove);
                        }
                    }
                    break;
                case 160:
                    if (sensor === 'Baterie apă') {
                        unitSensorItem.diameter = DN_VARIANTS.DN160;

                        // if the unit is standard we need to remove the cooling battery command from the unit's
                        // requirements and not push the temperature sensor
                        if (isUnitPremium) {
                            unitRequirements.push(tempSensor);
                        } else {
                            unitRequirements.push(coolingBatteryCommandRemove);
                        }
                    }
                    break;
                case 200:
                    if (sensor === 'Baterie apă') {
                        unitSensorItem.diameter = DN_VARIANTS.DN200;

                        // if the unit is standard we need to remove the cooling battery command from the unit's
                        // requirements and not push the temperature sensor
                        if (isUnitPremium) {
                            unitRequirements.push(tempSensor);
                        } else {
                            unitRequirements.push(coolingBatteryCommandRemove);
                        }
                    }
                    break;
            }

            //Do not push if sensor is Jonix purifier
            if (sensor !== "Purificator Jonix") {
                unitRequirements.push(unitSensorItem);
            }

            // Logic for adding Jonix purifier
            // If Purificator Jonix is found in the selectedSensors array we need to check the unit's totalAirVolume and
            // push the corresponding product (if < 500m3 = smaller version else = bigger version)
            let jonixPurifier = {
                "type": VMC_PRODUCT_TYPES.SENSOR,
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            }

            let jonixPurifierMountingBox = {
                "type": VMC_PRODUCT_TYPES.MOUNTING,
                "subtype": "purifier",
                "quantity": 1,
            }

            if (sensor === 'Purificator Jonix') {

                // Adding a mounting box for the Jonix Purifier is necessary when the first (good air) distributor of
                // a unit is metallic, and it's size is 4 or when the distributor is modular regardless of it's size.
                if (unit.distributors[0] && unit.distributors[0].type === 1 && unit.distributors[0].size === 4 ||
                    unit.distributors[0] && unit.distributors[0].type === 2 ||
                    unit.distribution_diameter !== 75) {
                    unitRequirements.push(jonixPurifierMountingBox);
                }

                if (unit.totalAirVolume < 500) {
                    unitRequirements.push(JSON.parse(JSON.stringify(jonixPurifier)));
                } else if (unit.totalAirVolume > 500) {
                    jonixPurifier.volume = 1000;
                    unitRequirements.push(JSON.stringify(jonixPurifier));
                }
            }

            let unitFilter = {
                "type": VMC_PRODUCT_TYPES.FILTER,
                "subtype": "standard",
                "versions": [unit.model.version],
                "quantity": 1,
            };

            let boxForF7Filters = {
                "subtype": "filter",
                "type": VMC_PRODUCT_TYPES.BOX,
                "variant": "F7",
                "quantity": 1
            };

            if (sensor === 'Filtre rezervă') {
                if (isUnitPremium && !unit.model.type.includes('Home')) {
                    unitFilter.subtype = "premium";
                    unitFilter.variant = "M5";

                    unitRequirements.push(unitFilter);
                } else if (isUnitPremium && unit.model.type.includes('Home')) {
                    unitFilter.variant = "M5";
                    unitFilter.subtype = "home";

                    unitRequirements.push(unitFilter);
                } else if (unit.model && unit.model.version.includes('400A') || unit.model.version.includes('590E') || unit.model.version.includes('550A')) {
                    // here we handle the special case of 400A and 590E unit versions that only have the F7 filter
                    // compatible and we need to add it without it's mounting box
                    conditionedUnitFilterProduct.versions = [unit.model.version];
                    conditionedUnitFilterProduct.variant = "F7";

                    let removeBoxForF7Filter = {
                        "type": VMC_PRODUCT_TYPES.BOX,
                        "subtype": "filter",
                        "variant": "F7",
                        "quantity": -1,
                    }

                    unitRequirements.push(JSON.parse(JSON.stringify(conditionedUnitFilterProduct)));
                    conditionedUnitFilterProduct.variant = "G4";
                    unitRequirements.push(JSON.parse(JSON.stringify(conditionedUnitFilterProduct)));
                    unitRequirements.push(removeBoxForF7Filter);
                }
            } else if (sensor === 'Filtre rezervă G4' || sensor === 'Filtre rezervă F7') {
                // here we check the conditions for the F7 or G4 filter depending on the user's choice and on the unit
                // he has selected

                if (sensor === 'Filtre rezervă G4') {
                    unitFilter.subtype = "standard";
                    unitFilter.variant = "G4";
                    unitRequirements.push(unitFilter);

                    if (conditionedUnitFilterRecommendation.versions.includes(unit.model.version)) {
                        conditionedUnitFilterRecommendation.variant = "F7";
                        conditionedUnitFilterRecommendation.versions.push(unit.model.version);

                        unitRequirements.push(conditionedUnitFilterRecommendation);
                        // unitRequirements.push(boxForF7Filters);
                    }
                }
                if (sensor === 'Filtre rezervă F7') {
                    conditionedUnitFilterProduct.versions = [unit.model.version];
                    conditionedUnitFilterProduct.variant = "F7";

                    unitRequirements.push(conditionedUnitFilterProduct);
                    unitRequirements.push(boxForF7Filters);
                }
            }
            if (sensor === 'Dispenser parfum') {
                // If the perfume dispenser is added as a sensor then we need to add a perfume by default
                let defaultPerfume = {
                    "type": VMC_PRODUCT_TYPES.PERFUME,
                    "subtype": "boreal",
                    "variant": 120,
                    "quantity": 1,
                }
                unitRequirements.push(defaultPerfume);

            }

            //If a sensor has the expansion property true we push an expansion module in the unit requirements
            if (isExpansionModuleNeeded && !unitRequirements.includes(expansionModule)) {
                unitRequirements.push(expansionModule);
            }

        });

        if (unit.model && unit.model.version) {
            // O cutie de dibluri per unitate (200buc in cutie)
            unitRequirements.push(beatingDowel);
        }
    });

    let unitMounts = addUnitMountingProduct(location);
    if (unitMounts && unitMounts.length > 0) {
        unitRequirements.push(...unitMounts);
    }
    return unitRequirements;
}

/**
 * Adds the required unit mounts based on the unit's model type and selected mounting option.
 * The mounting options and variants differ based on whether the unit is mounted on a wall (PERETE) or ceiling (TAVAN),
 * and the type of the unit (e.g., Premium V, Premium H, Premium Home, etc.).
 *
 * @param {Object} location - The location object containing units with model and mounting information.
 * @param {Array} location.units - Array of unit objects.
 * @param {Object} location.units[].model - The model object of the unit containing the unit type (e.g., Premium V, Premium Home).
 * @param {string} location.units[].model.type - The type of the unit (e.g., 'Premium V', 'Premium Home').
 * @param {number} location.units[].selected_mounting - The mounting type: 2 for wall (PERETE), 1 for ceiling (TAVAN).
 *
 * @returns {Array} resultedUnitMounts - An array of mount objects that specifies the type, subtype, and variant for mounting.
 */
export function addUnitMountingProduct(location) {
    let resultedUnitMounts = [];

    location.units.forEach(unit => {
        let unitMount = {
            "type": VMC_PRODUCT_TYPES.MOUNT,
            "subtype": "wall",
            "variant": "premium"
        };

        if (unit.model) {
            switch (unit.selected_mounting) {
                case 2: // PERETE
                    if (unit.model.type === 'Premium V' || unit.model.type === 'Enthalpic V' || unit.model.type === 'Premium V ++') {
                        unitMount.variant = "Premium v";
                        resultedUnitMounts.push(unitMount);
                    } else if (unit.model.type === 'Premium H' || unit.model.type === 'Premium H ++' || unit.model.type === 'Enthalpic H') {
                        unitMount.variant = "Premium v";
                        resultedUnitMounts.push(unitMount);
                    } else if (unit.model.type === 'Premium Home' || unit.model.type === 'Premium Home +') {
                        unitMount.variant = "horizontal";
                        resultedUnitMounts.push(unitMount);
                    }
                    break;
                case 1: // TAVAN
                    if (unit.model.type === 'Premium H' || unit.model.type === 'Premium H ++' || unit.model.type === 'Enthalpic H') {
                        unitMount.subtype = 'wall';
                        unitMount.variant = "Premium v";
                        resultedUnitMounts.push(unitMount);
                    } else if (unit.model.type === 'Premium Home' || unit.model.type === 'Premium Home +') {
                        unitMount.subtype = "ceiling";
                        unitMount.variant = "horizontal";
                        resultedUnitMounts.push(unitMount);
                    }
                    break;
            }
        }

    });

    return resultedUnitMounts;
}

/**
 * This function checks if a reference object matches any of the objects in an array.
 * If a match is found, it adds a specified object to the array, the number of times being determined by the `pushLogic` function.
 * The `pushLogic` function defines specific logic for determining the number of objects to be added, based on attributes of the `referenceObj`.
 * The `quantity` property is ignored during the comparison.
 *
 * @param {Object} referenceObj - The reference object used to check for a match.
 * @param {Object} objToPush - The object to be added to the array.
 * @param {Array} arr - The array into which the object should be added.
 * @param {Function} pushLogic - The function that determines the number of times the object is added to the array, based on the `referenceObj`.
 * @returns {Array} - Returns the modified array.
 */

function pushBasedOnReference(referenceObj, objToPush, arr, pushLogic) {
    if (matchesReference(referenceObj, arr)) {
        // Determine how many times to push based on the logic provided
        let timesToPush = pushLogic(referenceObj);

        for (let i = 0; i < timesToPush; i++) {
            let newObj = JSON.parse(JSON.stringify(objToPush));
            arr.push(newObj);
        }
    }
    return arr;
}

/**
 * Determines the number of EPDM connectors required based on the type of product in the reference object.
 * The number of connectors depends on the type of component, such as tubing, reduction, tee, or elbow (cot).
 *
 * @param {Object} referenceObj - The object representing the product for which EPDM connectors are required.
 * @param {string} referenceObj.type - The type of product (e.g., TUBULATURA, REDUCTIE, TEU, COT).
 * @param {number} referenceObj.quantity - The quantity of the product for which connectors are needed.
 *
 * @returns {number} - The number of EPDM connectors needed for the product based on its type and quantity.
 */
function epdmPushLogic(referenceObj) {
    switch (referenceObj.type) {
        case VMC_PRODUCT_TYPES.TUBULATURA:
            return 1 * referenceObj.quantity; // 1 connector for each tubing
        case VMC_PRODUCT_TYPES.REDUCTIE:
            return 1 * referenceObj.quantity; // 1 connector for each reduction
        case VMC_PRODUCT_TYPES.TEU:
            return 3 * referenceObj.quantity; // 3 connectors for each tee
        case VMC_PRODUCT_TYPES.COT:
            return Math.ceil(referenceObj.quantity / 2) * 3; // 3 connectors for every 2 elbows
        default:
            return 0;
    }
}

// This method looks for an item in an array of items based on the item's name and if an item is found it returns
// that item's expansion property value
function getSensorByName(items, sensorName) {
    // Find the element in the array whose name matches the item's name
    const foundItem = items.find(element => element.name === sensorName);

    // If the item is found, return its expansion property; otherwise, return null
    return foundItem ? foundItem.expansion : null;
}

/**
 * Verifică dacă un obiect de referință corespunde cu oricare dintre obiectele dintr-un array.
 * Proprietatea `quantity` este ignorată în timpul comparației.
 *
 * @param {Object} referenceObj - Obiectul de referință folosit pentru a verifica egalitatea.
 * @param {Array} arr - Array-ul în care se verifică egalitatea.
 * @returns {Boolean} - Returnează true dacă găsește o potrivire, altfel false.
 */
function matchesReference(referenceObj, arr) {
    // Check if arr is an array
    if (!Array.isArray(arr)) {
        console.error('arr is not an array:', arr);
        return false;
    }
    return arr.some(item => {
        for (let key in referenceObj) {
            if (key === 'quantity') continue;
            if (referenceObj[key] !== item[key]) {
                return false;
            }
        }
        return true;
    });
}

/**
 * Generates the requirements for plenums based on the configuration of rooms and plenums in the given location.
 * It creates a list of plenum-related products, adjusting size and subtype as necessary, and adds plugs based on the distribution diameter.
 *
 * @param {Object} location - The location object containing rooms and units information.
 * @param {Array} location.rooms - An array of rooms within the location, each containing plenums.
 * @param {Array} location.units - An array of units, where each unit holds distribution diameter information.
 *
 * @returns {Array} plenumRequirements - An array of objects representing the plenum-related product requirements.
 */
export function createPlenumsRequirements(location) {
    let plenumRequirements = [];

    location.rooms.forEach((room) => {

        let plugRequirement = {
            "type": VMC_PRODUCT_TYPES.DOP,
            "quantity": 1, // Default quantity
            "distribution_diameter": DN_VARIANTS.DN_OVAL
        };

        room.plenums.forEach((plenum) => {
            let modularGrate = {
                "type": VMC_PRODUCT_TYPES.GRATE,
                "subtype": "modular",
                "quantity": 1,
            };
            let plenumData = {
                type: VMC_PRODUCT_TYPES.PLENUM,
                subtype: plenum.type,
                size: plenum.size,
            };

            // If the plenum is dublu tavan we need to set the size to 1 in order to match the product's
            // specifications as there is only one product for both sizes (1 and 2)
            const typesToSetSizeOne = [6, 7];
            const typesToSetSizeTwo = [9, 10];
            if (typesToSetSizeOne.includes(plenum.type)) {
                plenumData.size = 1;
            } else if (typesToSetSizeTwo.includes(plenum.type) && plenum.size === 1) {
                plenumData.size = 2;
            }

            const typesToDefineCustomGrates = [6, 7, 1, 8, 9, 10];
            if (typesToDefineCustomGrates.includes(plenum.type)) {
                if (plenum.grate === "Difuzor rotund") {
                    modularGrate.subtype = "diffuser";
                    modularGrate.variant = "round";
                } else if (plenum.grate === "Difuzor patrăt") {
                    modularGrate.subtype = "diffuser";
                    modularGrate.variant = "square";
                } else if (plenum.grate === "Design") {
                    modularGrate.subtype = "design";
                    modularGrate.version = DN_VARIANTS.DN100;
                }
                plenumRequirements.push(modularGrate);
            }

            plenumRequirements.push(plenumData);
        });

        // Condition: If distribution_diameter is 117, count plenums of size 1 and adjust plug quantity
        if (location.units[0].distribution_diameter === 117 || location.units[0].distribution_diameter === 75) {
            const sizeOnePlenumsCount = room.plenums.filter(plenum => plenum.size === 1).length;
            if (sizeOnePlenumsCount > 0) {
                plugRequirement.quantity = sizeOnePlenumsCount; // Set the quantity to the number of size 1 plenums
                plugRequirement.distribution_diameter = location.units[0].distribution_diameter === 117 ? DN_VARIANTS.DN_OVAL : DN_VARIANTS.DN75;
                plenumRequirements.push(cloneDeep(plugRequirement)); // Add DOP
            }
        }
    });


    return plenumRequirements;
}

/**
 * Calculates the total circuit length and determines the number of flexible tubing pipes required based on circuit lengths.
 * It uses 50m pipes by default and computes the total number of circuits and pipes needed for the given location.
 *
 * @param {Object} location - The location object containing rooms and plenums information.
 * @param {Array} location.rooms - An array of rooms within the location, each containing plenums and circuit information.
 *
 * @returns {Object} - An object containing the total number of circuits and the required number of 50m tubing pipes.
 * @property {Object} pipe - Contains the number of 50m pipes required for the total circuit length.
 * @property {number} pipe.long - The number of 50m tubing pipes required.
 * @property {number} totalCircuits - The total number of circuits in the location.
 */
export function calculateNeededTubesForCircuits(location) {
    let locationTotalCircuitLength = 0;
    let totalCircuits = 0;
    location.rooms.forEach((room) => {
        totalCircuits += room.circuit_no_VMC;
        room.plenums.forEach((plenum) => {
            let plenumTotalCircuitLength = plenum.circuit_length * plenum.size;
            locationTotalCircuitLength += plenumTotalCircuitLength;
        });
    });

    // suma lungime circuite (prioritate colac de  50 m si colac de 25 de
    // metri daca restul impartirii la 50 este mai micsau egal cu  25)
    // Calcul pentru totalul circuitelor si numarul de tubulaturi flexibile de 25 si 50 metri necesare

    let quotient = Math.ceil(locationTotalCircuitLength / 50); // Using Math.floor to get the integer quotient

    return {
        pipe: {
            "long": quotient,
        },
        totalCircuits: totalCircuits
    };
}

/**
 * Creates a list of circuit tube requirements based on the location's circuit data and distribution diameter.
 * It calculates the required number of tubing pipes, perforated bands, O-ring kits, and cots for the circuits.
 *
 * @param {Object} location - The location object containing unit and room data.
 * @param {Array} location.units - An array of units in the location, each containing tubing and distributor information.
 * @param {Array} location.rooms - An array of rooms in the location, each containing plenums and circuit information.
 *
 * @returns {Array} - An array of requirements for tubing pipes, O-ring kits, and other circuit-related products.
 */
export function createCircuitTubesRequirements(location) {
    let circuitsResults = calculateNeededTubesForCircuits(location);
    let circuitTubesRequirements = [];
    let distribution_diameter = findMatchingDNVariant(location.units[0].distribution_diameter)

    let pipeRequirementLong = {
        "type": VMC_PRODUCT_TYPES.TUBULATURA,
        "distribution_diameter": distribution_diameter
    };

    let perforatedBandRequirement = {
        "type": "banda",
        "subtype": "perforata",
    };

    let oRingKit = {
        "type": VMC_PRODUCT_TYPES.KIT,
        "subtype": "o-ring",
        // Initially set quantity to 1, but this will be updated below
        "quantity": 1,
        "distribution_diameter": distribution_diameter
    }

    let verticalCotRequirement = {
        "type": VMC_PRODUCT_TYPES.COT,
        "layout": "vertical",
        "quantity": 2,
        "distribution_diameter": distribution_diameter,
    }

    let horizontalCotRequirement = {
        "type": VMC_PRODUCT_TYPES.COT,
        "layout": "horizontal",
        "quantity": 2,
        "distribution_diameter": distribution_diameter,
    }

    for (let i = 0; i < circuitsResults.pipe.long; i++) {
        circuitTubesRequirements.push({...pipeRequirementLong, length: 50}); // Clone and update length
        circuitTubesRequirements.push({...perforatedBandRequirement}); // Clone the object to avoid mutation
    }

    if (distribution_diameter === DN_VARIANTS.DN_OVAL) {
        for (let i = 0; i < circuitsResults.totalCircuits; i++) {
            circuitTubesRequirements.push({...verticalCotRequirement});
            circuitTubesRequirements.push({...horizontalCotRequirement});
        }
    }

    // Calculate the total number of oRingKits needed for the circuits
    // Each circuit needs 4 o-rings, and each kit contains 10 o-rings, oval orings do not come in kits

    let totalORingsNeeded = circuitsResults.totalCircuits * 4;
    let oRingQtyPerKit = 10;
    if (distribution_diameter === DN_VARIANTS.DN_OVAL || distribution_diameter === DN_VARIANTS.DN90) {
        oRingQtyPerKit = 1;
    }
    let oRingKitsNeeded = Math.ceil(totalORingsNeeded / oRingQtyPerKit); // Round up to ensure we have enough o-rings

    // Update the oRingKit quantity and add to the requirements
    oRingKit = {...oRingKit, quantity: oRingKitsNeeded}; // Update quantity
    circuitTubesRequirements.push(oRingKit);

    return circuitTubesRequirements;
}

/**
 * This method calculates the necessary distributors and their sizes based on the distributor's type and the unit's selected tubing diameter.
 * It updates each distributor's size within the `location` object, and it can push warnings or errors to the store if needed.
 *
 * @param {Object} location - The location object containing unit and distributor information.
 * @param {Array} location.units - An array of units, each containing tubing, distributor, and distribution diameter information.
 * @param {Array} location.units.distributors - An array of distributors associated with the unit.
 * @param {number} location.units.distributors.circuitNo - The number of circuits for a distributor.
 * @param {number} location.units.tubing_diameter - The selected tubing diameter for the unit.
 * @param {number} location.units.distribution_diameter - The selected distribution diameter for the unit (e.g., 75, 90, 117).
 *
 * @returns {void} - The function does not return a value. Instead, it modifies the `location` object by updating the size of each distributor.
 */
export function calculateNeededDistributors(location) {
    location.units.forEach((unit) => {
        unit.distributors.forEach((distributor) => {
            let possibleCircuits = [];

            if (unit.distribution_diameter === 75) {
                if (distributor.type === 1) { // Metallic distributors
                    if (unit.tubing_diameter === 125) {
                        possibleCircuits = [4, 6, 10];
                    } else if (unit.tubing_diameter === 160) {
                        possibleCircuits = [10, 15];
                    } else {
                        store.commit('pushVmcError', {
                            type: 'error',
                            text: 'Pentru selectia curenta de tubulatura nu exista distribuitoare metalice compatibile, va rugam ajustati manual'
                        });
                        distributor.size = 0;
                        return;
                    }
                } else if (distributor.type === 2) { // Modular distributors
                    possibleCircuits = modularDistributorPossibleCircuits;
                }
            } else if (unit.distribution_diameter === 90) {
                possibleCircuits = [5, 6, 8];
            } else if (unit.distribution_diameter === 117) {
                possibleCircuits = [8];
            } else {
                // Default case if no valid distribution_tubing is found
                possibleCircuits = modularDistributorPossibleCircuits;
            }

            distributor.size = calculateDistributorPermutations(distributor.circuitNo, possibleCircuits).distributors[0];

            if (distributor.type === 1 && unit.distribution_diameter === 75) {
                if (distributor.size - distributor.circuitNo >= 3) {
                    store.commit('pushVmcError', {
                        type: 'warning',
                        text: 'Pentru selectia curenta de tubulatura, selectia automata a distribuitoarelor poate fi gresita, va rugam verificati!',
                    });
                }
            }
        });
    });
}

export function findMatchingDNVariant(size) {
    return Object.values(DN_VARIANTS).find(variant => variant.size === size);
}

const TUBING_TYPES = {
    EPS: 1,
    FLEXIBLE: 2
};

const DISTRIBUTOR_TYPES = {
    METALLIC: 1,
    MODULAR: 2
};

const distributorConfig = {
    [TUBING_TYPES.EPS]: {
        125: {
            75: {needsNipple: true, diameter: DN_VARIANTS.DN125, teeSize: DN_VARIANTS.DN125},
            90: {diameter: DN_VARIANTS.DN160, needsConnector: true, teeSize: DN_VARIANTS.DN160},
            117: {diameter: DN_VARIANTS.DN160, needsConnector: true, teeSize: DN_VARIANTS.DN160}
        },
        160: {
            75: {needsNipple: true, diameter: DN_VARIANTS.DN160, teeSize: DN_VARIANTS.DN160},
            90: {diameter: DN_VARIANTS.DN160, teeSize: DN_VARIANTS.DN160},
            117: {diameter: DN_VARIANTS.DN160, teeSize: DN_VARIANTS.DN160}
        },
        200: {
            75: {needsNipple: true, diameter: DN_VARIANTS.DN200, teeSize: DN_VARIANTS.DN200, teeVariant: "normal"},
            90: {diameter: DN_VARIANTS.DN160, needsReduction: true, teeSize: DN_VARIANTS.DN200},
            117: {diameter: DN_VARIANTS.DN200, needsReduction: true, teeSize: DN_VARIANTS.DN200}
        }
    },
    [TUBING_TYPES.FLEXIBLE]: {
        125: {
            75: {diameter: DN_VARIANTS.DN125, needsNipple: true, teeSize: DN_VARIANTS.DN125},
            90: {diameter: DN_VARIANTS.DN160, needsPVCReduction: true, teeSize: DN_VARIANTS.DN160},
            117: {diameter: DN_VARIANTS.DN160, needsPVCReduction: true, teeSize: DN_VARIANTS.DN160}
        },
        160: {
            75: {diameter: DN_VARIANTS.DN160, needsNipple: true, teeSize: DN_VARIANTS.DN160},
            90: {diameter: DN_VARIANTS.DN160, teeSize: DN_VARIANTS.DN160},
            117: {diameter: DN_VARIANTS.DN160, teeSize: DN_VARIANTS.DN160}
        }
    }
};

const commonObjects = {
    epdmConnector: {
        "type": VMC_PRODUCT_TYPES.MUFA,
        "subtype": "EPDM",
        "diameter": DN_VARIANTS.DN125,
    },
    distributorNipple: {
        "type": VMC_PRODUCT_TYPES.NIPLU,
        "subtype": "modular",
        "diameter": DN_VARIANTS.DN125,
    },
    epsTee: {
        "type": VMC_PRODUCT_TYPES.TEU,
        "subtype": "EPS",
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
        "quantity": 1,
    },
    flexTee: {
        "type": VMC_PRODUCT_TYPES.TEU,
        "subtype": "flex",
        "quantity": 1,
    },
    EPSReduction200: {
        "type": VMC_PRODUCT_TYPES.REDUCTIE,
        "subtype": "general",
        "quantity": 1,
    },
    PVCReduction150: {
        "type": VMC_PRODUCT_TYPES.REDUCTIE,
        "subtype": "PVC",
        "variant": 150,
        "quantity": 1,
    },
    plug: {
        type: VMC_PRODUCT_TYPES.DOP,
        quantity: 1,
        distribution_diameter: DN_VARIANTS.DN_OVAL
    },
};

/**
 * Sets the distributor requirements for a given unit and distributor.
 * It determines the correct configuration and adds any necessary additional requirements
 * such as nipples, connectors, reductions, or plugs.
 *
 * @param {Object} unit - The unit object containing details about tubing, diameter, and distribution.
 * @param {Object} distributor - The distributor object with its size and circuit details.
 * @param {Array} distributorsRequirements - The array that will hold the additional requirements like nipples, connectors, etc.
 * @returns {Object|undefined} - The distributor requirement object or undefined if the configuration is invalid.
 */
export function setDistributorRequirements(unit, distributor, distributorsRequirements) {
    const tubingConfig = distributorConfig[unit.tubing]?.[unit.tubing_diameter]?.[unit.distribution_diameter];

    if (!tubingConfig) {
        console.error('Invalid configuration:', unit.tubing, unit.tubing_diameter, unit.distribution_diameter);
        return;
    }

    const distributorRequirement = {
        type: VMC_PRODUCT_TYPES.DISTRIBUTOR,
        size: distributor.size,
        subtype: distributor.type,
        diameter: tubingConfig.diameter,
        distribution_diameter: findMatchingDNVariant(unit.distribution_diameter)
    };

    if (tubingConfig.needsNipple && distributor.type === DISTRIBUTOR_TYPES.MODULAR) {
        distributorRequirement.additional = "fara niplu";
        distributorsRequirements.push({...commonObjects.distributorNipple, diameter: tubingConfig.diameter});
    }

    if (tubingConfig.needsConnector) {
        distributorsRequirements.push({...commonObjects.epdmConnector, diameter: tubingConfig.diameter});
    }

    if (tubingConfig.needsReduction) {
        distributorsRequirements.push(commonObjects.EPSReduction200);
    }

    if (tubingConfig.needsPVCReduction) {
        distributorsRequirements.push(commonObjects.PVCReduction150);
    }

    // Check if distributor type is 1 or distribution_diameter is different from 75
    // Here we need to add plugs for free holes in the distributor,
    // a free hole appears when the circuits of that distributor is smaller than it's size
    if (distributor.type === 1 || unit.distribution_diameter !== 75) {
        // Calculate the difference between circuitNo and size
        const difference = distributor.circuitNo - distributor.size;

        if (difference < 0) {
            // Add plugs based on the negative difference
            commonObjects.plug.quantity = Math.abs(difference);  // Set the quantity to the absolute difference
            commonObjects.plug.distribution_diameter = unit.distribution_diameter === 117 ? DN_VARIANTS.DN_OVAL : unit.distribution_diameter === 90 ? DN_VARIANTS.DN90 : DN_VARIANTS.DN75;
            distributorsRequirements.push(cloneDeep(commonObjects.plug));
        }
    }

    return distributorRequirement;
}

/**
 * Creates distributor requirements for all units in a given location.
 * It calculates the needed distributors and adds any relevant additional requirements such as EPS or Flex tees.
 *
 * @param {Object} location - The location object containing the units and their distributors.
 * @returns {Array} - The list of distributor requirements including any additional requirements like tees.
 */
export function createDistributorsRequirements(location) {
    calculateNeededDistributors(location);

    let distributorsRequirements = [];

    location.units.forEach((unit) => {
        const tubingConfig = distributorConfig[unit.tubing]?.[unit.tubing_diameter]?.[unit.distribution_diameter];
        unit.distributors.forEach((distributor) => {
            const distributorRequirement = setDistributorRequirements(unit, distributor, distributorsRequirements);
            if (distributorRequirement) {
                distributorsRequirements.push(distributorRequirement);
            }
        });

        if (unit.distributors.length > 2) {
            if (unit.tubing === 1) {
                commonObjects.epsTee.diameter = tubingConfig.teeSize;
                commonObjects.epsTee.quantity = unit.distributors.length - 2;
                if (tubingConfig.teeVariant) {
                    commonObjects.epsTee.variant = tubingConfig.teeVariant;
                }
                commonObjects.epdmConnector.diameter = tubingConfig.teeSize; // Assuming epsTee and epdmConnector share a 'diameter' property
                distributorsRequirements = pushBasedOnReference(commonObjects.epsTee, commonObjects.epdmConnector, distributorsRequirements, epdmPushLogic);
                distributorsRequirements.push(commonObjects.epsTee);
            } else if (unit.tubing === 2) {
                commonObjects.flexTee.quantity = unit.distributors.length - 2;
                distributorsRequirements.push(commonObjects.flexTee);
            }
        }
    });
    return distributorsRequirements;
}