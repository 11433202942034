import {products, VACUUM_TYPES} from '../Vacuum/VacuumProducts.js';
import {cloneDeep} from 'lodash';

/**
 * Function to create vacuum requirements based on the vacuum setup.
 * @param {Object} vacuumSetup - The setup of the vacuum system.
 * @returns {Array} - The array of vacuum requirements.
 */
export function createVacuumRequirements(vacuumSetup) {
    let vacuumRequirements = [];

    vacuumSetup.selected_connectors.forEach(connector => {
        // Determine the subtype based on the connector type
        let subtype = '';
        switch (connector.type) {
            case 1:
                subtype = 'Gewiss';
                break;
            case 2:
                subtype = 'Biticino';
                break;
            case 3:
                subtype = 'bucatarie';
                break;
            case 6:
                subtype = 'ECO';
                break;
            case 7:
                subtype = 'aparenta';
                break;
            case 8:
                subtype = 'pardoseala';
                break;
            case 9:
                subtype = 'exterior';
                break;
            default:
                break;
        }

        // Determine the color based on the connector color
        let color = '';
        switch (connector.color) {
            case 1:
                color = 'alb';
                break;
            case 2:
                color = 'neagra';
                break;
            case 3:
                color = 'silver';
                break;
            default:
                color = null;
                break; // Skip unknown colors
        }

        // Add priza requirement
        vacuumRequirements.push(
            cloneDeep({
                type: VACUUM_TYPES.PRIZA,
                subtype: subtype,
                color: color,
                quantity: 1,
            })
        );

        // Add suport priza requirement if necessary
        if (connector.ornamentSupport) {
            vacuumRequirements.push(
                cloneDeep({
                    type: VACUUM_TYPES.SUPORT,
                    subtype: 'priza',
                    variant: subtype,
                    color: color,
                    quantity: 1,
                })
            );
        }

        // Add doza requirement
        let dozaRequirement = {
            type: VACUUM_TYPES.DOZA,
            quantity: 1,
        };
        switch (connector.electrical_dose) {
            case 1:
                dozaRequirement.subtype = 'dreptunghiulara';
                dozaRequirement.connector = 'normal';
                break;
            case 2:
                dozaRequirement.subtype = 'dreptunghiulara';
                dozaRequirement.connector = 'spate';
                break;
            case 3:
                dozaRequirement.subtype = 'bucatarie';
                break;
            case 4:
                dozaRequirement.type = VACUUM_TYPES.KIT;
                dozaRequirement.subtype = 'Easy';
                dozaRequirement.variant = 'rigips';
                break;
            case 5:
                dozaRequirement.type = VACUUM_TYPES.KIT;
                dozaRequirement.subtype = 'Easy';
                dozaRequirement.variant = 'zidarie';
                break;
            case 6:
                dozaRequirement.subtype = 'pardoseala';
                dozaRequirement.connector = 'normal';
                break;
            case 7:
                dozaRequirement.subtype = 'pardoseala';
                dozaRequirement.connector = 'spate';
                break;
            default:
                break; // Skip unknown electrical doses
        }

        vacuumRequirements.push(cloneDeep(dozaRequirement));

        // Add ornament requirement if necessary
        if (connector.ornament) {
            let ornamentVariant = '';
            if (subtype === 'Gewiss') {
                ornamentVariant = 'dreptunghiular'; // Adjust based on your logic for variant
            } else if (subtype === 'bucatarie') {
                ornamentVariant = 'normal'; // Adjust based on your logic for variant
            }

            vacuumRequirements.push(
                cloneDeep({
                    type: VACUUM_TYPES.ORNAMENT,
                    subtype: subtype,
                    variant: ornamentVariant,
                    color: color,
                    quantity: 1,
                })
            );
        }
    });

    // Add accessory set requirement if necessary
    if (vacuumSetup.selected_accessory_set) {
        let accessorySetSubtype = vacuumSetup.selected_accessory_set === 1 ? 'standard' : 'basic';
        vacuumRequirements.push(
            cloneDeep({
                type: VACUUM_TYPES.SET,
                subtype: accessorySetSubtype,
                quantity: 1,
            })
        );
    }

    // Add hose requirement if necessary
    if (vacuumSetup.selected_hose_type) {
        let hoseRequirement = {
            "type": VACUUM_TYPES.FURTUN,
            "subtype": "maner",
            "variant": "normal",
            "length": 9000,
            "quantity": 1,
        };

        if (vacuumSetup.selected_hose_type === 1) {
            hoseRequirement.variant = "intrerupator";
        }

        vacuumRequirements.push(cloneDeep(hoseRequirement));
    }

    // Adjust the quantity of the PVCExtensionPlugRequirement based on the number of easy connectors
    const PVCExtensionPlugRequirement = {
        "type": VACUUM_TYPES.DERIVATIE,
        "subtype": "PVC",
        "angle": 45,
        "diameter": 50,
        "connection": "II",
    };

    if (PVCExtensionPlugRequirement) {
        if (vacuumSetup.selected_connectors.length - 1 === 0) {
            PVCExtensionPlugRequirement.quantity = null;
        }

        PVCExtensionPlugRequirement.quantity = vacuumSetup.selected_connectors.length - 1;

        vacuumRequirements.push(cloneDeep(PVCExtensionPlugRequirement));
    }

    return vacuumRequirements;
}

export function createEcoflexSetRequirements(vacuumSetup) {
    let ecoflexRequirements = [];

    let ecoflexRequirement = {
        type: VACUUM_TYPES.SET,
        subtype: 'ecoflex',
        quantity: 1,
    };

    if (vacuumSetup.has_ecoflex) {
        ecoflexRequirements.push(cloneDeep(ecoflexRequirement));
    }

    return ecoflexRequirements;
}

/**
 * Function to find the product in predefinedProducts and push it to resultedProducts.
 * @param {Object} context - The context containing resultedProducts.
 * @param {Object} specifications - The specifications of the product to find.
 * @param {number} quantity - The quantity to set for the found product.
 */
export function findAndPushProduct(context, specifications, quantity) {
    let product = products.find(
        p =>
            p.specifications.type === specifications.type &&
            p.specifications.subtype === specifications.subtype &&
            p.specifications.diameter === specifications.diameter &&
            p.specifications.length === specifications.length
    );

    if (product) {
        let existingProduct = context.resultedProducts.find(
            p =>
                p.specifications.type === specifications.type &&
                p.specifications.subtype === specifications.subtype &&
                p.specifications.diameter === specifications.diameter &&
                p.specifications.length === specifications.length
        );
        if (existingProduct) {
            existingProduct.quantity = quantity;
        } else {
            context.resultedProducts.push(cloneDeep({...product, quantity}));
        }
    }
}

/**
 * Function to check the cable conditions and update the resultedProducts.
 * @param {Object} context - The context containing vacuumSetup and resultedProducts.
 * @param {number} H7 - Quantity of H7 product.
 * @param {number} H8 - Quantity of H8 product.
 * @param {number} H3 - Total area.
 * @param {number} D2 - Bucatarie connector flag.
 */
export function checkCableConditions(context, H7, H8, H3, D2) {
    if (context.vacuumSetup.selected_installation_type === 2) {
        const value = Math.round(13 * (H7 + H8) + D2 * 8);
        const results = {
            cable25: 0,
            cable50: 0,
            cable100: 0,
        };

        if (H3 > 0) {
            let remainingLength = value;

            // Use 100m cables first
            results.cable100 = Math.floor(remainingLength / 100);
            remainingLength %= 100;

            // Use 50m cables next
            results.cable50 = Math.floor(remainingLength / 50);
            remainingLength %= 50;

            // Use 25m cables last
            results.cable25 = Math.ceil(remainingLength / 25); // Use ceil to cover any remaining length
        }

        // Track the number of cables
        const totalCables = results.cable25 + results.cable50 + results.cable100;

        // Handle cable 25
        if (results.cable25 > 0) {
            findAndPushProduct(
                context,
                {
                    type: VACUUM_TYPES.CABLU,
                    subtype: 'electric',
                    diameter: 2,
                    length: 25000,
                },
                results.cable25
            );
        }

        // Handle cable 50
        if (results.cable50 > 0) {
            findAndPushProduct(
                context,
                {
                    type: VACUUM_TYPES.CABLU,
                    subtype: 'electric',
                    diameter: 2,
                    length: 50000,
                },
                results.cable50
            );
        }

        // Handle cable 100
        if (results.cable100 > 0) {
            findAndPushProduct(
                context,
                {
                    type: VACUUM_TYPES.CABLU,
                    subtype: 'electric',
                    diameter: 2,
                    length: 100000,
                },
                results.cable100
            );
        }

        // Find and push adhesive products based on the number and length of cables
        for (let i = 0; i < results.cable25; i++) {
            let adhesiveProduct = products.find(product => product.specifications.type === VACUUM_TYPES.ADEZIV && product.specifications.subtype === "adeziv" && product.specifications.weight === 125);
            if (adhesiveProduct) {
                adhesiveProduct.quantity = 1;
                context.resultedProducts.push(cloneDeep(adhesiveProduct));
            }
        }

        for (let i = 0; i < results.cable50; i++) {
            let adhesiveProduct = products.find(product => product.specifications.type === VACUUM_TYPES.ADEZIV && product.specifications.subtype === "adeziv" && product.specifications.weight === 250);
            if (adhesiveProduct) {
                adhesiveProduct.quantity = 1;
                context.resultedProducts.push(cloneDeep(adhesiveProduct));
            }
        }

        for (let i = 0; i < results.cable100; i++) {
            let adhesiveProduct = products.find(product => product.specifications.type === VACUUM_TYPES.ADEZIV && product.specifications.subtype === "adeziv" && product.specifications.weight === 500);
            if (adhesiveProduct) {
                adhesiveProduct.quantity = 1;
                context.resultedProducts.push(cloneDeep(adhesiveProduct));
            }
        }

        // Push dowel pins based on the number of cables
        let dowelPinProduct = products.find(product => product.specifications.type === VACUUM_TYPES.DIBLU && product.specifications.subtype === "bataie");

        for (let i = 0; i < totalCables; i++) {
            if (dowelPinProduct) {
                dowelPinProduct.quantity = 1;
                context.resultedProducts.push(cloneDeep(dowelPinProduct));
            }
        }
    }
}

/**
 * Function to check the fasete conditions and update the resultedProducts.
 * @param {Object} context - The context containing vacuumSetup and resultedProducts.
 * @param {number} H3 - Total area.
 * @param {number} H7 - Quantity of H7 product.
 * @param {number} H8 - Quantity of H8 product.
 * @param {number} H9 - Quantity of H9 product.
 */
export function checkFaseteConditions(context, H3, H7, H8, H9) {
    const value = (H7 + H8 + H9) * 10;
    const result = H3 > 0 ? Math.ceil(value / 100) : 0;

    // Handle fasete 30 cm
    if (result > 0) {
        findAndPushProduct(
            context,
            {
                type: VACUUM_TYPES.FASETE,
                subtype: 'fasete',
                length: 300,
            },
            result
        );
    }
}

/**
 * Function to check the banda perforata conditions and update the resultedProducts.
 * @param {Object} context - The context containing vacuumSetup and resultedProducts.
 */
export function checkBandaPerforataConditions(context) {
    const H32 =
        context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.CABLU && p.specifications.subtype === 'electric' && p.specifications.length === 25000)?.quantity || 0;
    const H33 =
        context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.CABLU && p.specifications.subtype === 'electric' && p.specifications.length === 50000)?.quantity || 0;
    const H34 =
        context.resultedProducts.find(p => p.specifications.type === VACUUM_TYPES.CABLU && p.specifications.subtype === 'electric' && p.specifications.length === 100000)?.quantity ||
        0;

    const result = H32 + H33 + H34 * 2;

    // Handle banda perforata 12mm
    if (result > 0) {
        findAndPushProduct(
            context,
            {
                type: VACUUM_TYPES.BANDA,
                subtype: "perforata",
                width: 12,
            },
            result
        );
    }
}

/**
 * Function to check the teava PVC conditions and update the resultedProducts.
 * @param {Object} context - The context containing vacuumSetup and resultedProducts.
 */
export function checkTeavaPVCConditions(context) {
    // Check if there is at least one selectedConnector with electrical dose 1 or 2
    const hasRequiredDose = context.vacuumSetup.selected_connectors.some(connector =>
        connector.electrical_dose === 1 || connector.electrical_dose === 2
    );

    if (!hasRequiredDose) {
        return; // Exit the function if no connectors with the required electrical doses are found
    }

    const productIndex = context.resultedProducts.findIndex(p => {
        return (
            p.specifications.type === VACUUM_TYPES.TEAVA &&
            p.specifications.subtype === 'PVC' &&
            p.specifications.diameter === 50 &&
            p.specifications.width === 2.1 &&
            p.specifications.length === 2000
        );
    });

    if (productIndex !== -1) {
        let product = context.resultedProducts[productIndex];

        if (context.vacuumSetup.has_bucatarie_connector) {
            product.quantity += 5;
        }
        product.quantity = Math.ceil(product.quantity); // round up the quantity
        if (product.quantity % 2 !== 0) {
            product.quantity++; // make it even if it's odd
        }

        const PVCExtensionPlug = products.find(p => {
            return p.specifications.type === VACUUM_TYPES.MUFA && p.specifications.subtype === 'PVC' && p.specifications.diameter === 50;
        });
        if (PVCExtensionPlug) {
            context.resultedProducts.push(cloneDeep({...PVCExtensionPlug, quantity: product.quantity / 2}));
        }
    }
}

export function createEasySpecialRequirements(context) {
    let teavaPVCRequirement = {
        "type": VACUUM_TYPES.TEAVA,
        "subtype": "PVC",
        "diameter": 50,
        "width": 2.1,
        "length": 2000,
    }

    let extensionConnectorPVC50Requirement = {
        "type": VACUUM_TYPES.MUFA,
        "subtype": "PVC",
        "diameter": 50
    };

    let easySpecialRequirements = [];

    if (context.selected_installation_type === 1) { // Check if the installation type is 'Easy'
        if (context.has_bucatarie_connector || context.has_ecoflex) {
            teavaPVCRequirement.quantity = 12; // Add 12m of 'teava'
            extensionConnectorPVC50Requirement.quantity = 3;
        }
    }

    if (teavaPVCRequirement.quantity > 0) {
        easySpecialRequirements.push(cloneDeep(teavaPVCRequirement)); // Add to the requirements list
        easySpecialRequirements.push(cloneDeep(extensionConnectorPVC50Requirement));
    }

    return easySpecialRequirements;
}

/**
 * Function to check the conditions for PVC elbow products and update the resultedProducts.
 * @param {Object} context - The context containing vacuumSetup and resultedProducts.
 * @param {string} angle - The angle of the PVC elbow.
 * @param {string} diameter - The diameter of the PVC elbow.
 * @param {string} connection - The connection type of the PVC elbow.
 * @param {number|null} productIdToInclude - The product ID to include in the quantity calculation.
 */
export function checkCotPVCConditions(context, angle, diameter, connection, productIdToInclude) {
    const totalArea = context.vacuumSetup.total_area;
    if (totalArea) {
        const hasBucatarieConnector = context.vacuumSetup.has_bucatarie_connector;

        const matchingProductIndex = context.resultedProducts.findIndex(p => {
            return p.specifications.type === VACUUM_TYPES.DOZA && p.specifications.subtype === 'dreptunghiulara' && p.specifications.connector === 'normal';
        });

        const PVCElbowProductIndex = context.resultedProducts.findIndex(p => {
            return (
                p.specifications.type === VACUUM_TYPES.COT &&
                p.specifications.subtype === 'PVC' &&
                p.specifications.angle === angle &&
                p.specifications.diameter === diameter &&
                p.specifications.connection === connection
            );
        });

        const productToInclude = context.resultedProducts.find(p => p.id === productIdToInclude);
        const D2 = hasBucatarieConnector ? 2 : 0;
        const H20 = productToInclude ? productToInclude.quantity : 0;

        if (matchingProductIndex !== -1 && PVCElbowProductIndex !== -1) {
            let PVCElbowProduct = context.resultedProducts[PVCElbowProductIndex];
            PVCElbowProduct.quantity = PVCElbowProduct.quantity + D2 + H20;
        }
    }
}

/**
 * Function to check the conditions for derivatie PVC products and update the resultedProducts.
 * @param {Object} context - The context containing vacuumSetup and resultedProducts.
 */
export function checkDerivatiePVCConditions(context) {
    const productIndex = context.resultedProducts.findIndex(product => {
        return (
            product.specifications.type === VACUUM_TYPES.DERIVATIE &&
            product.specifications.subtype === 'PVC' &&
            product.specifications.diameter === 50 &&
            product.specifications.angle === 45 &&
            product.specifications.connection === 'II'
        );
    });
    if (productIndex !== -1) {
        let product = context.resultedProducts[productIndex];

        product.quantity -= 1;

        if (product.quantity === 0) {
            product.quantity = null;
        }
    }
}

export function checkColierMetalicConditions(context) {
    const productIndex = context.resultedProducts.findIndex(product => {
        return (
            product.specifications.type === VACUUM_TYPES.TEAVA &&
            product.specifications.subtype === 'flexibila' &&
            product.specifications.usage === 'bucatarie'
        );
    });

    if (productIndex !== -1) {
        const matchingProduct = context.resultedProducts[productIndex];
        if (matchingProduct.quantity > 0) {
            let product = products.find(p => p.specifications.type === VACUUM_TYPES.COLIER && p.specifications.subtype === 'metalic' && p.specifications.dimensions === [50, 70]);

            if (product) {
                product.quantity = 2;
                context.resultedProducts.push(cloneDeep(product));
            }
        }
    }
}
