<template>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title>
                Casa Verde
            </v-toolbar-title>
            <v-toolbar-items>
                <v-btn color="success" @click="navigateToCreateClient">
                    <v-icon>mdi-plus</v-icon>
                    Adaugă Client
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="clients"
                item-value="id"
                :hover="true"
                dense
                class="elevation-1"
            >
                <template v-slot:item="{ item }">
                    <tr
                        @click="navigateToEditClient(item.id)"
                        style="cursor: pointer;"
                    >
                        <td>{{ item.agent_full_name || "Necunoscut" }}</td>
                        <td>{{ item.beneficiary_code_afm }}</td>
                        <td>{{ item.file_number_afm || "-" }}</td>
                        <td>{{ formatDate(item.application_date) }}</td>
                        <td>{{ formatDate(item.cf_date) }}</td>
                        <td>{{ item.first_name }}</td>
                        <td>{{ item.last_name }}</td>
                        <td>
                            {{ item.cnp }}
                            <v-tooltip location="top" activator="parent">
                                Buletin <br/>
                                Serie: {{ item.id_card_series }} <br/>
                                Număr: {{ item.id_card_number }}
                            </v-tooltip>
                        </td>
                        <td>
                            <div class="ellipsis-text">
                                {{ formatStreet(item.street) }}
                                <template v-if="item.number"> nr. {{ item.number }}</template>
                                <template v-if="item.apartment">, Ap. {{ item.apartment }}</template>
                                <template v-if="item.city">, {{ item.city }}</template>
                                <template v-if="item.postal_code">, {{ item.postal_code }}</template>
                                <template v-if="getRegionName(item.region)">, {{
                                        getRegionName(item.region)
                                    }}
                                </template>
                            </div>
                            <v-tooltip location="top" activator="parent">
                                <span>
                                    {{ formatStreet(item.street) }}
                                    <template v-if="item.number"> nr. {{ item.number }}</template>
                                    <template v-if="item.apartment">, Ap. {{ item.apartment }}</template>
                                    <template v-if="item.city">, {{ item.city }}</template>
                                    <template v-if="item.postal_code">, {{ item.postal_code }}</template>
                                    <template v-if="getRegionName(item.region)">, {{
                                            getRegionName(item.region)
                                        }}</template>
                                </span>
                            </v-tooltip>
                        </td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.connection }}</td>
                        <td>{{ item.panel_count }}
                            <v-tooltip location="top" activator="parent">
                                Putere panouri: {{ item.panel_power }} kW
                            </v-tooltip>
                        </td>
                        <td>{{ item.inverter }}
                            <v-tooltip location="top" activator="parent">
                                Tip invertor: {{ item.inverter_type }}<br/>
                                Putere invertor: {{ item.inverter_power }} kW
                            </v-tooltip>
                        </td>
                        <td>
                            <v-btn variant="plain" color="red" @click.stop="openDeleteDialog(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="isDeleteDialogVisible" max-width="500px">
            <v-card>
                <v-card-title class="headline">
                    Confirmare ștergere
                </v-card-title>
                <v-card-text>
                    Ești sigur că vrei să ștergi acest client? Această acțiune nu poate fi anulată.
                </v-card-text>
                <v-card-actions class="d-flex flex-row justify-content-end">
                    <v-btn color="grey" text @click="closeDeleteDialog">Anulează</v-btn>
                    <v-btn color="red" text @click="deleteClient">Șterge</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";

export default {
    name: "GreenHouseSolarClientsList",
    components: {
    },
    data() {
        return {
            clients: [],
            headers: [
                {title: "Agent", key: "agent"},
                {title: "Cod Beneficiar AFM", key: "beneficiary_code_afm"},
                {title: "Nr. dosar AFM", key: "file_number_afm"},
                {title: "Data înscriere în aplicație", key: "application_date"},
                {title: "Data CF", key: "cf_date"},
                {title: "Prenume", key: "first_name"},
                {title: "Nume", key: "last_name"},
                {title: "CNP", key: "cnp"},
                {title: "Adresă", key: "address", width: "300px"},
                {title: "Email", key: "email"},
                {title: "Telefon", key: "phone"},
                {title: "Branșament", key: "connection"},
                {title: "Panouri", key: "panel_count", width: "50px"},
                {title: "Invertor", key: "inverter"},
                {title: "Acțiuni", key: "actions"},
            ],
            regions: [],
            cities: [],
            isDeleteDialogVisible: false,
            clientToDelete: null,
        };
    },
    async mounted() {
        await this.fetchClients();
        await this.loadRegionsAndCities();
    },
    methods: {
        async fetchClients() {
            try {
                const response = await apiClient.get("/api/greenhouse-solar-clients/");
                this.clients = response.data;
            } catch (error) {
                console.error("Error fetching clients:", error);
            }
        },
        formatDate(date) {
            if (!date) return "";
            const d = new Date(date);
            return d.toLocaleDateString("ro-RO", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
        },
        /**
         * Conditionally prepends "Str." to the street name if it doesn't start with "Strada", "Str.", or "Sat".
         *
         * @param {string} street - The name of the street to format.
         * @returns {string} - The formatted street name.
         */
        formatStreet(street) {
            if (/^(Strada|Str\.|Sat)/i.test(street)) {
                return street;
            }
            return `Str. ${street}`;
        },
        navigateToCreateClient() {
            this.$router.push({name: "GreenHouseSolarClientCreate"});
        },
        navigateToEditClient(greenHouseClientId) {
            this.$router.push({name: "GreenHouseSolarClientCreate", params: {clientId: greenHouseClientId}});
        },
        /**
         * Fetches regions and cities data, populating `regions` and `cities` arrays with the parsed data.
         *
         * This method retrieves a JSON file of regions and cities, parses it, and assigns
         * the appropriate information to `regions` and `cities`. This allows the component to
         * support region-based filtering in the client list.
         *
         * @async
         * @method
         */
        async loadRegionsAndCities() {
            try {
                const response = await fetch("/cities.json");
                const data = await response.json();

                this.regions = data
                    .filter((item) => item.model === "cities_light.region")
                    .map((item) => ({
                        id: item.pk,
                        name: item.fields.name,
                        display_name: item.fields.display_name,
                    }));

                this.cities = data
                    .filter((item) => item.model === "cities_light.subregion")
                    .map((item) => ({
                        id: item.pk,
                        name: item.fields.name,
                        display_name: item.fields.display_name,
                        region_id: item.fields.region,
                    }));
            } catch (error) {
                console.error("Error loading regions and cities:", error);
            }
        },
        /**
         * Retrieves the name of a region based on its ID.
         *
         * This method searches the `regions` array for the region object with a matching ID,
         * returning the region’s name if found, or an empty string otherwise.
         *
         * @param {number} regionId - The ID of the region to retrieve.
         * @returns {string} - The name of the region.
         */
        getRegionName(regionId) {
            const region = this.regions.find((r) => r.id === regionId);
            return region ? region.name : '';
        },
        openDeleteDialog(client) {
            this.clientToDelete = client;
            this.isDeleteDialogVisible = true;
        },
        closeDeleteDialog() {
            this.isDeleteDialogVisible = false;
            this.clientToDelete = null;
        },
        async deleteClient() {
            try {
                await apiClient.delete(`/api/greenhouse-solar-clients/${this.clientToDelete.id}/`);
                this.clients = this.clients.filter(client => client.id !== this.clientToDelete.id);
                this.closeDeleteDialog();
            } catch (error) {
                console.error("Error deleting client:", error);
                this.closeDeleteDialog();
            }
        },
    },
};
</script>

<style scoped>
/* Add any additional styling if required */
</style>
