export const PLUMBING_NON_STANDARD_MEASUREMENTS = {
    ONE_INCH: {
        length: 1,
        measurementUnit: "inch",
    },
    HALF_INCH: {
        length: 0.5,
        measurementUnit: "inch",
    },
    THREE_QUARTER_INCH: {
        length: 0.75,
        measurementUnit: "inch"
    },
}

export const PLUMBING_PRODUCT_TYPES = {
    TRECERE: 1,
    NIPLU: 2,
    TEU: 3,
    COT: 4,
    INEL: 5,
    TIJA: 6
};


export const plumbingProducts = [
    {
        "id": 1,
        "product_code": "702.05.45",
        "name": "Trecere cu racord olandez 16x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "olandez",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 2,
        "product_code": "702.06.45",
        "name": "Trecere cu racord olandez 25x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "olandez",
            "diameter": 25,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 3,
        "product_code": "702.07.45",
        "name": "Trecere cu racord olandez 32x1\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "olandez",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 4,
        "product_code": "702.08.45",
        "name": "Trecere cu filet exterior 16x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet exterior",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 5,
        "product_code": "702.09.45",
        "name": "Trecere cu filet exterior 16x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet exterior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 6,
        "product_code": "702.10.45",
        "name": "Trecere cu filet exterior 20x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet exterior",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 7,
        "product_code": "702.11.45",
        "name": "Trecere cu filet exterior 20x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet exterior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 8,
        "product_code": "702.12.45",
        "name": "Trecere cu filet exterior 25x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet exterior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 9,
        "product_code": "702.13.45",
        "name": "Trecere cu filet exterior 25x1\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet exterior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 10,
        "product_code": "702.14.45",
        "name": "Trecere cu filet exterior 32x1\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet exterior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 11,
        "product_code": "702.15.45",
        "name": "Trecere cu filet interior 16x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet interior",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 12,
        "product_code": "702.16.45",
        "name": "Trecere cu filet interior 20x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet interior",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 13,
        "product_code": "702.17.45",
        "name": "Trecere cu filet interior 20x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet interior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 14,
        "product_code": "702.18.45",
        "name": "Trecere cu filet interior 25x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet interior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 15,
        "product_code": "702.19.45",
        "name": "Trecere cu filet interior 25x1\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet interior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 16,
        "product_code": "702.20.45",
        "name": "Trecere cu filet interior 32x1\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "filet interior",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 17,
        "product_code": "702.21.45",
        "name": "Niplu egal 16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "egal",
            "diameter": 16
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 18,
        "product_code": "702.22.45",
        "name": "Niplu egal 20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "egal",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 19,
        "product_code": "702.23.45",
        "name": "Niplu egal 25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "egal",
            "diameter": 25,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 20,
        "product_code": "702.24.45",
        "name": "Niplu egal 32 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "egal",
            "diameter": 32,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 21,
        "product_code": "702.25.45",
        "name": "Niplu redus 20-16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "redus",
            "diameter": {
                "from": 20,
                "to": 16,
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 22,
        "product_code": "702.26.45",
        "name": "Niplu redus 25-16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "redus",
            "diameter": {
                "from": 25,
                "to": 16,
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 23,
        "product_code": "702.27.45",
        "name": "Niplu redus 25-20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "redus",
            "diameter": {
                "from": 25,
                "to": 20,
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 24,
        "product_code": "702.28.45",
        "name": "Niplu redus 32-25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.NIPLU,
            "subtype": "redus",
            "diameter": {
                "from": 32,
                "to": 25,
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 25,
        "product_code": "702.29.45",
        "name": "Cot egal 16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "egal",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 26,
        "product_code": "702.30.45",
        "name": "Cot egal 20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "egal",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 27,
        "product_code": "702.31.45",
        "name": "Cot egal 25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "egal",
            "diameter": 25,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 28,
        "product_code": "702.32.45",
        "name": "Cot egal 32 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "egal",
            "diameter": 32,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 29,
        "product_code": "702.33.45",
        "name": "Cot trecere FI 16x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FI",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 30,
        "product_code": "702.34.45",
        "name": "Cot trecere FI 20x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FI",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 31,
        "product_code": "702.35.45",
        "name": "Cot trecere FI 20x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FI",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 32,
        "product_code": "702.36.45",
        "name": "Cot trecere FI 25x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FI",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 33,
        "product_code": "702.37.45",
        "name": "Cot trecere FI 32x1\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FI",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 34,
        "product_code": "702.38.45",
        "name": "Cot trecere FE 16x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FE",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 35,
        "product_code": "702.39.45",
        "name": "Cot trecere FE 20x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FE",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 36,
        "product_code": "702.40.45",
        "name": "Cot trecere FE 25x3/4\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "trecere FE",
            "diameter": PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 37,
        "product_code": "702.41.45",
        "name": "Cot racord cu talpa 16x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "racord cu talpa",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 38,
        "product_code": "702.42.45",
        "name": "Cot racord cu talpa 20x1/2\" Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.COT,
            "subtype": "racord cu talpa",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 39,
        "product_code": "702.43.45",
        "name": "Teu egal 16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "egal",
            "diameter": 16,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 3,
            },
        ],
    },
    {
        "id": 40,
        "product_code": "702.44.45",
        "name": "Teu egal 20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "egal",
            "diameter": 20,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 3,
            },
        ],
    },
    {
        "id": 41,
        "product_code": "702.45.45",
        "name": "Teu egal 25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "egal",
            "diameter": 25,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 3,
            },
        ],
    },
    {
        "id": 42,
        "product_code": "702.46.45",
        "name": "Teu egal 32 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "egal",
            "diameter": 32,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 3,
            },
        ],
    },
    {
        "id": 43,
        "product_code": "702.47.45",
        "name": "Teu redus 16-20-16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 16,
                "diameter2": 20,
                "diameter3": 16
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 2,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 44,
        "product_code": "702.48.45",
        "name": "Teu redus 20-16-16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 20,
                "diameter2": 16,
                "diameter3": 16
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 2,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 45,
        "product_code": "702.49.45",
        "name": "Teu redus 20-16-20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 20,
                "diameter2": 16,
                "diameter3": 20
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 46,
        "product_code": "702.50.45",
        "name": "Teu redus 20-20-16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 20,
                "diameter2": 20,
                "diameter3": 16
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 47,
        "product_code": "702.51.45",
        "name": "Teu redus 20-25-20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 20,
                "diameter2": 25,
                "diameter3": 20
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 2,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 48,
        "product_code": "702.52.45",
        "name": "Teu redus 25-16-16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 16,
                "diameter3": 16
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 2,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 49,
        "product_code": "702.53.45",
        "name": "Teu redus 25-16-20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 16,
                "diameter3": 20
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 50,
        "product_code": "702.54.45",
        "name": "Teu redus 25-16-25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 16,
                "diameter3": 25
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 51,
        "product_code": "702.55.45",
        "name": "Teu redus 25-20-16 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 20,
                "diameter3": 16
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 52,
        "product_code": "702.56.45",
        "name": "Teu redus 25-20-20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 20,
                "diameter3": 20
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 53,
        "product_code": "702.57.45",
        "name": "Teu redus 25-20-25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 20,
                "diameter3": 25
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
        ]
    },
    {
        "id": 54,
        "product_code": "702.58.45",
        "name": "Teu redus 25-25-20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 25,
                "diameter3": 20
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 55,
        "product_code": "702.59.45",
        "name": "Teu redus 25-32-25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 25,
                "diameter2": 32,
                "diameter3": 25
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 56,
        "product_code": "702.60.45",
        "name": "Teu redus 32-20-25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 32,
                "diameter2": 20,
                "diameter3": 25
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 57,
        "product_code": "702.61.45",
        "name": "Teu redus 32-20-32 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 32,
                "diameter2": 20,
                "diameter3": 32
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 58,
        "product_code": "702.62.45",
        "name": "Teu redus 32-25-20 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 32,
                "diameter2": 25,
                "diameter3": 20
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 59,
        "product_code": "702.63.45",
        "name": "Teu redus 32-25-25 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 32,
                "diameter2": 25,
                "diameter3": 25
            },
        },
        "requiements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 60,
        "product_code": "702.64.45",
        "name": "Teu redus 32-25-32 (PPSU) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "redus",
            "outlets": {
                "diameter1": 32,
                "diameter2": 25,
                "diameter3": 32
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 1,
            },
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 61,
        "product_code": "702.65.45",
        "name": "Teu trecere FI 16x1/2\"x16 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "trecere FI",
            "diameter": [16, PLUMBING_NON_STANDARD_MEASUREMENTS.HALF_INCH, 16],
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 62,
        "product_code": "702.66.45",
        "name": "Teu trecere FI 20x1/2\"x20 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "trecere FI",
            "diameter": [20, PLUMBING_NON_STANDARD_MEASUREMENTS.HALF_INCH, 20],
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 20,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 63,
        "product_code": "702.67.45",
        "name": "Teu trecere FI 25x1/2\"x25 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "trecere FI",
            "diameter": [25, PLUMBING_NON_STANDARD_MEASUREMENTS.HALF_INCH, 25],
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 64,
        "product_code": "702.68.45",
        "name": "Teu trecere FI 25x3/4\"x25 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "trecere FI",
            "diameter": [25, PLUMBING_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH, 25],
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 25,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 65,
        "product_code": "702.69.45",
        "name": "Teu trecere FI 32x1\"x32 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TEU,
            "subtype": "trecere FI",
            "diameter": [32, PLUMBING_NON_STANDARD_MEASUREMENTS.ONE_INCH, 32],
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 32,
                "quantity": 2,
            },
        ],
    },
    {
        "id": 66,
        "product_code": "702.70.45",
        "name": "Trecere adaptor pentru cupru 16-Cu 15 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TRECERE,
            "subtype": "adaptor pentru cupru",
            "diameter": {
                "from": 16,
                "to": 15,
            },
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 67,
        "product_code": "702.71.45",
        "name": "Tija in L (25 cm) Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.TIJA,
            "subtype": "L",
            "diameter": 250,
        },
        "requirements": [
            {
                "type": PLUMBING_PRODUCT_TYPES.INEL,
                "subtype": "Q&E",
                "diameter": 16,
                "quantity": 1,
            },
        ],
    },
    {
        "id": 68,
        "product_code": "702.01.45",
        "name": "Inel 16 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.INEL,
            "subtype": "Q&E",
            "diameter": 16,
        }
    },
    {
        "id": 69,
        "product_code": "702.02.45",
        "name": "Inel 20 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.INEL,
            "subtype": "Q&E",
            "diameter": 20,
        }
    },
    {
        "id": 70,
        "product_code": "702.03.45",
        "name": "Inel 25 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.INEL,
            "subtype": "Q&E",
            "diameter": 25,
        }
    },
    {
        "id": 71,
        "product_code": "702.04.45",
        "name": "Inel 32 Q&E",
        "specifications": {
            "type": PLUMBING_PRODUCT_TYPES.INEL,
            "subtype": "Q&E",
            "diameter": 32,
        }
    },
];
