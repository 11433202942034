<template>
    <v-container class="pa-5" fluid>
        <v-row class="justify-center">
            <v-col cols="12" md="8">
                <v-card>
                    <v-card-title>Contract casă verde</v-card-title>
                    <v-card-text v-if="pdfLink">
                        <iframe v-if="!isMobile" :src="pdfLink" style="width: 100%; height: 750px; border: none;"
                                title="PDF Viewer"></iframe>
                        <div class="d-flex flex-column align-items-center">
                            <v-btn v-if="isMobile" variant="elevated" color="primary" :href="pdfLink"
                                   download="Contract.pdf">
                                Descarcă
                            </v-btn>
                            <v-btn color="success" variant="elevated" @click="handleSignAction" class="mt-3">Semnează
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- OTP Modal -->
        <v-dialog v-model="otpModal" max-width="600">
            <v-card>
                <v-card-title>Semnare</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="otp"
                        label="Introduceți OTP"
                        variant="outlined"
                        placeholder="Ex: 000000"
                    ></v-text-field>
                    <v-card-text v-if="otpMessage">
                        <v-list>
                            <v-list-item>
                                <template v-slot:prepend>
                                    <v-icon :color="otpColor">
                                        {{ otpColor === 'danger' ? 'mdi-alert-circle-outline' : 'mdi-check' }}
                                    </v-icon>
                                </template>
                                <v-list-item-title class="text-pre-wrap" :color="otpColor">
                                    {{ otpMessage }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="danger" @click="otpModal = false">Anulează</v-btn>
                    <v-btn color="success" @click="submitOtp">Trimite</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {showAlertModal} from "@/utils/utils";

export default {
    name: 'PDFViewer',
    data() {
        return {
            pdfLink: null,
            otpModal: false,
            otp: '',
            otpMessage: '',
            otpColor: '',
        };
    },
    computed: {
        linkUuid() {
            return this.$route.params.linkUuid.replace(/\.$/, ''); // Removes trailing dot if present
        },
        token() {
            return this.$route.query.token; // Directly retrieve the token without extra decoding
        },
        isMobile() {
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        }
    },
    async mounted() {
        await this.getContract();
    },
    methods: {
        /**
         * Fetches the contract PDF associated with the provided UUID and token.
         * Converts the response to a Blob and creates a downloadable/viewable URL.
         * Redirects to the 404 page if the contract is not found or if an error occurs.
         *
         * @async
         * @method
         */
        async getContract() {
            try {
                const response = await apiClient.post(`/api/pdf/view_contract/${this.linkUuid}/?token=${this.token}`, {}, {
                    responseType: 'blob' // Ensures the response is treated as a Blob
                });

                if (response.status === 200) {
                    const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
                    this.pdfLink = URL.createObjectURL(pdfBlob); // Create URL for iframe or download button
                } else {
                    // Redirect to 404 if status is not 200
                    this.$router.push({name: 'NotFound'});
                }
            } catch (error) {
                // Redirect to 404 if the error status is 404
                if (error.response && error.response.status === 404) {
                    this.$router.push({name: 'NotFound'});
                } else {
                    // Handle other unexpected errors if needed
                    showAlertModal(this.$store, 'An error occurred while loading the contract.', 'danger', 5000);
                }
            }
        },
        /**
         * Initiates the signing process by requesting an OTP for verification.
         * Displays an OTP modal if the OTP request is successful.
         * Handles errors such as an existing OTP or failed requests, showing appropriate messages.
         *
         * @async
         * @method
         */
        async handleSignAction() {
            try {
                const response = await apiClient.post(`/api/pdf/request_sign_otp/${this.linkUuid}/?token=${this.token}`);

                if (response.data.status === 'success') {
                    this.otpMessage = response.data.message;
                    this.otpColor = 'info';
                    this.otpModal = true; // Open OTP modal
                } else {
                    showAlertModal(this.$store, response.data.message || 'Failed to send OTP.', 'danger', 12000);
                }
            } catch (error) {
                if (error.response.status === 403 && error.response.data.message.includes('Un OTP există deja')) {
                    this.otpModal = true;
                    this.otpMessage = error.response.data.message;
                    this.otpColor = 'danger';
                } else {
                    showAlertModal(this.$store, error.response.data.message, 'danger', 12000);
                }
            }
        },
        /**
         * Submits the entered OTP to verify and sign the document.
         * Closes the OTP modal and clears OTP data on successful verification.
         * Displays error messages for invalid OTPs or other verification failures.
         *
         * @async
         * @method
         */
        async submitOtp() {
            try {
                const response = await apiClient.post(`/api/pdf/verify_otp_and_sign/${this.linkUuid}/?token=${this.token}`, {otp: this.otp});

                if (response.data.status === 'success') {
                    showAlertModal(this.$store, response.data.message, 'success', 5000);
                    this.otpModal = false; // Close modal
                    this.otpMessage = ''; // Clear any OTP error
                    this.otpColor = 'info';
                } else {
                    showAlertModal(this.$store, response.data.message, 'danger', 12000);
                }
            } catch (error) {
                if (error.response.status === 403 && error.response.data.message.includes('Cod OTP invalid')) {
                    this.otpMessage = error.response.data.message;
                    this.otpColor = 'danger';
                } else {
                    showAlertModal(this.$store, error.response.data.message, 'danger', 12000);
                }
            }
        }
    },
};
</script>
