<template>
    <div class="d-flex flex-column align-items-center justify-content-center w-100">
        <v-card class="w-100">
            <v-hover open-delay="150" close-delay="150" v-slot="{ isHovering, props }">
                <v-toolbar v-bind="props" @click="toggleComponentCollapse" class="cursor-pointer">
                    <v-toolbar-title>
                        Oferte
                    </v-toolbar-title>
                    <v-expand-transition>
                        <div v-if="isHovering" class="d-flex justify-content-center align-items-center"
                             style="background: rgb(var(--v-theme-on-surface-variant)); position: absolute; top: 65%; left: 50%;">
                            <v-icon v-if="collapsed">mdi-chevron-double-down</v-icon>
                            <v-icon v-else>mdi-chevron-double-up</v-icon>
                        </div>
                    </v-expand-transition>
                </v-toolbar>
            </v-hover>
            <v-expand-transition>
                <v-card-text v-if="!collapsed">
                    <v-data-table
                        :items="sortedItems"
                        :headers="headers"
                        class="elevation-1"
                        :variant="this.$store.state.vuetifyFieldType"
                        item-title="title"
                        item-key="key"
                        :loading="loading"
                        dense
                    >
                        <template v-slot:[`item.offer_id`]="{ item }">
                            <a
                                href="#"
                                class="text-decoration-none"
                                @click.prevent="showOfferDetails(item)"
                            ><span
                                v-if="differentCreator(item.agent ? item.agent : clientAgent,clientAgent)"
                            >#{{
                                    item.id
                                }}<span class="text-danger">*</span>
              <v-tooltip
                  content-class="custom-tooltip"
                  activator="parent"
                  location="top"
              >
                {{
                      differentCreator(item.agent, clientAgent)
                  }}
              </v-tooltip></span
                            >
                                <span v-else>#{{ item.id }}</span>
                            </a>
                        </template>
                        <template v-slot:[`item.modified`]="{ item }">
                            {{
                                new Date(item.modified).toLocaleString("ro-RO", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                })
                            }}
                        </template>
                        <template v-slot:[`item.dimension`]="{ item }">
                            <v-btn
                                v-if="item.type === 1 || item.type === 3"
                                color="primary"
                                variant="text"
                                href="#"
                                class="text-decoration-none"
                                @click.prevent="openDimensioning(item)"
                            >{{ item.offer_location_version }}
                            </v-btn>
                            <span v-else>Nu</span>
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            <span v-if="item.type === 1">IPA</span>
                            <span v-if="item.type === 2">SANITARE</span>
                            <span v-if="item.type === 3">VMC</span>
                            <span v-if="item.type === 4">{{
                                    item.custom_pdf_heading ? item.custom_pdf_heading + ' / ' : ''
                                }}SIMPLĂ</span>
                            <span v-if="item.type === 5">C. T. și COLOANE</span>
                            <span v-if="item.type === 6">TAVAN</span>
                            <span v-if="item.type === 7">PEREȚI</span>
                            <span v-if="item.type === 8">AUTOMATIZARE</span>
                            <span v-if="item.type === 9">VENTILOCONVECTOARE</span>
                            <span v-if="item.type === 10">CANAL TERMIC</span>
                            <span v-if="item.type === 11">PDC</span>
                            <span v-if="item.type === 12">ASPIRARE</span>
                            <span v-if="item.type === 13">SERVICII</span>
                        </template>
                        <template v-slot:[`item.total_area`]="{ item }">
                <span v-if="item.type === 1 || item.type === 3">
                    {{ item.total_area ? parseFloat(item.total_area).toFixed(2) + "m²" : "Nu" }}
                </span>
                            <span v-else>Nu</span>
                        </template>
                        <template v-slot:[`item.list_price_value`]="{ item }">
                    <span v-if="item.list_price_value !== '0.00'">{{
                            formatNumber(this, Math.round(item.list_price_value), true, 0)
                        }}</span>
                            <span v-else>--</span>
                        </template>
                        <template v-slot:[`item.discount`]="{ item }">
                    <span v-if="item.is_discount_mixed">
                          <span v-if="item.discount !== '0.00' && item.discount !== null">
                            {{ parseInt(item.discount) }}%
                          </span>
                          <span style="color: red;"> Mixt</span>
                    </span>
                            <span v-else-if="item.discount !== '0.00' && item.discount !== null">
                        {{ parseInt(item.discount) }}%
                    </span>
                            <span v-else>--</span>
                        </template>
                        <template v-slot:[`item.value_with_discount`]="{ item }">
                    <span v-if="item.value_with_discount !== '0.00'">{{
                            formatNumber(this, Math.round(item.value_with_discount), true, 0)
                        }}</span>
                            <span v-else>--</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon size="small" color="danger" @click="triggerDeleteDialog(item)">
                                mdi-delete
                            </v-icon>
                            <v-dialog v-model="deleteDialog" width="auto">
                                <v-card>
                                    <v-card-title
                                        class="text-danger d-flex align-items-center justify-content-between"
                                    >
                                        Atenție!
                                        <v-icon color="danger">mdi-alert-octagon-outline</v-icon>
                                    </v-card-title>
                                    <v-card-text>
                                        Această operație nu este reversibilă. Datele vor fi șterse
                                        permanent. <br/>
                                        Ești sigur că dorești să ștergi aceste date?
                                    </v-card-text>
                                    <v-card-actions class="justify-content-end">
                                        <v-btn
                                            color="primary"
                                            variant="text"
                                            @click="deleteDialog = false"
                                        >
                                            Nu
                                        </v-btn>
                                        <v-btn
                                            color="danger"
                                            variant="text"
                                            @click="deleteOffer(offerToDelete)"
                                        >
                                            Da
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import {checkForUpdates, formatNumber, showAlertModal} from "@/utils/utils";
import apiClient from "@/utils/apiClient";

export default {
    name: "OffersCard",
    components: {},
    emits: ['offers-fetched', 'location-offers-fetched'],
    props: {
        searchBy: Object,
        clientId: {
            type: String,
            default: null,
        },
        searchId: Number,
        clientOffersData: {
            type: Array,
            default: () => [],
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        clientAgent: {
            type: Number,
            default: null,
        },
        searchOfferType: {
            type: Number,
        }
    },
    data() {
        return {
            fetchedClientOffers: [],
            searchString: '',
            isCollapsed: this.collapsed,
            showOffersButton: false,
            collapsed: true,
            sortKey: null,
            sortDirection: 1,
            headers: [
                {title: 'Nr.', key: 'offer_id', align: 'center', sortable: true},
                {title: 'Data', key: 'modified', align: 'center', sortable: true},
                {title: 'Dim.', key: 'dimension', align: 'center', sortable: true},
                {title: 'Tip', key: 'type', align: 'center', sortable: true},
                {title: 'Suprafață', key: 'total_area', align: 'center', sortable: true},
                {title: 'Total preț listă', key: 'list_price_value', align: 'center', sortable: true, width: '120px'},
                {title: 'Discount', key: 'discount', align: 'center', sortable: true},
                {
                    title: 'Total cu discount',
                    key: 'value_with_discount',
                    align: 'center',
                    sortable: true,
                    width: '120px'
                },
                {title: 'Acțiuni', key: 'actions', align: 'center', sortable: false},
            ],
            deleteDialog: false,
            loading: false,
            offerToDelete: null,
        };
    },
    computed: {
        sortedItems() {
            const items = this.fetchedClientOffers.slice(); // Create a copy of the original array
            if (this.sortKey) {
                items.sort((a, b) => {
                    const valueA = this.getPropertyValue(a, this.sortKey);
                    const valueB = this.getPropertyValue(b, this.sortKey);

                    if (valueA === null) return 1; // Handle null values by pushing them to the end
                    if (valueB === null) return -1;

                    if (valueA > valueB) return this.sortDirection;
                    if (valueA < valueB) return -this.sortDirection;

                    return 0;
                });
            }
            return items;
        },
    },
    methods: {
        /**
         * Toggles the collapsed state of the OffersCard component.
         * If expanded, fetches offers data based on the `searchBy` criteria and updates the local state.
         * This method also filters offers by type if `searchOfferType` is defined.
         *
         * @async
         * @function toggleComponentCollapse
         * @returns {Promise<void>} Updates the `fetchedClientOffers` and `collapsed` state.
         */
        async toggleComponentCollapse() {
            this.collapsed = !this.collapsed;
            if (!this.collapsed) {
                this.searchString = '';
                if (this.searchBy.criteria === 'location') {
                    this.searchString = '_by_location_id';
                } else if (this.searchBy.criteria === 'client') {
                    this.searchString = '_by_client_id';
                }
                if (this.clientOffersData && this.clientOffersData.length > 0) {
                    this.fetchedClientOffers = this.clientOffersData;
                    if (this.searchOfferType) {
                        this.fetchedClientOffers = this.fetchedClientOffers.filter(offer => offer.type === this.searchOfferType);
                    }
                    this.fetchedClientOffers.sort((a, b) => b.id - a.id);
                } else if (this.searchBy.value && !(this.clientOffersData && this.clientOffersData.length > 0)) {
                    await this.fetchClientOffers();
                }
            }
        },
        formatNumber,
        /**
         * Checks if the creator of the offer is different from the client's assigned agent.
         *
         * @param {number} offerAgent - The agent ID associated with the offer.
         * @param {number} clientAgent - The agent ID assigned to the client.
         * @returns {string|null} Returns a message string if the creators are different, or `null` otherwise.
         */
        differentCreator(offerAgent, clientAgent) {
            if (clientAgent !== offerAgent) {
                let usersNames = JSON.parse(sessionStorage.getItem('myUsers'));
                let agent_name = usersNames.find(user => user.id === offerAgent);
                let string = 'Oferta a fost creată de '
                string += agent_name.full_name ? agent_name.full_name : agent_name.email;
                return string;
            }
            return null;
        },
        /**
         * Toggles the visibility of the delete confirmation dialog and sets the current offer to be deleted.
         * This method is intended to be called when a user initiates the delete action by clicking the delete
         * icon associated with an offer. It updates the component's state to reflect the offer selected for deletion
         * and toggles the visibility of a confirmation dialog.
         *
         * @param {Object} offer - The offer object that is intended to be deleted. This object should
         * contain at least an identifier (id) that uniquely identifies the offer within the dataset.
         */
        triggerDeleteDialog(offer) {
            this.offerToDelete = offer;
            this.deleteDialog = !this.deleteDialog;
        },
        /**
         * Sorts the fetched offers based on the provided key.
         * If the same key is clicked again, the sort direction toggles between ascending and descending.
         *
         * @param {string} key - The key by which to sort the offers.
         */
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortDirection *= -1; // Reverse the sort direction if the same key is clicked again
            } else {
                this.sortKey = key;
                this.sortDirection = 1; // Default to ascending order
            }
        },
        /**
         * Retrieves the value of a nested property from an object using a dot-separated key string.
         *
         * @param {Object} item - The object to retrieve the value from.
         * @param {string} key - The dot-separated key string representing the property path.
         * @returns {any} The value of the specified property, or `0` if the property is `null` or `undefined`.
         */
        getPropertyValue(item, key) {
            const keys = key.split('.');
            let value = item;

            for (const k of keys) {
                value = value[k];
                if (value === undefined || value === null) {
                    // Return a placeholder value for null values
                    return 0;
                }
            }

            return value;
        },
        lastModified(offer) {
            return 'Ultima modificare: ' + new Date(offer.modified).toLocaleDateString('ro-RO')
        },
        /**
         * Fetches client offers based on the specified search criteria and updates the component state.
         * Filter offers by type if `searchOfferType` is defined.
         *
         * @async
         * @function fetchClientOffers
         * @returns {Promise<void>} Updates `fetchedClientOffers` and emits relevant events.
         */
        async fetchClientOffers() {
            this.loading = true;
            try {
                const response = await apiClient.get(`/api/get_offer_data${this.searchString}/${this.searchBy.value}/`);
                if (response.data.offers.length > 0) {
                    this.fetchedClientOffers = JSON.parse(JSON.stringify(response.data.offers)) || [];
                    if (this.searchOfferType) {
                        this.fetchedClientOffers = this.fetchedClientOffers.filter(offer => offer.type === this.searchOfferType);
                    }
                    this.fetchedClientOffers.sort((a, b) => b.id - a.id);
                    this.$emit('location-offers-fetched', this.fetchedClientOffers);
                } else {
                    this.$emit('offers-fetched', false);
                }
            } catch (error) {
                console.error("Error fetching client offers:", error.response ? error.response.data : error.message);
                showAlertModal(this.$store, 'A apărut o eroare la încărcarea ofertelor. Vă rugăm să încercați din nou.', 'danger', 12000, error);
            } finally {
                this.loading = false;
            }
        },
        /**
         * Asynchronously deletes an offer from the server and updates the local state to reflect this change.
         * This method sends a DELETE request to the server targeting a specific offer by its ID. Upon successful
         * deletion, it filters out the deleted offer from the local list of fetched client offers. It also manages
         * loading state indicators and handles any errors that might occur during the process by logging the error
         * and displaying an alert modal. A success message is displayed in an alert modal after the offer is
         * successfully deleted.
         *
         * @param {Object} offer - The offer object to be deleted. This object must include an `id` property
         * that uniquely identifies the offer.
         * @returns {Promise<void>} A promise that resolves when the deletion process has completed. This includes
         * updating the local state and handling any errors. It does not return a value.
         * @throws {Error} Throws an error if the deletion request to the server fails. The error is logged to the console
         * and a warning message is displayed to the user via an alert modal.
         */
        async deleteOffer(offer) {
            this.loading = true;
            try {
                await apiClient.delete(`/api/delete_offer/${offer.id}/`);
                this.fetchedClientOffers = this.fetchedClientOffers.filter(o => o.id !== offer.id);
                this.$emit('location-offers-fetched', this.fetchedClientOffers);
                showAlertModal(this.$store, 'Oferta a fost ștearsă cu succes.', 'success', 2000);
            } catch (error) {
                console.error("Error deleting offer:", error.response ? error.response.data : error.message);
                showAlertModal(this.$store, 'A apărut o eroare la ștergerea ofertei.', 'warning', 2000, error);
            } finally {
                this.loading = false;
                this.deleteDialog = false;
            }
        },
        /**
         * Displays the details of the selected offer.
         * Redirects to the appropriate route based on the offer type or opens a new tab for unsupported types.
         *
         * @async
         * @function showOfferDetails
         * @param {Object} item - The offer object containing its details.
         * @returns {Promise<void>} Navigates to the appropriate route or shows an error if updates fail.
         */
        async showOfferDetails(item) {
            let offer = item;
            this.$store.state.fetchedClientOffers = this.fetchedClientOffers;

            try {
                // Check for updates before proceeding
                await checkForUpdates(this);

                // Proceed with showing the offer based on its type
                switch (offer.type) {
                    case 2:
                        this.$router.push({
                            name: "OfferPlumbing",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 3:
                        this.$router.push({
                            name: "OfferVMC",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 4:
                        this.$router.push({
                            name: "OfferSimple",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 5:
                        this.$router.push({
                            name: "OfferTechnicalRoom",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 6:
                        this.$router.push({
                            name: "OfferCeiling",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 7:
                        this.$router.push({
                            name: "OfferWalls",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 8:
                        this.$router.push({
                            name: "OfferAutomation",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 9:
                        this.$router.push({
                            name: "OfferFanCoilUnit",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 10:
                        this.$router.push({
                            name: "OfferThermalChannel",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 11:
                        this.$router.push({
                            name: "OfferPDC",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 12:
                        this.$router.push({
                            name: "OfferVacuum",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    case 13:
                        this.$router.push({
                            name: "OfferServices",
                            params: {
                                clientId: offer.client.id ? offer.client.id : offer.client,
                                locationId: offer.location.id,
                                offerId: offer.id,
                            },
                        });
                        break;
                    default:
                        window.open(process.env.VUE_APP_API_BASE_URL + `/app/oferta/detaliu/${offer.id}`, '_blank');
                        break;
                }
            } catch (error) {
                console.error('Error while checking for updates or showing offer details:', error);
                // You can also show a notification or modal if needed
            }
        },
        /**
         * Opens the dimensioning section for the specified offer.
         * Redirects to the appropriate route based on the offer type.
         *
         * @param {Object} offer - The offer object containing its details.
         */
        openDimensioning(offer) {
            switch (offer.type) {
                case 3:
                    this.$router.push({
                        name: "OfferVMC",
                        params: {
                            clientId: offer.client?.id ? offer.client.id : offer.client,
                            locationId: offer.location?.id ? offer.location.id : offer.location,
                        },
                        hash: "#section2",
                    });
                    break;
                default:
                    window.open(process.env.VUE_APP_API_BASE_URL + `/app/client/${offer.client?.id ? offer.client.id : offer.client}/${offer.location?.id ? offer.location.id : offer.location}`, '_blank');
                    break;
            }
        },
    },
};
</script>

<style scoped>
h5 {
    cursor: pointer;
}
</style>
