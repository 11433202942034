<template>
    <v-card>
        <v-toolbar class="return-client-toolbar" style="justify-content: center;">
            <v-row justify="start">
                <v-col
                    cols="auto"
                    class="mx-2"
                    style="
                        font-size: 1.25rem;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 1.75rem;
                        text-transform: none;
                        margin-left: 16px;
                        align-self: center;
                    "
                >
                    Client {{ clientIndex + 1 }}:
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    sm="12"
                    class="mr-9 mx-2"
                >
                    <v-text-field
                        v-model="clientData.name"
                        label="Nume client"
                        variant="outlined"
                        density="compact"
                        persistent-hint
                        :error="!clientData.name"
                        hint="Trebuie să corespundă cu numele de pe factură"
                        @update:modelValue="emitClientUpdate"
                        :readonly="!userPermissions.returnsManagement.canEditClientName || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                    ></v-text-field>
                </v-col>

                <!-- Search Bar -->
                <v-col cols="12" md="3" sm="12" class="mx-2">
                    <v-text-field
                        v-model="clientData.searchQuery"
                        variant="outlined"
                        density="compact"
                        hide-details
                        clearable
                        placeholder="Căutare produse"
                        @update:modelValue="emitClientUpdate"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </v-col>

                <!-- Checkbox -->
                <v-col cols="12" md="3" sm="12" class="mx-2">
                    <v-checkbox
                        v-model="clientData.showOnlyWithQuantity"
                        label="Cu cantitate"
                        density="compact"
                        hide-details
                        @update:modelValue="emitClientUpdate"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-toolbar-items>
                <v-btn
                    color="error"
                    icon
                    :readonly="!userPermissions.returnsManagement.canDeleteClient || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                    @click.stop="$emit('remove-client', clientIndex)"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <!-- Items Table -->
        <v-data-table
            v-if="$vuetify.display.mdAndUp"
            :headers="headers"
            :items="getFilteredItems"
            item-key="product_code"
            class="elevation-1"
            :items-per-page="5"
        >
            <template v-slot:[`item.quantity`]="{ item }">
                <div class="d-flex flex-row">
                    <v-text-field
                        :readonly="!canEditQuantities"
                        v-model="item.quantity"
                        variant="outlined"
                        density="compact"
                        hide-details
                        type="number"
                        @update:modelValue="
                            () => {
                                emitClientUpdate();
                                checkShowOnlyWithQuantity(item);
                            }
                        "
                    >
                        <template v-slot:append-inner>
                            {{
                                measurementUnits.find((unit) => unit.value ===
                                    item.measurement_unit)?.text ??
                                item.measurement_unit
                            }}
                        </template>
                    </v-text-field>
                    <v-checkbox
                        v-if="canEditRealQuantitiesOrReceived"
                        class="d-flex justify-content-center"
                        :readonly="!isCorrectQuantityCheckboxEditable()"
                        v-model="item.is_correct_quantity"
                        variant="outlined"
                        density="compact"
                        :hide-details="!shouldEnforceRealOrCorrect(item)"
                        :error="shouldEnforceRealOrCorrect(item)"
                        @update:modelValue="handleCorrectQuantity(item)"
                    >
                        <v-tooltip location="top" activator="parent">
                            Cantitate corectă
                        </v-tooltip>
                    </v-checkbox>
                </div>
            </template>
            <template v-slot:[`item.real_quantity`]="{ item }">
                <v-text-field
                    v-show="canEditRealQuantitiesOrReceived"
                    :readonly="!isRealQuantityFieldEditable(item)"
                    v-model="item.real_quantity"
                    variant="outlined"
                    density="compact"
                    hide-details
                    type="number"
                    :error="shouldEnforceRealOrCorrect(item)"
                    @update:modelValue="validateRealQuantity(item, item.real_quantity, item.quantity)"
                >
                    <template v-slot:append-inner>
                        {{
                            measurementUnits.find((unit) => unit.value ===
                                item.measurement_unit)?.text ??
                            item.measurement_unit
                        }}
                    </template>
                </v-text-field>
            </template>
            <template v-slot:[`item.observations`]="{ item }">
                <v-text-field
                    :readonly="!canEditQuantities"
                    v-model="item.observations"
                    variant="outlined"
                    density="compact"
                    hide-details
                    @update:modelValue="emitClientUpdate"
                ></v-text-field>
            </template>
            <template v-slot:[`item.reception_observations`]="{ item }">
                <v-text-field
                    :readonly="!canEditRealQuantities || returnData.received"
                    v-model="item.reception_observations"
                    variant="outlined"
                    density="compact"
                    :hide-details="!shouldShowReceptionError(item)"
                    :error="shouldShowReceptionError(item)"
                    :error-messages="shouldShowReceptionError(item) ? 'Acest câmp este obligatoriu!' : ''"
                    @update:modelValue="emitClientUpdate"
                ></v-text-field>
            </template>
            <template v-slot:[`item.is_for_service`]="{ item }">
                <v-checkbox
                    v-model="item.is_for_service"
                    :readonly="!userPermissions.returnsManagement.canEditProductService || !item.quantity"
                    class="d-flex justify-content-center"
                    @update:modelValue="emitClientUpdate"
                ></v-checkbox>
            </template>
        </v-data-table>

        <!-- Mobile Layout -->
        <v-card-text v-else>
            <recycle-scroller
                :items="getFilteredItems"
                :item-size="400"
                key-field="product_code"
                class="scroller"
                :style="{ height: clientData.showOnlyWithQuantity ? '400px' : '1150px', overflowY: 'auto' }"
            >
                <template v-slot="{ item }">
                    <v-card>
                        <v-toolbar density="compact">
                            <v-toolbar-title>{{ item.product_code }} - {{ item.product_name }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="item.quantity"
                                        label="Cantitate"
                                        type="number"
                                        density="compact"
                                        variant="outlined"
                                        hide-details
                                        dirty
                                        :readonly="!canEditQuantities"
                                        @update:modelValue="emitClientUpdate"
                                    >
                                        <template v-slot:append-inner>
                                            {{
                                                measurementUnits.find((unit) => unit.value ===
                                                    item.measurement_unit)?.text ??
                                                item.measurement_unit
                                            }}
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="item.observations"
                                        label="Observații"
                                        density="compact"
                                        variant="outlined"
                                        hide-details
                                        :readonly="!canEditQuantities"
                                        @update:modelValue="emitClientUpdate"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" v-show="canEditRealQuantitiesOrReceived">
                                    <v-text-field
                                        :readonly="!isRealQuantityFieldEditable(item)"
                                        v-model="item.real_quantity"
                                        variant="outlined"
                                        label="Cantitate reală"
                                        density="compact"
                                        hide-details
                                        type="number"
                                        :error="shouldEnforceRealOrCorrect(item)"
                                        @update:modelValue="validateRealQuantity(item, item.real_quantity, item.quantity)"
                                    >
                                        <template v-slot:append-inner>
                                            {{
                                                measurementUnits.find((unit) => unit.value ===
                                                    item.measurement_unit)?.text ??
                                                item.measurement_unit
                                            }}
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" class="d-flex flex-column align-items-start"
                                       v-show="canEditRealQuantitiesOrReceived">
                                    <v-checkbox
                                        label="Cantitate corectă"
                                        class="d-flex justify-content-center"
                                        :readonly="!isCorrectQuantityCheckboxEditable()"
                                        v-model="item.is_correct_quantity"
                                        variant="outlined"
                                        density="compact"
                                        hide-details
                                        :error="shouldEnforceRealOrCorrect(item)"
                                        @update:modelValue="handleCorrectQuantity(item)"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        :readonly="!canEditRealQuantities"
                                        v-model="item.reception_observations"
                                        variant="outlined"
                                        density="compact"
                                        :hide-details="!shouldShowReceptionError(item)"
                                        label="Observații recepție"
                                        :error="shouldShowReceptionError(item)"
                                        :error-messages="shouldShowReceptionError(item) ? 'Acest câmp este obligatoriu!' : ''"
                                        @update:modelValue="emitClientUpdate"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" class="d-flex flex-column align-items-start">
                                    <v-checkbox
                                        label="Service"
                                        v-model="item.is_for_service"
                                        density="compact"
                                        hide-details
                                        class="d-flex justify-content-center"
                                        @update:modelValue="emitClientUpdate"
                                        :readonly="!userPermissions.returnsManagement.canEditProductService || !item.quantity"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>
            </recycle-scroller>
        </v-card-text>
    </v-card>
</template>

<script>
import {sortByProductCode} from "@/utils/utils";
import {mapGetters} from "vuex";
import {RecycleScroller} from 'vue3-virtual-scroller';

export default {
    name: "ReturnClientComponent",
    components: {
        RecycleScroller,
    },
    props: {
        client: {
            type: Object,
            required: true,
        },
        clientIndex: {
            type: Number,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        returnData: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            clientData: {
                ...JSON.parse(JSON.stringify(this.client)), // Deep copy
                isValid: true, // Initialize as true, will be validated below
            },
            measurementUnits: [
                {value: 1, text: "m²"},
                {value: 2, text: "m"},
                {value: 3, text: "pungă"},
                {value: 4, text: "buc"},
                {value: 5, text: "l"},
                {value: 6, text: "set"},
                {value: 7, text: "box"},
                {value: 8, text: "rolă"},
                {value: 9, text: "km"},
                {value: 10, text: "kg"},
                {value: 11, text: "ora"},
            ],
        };
    },
    emits: ["update-client", "remove-client"],
    computed: {
        ...mapGetters(['userPermissions']),
        /**
         * Checks if quantities can be edited based on user permissions and returnData state.
         * @returns {boolean} True if the user can edit product quantities, false otherwise.
         */
        canEditQuantities() {
            return (
                this.userPermissions.isSuperuser ||
                (this.userPermissions.returnsManagement.canEditProductsQuantities && !this.returnData.operated)
            );
        },
        /**
         * Checks if user can edit real quantities or if the return has been received.
         * @returns {boolean} True if user can edit real quantities or return is received, false otherwise.
         */
        canEditRealQuantitiesOrReceived() {
            return (
                this.userPermissions.returnsManagement.canEditProductsRealQuantities ||
                this.returnData.received
            );
        },
        /**
         * Checks if the user can edit real quantities based on user permissions.
         * @returns {boolean} True if the user can edit real quantities, false otherwise.
         */
        canEditRealQuantities() {
            return this.userPermissions.returnsManagement.canEditProductsRealQuantities;
        },
        /**
         * Filters, searches, and sorts the products for display.
         * @returns {Array} The filtered and sorted list of product data.
         */
        getFilteredItems() {
            const {productData, searchQuery, showOnlyWithQuantity, sortDirection = 1} = this.clientData;

            return productData
                .filter((item) => {
                    // Apply search query
                    if (searchQuery) {
                        const query = searchQuery.toLowerCase();
                        return (
                            item.product_name.toLowerCase().includes(query) ||
                            item.product_code.toLowerCase().includes(query)
                        );
                    }
                    return true; // No search query
                })
                .filter((item) => {
                    // Apply quantity filter
                    if (showOnlyWithQuantity) {
                        return item.quantity && item.quantity > 0;
                    }
                    return true;
                })
                .sort((a, b) => sortByProductCode(a, b, sortDirection));
        },
    },
    mounted() {
        // Initialize productData if empty
        if (!this.clientData.productData || this.clientData.productData.length === 0) {
            this.clientData.productData = this.products.map((product) => ({
                ...product,
                quantity: null,
                observations: "",
                is_for_service: false,
                real_quantity: null,
                reception_observations: "",
                is_correct_quantity: false,
            }));
        }
        // Validate on mount
        this.updateValidationState();
    },
    methods: {
        handleCorrectQuantity(item) {
            if (item.is_correct_quantity) {
                item.real_quantity = item.quantity;
            }
            this.emitClientUpdate();
        },
        /**
         * Determines if enforcing real_quantity or is_correct_quantity is required.
         * Condition: Not superuser, can edit real quantities, and product has quantity > 0.
         * In that scenario, either real_quantity or is_correct_quantity must be provided.
         * @param {Object} product - The product item.
         * @returns {boolean} True if enforcement is required and not met, else false.
         */
        shouldEnforceRealOrCorrect(product) {
            if (
                !this.userPermissions.isSuperuser &&
                this.canEditRealQuantities &&
                product.quantity && product.quantity > 0
            ) {
                const realQty = parseInt(product.real_quantity, 10);
                const isCorrect = !!product.is_correct_quantity;
                const hasRealQty = !isNaN(realQty) && realQty > 0;
                return !(isCorrect || hasRealQty);
            }
            return false;
        },
        /**
         * Emits an event to update the client data to the parent component and revalidates the data.
         */
        emitClientUpdate() {
            this.updateValidationState();
            this.$emit("update-client", {
                clientIndex: this.clientIndex,
                clientData: this.clientData,
            });
        },
        /**
         * Parses the quantity and real_quantity fields of a product into integers.
         * @param {Object} product - The product object with quantity and real_quantity.
         * @returns {{realQty: number, qty: number}} Parsed integer quantities.
         */
        parseQuantities(product) {
            const realQty = parseInt(product.real_quantity, 10);
            const qty = parseInt(product.quantity, 10);
            return {realQty, qty};
        },
        /**
         * Checks if reception_observations is required and missing.
         * If real_quantity differs from quantity, reception_observations must be filled.
         * @param {Object} product - The product object.
         * @returns {boolean} True if the field is required and not provided, else false.
         */
        shouldShowReceptionError(product) {
            const {realQty, qty} = this.parseQuantities(product);
            if (isNaN(realQty) || isNaN(qty)) return false;
            if (realQty !== qty) {
                return !product.reception_observations || product.reception_observations.trim() === "";
            }
            return false;
        },
        /**
         * Validates the real_quantity entered by the user.
         * Shows a warning if it equals the previous quantity and then updates the client data.
         * @param item
         * @param {number|string} realQuantity - The entered real quantity.
         * @param {number|string} previousQuantity - The previous quantity.
         */
        validateRealQuantity(item, realQuantity, previousQuantity) {
            if (parseInt(realQuantity, 10) === parseInt(previousQuantity, 10) && !item.is_correct_quantity) {
                item.is_correct_quantity = true;
            }
            this.emitClientUpdate();
        },
        /**
         * Checks if showOnlyWithQuantity should be enabled based on the item's quantity.
         * If the item quantity is > 0 and showOnlyWithQuantity is not explicitly false, it is set to true.
         * @param {Object} item - The table item containing raw product data.
         */
        checkShowOnlyWithQuantity(item) {
            if (this.clientData.showOnlyWithQuantity === false) return;
            if (!this.clientData.showOnlyWithQuantity && item.quantity > 0) {
                this.clientData.showOnlyWithQuantity = true;
            }
        },
        /**
         * Updates the validation state of the client data.
         * Sets clientData.isValid based on the validity check of product data.
         */
        updateValidationState() {
            this.clientData.isValid = this.checkClientValidity();
        },
        /**
         * Checks the overall validity of the client's product data.
         * Ensures that if real_quantity equals quantity, it's invalid.
         * If real_quantity differs from quantity and is provided, reception_observations must be filled.
         * @returns {boolean} True if all products are valid, else false.
         */
        checkClientValidity() {
            for (const product of this.clientData.productData) {
                const realQty = parseInt(product.real_quantity, 10);
                const qty = parseInt(product.quantity, 10);

                if (!isNaN(realQty) && !isNaN(qty) && realQty === qty && this.returnData.is_correct_quantity) {
                    return false;
                }

                if (!isNaN(realQty) && !isNaN(qty) && realQty !== qty) {
                    if (!product.reception_observations || product.reception_observations.trim() === "") {
                        return false;
                    }
                }
            }
            return true;
        },
        /**
         * Determines if the real_quantity field should be editable.
         * It is editable if canEditRealQuantities is true and is_correct_quantity is false.
         * @param {Object} product - The product object.
         * @returns {boolean} True if real_quantity field is editable, else false.
         */
        isRealQuantityFieldEditable(product) {
            return this.canEditRealQuantities && !product.is_correct_quantity && this.userPermissions.returnsManagement.canEditProductsRealQuantities && !this.returnData.received;
        },
        /**
         * Determines if the is_correct_quantity checkbox is editable.
         * It is editable if canEditRealQuantities is true and no real_quantity is set.
         * @returns {boolean} True if the is_correct_quantity checkbox is editable, else false.
         */
        isCorrectQuantityCheckboxEditable() {
            return this.canEditRealQuantities && this.userPermissions.returnsManagement.canEditIsProductQuantityCorrect && !this.returnData.received;
        },
    },
};
</script>

<style>
.return-client-toolbar .v-toolbar__content {
    min-height: 80px !important;
    height: unset !important;
}
</style>
