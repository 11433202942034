<template>
    <div>
        <BreadCrumbsWithButtons :buttons="buttons" :pdfOptions="pdfOptions.simple" />
        <a href="#" id="section1" ref="section1"></a>
        <OfferInformation
            v-if="client && location"
            :client="client"
            :location="location"
            :offer="offer"
            :offerId="offerId"
            @update="updateOfferData"
            @offer-buttons-in-view="isOfferButtonsInView = $event"
        >
        </OfferInformation>
        <a href="#" id="section2" ref="section2"></a>
        <ProductTable
            v-if="loadProductTable"
            :offer="offer"
            :offered-products="offeredProducts ?? []"
            :offer-type="{type: 'SERVICII', productCategories: ['SERVICII']}"
            :headers="productTableHeaders"
            :high-priority-products="['1101.00.61']"
            @sort-pdf-by-number="setPDFCustomSorting"
            @update-products="updateOfferProducts"
            @update-offer="updateOfferData"
            @selected-price-list="updateOfferPriceList"
            :price-list-type="this.$store.state.PRICE_LIST_TYPES.SERVICES.value"
        >
        </ProductTable>
        <OfferPaymentTerms
            :offer="offer"
            @update_payment_terms="updatePaymentTerms"
        ></OfferPaymentTerms>
    </div>
</template>

<script>
import navMixin from "@/mixins/navMixin";
import OfferInformation from "@/components/offer/OfferInformation.vue";
import offerMixin from "@/mixins/offerMixin";
import ProductTable from "@/components/offer/ProductTable.vue";
import OfferPaymentTerms from "@/components/offer/OfferPaymentTerms.vue";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";

export default {
    name: "OfferServices",
    mixins: [navMixin, offerMixin],
    components: {
        BreadCrumbsWithButtons,
        OfferPaymentTerms,
        ProductTable,
        OfferInformation,
    },
    data() {
        return {
            sidebarLinks: [
                {
                    name: 'Informații',
                    link: '#section1',
                    id: 'section1'
                },
                {
                    name: 'Produse',
                    link: '#section2',
                    id: 'section2'
                },
            ],
            productTableHeaders: [
                {
                    title: "Nr.",
                    key: "number",
                    align: "center",
                    show: false,
                    width: "100px",
                },
                {
                    title: "Cod articol",
                    key: "product_code",
                    align: "center",
                    show: true,
                    width: "140px",
                },
                {
                    title: "Nume articol",
                    key: "product_name",
                    align: "start",
                    show: true,
                },
                {
                    title: "Categorie",
                    key: "assigned_sub_category",
                    align: "center",
                    show: true,
                },
                {
                    title: "Cantitate",
                    key: "quantity",
                    align: "center",
                    show: true,
                    width: "130px",
                },
                {
                    title: "UM",
                    key: "measurement_unit",
                    align: "center",
                    show: true,
                    width: "70px",
                },
                {
                    title: "Preț listă cu TVA",
                    key: "price",
                    align: "end",
                    show: true,
                },
                {
                    title: "Discount",
                    key: "discount",
                    align: "center",
                    show: true,
                    width: "120px",
                },
                {
                    title: "Preț final cu TVA",
                    key: "final_price",
                    align: "end",
                    show: true,
                },
                {
                    title: "Valoare finală cu TVA",
                    key: "final_total_price",
                    align: "end",
                    show: true,
                },
            ],
        }
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            if (from.name === 'OfferServices' && to.name !== 'OfferServices' && this.isProductsChanged === true) { // Replace 'FormPage' with your form's route name
                const shouldLeave = this.checkFormBeforeLeave();
                if (!shouldLeave) {
                    next(false); // Prevent route change
                } else {
                    this.isProductsChanged = false;
                    next(); // Proceed with the route change
                }
            } else {
                next(); // Proceed with the route change for routes other than the form page
            }
        });
    },
    mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks
    },
    methods: {
        checkFormBeforeLeave() {
            return confirm('Sunteți sigur că doriți să părăsiți formularul? Modificările nesalvate vor fi pierdute.');
        },
    },

}
</script>