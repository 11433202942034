<template>
    <v-expand-transition>
        <div class="alert" :class="alertClasses" v-if="this.$store.state.alert.show">
            <v-row>
                <v-col cols="11">
                    <div v-html="this.$store.state.alert.message"></div>
                </v-col>
                <v-col cols="1">
                    <v-btn icon variant="plain" class="alert-close-button" density="compact" @click="closeAlert">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <div v-if="this.$store.state.alert.errorDetails">
                <v-card color="danger" style="margin-top: 10px;">
                    <v-toolbar color="danger" density="compact">
                        <v-toolbar-title>Mesaj</v-toolbar-title>
                        <v-toolbar-items>
                            <v-btn color="white" icon @click="copyToClipboard">
                                <v-tooltip activator="parent" location="top">
                                    Copiază datele erorii
                                </v-tooltip>
                                <v-icon v-if="!isErrorCopied">
                                    mdi-content-copy
                                </v-icon>
                                <v-icon v-else>mdi-check</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        {{ errorMessage }}
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    data() {
        return {
            errorMessage: '',
            isErrorCopied: false,
        };
    },
    computed: {
        alertClasses() {
            return {
                'alert-success': this.$store.state.alert.type === 'success',
                'alert-danger': this.$store.state.alert.type === 'danger',
                'alert-warning': this.$store.state.alert.type === 'warning',
                'alert-info': this.$store.state.alert.type === 'info'
            };
        }
    },
    methods: {
        closeAlert() {
            this.$store.commit('clearCurrentAlert');
        },
        copyToClipboard() {
            const errorDetailsString = JSON.stringify(this.$store.state.alert.errorDetails.response, null, 2);
            navigator.clipboard.writeText(errorDetailsString).then(() => {
                this.isErrorCopied = true;
                setTimeout(() => {
                    this.isErrorCopied = false;
                }, 2000);
            });
        },
    },
    watch: {
        '$store.state.alert.show': {
            handler(newV) {
                if (newV && this.$store.state.alert.errorDetails) {
                    this.errorMessage = JSON.parse(JSON.stringify(this.$store.state.alert.errorDetails)).message;
                }
            },
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.alert-close-button {
    position: absolute !important;
    right: 0;
    top: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.alert {
    position: fixed !important;
    top: 2%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1999;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: auto;
    height: auto;
    overflow: auto;
}

.error-details {
    margin-top: 10px;
}

.error-details div {
    padding: 10px;
    overflow-x: auto;
    word-break: break-word;
    white-space: pre-wrap; /* Ensures text wraps correctly */
    word-wrap: break-word; /* Ensures long words break correctly */
}
</style>
