<!-- NotFound.vue -->
<template>
    <v-container class="text-center pa-5">
        <v-row>
            <v-col>
                <h1>404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'NotFound'
};
</script>
