<template>
    <v-row class="align-center">
        <v-col cols="6">
            <v-autocomplete
                variant="outlined"
                density="compact"
                hide-details
                v-model="selectedFitting"
                :items="plumbingFittings"
                item-title="title"
                item-value="value"
                label="Model"
                @click:clear="emitUpdate"
                @update:modelValue="emitUpdate"
            />
        </v-col>
        <v-col cols="5">
            <v-text-field
                variant="outlined"
                density="compact"
                hide-details
                v-model="quantity"
                type="number"
                label="Cantitate"
                min="1"
                @blur="emitUpdate"
            />
        </v-col>
        <v-col cols="1" class="text-right">
            <v-btn icon variant="plain" color="danger" @click="removeItem">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "PlumbingSetupItem",
    props: {
        plumbingItem: {
            type: Object,
            required: true
        },
        plumbingFittings: {
            type: Array,
            required: true,
        },
    },
    emits: ['update-item', 'remove-item'],
    data() {
        return {
            selectedFitting: this.plumbingItem.fitting || null,
            quantity: this.plumbingItem.quantity || 1,
        };
    },
    methods: {
        emitUpdate() {
            this.$emit("update-item", {
                fitting: this.selectedFitting,
                quantity: this.quantity,
            });
        },
        removeItem() {
            this.$emit("remove-item");
        }
    }
}
</script>

<style scoped>
</style>
