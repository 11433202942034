<template>
    <BreadCrumbsWithButtons :buttons="buttons" :pdfOptions="pdfOptions.simple" />
    <a href="#" id="section1" ref="section1"></a>
    <OfferInformation
        v-if="client && location"
        :client="client"
        :location="location"
        :offer="offer"
        :offerId="offerId"
        @update="updateOfferData"
        @offer-buttons-in-view="isOfferButtonsInView = $event"
    >
    </OfferInformation>
    <a href="#" id="section2" ref="section2"></a>
    <PlumbingSetup
        :offer-id="offerId"
        :plumbing-fitting-products="availablePlumbingFittingProducts"
        @update-selected-plumbing-items="handleUpdateSelectedPlumbingItems"
    />
    <ProductTable
        v-if="loadProductTable"
        :offer="offer"
        :offered-products="offeredProducts ?? []"
        :offer-type="offerType"
        :headers="productTableHeaders"
        @sort-pdf-by-number="setPDFCustomSorting"
        @update-products="updateOfferProducts"
        @update-offer="updateOfferData"
        @selected-price-list="updateOfferPriceList"
        :price-list-type="this.$store.state.PRICE_LIST_TYPES.GENERAL.value"
    >
    </ProductTable>
    <OfferPaymentTerms
        :offer="offer"
        @update_payment_terms="updatePaymentTerms"
    ></OfferPaymentTerms>
</template>

<script>
import navMixin from "@/mixins/navMixin";
import OfferInformation from "@/components/offer/OfferInformation.vue";
import offerMixin from "@/mixins/offerMixin";
import ProductTable from "@/components/offer/ProductTable.vue";
import OfferPaymentTerms from "@/components/offer/OfferPaymentTerms.vue";
import emitter from "@/utils/emitter";
import {plumbingProducts} from "@/PLUMBING/plumbingProducts";
import PlumbingSetup from "@/components/offer/PlumbingSetup.vue";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";

export default {
    name: "OfferPlumbing",
    mixins: [navMixin, offerMixin],
    components: {
        BreadCrumbsWithButtons,
        PlumbingSetup,
        OfferPaymentTerms,
        ProductTable,
        OfferInformation,
    },
    data() {
        return {
            sidebarLinks: [
                {
                    name: 'Informații',
                    link: '#section1',
                    id: 'section1'
                },
                {
                    name: 'Produse',
                    link: '#section2',
                    id: 'section2'
                },
            ],
            productTableHeaders: [
                {
                    title: "Nr.",
                    key: "number",
                    align: "center",
                    show: false,
                    width: "100px",
                },
                {
                    title: "Cod articol",
                    key: "product_code",
                    align: "center",
                    show: true,
                    width: "140px",
                },
                {
                    title: "Nume articol",
                    key: "product_name",
                    align: "start",
                    show: true,
                },
                {
                    title: "Categorie",
                    key: "assigned_sub_category",
                    align: "center",
                    show: false,
                },
                {
                    title: "Cantitate",
                    key: "quantity",
                    align: "center",
                    show: true,
                    width: "130px",
                },
                {
                    title: "UM",
                    key: "measurement_unit",
                    align: "center",
                    show: true,
                    width: "70px",
                },
                {
                    title: "Preț listă cu TVA",
                    key: "price",
                    align: "end",
                    show: true,
                },
                {
                    title: "Discount",
                    key: "discount",
                    align: "center",
                    show: true,
                    width: "120px",
                },
                {
                    title: "Preț final cu TVA",
                    key: "final_price",
                    align: "end",
                    show: true,
                },
                {
                    title: "Valoare finală cu TVA",
                    key: "final_total_price",
                    align: "end",
                    show: true,
                },
            ],
            requirementsObj: {
                requirements: [],
            },
            plumbingProducts,
            resultedProducts: [],
            finalResultedProducts: [],
            products: [],
            availablePlumbingFittingProducts: [],
            offerType: {type: 'PLUMBING', productCategories: ['SANITARE']},
            selectedPlumbingItems: [],
        }
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            if (from.name === 'OfferPlumbing' && to.name !== 'OfferPlumbing' && this.isProductsChanged === true) { // Replace 'FormPage' with your form's route name
                const shouldLeave = this.checkFormBeforeLeave();
                if (!shouldLeave) {
                    next(false); // Prevent route change
                } else {
                    this.isProductsChanged = false;
                    next(); // Proceed with the route change
                }
            } else {
                next(); // Proceed with the route change for routes other than the form page
            }
        });
    },
    async mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks;
        const priceListItems = await this.fetchPriceListProducts();
        this.products = this.reassignSubCategories(this.setZeroQtyForBackendProducts(priceListItems.items));
        this.filterPlumbingFittingProducts();
    },
    methods: {
        /**
         * Handles the update of selected plumbing items by updating the selectedPlumbingItems array.
         * @param {Array} items - The updated array of selected plumbing items.
         */
        handleUpdateSelectedPlumbingItems(items) {
            this.selectedPlumbingItems = items;
        },
        /**
         * Filters the products array to find plumbing fitting products within a specific product code range
         * and assigns them to availablePlumbingFittingProducts.
         */
        filterPlumbingFittingProducts() {
            this.availablePlumbingFittingProducts = this.products.filter(product => {
                const productCode = product.product_code;
                const startCode = "702.05.45";
                const endCode = "702.71.45";
                return productCode >= startCode && productCode <= endCode;
            });
        },
        /**
         * Generates requirements for selected plumbing items by matching them with the products in plumbingProducts,
         * and pushes the requirements (including quantity) to the requirementsObj.
         */
        generateRequirementsForSelectedPlumbingItems() {
            // Clear previous requirements
            this.requirementsObj.requirements = [];

            // Iterate over selected plumbing items
            this.selectedPlumbingItems.forEach(selectedItem => {
                const matchingProduct = this.plumbingProducts.find(product => product.product_code === selectedItem.fitting);

                if (matchingProduct) {
                    // Create a requirement object using the product's specifications
                    const requirement = {...matchingProduct.specifications, quantity: selectedItem.quantity};

                    // Push the requirement to the requirementsObj
                    this.requirementsObj.requirements.push(requirement);
                }
            });

        },
        checkFormBeforeLeave() {
            return confirm('Sunteți sigur că doriți să părăsiți formularul? Modificările nesalvate vor fi pierdute.');
        },
        /**
         * Processes the required products based on the selected vacuum unit.
         * This method emits an event to trigger an overlay, then processes the requirements, calculates products
         * and matches the final resulted products.
         * Finally, it updates the offered products and emits an event to set showOnlyOfferQty in the ProductTable.
         */
        processRequiredProducts() {
            emitter.emit('trigger-overlay');
            setTimeout(async () => {
                this.$store.commit('emptyVmcErrorArray');
                this.generateRequirementsForSelectedPlumbingItems();
                this.resultedProducts = [];
                this.finalResultedProducts = [];
                this.recommendedProducts = [];

                this.getProducts(this.requirementsObj, JSON.parse(JSON.stringify(this.plumbingProducts)));

                this.processResultedProducts();
                this.finalResultedProducts = this.matchProducts(this.products, this.finalResultedProducts);
                this.addOrUpdateOfferedProducts();
                // // emitting event for setting showOnlyOfferQty on true in ProductTable
                emitter.emit('set-products-with-quantity-true');
            }, 550);
        },
    },
}
</script>