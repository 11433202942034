import {PDC_PRODUCT_TYPES} from "../../src/PDC/pdcProducts.js";

export function createPDCUnitRequirements(units) {
    let PDCUnitsRequirements = [];

    // Filter units where unitType.value is 2
    let filteredUnits = units.filter(unit => unit.selectedUnit && unit.selectedUnit.type === 2);

    // Each unit adds 10 pipes, only count filtered units
    let totalPipes = filteredUnits.length * 10;
    let MYYMCableQuantity = (totalPipes / 2) + 5; // Apply the given formula
    let blankPVCStripQuantity = Math.ceil((totalPipes / 5) / 2);

    let MYYMCable = {
        "type": PDC_PRODUCT_TYPES.CABLU,
        "subtype": "MYYM",
        "quantity": MYYMCableQuantity,
    };

    let blankPVCStrip = {
        "type": PDC_PRODUCT_TYPES.BANDA,
        "subtype": "neadeziva",
        "variant": "PVC",
        "quantity": blankPVCStripQuantity,
    };

    // Add the MYYMCable and blank pvc strip requirement to the PDCUnitsRequirements array
    if(totalPipes) {
        PDCUnitsRequirements.push(MYYMCable);
        PDCUnitsRequirements.push(blankPVCStrip);
    }

    return PDCUnitsRequirements;
}